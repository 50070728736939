import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {axiosBaseConfig} from "../data/url";
import {toast} from "react-toastify";

const initialState = {
    showExportDialog: false,
    allExportDetails: [],
    formingCoilsData: [],
    numberOfDoneLinks: 0,
    currentTime: 0,
    intervalHours: 0,
}

export const exportWelding = createAsyncThunk(
    'export/exportWelding',
    async (name, thunkAPI) => {
        try {
            const endpoint = '/export/welding/'
            const payload = {
                layer_number: name.layerNumber,
                export_type: "welding",
                export_wire_type: name.exportWireType,
                export_extension_type: name.exportExtensionType,
            }
            const response = await axiosBaseConfig.post(endpoint, payload);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('can not export welding');
        }
    }
);

export const exportWeldingPolyline = createAsyncThunk(
    'export/exportWeldingPolyline',
    async (name, thunkAPI) => {
        try {
            const endpoint = '/export/welding-polyline/'
            const payload = {
                layer_number: name.layerNumber,
                export_type: "welding_polyline",
                export_wire_type: name.exportWireType,
                export_extension_type: name.exportExtensionType,
            }
            const response = await axiosBaseConfig.post(endpoint, payload);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('can not export welding polyline');
        }
    }
);

export const exportGetFormingData = createAsyncThunk(
    'export/exportGetFormingData',
    async (name, thunkAPI) => {
        try {
            const endpoint = '/export/forming/'
            const response = await axiosBaseConfig.get(endpoint);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('can not get export forming data');
        }
    }
);

export const exportForming = createAsyncThunk(
    'export/exportForming',
    async (name, thunkAPI) => {
        try {
            const endpoint = '/export/forming/'
            const payload = {
                coil_name: parseInt(name.coilName),
                export_type: "forming",
                export_wire_type: name.exportWireType,
                export_extension_type: name.exportExtensionType,
            }
            const response = await axiosBaseConfig.post(endpoint, payload);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('can not export forming');
        }
    }
);

export const exportFormingPolyline = createAsyncThunk(
    'export/exportFormingPolyline',
    async (name, thunkAPI) => {
        try {
            const endpoint = '/export/forming-polyline/'
            const payload = {
                coil_name: parseInt(name.coilName),
                export_type: "forming_polyline",
                export_wire_type: name.exportWireType,
                export_extension_type: name.exportExtensionType,
            }
            const response = await axiosBaseConfig.post(endpoint, payload);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('can not export forming polyline');
        }
    }
);

export const exportPaper = createAsyncThunk(
    'export/exportPaper',
    async (name, thunkAPI) => {
        try {
            const endpoint = '/export/paper/'
            const payload = {
                export_type: "paper",
                export_wire_type: name.exportWireType,
                export_extension_type: name.exportExtensionType,
            }
            const response = await axiosBaseConfig.post(endpoint, payload);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('can not export paper');
        }
    }
);

export const exportStator = createAsyncThunk(
    'export/exportStator',
    async (name, thunkAPI) => {
        try {
            const endpoint = '/export/stator/'
            const payload = {
                export_type: "stator",
                export_wire_type: name.exportWireType,
                export_extension_type: name.exportExtensionType,
            }
            const response = await axiosBaseConfig.post(endpoint, payload);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('can not export stator');
        }
    }
);

export const exportAssembly = createAsyncThunk(
    'export/exportAssembly',
    async (name, thunkAPI) => {
        try {
            const endpoint = '/export/assembly/'
            const payload = {
                export_type: "assembly",
                export_wire_type: name.exportWireType,
                export_extension_type: name.exportExtensionType,
            }
            const response = await axiosBaseConfig.post(endpoint, payload);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('can not export assembly');
        }
    }
);

export const getCurrentTime = createAsyncThunk(
    'export/getCurrentTime',
    async (name, thunkAPI) => {
        try {
            const endpoint = '/design/get-current-time/'
            const response = await axiosBaseConfig.get(endpoint);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('can not get current time');
        }
    }
);

const exportSlice = createSlice({
    name: 'camera',
    initialState,
    reducers: {
        updateAllExportDetails: (state, {payload}) => {
            state.allExportDetails = payload
            const numberOfDoneItems = Object.values(payload).filter(item => item && item.state === 'done').length
            if (state.numberOfDoneLinks !== 0 && numberOfDoneItems !== state.numberOfDoneLinks) {
                toast.success("Export links updated")
            }
            state.numberOfDoneLinks = numberOfDoneItems
            console.log("state.numberOfDoneLinks")
            console.log(state.numberOfDoneLinks)
        },
        showExportDialogFunc: (state) => {
            state.showExportDialog = true
        },
        hideExportDialogFunc: (state) => {
            state.showExportDialog = false
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(exportWelding.pending, () => {
                console.log("welding export started")
            })
            .addCase(exportWelding.fulfilled, () => {
                toast.success("welding export started")
            })
            .addCase(exportWelding.rejected, (state, action) => {
                toast.error(action)
            })
            .addCase(exportWeldingPolyline.pending, () => {
                console.log("welding polyline export started")
            })
            .addCase(exportWeldingPolyline.fulfilled, () => {
                toast.success("welding polyline export started")
            })
            .addCase(exportWeldingPolyline.rejected, (state, action) => {
                toast.error(action)
            })
            .addCase(exportGetFormingData.pending, () => {
                console.log("getting forming export data")
            })
            .addCase(exportGetFormingData.fulfilled, (state, action) => {
                console.log("got forming export data")
                state.formingCoilsData = action.payload;
            })
            .addCase(exportGetFormingData.rejected, (state, action) => {
                toast.error(action)
            })
            .addCase(exportForming.pending, () => {
                console.log("forming export started")
            })
            .addCase(exportForming.fulfilled, () => {
                toast.success("forming export started")
            })
            .addCase(exportForming.rejected, (state, action) => {
                toast.error(action)
            })
            .addCase(exportFormingPolyline.pending, () => {
                console.log("forming polyline export started")
            })
            .addCase(exportFormingPolyline.fulfilled, () => {
                toast.success("forming polyline export started")
            })
            .addCase(exportFormingPolyline.rejected, (state, action) => {
                toast.error(action)
            })
            .addCase(exportPaper.pending, () => {
                console.log("paper export started")
            })
            .addCase(exportPaper.fulfilled, () => {
                toast.success("paper export started")
            })
            .addCase(exportPaper.rejected, (state, action) => {
                toast.error(action)
            })
            .addCase(exportStator.pending, () => {
                console.log("stator export started")
            })
            .addCase(exportStator.fulfilled, () => {
                toast.success("stator export started")
            })
            .addCase(exportStator.rejected, (state, action) => {
                toast.error(action)
            })
            .addCase(exportAssembly.pending, () => {
                console.log("assembly export started")
            })
            .addCase(exportAssembly.fulfilled, () => {
                toast.success("assembly export started")
            })
            .addCase(exportAssembly.rejected, (state, action) => {
                toast.error(action)
            })
            .addCase(getCurrentTime.pending, () => {
                console.log("getting current time")
            })
            .addCase(getCurrentTime.fulfilled, (state, action) => {
                console.log("got current time")
                state.currentTime = action.payload["current_time"]
                state.intervalHours = action.payload["interval_hours"]
            })
            .addCase(getCurrentTime.rejected, (state, action) => {
                toast.error(action)
            })
        ;
    },
});

export const {
    updateAllExportDetails,
    showExportDialogFunc,
    hideExportDialogFunc,
} = exportSlice.actions


export default exportSlice.reducer;
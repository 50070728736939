import {configureStore} from '@reduxjs/toolkit';
import {
    userSliceReducer,
    globalReducer,
    guideLayoutReducer,
    stlFilesSliceReducer,
    cameraSliceReducer,
    connectionTableSlice,
    formingSideCoilsSliceReducer,
    weldingSideCoilsSliceReducer,
    consoleLogSliceReducer,
    viewerSliceReducer,
    projectsSliceReducer,
    exportSliceReducer
} from './features'

// these variables come from src/features and any component can access them
// check documents for features folder
// we pass store in ./index.js
export const store = configureStore({
    reducer: {
        user: userSliceReducer,
        globalItems: globalReducer,
        guideLayout: guideLayoutReducer,
        stlParameters: stlFilesSliceReducer,
        camera: cameraSliceReducer,
        connectionTable: connectionTableSlice,
        formingSideCoils: formingSideCoilsSliceReducer,
        weldingSideCoils: weldingSideCoilsSliceReducer,
        consoleLog: consoleLogSliceReducer,
        viewer: viewerSliceReducer,
        projects: projectsSliceReducer,
        export: exportSliceReducer,
    },
});
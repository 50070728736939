import {createSlice} from '@reduxjs/toolkit';

// READ DOCUMENTS FOR FEATURES FILES
const initialState = {
    showGuideLayout: true,
    currentGif: null,
    currentGifTitle: null,
};

const guideLayoutSlice = createSlice({
    name: 'guideLayout',
    initialState,
    reducers: {
        unHideGuideLayout: (state) => {
            state.showGuideLayout = true
        },
        hideGuideLayout: (state) => {
            state.showGuideLayout = false
        },
        changeGif: (state, {payload}) => {
            state.currentGif = payload.currentGif
            state.currentGifTitle = payload.currentGifTitle
        },
    },
});

// console.log(cartSlice);
export const {unHideGuideLayout, hideGuideLayout, changeGif} =
    guideLayoutSlice.actions;

export default guideLayoutSlice.reducer;

// default view for stator design
export const statorDesignCameraView = {
    meshPositionDescription: "stl-with-stator",
    enableRotate: true,
    fov: 2,
    view1: {
        cameraXPosition: 0,
        cameraYPosition: -405,
        cameraZPosition: 570,
        cameraXUp: 0,
        cameraYUp: 1,
        cameraZUp: 0,
        controlsXPosition: 0,
        controlsYPosition: 0,
        controlsZPosition: 0,
        zoom: 0.05,
    },
    view2: {
        cameraXPosition: 0,
        cameraYPosition: -700,
        cameraZPosition: 0,
        cameraXUp: 0,
        cameraYUp: 0,
        cameraZUp: 1,
        controlsXPosition: 0,
        controlsYPosition: 0,
        controlsZPosition: 0,
        zoom: 0.05,
    },
    view3: {
        cameraXPosition: 0,
        cameraYPosition: 0,
        cameraZPosition: 700,
        cameraXUp: 0,
        cameraYUp: 1,
        cameraZUp: 0,
        controlsXPosition: 0,
        controlsYPosition: 0,
        controlsZPosition: 0,
        zoom: 0.05,
    },
    view4: {
        cameraXPosition: 0,
        cameraYPosition: 0,
        cameraZPosition: -700,
        cameraXUp: 0,
        cameraYUp: 1,
        cameraZUp: 0,
        controlsXPosition: 0,
        controlsYPosition: 0,
        controlsZPosition: 0,
        zoom: 0.05,
    },
};

// default view for stator slot design
export const statorSlotDesignCameraView = {
    meshPositionDescription: "based-on-bore-diameter-stator-slot",
    enableRotate: false,
    fov: 2,
    view1: {
        cameraXPosition: 0,
        cameraYPosition: 0,
        cameraZPosition: 700,
        cameraXUp: 0,
        cameraYUp: 1,
        cameraZUp: 0,
        controlsXPosition: 0,
        controlsYPosition: 0,
        controlsZPosition: 0,
        zoom: 0.65,
    },
    view2: {
        cameraXPosition: 0,
        cameraYPosition: -700,
        cameraZPosition: 0,
        cameraXUp: 0,
        cameraYUp: 0,
        cameraZUp: 1,
        controlsXPosition: 0,
        controlsYPosition: 0,
        controlsZPosition: 0,
        zoom: 0.05,
    },
    view3: {
        cameraXPosition: 0,
        cameraYPosition: 0,
        cameraZPosition: 700,
        cameraXUp: 0,
        cameraYUp: 1,
        cameraZUp: 0,
        controlsXPosition: 0,
        controlsYPosition: 0,
        controlsZPosition: 0,
        zoom: 0.05,
    },
    view4: {
        cameraXPosition: 0,
        cameraYPosition: 0,
        cameraZPosition: -700,
        cameraXUp: 0,
        cameraYUp: 1,
        cameraZUp: 0,
        controlsXPosition: 0,
        controlsYPosition: 0,
        controlsZPosition: 0,
        zoom: 0.05,
    },
};

// default view for forming side
export const formingSideCameraView = {
    meshPositionDescription: "stl-with-stator",
    enableRotate: true,
    fov: 2,
    view1: {
        cameraXPosition: 0,
        cameraYPosition: -405,
        cameraZPosition: -570,
        cameraXUp: 0,
        cameraYUp: 1,
        cameraZUp: 0,
        controlsXPosition: 0,
        controlsYPosition: 0,
        controlsZPosition: 0,
        zoom: 0.05,
    },
    view2: {
        cameraXPosition: 0,
        cameraYPosition: -700,
        cameraZPosition: 0,
        cameraXUp: 0,
        cameraYUp: 0,
        cameraZUp: 1,
        controlsXPosition: 0,
        controlsYPosition: 0,
        controlsZPosition: 0,
        zoom: 0.05,
    },
    view3: {
        cameraXPosition: 0,
        cameraYPosition: 0,
        cameraZPosition: 700,
        cameraXUp: 0,
        cameraYUp: 1,
        cameraZUp: 0,
        controlsXPosition: 0,
        controlsYPosition: 0,
        controlsZPosition: 0,
        zoom: 0.05,
    },
    view4: {
        cameraXPosition: 0,
        cameraYPosition: 0,
        cameraZPosition: -700,
        cameraXUp: 0,
        cameraYUp: 1,
        cameraZUp: 0,
        controlsXPosition: 0,
        controlsYPosition: 0,
        controlsZPosition: 0,
        zoom: 0.05,
    },
};

//default view for optimisation view
export const optimisationOptionsCameraView = {
    meshPositionDescription: "stl-with-stator",
    enableRotate: true,
    fov: 2,
    view1: {
        cameraXPosition: 0,
        cameraYPosition: -405,
        cameraZPosition: 570,
        cameraXUp: 0,
        cameraYUp: 1,
        cameraZUp: 0,
        controlsXPosition: 0,
        controlsYPosition: 0,
        controlsZPosition: 0,
        zoom: 0.04,
    },
    view2: {
        cameraXPosition: 0,
        cameraYPosition: -700,
        cameraZPosition: 0,
        cameraXUp: 0,
        cameraYUp: 0,
        cameraZUp: 1,
        controlsXPosition: 0,
        controlsYPosition: 0,
        controlsZPosition: 0,
        zoom: 0.05,
    },
    view3: {
        cameraXPosition: 0,
        cameraYPosition: 0,
        cameraZPosition: 700,
        cameraXUp: 0,
        cameraYUp: 1,
        cameraZUp: 0,
        controlsXPosition: 0,
        controlsYPosition: 0,
        controlsZPosition: 0,
        zoom: 0.05,
    },
    view4: {
        cameraXPosition: 0,
        cameraYPosition: 0,
        cameraZPosition: -700,
        cameraXUp: 0,
        cameraYUp: 1,
        cameraZUp: 0,
        controlsXPosition: 0,
        controlsYPosition: 0,
        controlsZPosition: 0,
        zoom: 0.05,
    },
};
import {Navigate} from "react-router-dom";
import {useSelector} from "react-redux";

const ProjectRoute = ({children}) => {
    const {userToken, username} = useSelector((store) => store.user);

    // here we check user token, if there is no token, we send user to login page
    if (!userToken || !username) {
        return <Navigate to='/login'/>
    }
    return children
};

export default ProjectRoute
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {axiosBaseConfig} from "../data/url";

// READ DOCUMENTS FOR FEATURES FILES
const initialState = {
    tagSide: null,
    numberOfPhases: 1,
    numberOfParallelPath: 1,
    showBusbarsDialog: true,
    showNumberOfPhasesDialog: true,
    showDefinePattern: false,
    showAddCoil: false,
};

export const getConnectionTableParameters = createAsyncThunk(
    'stlFiles/getConnectionTableParameters',
    async (name, thunkAPI) => {
        try {
            const endpoint = 'design/connection-table-parameters/'
            const response = await axiosBaseConfig.get(endpoint);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('can not get connection table parameters');
        }
    }
);

export const changeTagSideParameters = createAsyncThunk(
    'stlFiles/changeTagSideParameters',
    async (name, thunkAPI) => {
        try {
            const endpoint = 'design/connection-table-parameters/'
            const payload = {
                tag_side: name["tagSide"]
            }
            const response = await axiosBaseConfig.post(endpoint, payload);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('can not change connection table tag side');
        }
    }
);

export const changeNumberOfPhasesAndParallelPathParameters = createAsyncThunk(
    'stlFiles/changeNumberOfPhasesAndParallelPathParameters',
    async (name, thunkAPI) => {
        try {
            const endpoint = 'design/connection-table-parameters/'
            const payload = {
                number_of_phases: name["numberOfPhases"],
                number_of_parallel_path: name["numberOfParallelPath"]
            }
            const response = await axiosBaseConfig.post(endpoint,payload);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('can not change connection table phases and parallel path');
        }
    }
);

const connectionTableSlice = createSlice({
    name: 'connectionTable',
    initialState,
    reducers: {
        setTagSide: (state, {payload}) => {
            state.tagSide = payload.tagSide
        },
        setNumberOfPhasesAndParallelPath: (state, {payload}) => {
            state.numberOfPhases = payload.numberOfPhases
            state.numberOfParallelPath = payload.numberOfParallelPath
        },
        closeBusbarsDialog: (state) => {
            state.showBusbarsDialog = false
        },
        openBusbarsDialog: (state) => {
            state.showBusbarsDialog = true
        },
        closeNumberOfPhasesDialog: (state) => {
            state.showNumberOfPhasesDialog = false
        },
        openNumberOfPhasesDialog: (state) => {
            state.showNumberOfPhasesDialog = true
        },
        closeDefinePattern: (state) => {
            state.showDefinePattern = false
        },
        openDefinePattern: (state) => {
            state.showDefinePattern = true
        },
        closeAddCoil: (state) => {
            state.showAddCoil = false
        },
        openAddCoil: (state) => {
            state.showAddCoil = true
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getConnectionTableParameters.pending, () => {
                console.log("getting connection table parameters")
            })
            .addCase(getConnectionTableParameters.fulfilled, (state, action) => {
                state.tagSide = action.payload["tag_side"]
                state.numberOfPhases = action.payload["number_of_phases"]
                state.numberOfParallelPath = action.payload["number_of_parallel_path"]
                state.showBusbarsDialog = action.payload["show_busbars_dialog"]
                state.showNumberOfPhasesDialog = action.payload["show_show_number_of_phases_dialog"]
            })
            .addCase(getConnectionTableParameters.rejected, (state, action) => {
                console.log(action);
            })
            .addCase(changeTagSideParameters.pending, () => {
                console.log("changing connection table tag side")
            })
            .addCase(changeTagSideParameters.fulfilled, (state, action) => {
                state.tagSide = action.payload["tag_side"]
                state.numberOfPhases = action.payload["number_of_phases"]
                state.numberOfParallelPath = action.payload["number_of_parallel_path"]
                state.showBusbarsDialog = action.payload["show_busbars_dialog"]
                state.showNumberOfPhasesDialog = action.payload["show_show_number_of_phases_dialog"]
            })
            .addCase(changeTagSideParameters.rejected, (state, action) => {
                console.log(action);
            })
            .addCase(changeNumberOfPhasesAndParallelPathParameters.pending, () => {
                console.log("changing connection table phases and parallel path")
            })
            .addCase(changeNumberOfPhasesAndParallelPathParameters.fulfilled, (state, action) => {
                state.tagSide = action.payload["tag_side"]
                state.numberOfPhases = action.payload["number_of_phases"]
                state.numberOfParallelPath = action.payload["number_of_parallel_path"]
                state.showBusbarsDialog = action.payload["show_busbars_dialog"]
                state.showNumberOfPhasesDialog = action.payload["show_show_number_of_phases_dialog"]
            })
            .addCase(changeNumberOfPhasesAndParallelPathParameters.rejected, (state, action) => {
                console.log(action);
            });
    },
});

// console.log(cartSlice);
export const {
    setTagSide,
    setNumberOfPhasesAndParallelPath,
    closeBusbarsDialog,
    closeNumberOfPhasesDialog,
    closeDefinePattern,
    closeAddCoil,
    openBusbarsDialog,
    openNumberOfPhasesDialog,
    openAddCoil,
    openDefinePattern
} =
    connectionTableSlice.actions;

export default connectionTableSlice.reducer;

import styled from 'styled-components'
import {Viewer, ViewerState} from "./index";
import {
    VIEWER_STATE_ASSEMBLY,
    VIEWER_STATE_FORMING_SIDE,
    VIEWER_STATE_SINGLE_SLOT,
    VIEWER_STATE_STANDARD,
    // VIEWER_STATE_STATOR_CORE_LAMINATED,
    VIEWER_STATE_STATOR_CORE_SOLID, VIEWER_STATE_WELDING_SIDE
} from "../data/Viewer";
import {
    INPUT_FORMING_SIDE, INPUT_OPTIMISATION_OPTIONS,
    INPUT_STATOR_DESIGN,
    INPUT_STATOR_SLOT_DESIGN,
    INPUT_WELDING_SIDE_DESIGN
} from "../data/states";
import {toast} from "react-toastify";
import {
    useSelector
} from "react-redux";
import {LoadingDialog} from "../fields/dialogs";
import FormingLegend from "../fields/viewer/FormingLegend";

const MultipleElements = () => {
    // const dispatch = useDispatch();
    const {viewerState} = useSelector((store) => store.viewer);
    const {inputState} = useSelector((store) => store.globalItems);
    const {
        isLoading,
        statorStl,
        statorSlotStl,
        papers3DStl,
        papers2DStl,
        wireSlotStl,
        wireInsulationSlotStl,
        weldingSideStl,
        weldingOptimisedStl,
    } = useSelector((store) => store.stlParameters);
    const {
        formingSideStl,
        formingSideCoils,
        isLoading: formingSideIsLoading,
    } = useSelector((store) => store.formingSideCoils);
    const {
        statorDesignCameraData,
        statorSlotDesignCameraData,
        weldingSideDesignCameraData,
        formingSideCameraData,
        optimisationCameraData,
        stateStatorCoreSolidCameraData,
        stateSingleSlotCameraData,
        stateWeldingSideCameraData,
        stateFormingSideCameraData,
        stateAssemblyCameraData,
        currentViewId
    } = useSelector((store) => store.camera);

    const generateStl = () => {
        try {
            // if (canGenerateView){
            //     setCanGenerateView(false)
                if (viewerState === VIEWER_STATE_STANDARD) {
                    if (inputState === INPUT_STATOR_DESIGN) {
                        if (statorStl !== null && papers3DStl !== null) {
                            return {
                                inputData: [[[statorStl], 0xffffff]],
                                cameraData: statorDesignCameraData,
                                showNumbers: true,
                                is2DView: false
                            }
                        }

                    }
                    if (inputState === INPUT_STATOR_SLOT_DESIGN) {
                        if (statorSlotStl !== null && wireSlotStl !== null && wireInsulationSlotStl !== null && papers2DStl !== null) {
                            return {
                                inputData: [[[statorSlotStl], 0xffffff], [[wireSlotStl], 0xC6683A], [[wireInsulationSlotStl], 0x983D0F], [[papers2DStl], 0x09E0C3]],
                                cameraData: statorSlotDesignCameraData,
                                showNumbers: false,
                                is2DView: true
                            }
                        }
                    }
                    if (inputState === INPUT_WELDING_SIDE_DESIGN) {
                        if (statorStl !== null && papers3DStl !== null && weldingSideStl !== null) {
                            return {
                                inputData: [[[statorStl], 0xffffff], [weldingSideStl, 0xC6683A, "shouldRotate"]],
                                cameraData: weldingSideDesignCameraData,
                                showNumbers: true,
                                is2DView: false
                            }
                        }
                    }
                    if (inputState === INPUT_FORMING_SIDE) {
                        if (formingSideStl !== null) {
                            return {
                                inputData: [[[formingSideStl], 0xC6683A]],
                                cameraData: formingSideCameraData,
                                showNumbers: false,
                                is2DView: false
                            }
                        }
                    }
                    if (inputState === INPUT_OPTIMISATION_OPTIONS) {
                        console.log(weldingOptimisedStl)
                        if (statorStl !== null && papers3DStl !== null && weldingOptimisedStl !== null) {
                            return {
                                inputData: [[[statorStl], 0xffffff], [[papers3DStl], 0x09E0C3], [[weldingOptimisedStl], 0xC6683A, "shouldRotate"]],
                                cameraData: optimisationCameraData,
                                showNumbers: true,
                                is2DView: false
                            }
                        } else if (statorStl !== null && papers3DStl !== null) {
                            return {
                                inputData: [[[statorStl], 0xffffff], [[papers3DStl], 0x09E0C3]],
                                cameraData: optimisationCameraData,
                                showNumbers: true,
                                is2DView: false
                            }
                        }
                    }
                }
                if (viewerState === VIEWER_STATE_STATOR_CORE_SOLID) {
                    if (statorStl !== null) {
                        return {
                            inputData: [[[statorStl], 0xffffff]],
                            cameraData: stateStatorCoreSolidCameraData,
                            showNumbers: true,
                            is2DView: false
                        }
                    }
                }
                if (viewerState === VIEWER_STATE_SINGLE_SLOT) {
                    if (statorSlotStl !== null && wireSlotStl !== null && wireInsulationSlotStl !== null && papers2DStl !== null) {
                        return {
                            inputData: [[[statorSlotStl], 0xffffff], [[wireSlotStl], 0xC6683A], [[wireInsulationSlotStl], 0x983D0F], [[papers2DStl], 0x09E0C3]],
                            cameraData: stateSingleSlotCameraData,
                            showNumbers: false,
                            is2DView: true
                        }
                    }
                }
                if (viewerState === VIEWER_STATE_WELDING_SIDE) {
                    if (statorStl !== null && papers3DStl !== null && weldingSideStl !== null) {
                        return {
                            inputData: [[[statorStl], 0xffffff], [[papers3DStl], 0x09E0C3], [weldingSideStl, 0xC6683A, "shouldRotate"]],
                            cameraData: stateWeldingSideCameraData,
                            showNumbers: true,
                            is2DView: false
                        }
                    }
                }
                if (viewerState === VIEWER_STATE_FORMING_SIDE) {
                    if (statorStl !== null && formingSideStl !== null) {
                        return {
                            inputData: [[[statorStl], 0xffffff], [formingSideStl, 0xC6683A, "shouldRotateForming"]],
                            cameraData: stateFormingSideCameraData,
                            showNumbers: true,
                            is2DView: false
                        }
                    }
                }
                if (viewerState === VIEWER_STATE_ASSEMBLY) {
                    if (statorStl !== null && papers3DStl !== null && weldingSideStl !== null && formingSideStl !== null) {
                        return {
                            inputData: [[[statorStl], 0xffffff], [[papers3DStl], 0x09E0C3], [weldingSideStl, 0xC6683A, "shouldRotate"], [formingSideStl, 0xC6683A, "shouldRotateForming"]],
                            cameraData: stateAssemblyCameraData,
                            showNumbers: true,
                            is2DView: false
                        }
                    }
                }
                return {
                    inputData: [],
                    cameraData: {},
                    showNumbers: false,
                    is2DView: true
                }
            // }
            // return {
            //     inputData: [],
            //     cameraData: {},
            //     showNumbers: false,
            //     is2DView: true
            // }
        } catch (e) {
            console.log(e)
            toast.error("Input is wrong")
        }
    }
    const formingColors = [0x646b40, 0x93c3b6, 0xf3cd54, 0x9379ff, 0xa84489, 0xa0bde1,
        0x33574e, 0xc9d895, 0xe6b155, 0xd35460, 0xdda7b5, 0x354d96]


    const values = generateStl();
    const showNumbers = values.showNumbers
    const is2DView = values.is2DView
    const inputData = values.inputData;
    const cameraData = values.cameraData;
    // const keyData = {...inputData};
    // const viewerKey = JSON.stringify(keyData);
    const layerLegend = formingSideCoils.map(formingCoil => ({
        number: formingCoil["coil_name"],
        color: formingColors[(formingCoil["coil_name"] - 1) % 12],
    }));

    return (<StyledWrapper>
        {(isLoading !== 0 || formingSideIsLoading !== 0) && <LoadingDialog/>}
        <Viewer inputData={inputData} cameraData={cameraData} currentViewIdInput={currentViewId}
                showNumbers={showNumbers} is2DView={is2DView} inputState={inputState} viewerState={viewerState}/>
        {viewerState === VIEWER_STATE_FORMING_SIDE && <FormingLegend layersList={layerLegend}/>}
        <ViewerState/>
    </StyledWrapper>);
};

const StyledWrapper = styled.div`
`

export default MultipleElements;

import styled from "styled-components";
import {useSelector} from "react-redux";
import {INPUT_FORMING_SIDE} from "../../data/states";

// here we added a legend in viewer “only” when we are in viewer state === forming_side state
// you can see this condition in src/components/viewer.js where we call FormingLegend
// number of wires are  based on number of forming coils
const FormingLegend = ({layersList}) => {
    const {inputState} = useSelector((store) => store.globalItems);
    return (
        <StyledWrapper left_position={inputState !== INPUT_FORMING_SIDE ? '70vw' : '92vw'}>
            {layersList.slice().sort((a, b) => a.number - b.number).map((layer, index) => {
                return <div key={index} className="legend-line">
                    <p>Wire {(layer["number"]).toString().padStart(2, '0')}</p>
                    <div style={{background: `#${layer["color"].toString(16)}`}} className="line-color"></div>
                </div>
            })}
        </StyledWrapper>
    )
}

const StyledWrapper = styled.div`
    position: absolute;
    top: 10vh;
    left: ${(props) => props.left_position || '70vw'};
    background: transparent;
    text-align: center;
    justify-items: center;
    color: var(--clr-grey-9);
    padding: 2vh;
    height: 20vh;
    overflow: auto;
    font-size: var(--font-size-2);

    .legend-line {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .line-color {
        width: 2vw; /* Length of the line */
        height: 5px; /* Thickness of the line */
        margin-left: 0.5vw;
        border-radius: 4px;
    }
`
export default FormingLegend;

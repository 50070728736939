import {createSlice} from '@reduxjs/toolkit';
import {
    NAVBAR_STATOR,
    NAVBAR_WINDING_SIDE,
    INPUT_STATOR_DESIGN,
    NAVBAR_CONNECTION_TABLE,
    INPUT_CONNECTION_TABLE_FORMING_SIDE_DESIGN,
    NAVBAR_OPTIMISATION,
    INPUT_OPTIMISATION_OPTIONS,
    // INPUT_WIRE_DESIGN,
    NAVBAR_FORMING_SIDE,
    INPUT_FORMING_SIDE,
    INPUT_WELDING_SIDE_DESIGN
} from "../data/states";

// READ DOCUMENTS FOR FEATURES FILES
const initialState = {
    navbarState: NAVBAR_STATOR,
    inputState: INPUT_STATOR_DESIGN,
};

const globalSlice = createSlice({
    name: 'globalItems',
    initialState,
    reducers: {
        changeNavbarState: (state, {payload}) => {
            state.navbarState = payload.navbarState
            if (state.navbarState === NAVBAR_STATOR) {
                state.inputState = INPUT_STATOR_DESIGN
            }
            if (payload.navbarState === NAVBAR_WINDING_SIDE) {
                state.inputState = INPUT_WELDING_SIDE_DESIGN
            }
            if (payload.navbarState === NAVBAR_CONNECTION_TABLE) {
                state.inputState = INPUT_CONNECTION_TABLE_FORMING_SIDE_DESIGN
            }
            if (payload.navbarState === NAVBAR_FORMING_SIDE) {
                state.inputState = INPUT_FORMING_SIDE
            }
            if (payload.navbarState === NAVBAR_OPTIMISATION) {
                state.inputState = INPUT_OPTIMISATION_OPTIONS
            }
        },
        changeInputState: (state, {payload}) => {
            state.inputState = payload.inputState
        },
    },
});

// console.log(cartSlice);
export const {changeNavbarState, changeInputState} =
    globalSlice.actions;

export default globalSlice.reducer;

import {fitScreenIcon} from "../../icons/actionbar";
// import {useDispatch} from "react-redux";


const StatorIcons = ({fitScreenFunc}) => {
    // const dispatch = useDispatch();

    return (
        <>
            {/*/!*{isIconAvailable && <img src={isometricViewIcon} alt="isometricView"*!/*/}
            {/*/!*                           title="Isometric View"*!/*/}
            {/*/!*                           onClick={() => {*!/*/}
            {/*/!*                               dispatch(toggleParallelView());*!/*/}
            {/*/!*                           }}/>}*!/*/}
            {/*/!*{isIconAvailable && <img src={yzViewIcon} alt="yzView"*!/*/}
            {/*/!*                           title="Y-Z View"*!/*/}
            {/*/!*                           onClick={() => {*!/*/}
            {/*/!*                               dispatch(setYZView());*!/*/}
            {/*/!*                           }}/>}*!/*/}
            {/*/!*{isIconAvailable && <img src={xzViewIcon} alt="xzView"*!/*/}
            {/*/!*                           title="X-Z View"*!/*/}
            {/*/!*                           onClick={() => {*!/*/}
            {/*/!*                               dispatch(setXZView());*!/*/}
            {/*/!*                           }}/>}*!/*/}
            {/*{isIconAvailable && <img src={xyViewIcon} alt="xyView"*/}
            {/*                           title="X-Y View"*/}
            {/*                           onClick={() => {*/}
            {/*                               dispatch(setXYView());*/}
            {/*                           }}/>}*/}
            <img src={fitScreenIcon} alt="fitScreen"
                 title="Fit Screen"
                 onClick={() => {
                     fitScreenFunc();
                 }}/>
            <hr/>
        </>
    );
};


export default StatorIcons;

import {useDispatch, useSelector} from "react-redux";
import {
    setStlParameters, getAllStlFiles
} from "../../../features/stlFilesSlice";
import {restoreDefaultIcon} from "../../../icons/inputLayout";
import {TextInput, TowButtonInput} from "../components";
import {
    WIDENING_GAP_INPUT,
    STATOR_WIRE_OVERHANG_INPUT,
    ISOLATED_WIRE_END_LENGTH_INPUT,
    STRIPPED_WIRE_LENGTH_INPUT,
    COIL_HEIGHT_INPUT,
    windingSideDefaultValues, SPAN_INPUT,
    WELDING_WIRE_HEIGHT_INPUT,
} from "../../../data/windingSideInputs";
import {
    isolatedWireEndLengthGif,
    statorWireOverhangGif,
    strippedWireLengthGif,
    wideningGapGif,
    // spanGif
} from "../../../icons/inputLayout/gif/weldingSideDesign";
import {useEffect, useState} from "react";
import {
    VALIDATE_SPAN_BIGGER_THAN_NUMBER_OF_SLOT,
    VALIDATE_WIDENING_GAP_BIGGER_THAN_THREE
} from "../../../data/validatonErrors";
import {WarningDialog} from "../../dialogs";
import {
    NUMBER_OF_SLOT_INPUT,
    PAPER_OVERHANG_LENGTH_INPUT,
    PAPER_OVERHANG_LENGTH_FORMING_INPUT,
    PAPER_OVERHANG_LENGTH_WELDING_INPUT,
    statorDefaultValues,
    OVERHANG_SAME_OR_DIFFERENT_SAME,
    OVERHANG_SAME_OR_DIFFERENT_DIFFERENT,
} from "../../../data/statorInputs";


const WireDesignInputs = () => {
    const dispatch = useDispatch();
    const [validationError, setValidationError] = useState("")
    const stlParameters = useSelector((store) => store.stlParameters);
    const {
        span,
        widening_gap,
        stator_wire_overhang,
        isolated_wire_end_length,
        stripped_wire_length,
        coil_height,
        paper_overhang_length,
        paper_overhang_length_forming,
        paper_overhang_length_welding,
        overhangSameOrDifferent
    } = useSelector((store) => store.stlParameters);
    const [wideningGapInput, setWideningGapInput] = useState(stlParameters[WIDENING_GAP_INPUT])
    const [statorWireOverhangInput, setStatorWireOverhangInput] = useState(stlParameters[STATOR_WIRE_OVERHANG_INPUT])
    const [isolatedWireEndLengthInput, setIsolatedWireEndLengthInput] = useState(stlParameters[ISOLATED_WIRE_END_LENGTH_INPUT])
    const [strippedWireLengthInput, setStrippedWireLengthInput] = useState(stlParameters[STRIPPED_WIRE_LENGTH_INPUT])
    const [coilHeightInput, setCoilHeightInput] = useState(stlParameters[COIL_HEIGHT_INPUT])
    const [spanInput, setSpanInput] = useState(stlParameters[SPAN_INPUT])
    const [paperOverhangLengthInput, setPaperOverhangLengthInput] = useState(stlParameters[PAPER_OVERHANG_LENGTH_INPUT])
    const [paperOverhangLengthFormingInput, setPaperOverhangLengthFormingInput] = useState(stlParameters[PAPER_OVERHANG_LENGTH_INPUT])
    const [paperOverhangLengthWeldingInput, setPaperOverhangLengthWeldingInput] = useState(stlParameters[PAPER_OVERHANG_LENGTH_INPUT])
    const towButtonEnableStyle = {background: "var(--clr-grey-6)", color: "var(--clr-grey-9)"}
    const towButtonDisableStyle = {background: "var(--clr-grey-5)", color: "var(--clr-grey-6)"}

    // we set inputs from redux values
    useEffect(() => {
        setWideningGapInput(widening_gap)
        setStatorWireOverhangInput(stator_wire_overhang)
        setIsolatedWireEndLengthInput(isolated_wire_end_length)
        setStrippedWireLengthInput(stripped_wire_length)
        setCoilHeightInput(coil_height)
        setSpanInput(span)
        setPaperOverhangLengthInput(paper_overhang_length_welding)
        setPaperOverhangLengthFormingInput(paper_overhang_length_forming)
        setPaperOverhangLengthWeldingInput(paper_overhang_length_welding)
        dispatch(setStlParameters({
            overhangSameOrDifferent: paper_overhang_length_forming === paper_overhang_length_welding ?
                OVERHANG_SAME_OR_DIFFERENT_SAME : OVERHANG_SAME_OR_DIFFERENT_DIFFERENT,
        }))
        console.log("welding side design parameters changed")
    }, [widening_gap,
        stator_wire_overhang,
        isolated_wire_end_length,
        stripped_wire_length,
        coil_height,
        paper_overhang_length,
        paper_overhang_length_forming,
        paper_overhang_length_welding,
        span,
        dispatch])


    // we check validations here
    const validations = (wideningGap, isolatedWireEndLength, strippedWireLength, span) => {
        if (span > stlParameters[NUMBER_OF_SLOT_INPUT]) {
            return VALIDATE_SPAN_BIGGER_THAN_NUMBER_OF_SLOT
        }
        if (wideningGap > 10) {
            return VALIDATE_WIDENING_GAP_BIGGER_THAN_THREE
        }
        return false
    }

    // we send new inputs to backend here and update stl files
    const setParameters = (wideningGap, statorWireOverhang, isolatedWireEndLength, strippedWireLength, span, coilHeight,
                           paperOverhangLength, paperOverhangLengthForming, paperOverhangLengthWelding) => {

        const checkValidations = validations(wideningGap, isolatedWireEndLength, strippedWireLength, span)
        if (checkValidations) {
            setValidationError(checkValidations)
            return
        }
        dispatch(setStlParameters({
            [WIDENING_GAP_INPUT]: wideningGap,
            [STATOR_WIRE_OVERHANG_INPUT]: statorWireOverhang,
            [ISOLATED_WIRE_END_LENGTH_INPUT]: isolatedWireEndLength,
            [STRIPPED_WIRE_LENGTH_INPUT]: strippedWireLength,
            [COIL_HEIGHT_INPUT]: coilHeight,
            [SPAN_INPUT]: span,
        }))
        if (overhangSameOrDifferent === OVERHANG_SAME_OR_DIFFERENT_SAME) {
            dispatch(setStlParameters({
                [PAPER_OVERHANG_LENGTH_INPUT]: paperOverhangLength,
                [PAPER_OVERHANG_LENGTH_FORMING_INPUT]: paperOverhangLength,
                [PAPER_OVERHANG_LENGTH_WELDING_INPUT]: paperOverhangLength,
            }))
            setPaperOverhangLengthFormingInput(paperOverhangLength)
            setPaperOverhangLengthWeldingInput(paperOverhangLength)
        } else {
            dispatch(setStlParameters({
                [PAPER_OVERHANG_LENGTH_FORMING_INPUT]: paperOverhangLengthForming,
                [PAPER_OVERHANG_LENGTH_WELDING_INPUT]: paperOverhangLengthWelding,
            }))
        }
        dispatch(getAllStlFiles())
    }

    // we call setParameters when user pressed enter
    const handleOnEnterPressed = (event) => {
        if (event.key === 'Enter') {
            setParameters(parseFloat(wideningGapInput),
                parseFloat(statorWireOverhangInput),
                parseFloat(isolatedWireEndLengthInput),
                parseInt(strippedWireLengthInput),
                parseInt(spanInput),
                parseFloat(coilHeightInput),
                parseFloat(paperOverhangLengthInput),
                parseFloat(paperOverhangLengthFormingInput),
                parseFloat(paperOverhangLengthWeldingInput),
            )
        }
    }

    // this function add background to selected TowButtonInput
    const overhangSameOrDifferentStyle = () => {
        if (overhangSameOrDifferent === OVERHANG_SAME_OR_DIFFERENT_SAME) {
            return [towButtonEnableStyle, towButtonDisableStyle]
        }
        return [towButtonDisableStyle, towButtonEnableStyle]
    }

    return (
        <>
            {validationError && <WarningDialog message={validationError} closeFunc={() => {
                setValidationError("")
            }}/>}
            <div className="title">
                <span>Properties</span>
                <div className="restore-default-component" onClick={() => {
                    // restore default change inputs and redux values based on values in src/data/statorInputs.js
                    // setParameters(windingSideDefaultValues[WIDENING_GAP_INPUT],
                    //     windingSideDefaultValues[STATOR_WIRE_OVERHANG_INPUT],
                    //     windingSideDefaultValues[ISOLATED_WIRE_END_LENGTH_INPUT],
                    //     windingSideDefaultValues[STRIPPED_WIRE_LENGTH_INPUT],
                    //     windingSideDefaultValues[SPAN_INPUT],
                    //     windingSideDefaultValues[COIL_HEIGHT_INPUT],
                    //     statorDefaultValues[PAPER_OVERHANG_LENGTH_INPUT],
                    //     statorDefaultValues[PAPER_OVERHANG_LENGTH_FORMING_INPUT],
                    //     statorDefaultValues[PAPER_OVERHANG_LENGTH_WELDING_INPUT],)
                    setWideningGapInput(windingSideDefaultValues[STATOR_WIRE_OVERHANG_INPUT])
                    setStatorWireOverhangInput(windingSideDefaultValues[STATOR_WIRE_OVERHANG_INPUT])
                    setIsolatedWireEndLengthInput(windingSideDefaultValues[ISOLATED_WIRE_END_LENGTH_INPUT])
                    setStrippedWireLengthInput(windingSideDefaultValues[STRIPPED_WIRE_LENGTH_INPUT])
                    setCoilHeightInput(windingSideDefaultValues[COIL_HEIGHT_INPUT])
                    setSpanInput(windingSideDefaultValues[SPAN_INPUT])
                    setPaperOverhangLengthInput(statorDefaultValues[PAPER_OVERHANG_LENGTH_INPUT])
                    setPaperOverhangLengthFormingInput(statorDefaultValues[PAPER_OVERHANG_LENGTH_FORMING_INPUT])
                    setPaperOverhangLengthWeldingInput(statorDefaultValues[PAPER_OVERHANG_LENGTH_WELDING_INPUT])
                }}>
                    <span className="restore-default">Restore Default</span>
                    <img src={restoreDefaultIcon} alt="restoreDefaultIcon"/>
                </div>
            </div>
            <div className="inputs">
                <form>
                    {/*<TextInput id={SPAN_INPUT} text="Span" gifFile={spanGif} value={spanInput} inputType={'int'}*/}
                    {/*           onChangeFunc={setSpanInput} onEnterPressedFunc={handleOnEnterPressed} unitText="No."/>*/}
                    <TowButtonInput labelInput="Overhang Length" firstButtonText="Same" secondButtonText="Different"
                                    firsButtonFunc={() => {
                                        dispatch(setStlParameters({
                                            overhangSameOrDifferent: OVERHANG_SAME_OR_DIFFERENT_SAME
                                        }))
                                    }}
                                    secondButtonFunc={() => {
                                        dispatch(setStlParameters({
                                            overhangSameOrDifferent: OVERHANG_SAME_OR_DIFFERENT_DIFFERENT
                                        }))
                                    }}
                                    getStyles={overhangSameOrDifferentStyle()}/>
                    {overhangSameOrDifferent === OVERHANG_SAME_OR_DIFFERENT_SAME &&
                        <TextInput id={PAPER_OVERHANG_LENGTH_INPUT} text="Paper Overhang Length"
                                   value={paperOverhangLengthInput} onChangeFunc={setPaperOverhangLengthInput}
                                   inputType={'float'} unitText="mm"
                                   onEnterPressedFunc={handleOnEnterPressed}/>}
                    {overhangSameOrDifferent === OVERHANG_SAME_OR_DIFFERENT_DIFFERENT &&
                        <TextInput id={PAPER_OVERHANG_LENGTH_FORMING_INPUT} text="Paper Overhang Length Forming"
                                   value={paperOverhangLengthFormingInput}
                                   onChangeFunc={setPaperOverhangLengthFormingInput}
                                   inputType={'float'} unitText="mm"
                                   onEnterPressedFunc={handleOnEnterPressed}/>}
                    {overhangSameOrDifferent === OVERHANG_SAME_OR_DIFFERENT_DIFFERENT &&
                        <TextInput id={PAPER_OVERHANG_LENGTH_WELDING_INPUT} text="Paper Overhang Length Welding"
                                   value={paperOverhangLengthWeldingInput}
                                   onChangeFunc={setPaperOverhangLengthWeldingInput}
                                   inputType={'float'} unitText="mm"
                                   onEnterPressedFunc={handleOnEnterPressed}/>}
                    <TextInput id={WIDENING_GAP_INPUT} text="Widening Gap" gifFile={wideningGapGif}
                               value={wideningGapInput} inputType={'float'} onChangeFunc={setWideningGapInput}
                               onEnterPressedFunc={handleOnEnterPressed} unitText="mm"/>
                    <TextInput id={STATOR_WIRE_OVERHANG_INPUT} text="Stator Wire Overhang" unitText="mm"
                               gifFile={statorWireOverhangGif} value={statorWireOverhangInput} inputType={'float'}
                               onChangeFunc={setStatorWireOverhangInput} onEnterPressedFunc={handleOnEnterPressed}/>
                    <TextInput id={ISOLATED_WIRE_END_LENGTH_INPUT} text="Insulated Wire-End Length" unitText="mm"
                               gifFile={isolatedWireEndLengthGif} value={isolatedWireEndLengthInput} inputType={'float'}
                               onChangeFunc={setIsolatedWireEndLengthInput} onEnterPressedFunc={handleOnEnterPressed}/>
                    <TextInput id={STRIPPED_WIRE_LENGTH_INPUT} text="Uninsulated Wire Length" unitText="mm"
                               gifFile={strippedWireLengthGif} value={strippedWireLengthInput} inputType={'float'}
                               onChangeFunc={setStrippedWireLengthInput} onEnterPressedFunc={handleOnEnterPressed}/>
                    <TextInput id={COIL_HEIGHT_INPUT} text="Coil Height" unitText="mm"
                               value={coilHeightInput} inputType={'float'}
                               onChangeFunc={setCoilHeightInput} onEnterPressedFunc={handleOnEnterPressed}/>
                    <TextInput id={WELDING_WIRE_HEIGHT_INPUT} text="Welding Wire Height" unitText="mm"
                               value={parseFloat(statorWireOverhangInput) +
                                   parseFloat(isolatedWireEndLengthInput) +
                                   parseInt(strippedWireLengthInput) +
                                   parseFloat(coilHeightInput)} inputType={'int'} readOnly={true}/>
                </form>
            </div>
        </>
    );
};

export default WireDesignInputs;

import {createSlice} from '@reduxjs/toolkit';
import {
    formingSideCameraView,
    optimisationOptionsCameraView,
    statorDesignCameraView,
    statorSlotDesignCameraView,
    // windingDesignCameraView,
    // wireDesignCameraView
} from '../data/camera'
import {
    VIEWER_STATE_ASSEMBLY,
    VIEWER_STATE_FORMING_SIDE,
    VIEWER_STATE_SINGLE_SLOT,
    VIEWER_STATE_STANDARD,
    VIEWER_STATE_STATOR_CORE_SOLID,
    VIEWER_STATE_WELDING_SIDE,
} from "../data/Viewer";
import {
    INPUT_FORMING_SIDE, INPUT_OPTIMISATION_OPTIONS,
    INPUT_STATOR_DESIGN,
    INPUT_STATOR_SLOT_DESIGN,
    INPUT_WELDING_SIDE_DESIGN,
    // INPUT_WIRE_DESIGN
} from "../data/states";

// READ DOCUMENTS FOR FEATURES FILES
const initialState = {
    statorDesignCameraData: statorDesignCameraView,
    statorSlotDesignCameraData: statorSlotDesignCameraView,
    weldingSideDesignCameraData: optimisationOptionsCameraView,
    formingSideCameraData: formingSideCameraView,
    optimisationCameraData: optimisationOptionsCameraView,
    stateStatorCoreSolidCameraData: statorDesignCameraView,
    stateSingleSlotCameraData: statorSlotDesignCameraView,
    stateWeldingSideCameraData: optimisationOptionsCameraView,
    stateFormingSideCameraData: formingSideCameraView,
    stateAssemblyCameraData: optimisationOptionsCameraView,
    toggleButtonAction: false,
    currentViewId: 1
};

const cameraSlice = createSlice({
    name: 'camera',
    initialState,
    reducers: {
        setCameraPosition: (state, {payload}) => {
            if (payload.viewerState === VIEWER_STATE_STANDARD) {
                if (payload.inputState === INPUT_STATOR_DESIGN) {
                    state.statorDesignCameraData = {...state.statorDesignCameraData, ...payload.data}
                }
                if (payload.inputState === INPUT_STATOR_SLOT_DESIGN) {
                    if (!payload.data.enableRotate){
                        state.statorSlotDesignCameraData = {...state.statorSlotDesignCameraData, ...payload.data}
                    }
                }
                if (payload.inputState === INPUT_WELDING_SIDE_DESIGN) {
                    state.weldingSideDesignCameraData = {...state.weldingSideDesignCameraData, ...payload.data}
                }
                if (payload.inputState === INPUT_FORMING_SIDE) {
                    state.formingSideCameraData = {...state.formingSideCameraData, ...payload.data}
                }
                if (payload.inputState === INPUT_OPTIMISATION_OPTIONS) {
                    state.optimisationCameraData = {...state.optimisationCameraData, ...payload.data}
                }
            }
            if (payload.viewerState === VIEWER_STATE_STATOR_CORE_SOLID) {
                state.stateStatorCoreSolidCameraData = {...state.stateStatorCoreSolidCameraData, ...payload.data}
            }
            if (payload.viewerState === VIEWER_STATE_SINGLE_SLOT) {
                state.stateSingleSlotCameraData = {...state.stateSingleSlotCameraData, ...payload.data}
            }
            if (payload.viewerState === VIEWER_STATE_WELDING_SIDE) {
                state.stateWeldingSideCameraData = {...state.stateWeldingSideCameraData, ...payload.data}
            }
            if (payload.viewerState === VIEWER_STATE_FORMING_SIDE) {
                state.stateFormingSideCameraData = {...state.stateFormingSideCameraData, ...payload.data}
            }
            if (payload.viewerState === VIEWER_STATE_ASSEMBLY) {
                state.stateAssemblyCameraData = {...state.stateAssemblyCameraData, ...payload.data}
            }
        },
        setStatorDefaultView: (state) => {
            state.statorDesignCameraData = statorDesignCameraView
            state.toggleButtonAction = !state.toggleButtonAction
        },
        setStatorSlotDefaultView: (state) => {
            state.statorSlotDesignCameraData = statorSlotDesignCameraView
            state.toggleButtonAction = !state.toggleButtonAction
        },
        setWindingDefaultView: (state) => {
            state.weldingSideDesignCameraData = optimisationOptionsCameraView
            state.toggleButtonAction = !state.toggleButtonAction
        },
        setFormingSideDefaultView: (state) => {
            state.formingSideCameraData = formingSideCameraView
            state.toggleButtonAction = !state.toggleButtonAction
        },
        setOptimisationDefaultView: (state) => {
            state.optimisationCameraData = optimisationOptionsCameraView
            state.toggleButtonAction = !state.toggleButtonAction
        },
        setStateStatorCoreSolidDefaultView: (state) => {
            state.stateStatorCoreSolidCameraData = statorDesignCameraView
            state.toggleButtonAction = !state.toggleButtonAction
        },
        setStateSingleSlotDefaultView: (state) => {
            state.stateSingleSlotCameraData = statorSlotDesignCameraView
            state.toggleButtonAction = !state.toggleButtonAction
        },
        setStateWeldingDefaultView: (state) => {
            state.stateWeldingSideCameraData = optimisationOptionsCameraView
            state.toggleButtonAction = !state.toggleButtonAction
        },
        setStateFormingSideDefaultView: (state) => {
            state.stateFormingSideCameraData = formingSideCameraView
            state.toggleButtonAction = !state.toggleButtonAction
        },
        setStateAssemblyDefaultView: (state) => {
            state.stateAssemblyCameraData = optimisationOptionsCameraView
            state.toggleButtonAction = !state.toggleButtonAction
        },
        changeCurrentViewId: (state, {payload}) => {
            state.currentViewId = payload.currentViewId
        },
    },
});

export const {
    // statorDesignView,
    // statorSlotDesignView,
    // // wireDesignView,
    // weldingSideDesignView,
    // formingSideView,
    // optimisationOptionsView,
    // // stateStatorLaminationView,
    // stateStatorCoreSolidView,
    // // stateStatorCoreLaminatedView,
    // stateSingleSlotView,
    // // stateWindingView,
    // stateWeldingSideView,
    // stateFormingSideView,
    // stateAssemblyView,
    // // toggleParallelView,
    setCameraPosition,
    // setXYView,
    // setXZView,
    // setYZView,
    setStatorDefaultView,
    setStatorSlotDefaultView,
    // setWireDesignDefaultView,
    setWindingDefaultView,
    setFormingSideDefaultView,
    setOptimisationDefaultView,
    setStateStatorLaminationDefaultView,
    setStateStatorCoreSolidDefaultView,
    setStateSingleSlotDefaultView,
    setStateWindingDefaultView,
    setStateWeldingDefaultView,
    setStateFormingSideDefaultView,
    setStateAssemblyDefaultView,
    changeCurrentViewId
} = cameraSlice.actions;

export default cameraSlice.reducer;

import styled from "styled-components";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getUser, updateUsernameAndPassword} from "../features/userSlice";
import {Navigate} from "react-router-dom";
import {appLogo} from "../icons/login";
import {userIcon} from "../icons/login";

const Login = () => {
    const dispatch = useDispatch();
    const {userToken, username} = useSelector((store) => store.user);
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");

    // we navigate user to projects page if user has token
    if (userToken && username) {
        return <Navigate to='/projects'/>
    }

    // full screen the site
    const toggleFullScreen = () => {
        if (document.mozFullScreen || document.webkitIsFullScreen) {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            }
        } else {
            const element = document.documentElement;
            if (element.requestFullscreen) {
                element.requestFullscreen();
            } else if (element.mozRequestFullScreen) {
                element.mozRequestFullScreen();
            } else if (element.webkitRequestFullscreen) {
                element.webkitRequestFullscreen();
            } else if (element.msRequestFullscreen) {
                element.msRequestFullscreen();
            }
        }
    };

    return (
        <StyledWrapper>
            <div className="app-data" onDoubleClick={toggleFullScreen}>
                <img src={appLogo} alt="appLogo" className="logo"/>
                <p className="welcome-text">Welcome to</p>
                <p className="amps-text">COILWORKS</p>
                <p className="version-text">Version: {process.env.REACT_APP_VERSION}</p>
                <p className="power-by">Powered By Agile Manufacturing Power Systems LTD.</p>
                <div className="tag-content-box">
                    <p className="tag-content">Login</p>
                </div>
            </div>
            <div className="main-login-box">
                <img src={userIcon} alt="userIcon" className="userIcon"/>
                <input className="text-input" type="text" id="username" name="username" value={userName}
                       placeholder="Username"
                       onChange={(e) => setUserName(e.target.value)}/>
                <input className="text-input" type="password" id="password" name="password" value={password}
                       placeholder="Password"
                       onChange={(e) => setPassword(e.target.value)}/>
                <div className="btn-box">
                    <button onClick={() => {
                        dispatch(getUser({username: userName, password: password}))
                        dispatch(updateUsernameAndPassword({username: userName, password: password}))
                    }}>Login
                    </button>
                </div>
                <div className="error-log">
                    {userToken === false && <p>invalid username and password</p>}
                </div>
            </div>
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    width: 100vw;
    height: 100vh;
    background: var(--clr-grey-1);
    display: flex;
    align-items: center;
    justify-content: center;

    .app-data {
        position: relative;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
        background: linear-gradient(56deg, #4942e4 8%, #7149c6 92%);
        height: 60vh;
        width: 20vw;
        padding: 0 1vw 1vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        img {
            width: 9vw;
            filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.6));
        }

        p {
            color: var(--clr-grey-9);
            text-shadow: 1px 3px 3px #00000030;
        }

        .welcome-text {
            font-family: Ubuntu-Light, serif;
            margin-top: 3vh;
            font-size: min(2.2vh, 1.1vw);
        }

        .amps-text {
            font-size: min(3.3vh, 1.65vw);
            font-family: Ubuntu-Bold, serif;
        }

        .version-text {
            margin-top: 1.3vh;
            font-size: var(--font-size-5);
            font-family: Ubuntu-Light, serif;
        }

        .power-by {
            position: absolute;
            bottom: 0.5vh;
            font-size: var(--font-size-6);
        }

        .tag-content-box {
            position: absolute;
            right: 0;
            top: 0;
            background: var(--clr-grey-4);
            height: 4vh;
            width: 3.5vh;
            padding-top: 2vh;
            border-bottom-left-radius: 6px;

            .tag-content {
                color: var(--clr-grey-8);
                rotate: 270deg;
                font-size: var(--font-size-2);
            }
        }
    }

    .main-login-box {
        background: var(--clr-grey-3);
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
        width: 30vw;
        height: 60vh;
        padding: 0 1vw 1vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
            margin-top: 7vh;
            width: 8vw;
        }

        h1 {
            margin-top: 6vh;
            font-size: 45px;
            text-align: center;
            margin-bottom: 10vh;
            color: var(--clr-grey-5);
        }

        input {
            font-family: Ubuntu-Light, serif;
            width: 50%;
            height: 3vh;
            margin-top: 5vh;
            padding-left: 0.25vw;
            font-size: var(--font-size-1);
            border: none;
            border-bottom: solid 1px var(--clr-grey-5);
            background: var(--clr-grey-3);
            color: var(--clr-grey-8);
            outline: none;
        }

        ::placeholder {
            color: var(--clr-grey-6);
        }

        .btn-box {
            margin-top: 10vh;
            width: 50%;
            background: linear-gradient(160deg, #4742d7 10%, #843eff 90%);
            padding: 0.12vh;
            border-radius: 10px;
        }

        button {
            font-size: var(--font-size-1);
            text-align: center;
            width: 100%;
            padding-top: 1vh;
            padding-bottom: 1.2vh;
            //background: transparent;
            border: none;
            border-radius: 10px;
            color: var(--clr-grey-8);
            cursor: pointer;
            background: var(--clr-grey-3);

            &:hover {
                color: var(--clr-grey-9);
            }
        }


        .error-log {
            margin-top: 2vh;
            height: 2vh;
            color: red;
        }
    }
`

export default Login;
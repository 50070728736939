import styled from "styled-components";
import {
    ASSEMBLY_EXPORT,
    FORMING_EXPORT,
    PAPER_EXPORT,
    STATOR_EXPORT,
    WELDING_EXPORT
} from "../../../data/exportStates";

const MainButtons = ({currentState, changeCurrentStateFunc}) => {

    const buttonsList =
        [STATOR_EXPORT, WELDING_EXPORT, FORMING_EXPORT, PAPER_EXPORT, ASSEMBLY_EXPORT]

    const checkInputState = (fieldState) => {
        if (fieldState === currentState) {
            return {background: "var(--clr-primary-dark)"}
        }
        return {background: "var(--clr-grey-5)"}
    }

    return (
        <StyledWrapper>
            {buttonsList.map((title, index) => {
                return <button style={checkInputState(title)} key={index}
                               onClick={() => changeCurrentStateFunc(title)}
                >{title}
                </button>
            })}

        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0.8vh 1vw;

    button {
        width: 9vw;
        height: 4vh;
        border: none;
        color: var(--clr-grey-9);
        border-radius: 6px;
        cursor: pointer;
    }
`

export default MainButtons;
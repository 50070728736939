import styled from "styled-components";

const WarningDialog = ({title = "VALIDATION ERROR!", message, closeFunc}) => {
    return (
        <>
            <div className="modal-container-background"/>
            <div className="modal-container">
                <StyledWrapper>
                    <div className="warning-dialog-title">
                        <span>{title}</span>
                    </div>
                    <div className="message-box">
                        <span>{message}</span>
                        <div className="ok-button">
                            <button onClick={() => {
                                closeFunc()
                            }}>OK
                            </button>
                        </div>
                    </div>
                </StyledWrapper>
            </div>
        </>
    );
};

const StyledWrapper = styled.div`
    width: 25vw;
    color: var(--clr-grey-9);

    .warning-dialog-title {
        background: var(--clr-grey-1);
        border-top: solid 1px orangered;
        height: 6vh;
        padding-right: 1vw;
        padding-left: 1vw;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        span {
            font-size: 16px;
        }
    }

    .message-box {
        padding: 2vh 1vw;
        background: var(--clr-grey-3);
        display: flex;
        flex-direction: column;
        justify-content: left;
        height: 100%;

        span {
            text-align: left;
            //align-self: flex-start;
            line-height: 30px;
        }

        .ok-button {
            padding-top: 2vh;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;

            button {
                align-self: flex-end;
                background: var(--clr-primary);
                width: 6vw;
                height: 4vh;
                border: none;
                border-radius: 10px;
                color: var(--clr-grey-9);
                font-weight: bold;
                font-size: 16px;
                cursor: pointer;
            }
        }
    }
`

export default WarningDialog;
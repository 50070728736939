// Stator Design
export const NUMBER_OF_SLOT_INPUT = 'number_of_slot'
export const STATOR_BORE_DIAMETER_INPUT = 'stator_bore_diameter'
export const STATOR_OUTER_DIAMETER_INPUT = 'stator_outer_diameter'
export const STACK_HEIGHT_INPUT = 'stack_height'
export const LAMINATION_THICKNESS_INPUT = 'lamination_thickness'
export const LAMINATION_THICKNESS_INPUT_OPTIONS = [0.2, 0.35, 0.5, 0.65]
export const NUMBER_OF_LAMINATION_INPUT = 'number_of_lamination'
export const LAMINATION_AREA_INPUT = 'lamination_area'


// Stator Slot Design
export const NUMBER_OF_LAYERS_INPUT = 'number_of_layers'
export const NUMBER_OF_LAYERS_INPUT_OPTIONS = [2, 4, 6, 8, 10, 12]
export const SLOT_OPENING_INPUT = 'slot_opening'
export const TOOTH_TIP_INPUT = 'tooth_tip'
export const SLOT_OR_WIRE_EQUAL_SLOT = 'slot'
export const SLOT_OR_WIRE_EQUAL_WIRE = 'wire'
export const SLOT_DEPTH_INPUT = 'slot_depth'
export const SLOT_WIDTH_INPUT = 'slot_width'
export const WIRE_DEPTH_INPUT = 'wire_depth'
export const WIRE_WIDTH_INPUT = 'wire_width'
export const WIRE_DEPTH_WITHOUT_INSULATION_INPUT = 'wire_depth_without_insulation'
export const WIRE_WIDTH_WITHOUT_INSULATION_INPUT = 'wire_width_without_insulation'
export const CORE_BACK_INPUT = 'core_back'
export const PAPER_LENGTH_INPUT = 'paper_length'
export const SLOT_AREA_INPUT = 'slot_area'
export const FILL_FACTOR_INPUT = 'fill_factor'
export const TOOTH_TIP_ANGLE_INPUT = 'tooth_tip_angle'
export const PAPER_THICKNESS_INPUT = 'paper_thickness'
export const PAPER_OVERHANG_LENGTH_INPUT = 'paper_overhang_length'
export const PAPER_OVERHANG_LENGTH_FORMING_INPUT = 'paper_overhang_length_forming'
export const PAPER_OVERHANG_LENGTH_WELDING_INPUT = 'paper_overhang_length_welding'
export const OVERLAP_LEFT_OR_RIGHT_INPUT = 'overlap_left_or_right'
export const AUTO_OR_MANUAL_AUTO = 'auto'
export const AUTO_OR_MANUAL_MANUAL = 'manual'
export const PAPER_OVERLAP_LENGTH_INPUT = 'paper_overlap_length'
export const PAPER_OVERLAP_RIGHT = 'right'
export const PAPER_OVERLAP_LEFT = 'left'
export const SLOT_MODE_SEMI = 'semi'
// export const SLOT_MODE_NOT_SEMI = 'not_semi'
export const OVERHANG_SAME_OR_DIFFERENT_SAME = 'same'
export const OVERHANG_SAME_OR_DIFFERENT_DIFFERENT = 'different'
export const SAME_OR_DIFFERENT_SAME = 'same'
export const SAME_OR_DIFFERENT_DIFFERENT = 'different'
export const SFR_INPUT = 'sfr'
export const SFR1_INPUT = 'sfr1'
export const SFR2_INPUT = 'sfr2'
export const SFR3_INPUT = 'sfr3'
export const SFR4_INPUT = 'sfr4'
export const PAPER_TO_WIRE_CLEARANCE_INPUT = 'paper_to_wire_clearance'
export const WIRE_TO_WIRE_CLEARANCE_INPUT = 'wire_to_wire_clearance'


// Default Values
export const statorDefaultValues = {
    [NUMBER_OF_SLOT_INPUT]: 54,
    [STATOR_BORE_DIAMETER_INPUT]: 123.60,
    [STATOR_OUTER_DIAMETER_INPUT]: 190.20,
    [STACK_HEIGHT_INPUT]: 104,
    [LAMINATION_THICKNESS_INPUT]: LAMINATION_THICKNESS_INPUT_OPTIONS[0],
    [NUMBER_OF_LAMINATION_INPUT]: 500,
    [LAMINATION_AREA_INPUT]: 0.0,
    [NUMBER_OF_LAYERS_INPUT]: NUMBER_OF_LAYERS_INPUT_OPTIONS[2],
    [SLOT_OPENING_INPUT]: 1.75,
    [TOOTH_TIP_INPUT]: 0.80,
    [SLOT_DEPTH_INPUT]: 15.39,
    [SLOT_WIDTH_INPUT]: 3.95,
    [WIRE_DEPTH_INPUT]: 2.95,
    [WIRE_WIDTH_INPUT]: 3.37,
    [WIRE_DEPTH_WITHOUT_INSULATION_INPUT]: 2.71,
    [WIRE_WIDTH_WITHOUT_INSULATION_INPUT]: 3.13,
    [CORE_BACK_INPUT]: 7.0,
    [PAPER_LENGTH_INPUT]: 0,
    [SLOT_AREA_INPUT]: 49.33,
    [FILL_FACTOR_INPUT]: 0.92,
    [TOOTH_TIP_ANGLE_INPUT]: 20.00,
    [PAPER_THICKNESS_INPUT]: 0.1,
    [PAPER_OVERHANG_LENGTH_INPUT]: 1,
    [PAPER_OVERHANG_LENGTH_FORMING_INPUT]: 1,
    [PAPER_OVERHANG_LENGTH_WELDING_INPUT]: 1,
    [PAPER_OVERLAP_LENGTH_INPUT]: 1,
    [SFR_INPUT]: 0.2,
    [SFR1_INPUT]: 0.2,
    [SFR2_INPUT]: 0.2,
    [SFR3_INPUT]: 0.2,
    [SFR4_INPUT]: 0.2,
    [PAPER_TO_WIRE_CLEARANCE_INPUT]: 0.2,
    [WIRE_TO_WIRE_CLEARANCE_INPUT]: 0.2,
};

import styled from "styled-components";
import {useDispatch} from "react-redux";
import {changeGif} from "../../../features/guideLayoutSlice";

const DropdownInput = ({id, text, options, value, onChangeFunc, itemSelectedFunc, inputType, addOptionFunc, gifFile, unitText}) => {
    const dispatch = useDispatch();

    // this field used for integer and float input, we send type of input to the component
    // here we make sure input type is correct
    const handleInputChange = (e) => {
        const value = e.target.value.replace(/^0+(?=\d)/, "");
        if (!value) {
            onChangeFunc(0);
            return
        }
        let isValidFormat = false
        if (inputType === 'int') {
            isValidFormat = /^\d+$/.test(value);
        } else if (inputType === 'float') {
            isValidFormat = /^\d+(\.\d{0,2})?$/.test(value);
        }
        if (isValidFormat) {
            onChangeFunc(value);
        }
    };

    return (
        <StyledWrapper>
            <div className="dropdown-input" onClick={() =>
                dispatch(changeGif({currentGif: gifFile, currentGifTitle: text}))}>
                <label>{text}</label><br/>
                <div className="select-editable">
                    <select onChange={(e) => itemSelectedFunc(parseFloat(e.target.value))} value={value}>
                        {options.map((option, index) => {
                            return <option value={option} key={index}>{option}</option>
                        })}
                    </select>
                    <input type="text" id={id} name={id} onKeyDown={addOptionFunc} value={value}
                           onChange={(e) => handleInputChange(e)}/>
                </div>
                <small>{unitText}</small>
            </div>
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    .dropdown-input {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: end;
        margin-bottom: 0.9vh;
    }

    small {
        color: var(--clr-grey-6);
        position: absolute;
        text-align: center;
        margin-right: 0.5vw;
        font-size: var(--font-size-5)
    }

    label {
        margin-right: 0.5vw;
        font-size: var(--font-size-5)
    }

    select {
        width: 11.3vw;
        height: 2.3vh;
        border: none;
        border-radius: 6px;
        color: var(--clr-grey-9);
        outline: none;
        box-shadow: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        background: url("data:image/svg+xml,%3Csvg width='18' height='10' viewBox='0 0 20 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 7.99981C9.82984 7.99895 9.662 7.96023 9.50867 7.88644C9.35534 7.81266 9.22035 7.70566 9.11351 7.57323L6.30739 4.17389C6.14341 3.96924 6.04023 3.72264 6.00959 3.4622C5.97895 3.20176 6.02209 2.93796 6.13409 2.70084C6.22492 2.49476 6.37315 2.31919 6.56108 2.19509C6.74901 2.07098 6.96869 2.0036 7.19388 2.00098H12.8061C13.0313 2.0036 13.251 2.07098 13.4389 2.19509C13.6268 2.31919 13.7751 2.49476 13.8659 2.70084C13.9779 2.93796 14.0211 3.20176 13.9904 3.4622C13.9598 3.72264 13.8566 3.96924 13.6926 4.17389L10.8865 7.57323C10.7796 7.70566 10.6447 7.81266 10.4913 7.88644C10.338 7.96023 10.1702 7.99895 10 7.99981Z' fill='%23828282'/%3E%3C/svg%3E%0A") no-repeat;
        background-position-x: 10.2vw;
        background-position-y: 0.7vh;
        cursor: pointer;
    }

    .select-editable {
        position: relative;
        margin-right: -0.8vw;
        width: 11.3vw;
        height: 2.3vh;
        background-color: var(--clr-grey-2);
        border: none;
        border-radius: 6px;
        color: var(--clr-grey-9);
    }

    .select-editable select {
        position: absolute;
        top: 0;
        left: 0;
        font-size: var(--font-size-5);
        border: none;
        width: 11.3vw;
        margin: 0;
        height: 2.3vh;
        background-color: var(--clr-grey-2);
        border-radius: 6px;
        color: var(--clr-grey-9);
        cursor: pointer;
    }

    .select-editable input {
        position: absolute;
        top: 0;
        left: 0;
        width: 8vw;
        font-size: var(--font-size-5);
        border: none;
        height: 2.3vh;
        background-color: var(--clr-grey-2);
        border-radius: 6px;
        color: var(--clr-grey-9);
        padding-left: 1vh;
    }

    .select-editable select:focus, .select-editable input:focus {
        outline: none;
    }
`

export default DropdownInput;

import styled from 'styled-components'
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import {NUMBER_OF_SLOT_INPUT, NUMBER_OF_LAYERS_INPUT} from "../../data/statorInputs";
import {Chart, ArcElement} from 'chart.js';
import {Doughnut} from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(ArcElement);
Chart.register(ChartDataLabels);


const ConnectionTableViewerWelding = ({coils, addCoilFunction}) => {
    const defaultCoil = {
        coil_name: "UI Coil",
        starting_slot: null,
        starting_layer: null,
        ending_slot: null,
        ending_layer: null,
        tag: null,
        coil_children: null
    }
    const numberOfSlot = useSelector((store) => store.stlParameters[NUMBER_OF_SLOT_INPUT]);
    const numberOfLayers = useSelector((store) => store.stlParameters[NUMBER_OF_LAYERS_INPUT]);
    const dispatch = useDispatch();
    const [totalMatrix, setTotalMatrix] = useState([])
    const [coilSelected, setCoilSelected] = useState(defaultCoil)
    const [data, setData] = useState({
        labels: [],
        datasets: []
    })


    useEffect(() => {
        // this function will fill a 2d matrix [numberOfLayers][numberOfSlot] based on welding coils
        const generateConnectionTableMatrix = (defaultValue, parentStartingValue, parentEndingValue, childStartingValue, childEndingValue, selectedCoilValue, aTagColor, bTagColor, cTagColor, checkTags) => {
            let result = Array.from({length: numberOfLayers}, (_) => Array.from({length: numberOfSlot}, (_) => defaultValue));
            // we color black all pieces except needed layer (when user selected start of coil and want to select end of coil)
            if (coilSelected.starting_slot !== null) {
                let validateLayer = coilSelected.starting_layer
                if (coilSelected.starting_layer % 2 === 0) {
                    validateLayer = coilSelected.starting_layer - 2
                }
                for (let i = 0; i <= numberOfLayers - 1; i++) {
                    for (let j = 1; j <= numberOfSlot; j++) {
                        if (i === validateLayer) {
                            if (coilSelected.starting_slot === j) {
                                result[i][numberOfSlot - j] = "#2b2b2b";
                            }
                        } else {
                            result[i][numberOfSlot - j] = "#2b2b2b";
                        }
                    }
                }
            }
            // we color all pieces black if we have welding coils
            if (coils.length) {
                for (let i = 0; i <= numberOfLayers - 1; i++) {
                    for (let j = 1; j <= numberOfSlot; j++) {
                        result[i][numberOfSlot - j] = "#2b2b2b";
                    }
                }
            }
            // eslint-disable-next-line array-callback-return
            // coils.map((parentCoil) => {
            //     if (checkTags && parentCoil.tag && !parentCoil.tag.startsWith("F")) {
            //         if (parentCoil.tag.startsWith("A")) {
            //             result[parentCoil.starting_layer - 1][numberOfSlot - parentCoil.starting_slot] = aTagColor
            //             result[parentCoil.ending_layer - 1][numberOfSlot - parentCoil.ending_slot] = aTagColor
            //         } else if (parentCoil.tag.startsWith("B")) {
            //             result[parentCoil.starting_layer - 1][numberOfSlot - parentCoil.starting_slot] = bTagColor
            //             result[parentCoil.ending_layer - 1][numberOfSlot - parentCoil.ending_slot] = bTagColor
            //         } else if (parentCoil.tag.startsWith("C")) {
            //             result[parentCoil.starting_layer - 1][numberOfSlot - parentCoil.starting_slot] = cTagColor
            //             result[parentCoil.ending_layer - 1][numberOfSlot - parentCoil.ending_slot] = cTagColor
            //         } else {
            //             result[parentCoil.starting_layer - 1][numberOfSlot - parentCoil.starting_slot] = parentStartingValue
            //             result[parentCoil.ending_layer - 1][parentCoil.ending_slot - 2] = parentEndingValue
            //         }
            //     } else {
            //         result[parentCoil.starting_layer - 1][numberOfSlot - parentCoil.starting_slot] = parentStartingValue
            //         result[parentCoil.ending_layer - 1][numberOfSlot - parentCoil.ending_slot] = parentEndingValue
            //     }
            //     // eslint-disable-next-line array-callback-return
            //     parentCoil.coil_children && parentCoil.coil_children.map((childCoil) => {
            //         if (checkTags && childCoil.tag && !childCoil.tag.startsWith("F")) {
            //             if (childCoil.tag.startsWith("A")) {
            //                 result[childCoil.starting_layer - 1][numberOfSlot - childCoil.starting_slot] = aTagColor
            //                 result[childCoil.ending_layer - 1][numberOfSlot - childCoil.ending_slot] = aTagColor
            //             } else if (childCoil.tag.startsWith("B")) {
            //                 result[childCoil.starting_layer - 1][numberOfSlot - childCoil.starting_slot] = bTagColor
            //                 result[childCoil.ending_layer - 1][numberOfSlot - childCoil.ending_slot] = bTagColor
            //             } else if (childCoil.tag.startsWith("C")) {
            //                 result[childCoil.starting_layer - 1][numberOfSlot - childCoil.starting_slot] = cTagColor
            //                 result[childCoil.ending_layer - 1][numberOfSlot - childCoil.ending_slot] = cTagColor
            //             } else {
            //                 result[childCoil.starting_layer - 1][numberOfSlot - childCoil.starting_slot] = childStartingValue
            //                 result[childCoil.ending_layer - 1][numberOfSlot - childCoil.ending_slot] = childEndingValue
            //             }
            //         } else {
            //             result[childCoil.starting_layer - 1][numberOfSlot - childCoil.starting_slot] = childStartingValue
            //             result[childCoil.ending_layer - 1][numberOfSlot - childCoil.ending_slot] = childEndingValue
            //         }
            //     })
            // })
            // here we color a start of coil when user selected just start of coil
            if (coilSelected.starting_layer !== null && coilSelected.starting_slot !== null) {
                result[coilSelected.starting_layer - 1][numberOfSlot - coilSelected.starting_slot] = selectedCoilValue
            }
            // we go in this when we have welding coils
            if (coils.length === 2) {
                let span = coils[0].starting_slot - coils[0].ending_slot;
                let clockwise = true

                if (span > 0) {
                    if (span > numberOfSlot / 2) {
                        span = (numberOfSlot - coils[0].starting_slot + coils[0].ending_slot);
                        clockwise = false
                    }
                } else {
                    if (-span > numberOfSlot / 2) {
                        clockwise = true
                        span = (numberOfSlot - coils[0].ending_slot + coils[0].starting_slot);
                    } else {
                        clockwise = false
                        span = -span;
                    }
                }
                let oddSpan = false
                if (parseInt(span) !== span) {
                    // we add a new slot if we have odd span
                    oddSpan = true
                    span = span + 0.5
                    result = Array.from({length: numberOfLayers}, (_) => Array.from({length: numberOfSlot + 1}, (_) => defaultValue));
                    // in this if, we change extra slot color
                    if (coils.length) {
                        for (let i = 0; i <= numberOfLayers - 1; i++) {
                            for (let j = 0; j <= numberOfSlot; j++) {
                                if (j + 0.5 === coils[0].ending_slot)
                                    result[i][numberOfSlot - j] = "#807c7c";
                                else
                                    result[i][numberOfSlot - j] = "#2b2b2b";
                            }
                        }
                    }
                }

                const firstLayerColor = '#64b1cb'
                const secondLayerColor = '#9379ff'
                const endColor = '#ef6262'

                // here we set color pieces between start and end of each coil based on clockwise and oddSpan
                for (let i = 1; i < span; i++) {
                    if (!clockwise) {
                        if (oddSpan) {
                            result[coils[1].starting_layer - 1][(numberOfSlot - coils[1].starting_slot + i) >= numberOfSlot
                                ? (numberOfSlot - coils[1].starting_slot + i) - numberOfSlot
                                : coils[1].starting_slot > coils[1].ending_slot
                                    ? (numberOfSlot - coils[1].starting_slot + i)
                                    : (numberOfSlot - coils[1].starting_slot + i) + 1] = secondLayerColor
                            result[coils[0].starting_layer - 1][(numberOfSlot - coils[0].starting_slot - i) < 0
                                ? numberOfSlot + (numberOfSlot - coils[0].starting_slot - i) + 1
                                : coils[0].starting_slot > coils[0].ending_slot
                                    ? (numberOfSlot - coils[0].starting_slot - i)
                                    : (numberOfSlot - coils[0].starting_slot - i) + 1] = firstLayerColor
                        } else {
                            result[coils[1].starting_layer - 1][(numberOfSlot - coils[1].starting_slot + i) >= numberOfSlot
                                ? (numberOfSlot - coils[1].starting_slot + i) - numberOfSlot
                                : (numberOfSlot - coils[1].starting_slot + i)] = secondLayerColor
                            result[coils[0].starting_layer - 1][(numberOfSlot - coils[0].starting_slot - i) < 0
                                ? numberOfSlot + (numberOfSlot - coils[0].starting_slot - i)
                                : (numberOfSlot - coils[0].starting_slot - i)] = firstLayerColor
                        }
                    } else {
                        if (oddSpan) {
                            result[coils[0].starting_layer - 1][(numberOfSlot - coils[0].starting_slot + i) >= numberOfSlot
                                ? (numberOfSlot - coils[0].starting_slot + i) - numberOfSlot
                                : coils[0].starting_slot > coils[0].ending_slot
                                    ? (numberOfSlot - coils[0].starting_slot + i)
                                    : (numberOfSlot - coils[0].starting_slot + i) + 1] = firstLayerColor
                            result[coils[1].starting_layer - 1][(numberOfSlot - coils[1].starting_slot - i) < 0
                                ? numberOfSlot + (numberOfSlot - coils[1].starting_slot - i) + 1
                                : coils[1].starting_slot > coils[1].ending_slot
                                    ? (numberOfSlot - coils[1].starting_slot - i)
                                    : (numberOfSlot - coils[1].starting_slot - i) + 1] = secondLayerColor
                        } else {
                            result[coils[0].starting_layer - 1][(numberOfSlot - coils[0].starting_slot + i) >= numberOfSlot
                                ? (numberOfSlot - coils[0].starting_slot + i) - numberOfSlot
                                : (numberOfSlot - coils[0].starting_slot + i)] = firstLayerColor
                            result[coils[1].starting_layer - 1][(numberOfSlot - coils[1].starting_slot - i) < 0
                                ? numberOfSlot + (numberOfSlot - coils[1].starting_slot - i)
                                : (numberOfSlot - coils[1].starting_slot - i)] = secondLayerColor
                        }
                    }
                }

                // here we set color start and end of first coil based on clockwise and oddSpan
                if (oddSpan) {
                    if (!clockwise) {
                        if (coils[0].starting_slot > coils[0].ending_slot)
                            result[coils[0].starting_layer - 1][numberOfSlot - coils[0].starting_slot] = firstLayerColor
                        else
                            result[coils[0].starting_layer - 1][numberOfSlot - coils[0].starting_slot + 1] = firstLayerColor
                        result[coils[0].ending_layer - 1][numberOfSlot - coils[0].ending_slot + 0.5] = endColor
                    } else {
                        if (coils[0].starting_slot < coils[0].ending_slot)
                            result[coils[0].starting_layer - 1][numberOfSlot - coils[0].starting_slot + 1] = firstLayerColor
                        else
                            result[coils[0].starting_layer - 1][numberOfSlot - coils[0].starting_slot] = firstLayerColor
                        result[coils[0].ending_layer - 1][numberOfSlot - coils[0].ending_slot + 0.5] = endColor
                    }
                } else {
                    result[coils[0].starting_layer - 1][numberOfSlot - coils[0].starting_slot] = firstLayerColor
                    result[coils[0].ending_layer - 1][numberOfSlot - coils[0].ending_slot] = endColor
                }

                // here we set color start and end of second coil based on clockwise and oddSpan
                if (oddSpan) {
                    if (!clockwise) {
                        if (coils[1].starting_slot < coils[1].ending_slot)
                            result[coils[1].starting_layer - 1][numberOfSlot - coils[1].starting_slot + 1] = secondLayerColor
                        else
                            result[coils[1].starting_layer - 1][numberOfSlot - coils[1].starting_slot] = secondLayerColor
                        result[coils[1].ending_layer - 1][numberOfSlot - coils[1].ending_slot + 0.5] = endColor
                    } else {
                        if (coils[1].starting_slot > coils[1].ending_slot)
                            result[coils[1].starting_layer - 1][numberOfSlot - coils[1].starting_slot] = secondLayerColor
                        else
                            result[coils[1].starting_layer - 1][numberOfSlot - coils[1].starting_slot + 1] = secondLayerColor
                        result[coils[1].ending_layer - 1][numberOfSlot - coils[1].ending_slot + 0.5] = endColor
                    }
                } else {
                    result[coils[1].starting_layer - 1][numberOfSlot - coils[1].starting_slot] = secondLayerColor
                    result[coils[1].ending_layer - 1][numberOfSlot - coils[1].ending_slot] = endColor
                }
            }
            return result
        }
        // we use Total Matrix to which pieces are taken and which one available for user to select it
        setTotalMatrix(generateConnectionTableMatrix(0, 1, 1, 1, 1, 2, null, null, null, false))
        // we use arrayOfColorArrays to know each pieces color
        const arrayOfColorArrays = generateConnectionTableMatrix("#bcbcbc", "#1b7039", "#bb3434", "#68b984", "#ef6262", "#4942E4", "#f59e3d", "#ea5547", "#2e58bf", true)
        // we use arrayOfColorArrays to know each pieces hover color
        const arrayOfHoverColorArrays = generateConnectionTableMatrix("#757575", "#0d381c", "#691d1d", "#3f7250", "#a94646", "#2e2a8c", "#9c6a2a", "#b50c00", "#161880", true)


        // here we generate data of doughnut chart
        const generateData = () => {
            let labels = Array.from({length: numberOfSlot}, (_, index) => index + 1);
            if (coils.length && parseInt(coils[0].ending_slot) !== coils[0].ending_slot) {
                labels = Array.from({length: numberOfSlot + 1}, (_, index) => index + 1);
            }
            const datasets = [];
            const fontSizeOuter = 26 - (14 * (numberOfSlot / 100))
            const fontSize = 18 - (14 * (numberOfLayers / 18))
            // this is the first layer data, we add slot numbers here (numberOfSlot will change to numberOfSlot + 1 if we have odd span)
            datasets.push({
                data: coils.length && parseInt(coils[0].ending_slot) !== coils[0].ending_slot ? Array(numberOfSlot + 1).fill(numberOfLayers) : Array(numberOfSlot).fill(numberOfLayers),
                backgroundColor: arrayOfColorArrays[numberOfLayers - 1],
                hoverBackgroundColor: arrayOfHoverColorArrays[numberOfLayers - 1],
                borderColor: '#1e1e1e',
                datalabels: {
                    labels: {
                        index: {
                            color: '#1e1e1e',
                            font: {
                                size: fontSizeOuter,
                            },
                            formatter: (val, ctx) => coils.length && parseInt(coils[0].ending_slot) !== coils[0].ending_slot ? (
                                numberOfSlot - ctx.chart.data.labels[ctx.dataIndex] + 2 < coils[0].ending_slot ?
                                    numberOfSlot - ctx.chart.data.labels[ctx.dataIndex] + 2 :
                                    numberOfSlot - ctx.chart.data.labels[ctx.dataIndex] + 1.5 === coils[0].ending_slot ?
                                        "" :
                                        numberOfSlot - ctx.chart.data.labels[ctx.dataIndex + 1] + 2
                            ) : (numberOfSlot - ctx.chart.data.labels[ctx.dataIndex] + 1),
                            align: 'end',
                            anchor: 'end',
                            padding: 10,
                        },
                        value: {
                            color: ((coils.length || coilSelected.starting_slot !== null)) ? '#2b2b2b' : '#bcbcbc',
                            borderWidth: 2,
                            borderRadius: 4,
                            padding: 0,
                            display: true,
                            font: {
                                size: fontSize,
                            },
                        },
                    },
                }
            });
            // this is other layers data  (numberOfSlot will change to numberOfSlot + 1 if we have odd span)
            for (let i = 1; i < numberOfLayers; i++) {
                datasets.push({
                    data: coils.length && parseInt(coils[0].ending_slot) !== coils[0].ending_slot ? Array(numberOfSlot + 1).fill(numberOfLayers - i) : Array(numberOfSlot).fill(numberOfLayers - i),
                    backgroundColor: arrayOfColorArrays[numberOfLayers - i - 1],
                    hoverBackgroundColor: arrayOfHoverColorArrays[numberOfLayers - i - 1],
                    borderColor: '#1e1e1e',
                    datalabels: {
                        display: true,
                        color: ((coils.length || coilSelected.starting_slot !== null)) ? '#2b2b2b' : '#bcbcbc',
                        font: {
                            size: fontSize,
                        },
                    },
                });
            }
            return {
                labels: labels,
                datasets: datasets
            }
        }
        setData(generateData)
    }, [numberOfSlot, numberOfLayers, coils, coilSelected.starting_slot, coilSelected.starting_layer]);

    // we call this function when user click on a piece
    const handlePieceClick = (clickedPiece) => {
        // this is when coil is already taken
        if (totalMatrix[numberOfLayers - clickedPiece.datasetIndex - 1][clickedPiece.index] === 1) {
            toast.error("Coil Is Unselectable")
            return
        }
        // this is when user select a piece that already selected, so we unselect the piece
        if (totalMatrix[numberOfLayers - clickedPiece.datasetIndex - 1][clickedPiece.index] === 2) {
            setCoilSelected(defaultCoil)
            return
        }
        // this is when this is the start of coil
        if (coilSelected.starting_slot === null && coilSelected.starting_layer === null) {
            // this means user want to add another coil, so we do not let user do it
            if (coils.length) {
                toast.error(`There is only One layer needed for generating welding`)
                return;
            }
            setCoilSelected((currentState) => {
                return {
                    ...currentState,
                    starting_slot: numberOfSlot - clickedPiece.index,
                    starting_layer: numberOfLayers - clickedPiece.datasetIndex
                }
            })
        // this is when this is the end of coil, so we add coil to forming coil
        } else {
            // this means user want to add another coil, so we do not let user do it
            if (coils.length) {
                toast.error(`There is only One layer needed for generating welding`)
                return;
            }
            // we show this toast to user if user selected a wrong layer for end of coil
            if (coilSelected.starting_layer % 2 === 0) {
                if (numberOfLayers - clickedPiece.datasetIndex !== coilSelected.starting_layer - 1) {
                    toast.error(`You must select layer ${coilSelected.starting_layer - 1}`)
                    return;
                }
            } else {
                if (numberOfLayers - clickedPiece.datasetIndex !== coilSelected.starting_layer + 1) {
                    toast.error(`You must select layer ${coilSelected.starting_layer + 1}`)
                    return;
                }
            }
            let span = coilSelected.starting_slot - (numberOfSlot - clickedPiece.index);
            let clockwise = true

            if (span > 0) {
                if (span > numberOfSlot / 2) {
                    span = (numberOfSlot - coilSelected.starting_slot + (numberOfSlot - clickedPiece.index));
                    clockwise = false
                }
            } else {
                if (-span > numberOfSlot / 2) {
                    clockwise = true
                    span = (numberOfSlot - (numberOfSlot - clickedPiece.index) + coilSelected.starting_slot);
                } else {
                    clockwise = false
                    span = -span;
                }
            }

            // if (span % 2 !== 0 || coilSelected.starting_slot === numberOfSlot - clickedPiece.index) {
            //     toast.error(`Wrong span`)
            //     return;
            // }

            let middleSlot = coilSelected.starting_slot + (span / 2)
            if (middleSlot > numberOfSlot) {
                middleSlot = middleSlot - numberOfSlot
            }
            if (clockwise) {
                middleSlot = coilSelected.starting_slot - (span / 2)
                if (middleSlot < 1) {
                    middleSlot = numberOfSlot + middleSlot
                }
            }

            if (middleSlot === numberOfSlot + 0.5)
                middleSlot = 0.5

            // here we add two coils based on start of coil and end of coil
            const newCoilFirst = {
                ...coilSelected,
                // ending_slot: ((numberOfSlot - clickedPiece.index) + coilSelected.starting_slot) / 2,
                ending_slot: middleSlot,
                ending_layer: coilSelected.starting_layer
            }
            const newCoilSecond = {
                ...coilSelected,
                starting_slot: numberOfSlot - clickedPiece.index,
                starting_layer: numberOfLayers - clickedPiece.datasetIndex,
                // ending_slot: ((numberOfSlot - clickedPiece.index) + coilSelected.starting_slot) / 2,
                ending_slot: middleSlot,
                ending_layer: numberOfLayers - clickedPiece.datasetIndex,
                
            }
            dispatch(addCoilFunction({newCoil: newCoilFirst})).then(() => dispatch(addCoilFunction({newCoil: newCoilSecond})));
            setCoilSelected(defaultCoil)
        }
    };

    const options = {
        rotation: 90 + (180 / numberOfSlot),
        layout: {
            padding: {
                top: 50,
                bottom: 50,
                right: 50,
                left: 50
            },
        },
        animation: {
            animateRotate: false,
        },
        cutout: '50%',
        onClick: (_, elements) => {
            if (elements.length > 0) {
                const clickedPiece = elements[0];
                handlePieceClick(clickedPiece);
            }
        },
        plugins: {
            datalabels: {
                display: true,
            },
        },
    };

    return (
        <StyledWrapper>
            <div className="pie-chart">
                <Doughnut id="doughnut-chart" data={data} options={options}/>
            </div>
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    background: var(--clr-grey-7);
    text-align: center;
    justify-items: center;
    width: 72vw;
    height: 89vh;
    position: relative;

    .pie-chart {
        position: absolute;
        top: 7vh;
        left: calc((72vw - 75vh) / 2);
        width: 75vh;
    }


`
export default ConnectionTableViewerWelding;

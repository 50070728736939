import {
    Actionbar,
    ConnectionTableViewer,
    ConsoleBar,
    ConsoleWindow,
    InputLayout,
    NavBar,
    Toolbar,
    ViewerManager
} from "./index";
import {
    INPUT_CONNECTION_TABLE_FORMING_SIDE_DESIGN, INPUT_CONNECTION_TABLE_WELDING_SIDE_DESIGN,
    NAVBAR_CONNECTION_TABLE,
    NAVBAR_FORMING_SIDE,
    NAVBAR_OPTIMISATION,
    NAVBAR_STATOR,
    NAVBAR_WINDING_SIDE
} from "../data/states";
import {ToastContainer} from "react-toastify";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {
    completedStatorStl,
    completedWeldingStl,
    getAllStlFiles,
    getAllStlParameters, getStatorStl,
    getWeldingOptimisedStlFinished,
    getWeldingStl,
    globalRedo,
    globalUndo, updateOptimisationStl, updateStatorStl,
    updateWeldingStl
} from "../features/stlFilesSlice";
import {updateLogs, updateProgressBarInfo} from "../features/consoleLogSlice";
import {onValue, ref, off} from "firebase/database";
import {signInWithEmailAndPassword} from "firebase/auth";
import {db, auth} from "../firebase";
import {weldingRedo, weldingUndo} from "../features/weldingSideCoilsSlice";
import {
    completedFormingSideStl,
    formingRedo,
    formingUndo,
    getGeneratedFormingSideStl,
    updateFormingSideStl
} from "../features/formingSideCoilsSlice";
import FormingSideViewerMain from "./FormingSideViewerMain";
import {VIEWER_STATE_STANDARD} from "../data/Viewer";
import {getConnectionTableParameters} from "../features/connectionTableSlice";
import {updateAllExportDetails} from "../features/exportSlice";

const WholeWindow = () => {
    const {
        parametersLoaded,
        isWeldingReady,
        isWeldingHasError,
        isOptimisationReady,
        isOptimisationHasError,
        isStatorReady,
        isStatorHasError,
    } = useSelector((store) => store.stlParameters);
    const {
        isFormingStlHasError,
        isFormingStlReady
    } = useSelector((store) => store.formingSideCoils);
    const {viewerState} = useSelector((store) => store.viewer);
    const {showConsoleWindow} = useSelector((store) => store.consoleLog);
    const {username, password} = useSelector((store) => store.user);
    const {currentProject} = useSelector((store) => store.projects);
    const {navbarState, inputState} = useSelector((store) => store.globalItems);
    const dispatch = useDispatch();

    // full screen the site
    const toggleFullScreen = () => {
        if (document.mozFullScreen || document.webkitIsFullScreen) {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            }
        } else {
            const element = document.documentElement;
            if (element.requestFullscreen) {
                element.requestFullscreen();
            } else if (element.mozRequestFullScreen) {
                element.mozRequestFullScreen();
            } else if (element.webkitRequestFullscreen) {
                element.webkitRequestFullscreen();
            } else if (element.msRequestFullscreen) {
                element.msRequestFullscreen();
            }
        }
    };

    // we handle undo and redo functions based on navbarState in WholeWindo
    const handleUndoRedo = (event) => {
        if ((event.ctrlKey || event.metaKey) && event.shiftKey && event.key.toUpperCase() === 'Z') {
            if (navbarState === NAVBAR_STATOR || navbarState === NAVBAR_WINDING_SIDE) {
                console.log("global redo")
                dispatch(globalRedo())
            } else if (inputState === INPUT_CONNECTION_TABLE_FORMING_SIDE_DESIGN || navbarState === NAVBAR_FORMING_SIDE) {
                console.log("forming redo")
                dispatch(formingRedo())
            } else if (inputState === INPUT_CONNECTION_TABLE_WELDING_SIDE_DESIGN) {
                console.log("welding redo")
                dispatch(weldingRedo())
            }
        } else if ((event.ctrlKey || event.metaKey) && event.key.toUpperCase() === 'Z') {
            if (navbarState === NAVBAR_STATOR || navbarState === NAVBAR_WINDING_SIDE) {
                console.log("global undo")
                dispatch(globalUndo())
            } else if (inputState === INPUT_CONNECTION_TABLE_FORMING_SIDE_DESIGN || navbarState === NAVBAR_FORMING_SIDE) {
                console.log("forming undo")
                dispatch(formingUndo())
            } else if (inputState === INPUT_CONNECTION_TABLE_WELDING_SIDE_DESIGN) {
                console.log("welding undo")
                dispatch(weldingUndo())
            }
        }
    };

    // useEffect(() => {
    //     dispatch(formingBSpline())
    // }, [dispatch])

    // in this part we first get motor info data from backend then we get all stl files
    // (check src/features/stlFilesSlice.js for more details)
    useEffect(() => {
        if (parametersLoaded) {
            dispatch(getAllStlFiles())
        } else {
            dispatch(getConnectionTableParameters())
            dispatch(getAllStlParameters())
        }
    }, [dispatch, parametersLoaded])


    // we call needed api when welding stl file is ready, we listen it in firebase
    useEffect(() => {
        if (isWeldingHasError) {
            dispatch(completedWeldingStl())
        }
        if (isWeldingReady) {
            dispatch(getWeldingStl())
        }
    }, [dispatch, isWeldingReady, isWeldingHasError])

    useEffect(() => {
        if (isStatorHasError) {
            dispatch(completedStatorStl())
        }
        if (isStatorReady) {
            dispatch(getStatorStl())
        }
    }, [dispatch, isStatorReady, isStatorHasError])

    // we call needed api when forming stl file is ready, we listen it in firebase
    useEffect(() => {
        if (isFormingStlHasError) {
            dispatch(completedFormingSideStl())
        }
        if (isFormingStlReady) {
            dispatch(getGeneratedFormingSideStl())
        }
    }, [dispatch, isFormingStlHasError, isFormingStlReady])

    // we call needed api when welding optimised stl file is ready, we listen it in firebase
    useEffect(() => {
        if (isOptimisationHasError) {
            dispatch(completedFormingSideStl())
        }
        if (isOptimisationReady) {
            dispatch(getWeldingOptimisedStlFinished())
        }
    }, [dispatch, isOptimisationHasError, isOptimisationReady])

    // we sign in user to firebase with his username and password
    // so user can access data with his username and pass security rules in firebase
    useEffect(() => {
        signInWithEmailAndPassword(auth, `${username}@coil.co`, password)
    }, [username, password])

    // we start listening firebase realtime database log_topic here
    useEffect(() => {
        if (currentProject) {
            const queryLogs = ref(db, `${username}/${currentProject}/logs`);
            console.log(`${username}/${currentProject}/logs`)
            onValue(queryLogs, (snapshot) => {
                const data = snapshot.val();
                const values = data ? Object.keys(data).map(function (key) {
                    return data[key];
                }) : [];
                dispatch(updateLogs({consoleLog: values}))
            });
            return () => {
                console.log("off(queryLogs)")
                off(queryLogs)
            }
        }
    }, [dispatch, username, currentProject]);

    // we start listening firebase realtime database optimisation_topic here
    useEffect(() => {
        if (currentProject) {
            const queryOptimisation = ref(db, `${username}/${currentProject}/optimisation`);
            console.log(`${username}/${currentProject}/optimisation`)
            onValue(queryOptimisation, (snapshot) => {
                const data = snapshot.val();
                if (data) {
                    if (data["progress_bar_percent"] === 0) {
                        dispatch(updateProgressBarInfo({
                            progress_bar_title: "",
                            progress_bar_percent: 0
                        }))
                    } else {
                        dispatch(updateProgressBarInfo(data))
                    }
                }
            });
            return () => {
                console.log("off(queryOptimisation)")
                off(queryOptimisation)
            }
        }
    }, [dispatch, username, currentProject]);

    // we start listening firebase realtime database welding_stl_topic here
    useEffect(() => {
        if (currentProject) {
            const queryWeldingStl = ref(db, `${username}/${currentProject}/welding_stl`);
            console.log(`${username}/${currentProject}/welding_stl`)
            onValue(queryWeldingStl, (snapshot) => {
                const data = snapshot.val();
                if (data) {
                    dispatch(updateWeldingStl(data))
                }
            });
            return () => {
                console.log("off(queryWeldingStl)")
                off(queryWeldingStl)
            }
        }
    }, [dispatch, username, currentProject]);


    // we start listening firebase realtime database optimisation_stl_topic here
    useEffect(() => {
        if (currentProject) {
            const queryOptimisationStl = ref(db, `${username}/${currentProject}/optimisation_stl`);
            console.log(`${username}/${currentProject}/optimisation_stl`)
            onValue(queryOptimisationStl, (snapshot) => {
                const data = snapshot.val();
                if (data) {
                    dispatch(updateOptimisationStl(data))
                }
            });
            return () => {
                console.log("off(queryOptimisationStl)")
                off(queryOptimisationStl)
            }
        }
    }, [dispatch, username, currentProject]);

    useEffect(() => {
        if (currentProject) {
            const queryStatorStl = ref(db, `${username}/${currentProject}/stator_stl`);
            console.log(`${username}/${currentProject}/stator_stl`)
            onValue(queryStatorStl, (snapshot) => {
                const data = snapshot.val();
                if (data) {
                    dispatch(updateStatorStl(data))
                }
            });
            return () => {
                console.log("off(queryStatorStl)")
                off(queryStatorStl)
            }
        }
    }, [dispatch, username, currentProject]);

    // we start listening firebase realtime database forming_side_stl_topic here
    useEffect(() => {
        if (currentProject) {
            const queryFormingSideStl = ref(db, `${username}/${currentProject}/forming_side_stl`);
            console.log(`${username}/${currentProject}/forming_side_stl`)
            onValue(queryFormingSideStl, (snapshot) => {
                const data = snapshot.val();
                if (data) {
                    dispatch(updateFormingSideStl(data))
                }
            });
            return () => {
                console.log("off(queryFormingSideStl)")
                off(queryFormingSideStl)
            }
        }
    }, [dispatch, username, currentProject]);

    useEffect(() => {
        if (currentProject) {
            const queryExportDetails = ref(db, `${username}/${currentProject}/export`);
            console.log(`${username}/${currentProject}/export`)
            onValue(queryExportDetails, (snapshot) => {
                const data = snapshot.val();
                if (data) {
                    dispatch(updateAllExportDetails(data))
                }
            });
            return () => {
                console.log("off(queryExportDetails)")
                off(queryExportDetails)
            }
        }
    }, [dispatch, username, currentProject]);

    return <StyledWrapper onKeyDown={handleUndoRedo} tabIndex="0">
        <Toolbar toggleFullScreenFunc={toggleFullScreen}/>
        <Actionbar toggleFullScreenFunc={toggleFullScreen}/>
        <NVIStyledWrapper>
            <NavBar/>
            {(navbarState === NAVBAR_STATOR || navbarState === NAVBAR_WINDING_SIDE || navbarState === NAVBAR_OPTIMISATION) && (<>
                <ViewerManager toggleFullScreenFunc={toggleFullScreen}/>
                {parametersLoaded && <InputLayout/>}
            </>)}
            {(navbarState === NAVBAR_CONNECTION_TABLE) && (<>
                <InputLayout/>
                <ConnectionTableViewer/>
            </>)}
            {(navbarState === NAVBAR_FORMING_SIDE) && (<>
                <InputLayout/>
                {/*here we render FormingSideViewerMain if viewerState === VIEWER_STATE_STANDARD,
                else we render Viewer cause we must show stl files*/}
                {viewerState === VIEWER_STATE_STANDARD ? <FormingSideViewerMain/> : <ViewerManager toggleFullScreenFunc={toggleFullScreen}/>}
            </>)}
        </NVIStyledWrapper>
        <ConsoleBar toggleFullScreenFunc={toggleFullScreen}/>
        {showConsoleWindow && <ConsoleWindow/>}
        <ToastContainer position="top-center" style={{width: "25vw"}}/>
    </StyledWrapper>
};

const StyledWrapper = styled.div`
    .modal-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .modal-container-background {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
        z-index: 8;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`

const NVIStyledWrapper = styled.div`
    text-align: center;
    justify-items: center;
    width: 100vw;
    height: 89vh;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
`

export default WholeWindow
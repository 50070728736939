// Stator
// export const VALIDATE_NEGATIVE_NUMBER_OF_SLOT = "Number of slot can't be negative"
export const VALIDATE_NUMBER_OF_SLOT_MUST_BE_MULTIPLE_OF_THREE = "Number of slot must be multiple of 3"
// export const VALIDATE_NEGATIVE_STATOR_BORE_DIAMETER = "Stator bore diameter can't be negative"
// export const VALIDATE_NEGATIVE_STATOR_OUTER_DIAMETER = "Stator outer diameter can't be negative"
export const VALIDATE_STATOR_OUTER_DIAMETER_BIGGER_THAN_STATOR_BORE_DIAMETER = "Stator bore diameter can't be equal or bigger than stator outer diameter"
// export const VALIDATE_NEGATIVE_STACK_HEIGHT = "Stack height can't be negative"

// Stator Slot
// export const VALIDATE_NEGATIVE_SLOT_OPENING = "Slot opening can't be negative"
// export const VALIDATE_NEGATIVE_TOOTH_TIP = "Tooth tip can't be negative"
export const VALIDATE_SLOT_DEPTH_FORMULA = "This formula didn't get approved: (stator bore diameter) + 2 * (slot depth) <= (stator outer diameter) "
export const VALIDATE_SLOT_OPENING_BIGGER_SLOT_WIDTH = "Slot opening can't be bigger than slot width"
export const VALIDATE_TOOTH_TIP_BIGGER_THAN_SLOT_WIDTH = "Tooth tip can't be bigger than slot width "
export const VALIDATE_TOOTH_TIP_ANGLE = "Tooth tip angle must be between 90 and 0 "
export const VALIDATE_PAPER_THICKNESS_RANGE = "Paper thickness must be equal or between 0.05 and 0.5"
export const VALIDATE_TOOTH_TIP_SFR3_AND_SFR4 = "Tooth tip must be bigger than sfr3 + sfr4"
export const VALIDATE_PAPER_OVERLAP_LENGTH_MAX = "Paper overlap must be lower than output of the following formula: (slot width) - (2 * (paper thickness))"
export const VALIDATE_PAPER_OVERLAP_LENGTH_MIN = "Paper overlap must be equal or more than 0.1"
export const VALIDATE_SFR1_BIGGER_THAN_SLOT_WIDTH = "Sfr1 can't be bigger than half of slot width"
export const VALIDATE_SFR2_BIGGER_THAN_SLOT_WIDTH = "Sfr2 can't be bigger than half of slot width"
export const VALIDATE_SFR3_BIGGER_THAN_SLOT_WIDTH = "Sfr3 can't be bigger than half of slot width"
export const VALIDATE_SFR4_BIGGER_THAN_SLOT_WIDTH = "Sfr4 can't be bigger than half of slot width"
export const VALIDATE_PAPER_TO_WIRE_CLEARANCE = "Paper to wire clearance must be equal or between 0.1 and 0.2"
export const VALIDATE_WIRE_INSULATION_THICKNESS_BIGGER_THAN_WIRE_FILLET_RADIUS = "Wire Fillet Radius must be bigger than Wire Insulation Thickness"
export const VALIDATE_WIRE_INSULATION_RANGE = "Wire Insulation must be equal or between 0.01 and 1"
export const VALIDATE_WIRE_TO_WIRE_CLEARANCE = "Wire to wire clearance must be equal or between 0.1 and 0.2"
export const VALIDATE_PAPER_THICKNESS_BIGGER_THAN_SFR1 = "Paper thickness can't be equal or bigger than sfr1"
export const VALIDATE_CORE_BACK_LESS_THAN_STATIC = "Slot depth can not exceed outer diameter"

// Winding Design
export const VALIDATE_NUMBER_OF_LAYERS_MUST_BE_MULTIPLE_OF_TWO = "Number of layers must be multiple of 2"
export const VALIDATE_SPAN_BIGGER_THAN_NUMBER_OF_SLOT = "Span can't be bigger than number of slot"

// Welding Side Design
export const VALIDATE_WIDENING_GAP_BIGGER_THAN_THREE = "Widening gap can't be more than 10"
export const VALIDATE_ISOLATED_WIRE_END_LENGTH_RANGE = "Isolated wire-end length must be equal or between 2 and 3"
export const VALIDATE_STRIPPED_WIRE_LENGTH_RANGE = "Stripped wire length must be equal one of :  8 10 12"

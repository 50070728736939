import {VIEWER_STATE_STANDARD} from "../data/Viewer";
import {createSlice} from "@reduxjs/toolkit";

// READ DOCUMENTS FOR FEATURES FILES
const initialState = {
    viewerState: VIEWER_STATE_STANDARD
};

const viewerSlice = createSlice({
    name: 'viewer',
    initialState,
    reducers: {
        changeViewerState: (state, {payload}) => {
            state.viewerState = payload.viewerState
        },
    },
});

export const {changeViewerState} = viewerSlice.actions;
export default viewerSlice.reducer;
import styled from "styled-components";
import {useState} from "react";

const AnimationOptionsInputs = () => {
    const [isAnimationReady, setIsAnimationReady] = useState(false);

    // change column style
    // we do not use functionality of this window in this version
    const getButtonsStyle = () => {
        if (isAnimationReady){
            return {gridTemplateColumns: "2fr 3fr 2fr"}
        }
        return {gridTemplateColumns: "1fr"}
    }

    return (
            <StyledWrapper>
                <div className="welding-side">
                    <span className="section-title">Welding Section:</span>
                    <div className="select-model">
                        <select>
                            <option value="potimised-model">Optimised Model</option>
                            <option value="real-model">Real Model</option>
                        </select>
                    </div>
                    <div className="radio-input-wrapper">
                        <label className="label">
                            <input className="radio-input" type="radio"  id="optimisation" value="optimisation" name="animation-welding-section-options" onChange={(e) => {
                                console.log(e.target)
                                setIsAnimationReady(true)
                            }}/>
                            <div className="radio-design"></div>
                            <div className="label-text">Optimisation</div>
                        </label>
                        <label className="label">
                            <input className="radio-input" type="radio" id="twisting" value="twisting" name="animation-welding-section-options" onChange={(e) => {
                                console.log(e.target)
                                setIsAnimationReady(false)
                            }}/>
                            <div className="radio-design"></div>
                            <div className="label-text">Twisting</div>
                        </label>
                        <label className="label">
                            <input className="radio-input" type="radio" id="spring-back-effect" value="spring-back-effect" name="animation-welding-section-options" onChange={(e) => {
                                console.log(e.target)
                                setIsAnimationReady(false)
                            }}/>
                            <div className="radio-design"></div>
                            <div className="label-text">Spring Back Effect</div>
                        </label>
                    </div>
                </div>
                <div className="animation-buttons" style={getButtonsStyle()}>
                    {isAnimationReady && <button className="previous-btn">Previous</button>}
                    <button className="play-btn">Play</button>
                    {isAnimationReady && <button className="next-btn">Next</button>}
                </div>
            </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    .welding-side {
        margin-top: 2vh;
        text-align: left;

        .section-title {
            font-size: 14px;
            color: var(--clr-grey-7);
        }

        .select-model {
            select {
                margin-top: 1vh;
                padding-left: 0.5vw;
                width: 20vh;
                height: 3vh;
                background-color: var(--clr-grey-2);
                border: none;
                border-radius: 6px;
                color: var(--clr-grey-9);
                cursor: pointer;
            }
        }
    }

    .animation-buttons {
        //width: 5vw;
        display: grid;
        grid-template-columns: 2fr 3fr 2fr;

        button {
            cursor: pointer;
            height: 3.5vh;
            color: var(--clr-grey-9);
            border-radius: 7px;

            &:hover {
                font-family: Ubuntu-Bold, serif;
            }
        }

        .play-btn {
            background: var(--clr-primary);
            border: none;
        }

        .previous-btn {
            background: transparent;
            margin-right: 0.5vw;
            border: solid 1px var(--clr-grey-7);
        }

        .next-btn {
            background: transparent;
            margin-left: 0.5vw;
            border: solid 1px var(--clr-grey-7);
        }
    }
`


export default AnimationOptionsInputs;

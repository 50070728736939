import React, {useRef, useEffect} from 'react';
import * as THREE from 'three';
import {OrbitControls} from 'three/addons/controls/OrbitControls';
import {STLLoader, TrackballControls} from "three/addons";
import {useDispatch, useSelector} from "react-redux";
import styled from "styled-components";
import {
    changeCurrentViewId,
    setCameraPosition,
} from "../features/cameraSlice";
import {FontLoader} from "three/addons/loaders/FontLoader.js";
import {TextGeometry} from "three/addons/geometries/TextGeometry.js";

const MultiViewScene = ({
                            cameraData, inputData: stlFilesWithColors, currentViewIdInput, inputState, viewerState,
                            showNumbers, is2DView
                        }) => {
    const dispatch = useDispatch();
    const currentViewId = is2DView ? 1 : currentViewIdInput
    const {
        stator_bore_diameter,
        number_of_slot,
        stator_outer_diameter,
        slot_depth,
        slot_width,
        stack_height
    } = useSelector((store) => store.stlParameters);
    const {
        toggleButtonAction,
    } = useSelector((store) => store.camera);

    const {
        formingSideCoils,
    } = useSelector((store) => store.formingSideCoils);
    const formingColors = [0x646b40, 0x93c3b6, 0xf3cd54, 0x9379ff, 0xa84489, 0xa0bde1,
        0x33574e, 0xc9d895, 0xe6b155, 0xd35460, 0xdda7b5, 0x354d96]
    const mountRef = useRef(null);
    const isMouseDown = useRef(false);

    useEffect(() => {
        if (mountRef.current === null || !stlFilesWithColors.length) return;
        const newMeshes = [];        // const mount = mountRef.current;
        while (mountRef.current.firstChild) {
            mountRef.current.removeChild(mountRef.current.firstChild);
        }
        const width = mountRef.current.clientWidth;
        const height = mountRef.current.clientHeight;
        const scene = new THREE.Scene();
        const renderers = []
        const cameras = []
        const controllers = []
        const axisScenes = []
        const axisRenderers = []
        const axisCameras = []
        const textObjects = []

        let centerX = 0
        let centerY = 0
        let centerZ = 0
        for (const stlFileWithColors of stlFilesWithColors) {
            // every input is a list, so we get them one by one
            for (const stlData of stlFileWithColors[0]) {
                // our welding layer is just one coil per layer, so we must rotate the stl based on number_of_slot
                if (stlFileWithColors.length > 2 && stlFileWithColors[2] === "shouldRotate") {
                    for (let i = 0; i < number_of_slot; i++) {
                        const loader = new STLLoader();
                        const geometry = loader.parse(stlData);
                        const material = new THREE.MeshMatcapMaterial({color: stlFileWithColors[1]});
                        material.side = THREE.DoubleSide;
                        const meshWelding = new THREE.Mesh(geometry, material);
                        meshWelding.scale.set(1, 1, 1);
                        meshWelding.castShadow = true;
                        meshWelding.receiveShadow = true;
                        // we set stl in the middle
                        if (cameraData.meshPositionDescription === "based-on-bore-diameter-stator-slot") {
                            meshWelding.position.x = -((stator_outer_diameter + stator_bore_diameter) / 4);
                            // meshWelding.position.y += meshYPosition;
                            // meshWelding.position.z += meshZPosition;
                        } else if (cameraData.meshPositionDescription === "based-on-bore-diameter-wire") {
                            meshWelding.position.x = -((2 * slot_depth + stator_bore_diameter) / 2);
                            // meshWelding.position.y += meshYPosition;
                            // meshWelding.position.z += meshZPosition;
                        } else if (cameraData.meshPositionDescription !== "stl-with-stator") {
                            // meshWelding.position.x += meshXPosition;
                            // meshWelding.position.y += meshYPosition;
                            // meshWelding.position.z += meshZPosition;
                        }

                        const degrees = i * (360 / number_of_slot)
                        meshWelding.rotation.z = degrees * (Math.PI / 180);
                        if (cameraData.meshPositionDescription === "stl-with-stator") {
                            meshWelding.position.x = centerX;
                            meshWelding.position.y = centerY;
                            meshWelding.position.z = centerZ;
                        }
                        scene.add(meshWelding);
                        newMeshes.push(meshWelding);
                        geometry.dispose()
                        material.dispose()
                    }
                    // our forming layer is just one coil per layer, so we must rotate the stl based on coil children
                } else if (stlFileWithColors.length > 2 && stlFileWithColors[2] === "shouldRotateForming") {
                    let coil = formingSideCoils.find(forming_coil => forming_coil.coil_name === stlData["coil_name"]);
                    let coilNumber = coil["coil_children"].length
                    let coilChangeSlot = 0
                    for (let i = 0; i <= coilNumber; i++) {
                        const loader = new STLLoader();
                        const geometry = loader.parse(atob(stlData["stl"]));
                        // group.rotation.z = (coil["starting_slot"] - 1) * (360/number_of_slot) * (Math.PI / 180)
                        // const material = new THREE.MeshMatcapMaterial({color: stlFileWithColors[1]});
                        const material = new THREE.MeshMatcapMaterial({color: formingColors[(coil["coil_name"] - 1) % 12]});
                        material.side = THREE.DoubleSide;
                        const meshForming = new THREE.Mesh(geometry, material);
                        // meshForming.scale.set(1, 1, -1);
                        meshForming.castShadow = true;
                        meshForming.receiveShadow = true;
                        // we set stl in the middle
                        if (cameraData.meshPositionDescription === "based-on-bore-diameter-stator-slot") {
                            meshForming.position.x = -((stator_outer_diameter + stator_bore_diameter) / 4);
                            // meshForming.position.y += meshYPosition;
                            // meshForming.position.z += meshZPosition;
                        } else if (cameraData.meshPositionDescription === "based-on-bore-diameter-wire") {
                            meshForming.position.x = -((2 * slot_depth + stator_bore_diameter) / 2);
                            // meshForming.position.y += meshYPosition;
                            // meshForming.position.z += meshZPosition;
                        } else if (cameraData.meshPositionDescription !== "stl-with-stator") {
                            // meshForming.position.x += meshXPosition;
                            // meshForming.position.y += meshYPosition;
                            // meshForming.position.z += meshZPosition;
                        }
                        meshForming.position.z = 0;
                        let degrees = 0
                        if (coilNumber !== i) {
                            coilChangeSlot = coil["starting_slot"] -
                                coil["coil_children"][i]["starting_slot"]
                            coilChangeSlot = coilChangeSlot > 0 ? coilChangeSlot : number_of_slot + coilChangeSlot
                            degrees = 360 * coilChangeSlot / number_of_slot
                        }
                        // meshForming.rotation.z = -degrees * (Math.PI / 180);
                        meshForming.rotation.z = -(degrees * (Math.PI / 180))
                        if (cameraData.meshPositionDescription === "stl-with-stator") {
                            meshForming.position.x = centerX;
                            meshForming.position.y = centerY;
                            meshForming.position.z = centerZ;
                        }
                        scene.add(meshForming);
                        newMeshes.push(meshForming);
                        geometry.dispose()
                        material.dispose()
                    }
                    // we show normal stl files
                } else {
                    // code to render string response file directly:
                    const loader = new STLLoader();
                    const geometry = loader.parse(stlData);
                    const material = new THREE.MeshMatcapMaterial({color: stlFileWithColors[1]});
                    material.side = THREE.DoubleSide;
                    const meshOther = new THREE.Mesh(geometry, material);
                    meshOther.scale.set(1, 1, 1);
                    meshOther.castShadow = true;
                    meshOther.receiveShadow = true;
                    // we set stl in the middle
                    if (cameraData.meshPositionDescription === "based-on-bore-diameter-stator-slot") {
                        meshOther.position.x = -((stator_outer_diameter + stator_bore_diameter) / 4);
                        // meshOther.position.y += meshYPosition;
                        // meshOther.position.z += meshZPosition;
                    } else if (cameraData.meshPositionDescription === "based-on-bore-diameter-wire") {
                        meshOther.position.x = -((2 * slot_depth + stator_bore_diameter) / 2);
                        // meshOther.position.y += meshYPosition;
                        // meshOther.position.z += meshZPosition;
                    } else if (cameraData.meshPositionDescription === "stl-with-stator") {
                        geometry.computeBoundingBox();
                        const boundingBox = geometry.boundingBox;
                        centerX = -0.5 * (boundingBox.min.x + boundingBox.max.x);
                        centerY = -0.5 * (boundingBox.min.y + boundingBox.max.y);
                        centerZ = -0.5 * (boundingBox.min.z + boundingBox.max.z);
                    } else {
                        // meshOther.position.x += meshXPosition;
                        // meshOther.position.y += meshYPosition;
                        // meshOther.position.z += meshZPosition;
                    }

                    if (cameraData.meshPositionDescription === "stl-with-stator") {
                        meshOther.position.x = centerX;
                        meshOther.position.y = centerY;
                        meshOther.position.z = centerZ;
                    }
                    scene.add(meshOther);
                    newMeshes.push(meshOther);
                    geometry.dispose()
                    material.dispose()
                }
            }
        }
        if (showNumbers) {
            const loader = new FontLoader();
            loader.load('ubuntu_bold.typeface.json', function (font) {
                const textMaterial = new THREE.MeshBasicMaterial({color: 0x757575});
                const numberDistance = ((stator_bore_diameter + stator_outer_diameter + (2 * slot_depth)) / 4) - (slot_width * 0.325);
                const groupTopNumbers = new THREE.Group();
                const groupBottomNumbers = new THREE.Group();
                for (let i = 1; i <= number_of_slot; i++) {
                    const theta = (i * 360 / number_of_slot); // degrees
                    const x = numberDistance * Math.cos(THREE.MathUtils.degToRad(theta));
                    const y = numberDistance * Math.sin(THREE.MathUtils.degToRad(theta));
                    const textGeometry = new TextGeometry(i.toString().padStart(2, '0'), {
                        font: font,
                        size: slot_width * 0.65,
                        height: 0.01,
                    });
                    const textMeshTop = new THREE.Mesh(textGeometry, textMaterial);
                    textMeshTop.position.set(x, y, 0);
                    textMeshTop.rotation.z = THREE.MathUtils.degToRad(theta) + Math.PI + Math.PI / 2; // optional rotation adjustment
                    // textMeshTop.position.z += meshZPosition + stack_height;
                    textMeshTop.position.z += stack_height;
                    groupTopNumbers.add(textMeshTop);

                    const textMeshBottom = new THREE.Mesh(textGeometry, textMaterial);
                    textMeshBottom.position.set(x, y, 0);
                    textMeshBottom.rotation.z = -THREE.MathUtils.degToRad(theta) + Math.PI / 2; // optional rotation adjustment
                    textMeshBottom.rotation.y = Math.PI; // optional rotation adjustment
                    // textMeshBottom.position.z += meshZPosition;
                    groupBottomNumbers.add(textMeshBottom);

                }
                groupTopNumbers.rotation.z = THREE.MathUtils.degToRad(slot_width * 0.65 / 2 - (360 / number_of_slot))
                groupBottomNumbers.rotation.z = THREE.MathUtils.degToRad(-slot_width * 0.65 / 2 - (360 / number_of_slot))
                if (cameraData.meshPositionDescription === "stl-with-stator") {
                    groupTopNumbers.position.x += centerX;
                    groupTopNumbers.position.y += centerY;
                    groupTopNumbers.position.z += centerZ;
                    groupBottomNumbers.position.x += centerX;
                    groupBottomNumbers.position.y += centerY;
                    groupBottomNumbers.position.z += centerZ;
                }
                topMesh.add(groupTopNumbers)
                bottomMesh.add(groupBottomNumbers)

            })
            const geometry2 = new THREE.SphereGeometry(0, 64, 32);
            const material2 = new THREE.MeshBasicMaterial({color: 0xbcbcbc});
            const topMesh = new THREE.Mesh(geometry2, material2);
            const bottomMesh = new THREE.Mesh(geometry2, material2);
            scene.add(topMesh)
            scene.add(bottomMesh)
        }

        // Create cameras
        const aspect = width / height;
        (!currentViewId || currentViewId === 1) && cameras.push({
            camera: new THREE.OrthographicCamera(-10 * aspect, 10 * aspect, 10, -10),
            id: 1
        });
        (!currentViewId || currentViewId === 2) && cameras.push({
            camera: new THREE.OrthographicCamera(-10 * aspect, 10 * aspect, 10, -10),
            id: 2
        });
        (!currentViewId || currentViewId === 3) && cameras.push({
            camera: new THREE.OrthographicCamera(-10 * aspect, 10 * aspect, 10, -10),
            id: 3
        });
        (!currentViewId || currentViewId === 4) && cameras.push({
            camera: new THREE.OrthographicCamera(-10 * aspect, 10 * aspect, 10, -10),
            id: 4
        });

        const lookAtPosition = new THREE.Vector3(0, 0, 0);

        function setCameraConfigs(camera, cameraId) {
            camera.lookAt(lookAtPosition);
            if (cameraId === 1) {
                camera.position.set(cameraData.view1.cameraXPosition, cameraData.view1.cameraYPosition, cameraData.view1.cameraZPosition);
                camera.up.set(cameraData.view1.cameraXUp, cameraData.view1.cameraYUp, cameraData.view1.cameraZUp);
                camera.quaternion.set(0, 0, 0);
                camera.zoom = cameraData.view1.zoom
                camera.updateProjectionMatrix();
            } else if (cameraId === 2) {
                camera.position.set(cameraData.view2.cameraXPosition, cameraData.view2.cameraYPosition, cameraData.view2.cameraZPosition);
                camera.up.set(cameraData.view2.cameraXUp, cameraData.view2.cameraYUp, cameraData.view2.cameraZUp);
                camera.zoom = cameraData.view2.zoom
                camera.updateProjectionMatrix();
            } else if (cameraId === 3) {
                camera.position.set(cameraData.view3.cameraXPosition, cameraData.view3.cameraYPosition, cameraData.view3.cameraZPosition);
                camera.up.set(cameraData.view3.cameraXUp, cameraData.view3.cameraYUp, cameraData.view3.cameraZUp);
                camera.zoom = cameraData.view3.zoom
                camera.updateProjectionMatrix();
            } else if (cameraId === 4) {
                camera.position.set(cameraData.view4.cameraXPosition, cameraData.view4.cameraYPosition, cameraData.view4.cameraZPosition);
                camera.up.set(cameraData.view4.cameraXUp, cameraData.view4.cameraYUp, cameraData.view4.cameraZUp);
                camera.zoom = cameraData.view4.zoom
                camera.updateProjectionMatrix();
            }
        }

        cameras.forEach(camera => {
            setCameraConfigs(camera.camera, camera.id)
        });

        // Create renderers for each viewport
        (!currentViewId || currentViewId === 1) && renderers.push({
            renderer: new THREE.WebGLRenderer(),
            id: 1
        });
        (!currentViewId || currentViewId === 2) && renderers.push({
            renderer: new THREE.WebGLRenderer(),
            id: 2
        });
        (!currentViewId || currentViewId === 3) && renderers.push({
            renderer: new THREE.WebGLRenderer(),
            id: 3
        });
        (!currentViewId || currentViewId === 4) && renderers.push({
            renderer: new THREE.WebGLRenderer(),
            id: 4
        });

        const halfWidth = !currentViewId ? Math.floor(width / 2) : Math.floor(width);
        const halfHeight = !currentViewId ? Math.floor(height / 2) : Math.floor(height);
        const backgroundColor = getComputedStyle(document.documentElement).getPropertyValue('--clr-grey-7').trim();
        const borderColor = getComputedStyle(document.documentElement).getPropertyValue('--clr-grey-5').trim();

        function setRendererConfigs(renderer, rendererId) {
            mountRef.current.appendChild(renderer.domElement);
            renderer.setSize(halfWidth - 2, halfHeight - 2);
            renderer.domElement.style.position = 'absolute';
            renderer.domElement.style.border = `1px solid ${borderColor}`;
            renderer.setClearColor(new THREE.Color(backgroundColor));
            if (currentViewId) {
                renderer.domElement.style.top = '0px';
                renderer.domElement.style.left = '0px';
                renderer.domElement.style.border = `1px solid ${borderColor}`;
            } else {
                if (rendererId === 1) {
                    renderer.domElement.style.top = '0px';
                    renderer.domElement.style.left = '0px';
                    renderer.domElement.style.border = `1px solid ${borderColor}`;
                } else if (rendererId === 2) {
                    renderer.domElement.style.top = '0px';
                    renderer.domElement.style.left = `${halfWidth}px`;
                    renderer.domElement.style.border = `1px solid ${borderColor}`;
                } else if (rendererId === 3) {
                    renderer.domElement.style.top = `${halfHeight}px`;
                    renderer.domElement.style.left = '0px';
                    renderer.domElement.style.border = `1px solid ${borderColor}`;
                } else if (rendererId === 4) {
                    renderer.domElement.style.top = `${halfHeight}px`;
                    renderer.domElement.style.left = `${halfWidth}px`;
                    renderer.domElement.style.border = `1px solid ${borderColor}`;
                }
            }
        }

        renderers.forEach(renderer => {
            setRendererConfigs(renderer.renderer, renderer.id)
        });

        (!currentViewId || currentViewId === 1) && controllers.push({
            controller: new TrackballControls(
                cameras.find(cameraObj => cameraObj.id === 1).camera,
                renderers.find(rendererObj => rendererObj.id === 1).renderer.domElement
            ),
            id: 1
        });
        (!currentViewId || currentViewId === 2) && controllers.push({
            controller: new OrbitControls(
                cameras.find(cameraObj => cameraObj.id === 2).camera,
                renderers.find(rendererObj => rendererObj.id === 2).renderer.domElement
            ),
            id: 2
        });
        (!currentViewId || currentViewId === 3) && controllers.push({
            controller: new OrbitControls(
                cameras.find(cameraObj => cameraObj.id === 3).camera,
                renderers.find(rendererObj => rendererObj.id === 3).renderer.domElement
            ),
            id: 3
        });
        (!currentViewId || currentViewId === 4) && controllers.push({
            controller: new OrbitControls(
                cameras.find(cameraObj => cameraObj.id === 4).camera,
                renderers.find(rendererObj => rendererObj.id === 4).renderer.domElement
            ),
            id: 4
        });

        function setControllerConfigs(controller, controllerId) {
            if (controllerId === 1) {
                controller.target.set(cameraData.view1.controlsXPosition, cameraData.view1.controlsYPosition, cameraData.view1.controlsZPosition)
                controller.rotateSpeed = 2.0;
                controller.zoomSpeed = 1.2;
                controller.panSpeed = 0.8;
                controller.noRotate = !cameraData.enableRotate;
            } else if (controllerId === 2) {
                controller.target.set(cameraData.view2.controlsXPosition, cameraData.view2.controlsYPosition, cameraData.view2.controlsZPosition)
                controller.enableRotate = false;
                controller.enablePan = false;
            } else if (controllerId === 3) {
                controller.target.set(cameraData.view3.controlsXPosition, cameraData.view3.controlsYPosition, cameraData.view3.controlsZPosition)
                controller.enableRotate = false;
                controller.enablePan = false;
            } else if (controllerId === 4) {
                controller.target.set(cameraData.view4.controlsXPosition, cameraData.view4.controlsYPosition, cameraData.view4.controlsZPosition)
                controller.enableRotate = false;
                controller.enablePan = false;
            }
        }

        controllers.forEach(controller => {
            setControllerConfigs(controller.controller, controller.id)
        });

        (!currentViewId || currentViewId === 1) && axisScenes.push({
            axisScene: new THREE.Scene(),
            id: 1
        });
        (!currentViewId || currentViewId === 2) && axisScenes.push({
            axisScene: new THREE.Scene(),
            id: 2
        });
        (!currentViewId || currentViewId === 3) && axisScenes.push({
            axisScene: new THREE.Scene(),
            id: 3
        });
        (!currentViewId || currentViewId === 4) && axisScenes.push({
            axisScene: new THREE.Scene(),
            id: 4
        });

        const geometry = new THREE.SphereGeometry(1, 64, 32);
        const material = new THREE.MeshBasicMaterial({color: 0xbcbcbc});

        function generateArrow(color, position, rotation) {
            const length = 9;
            const hex = color;

            const arrowHead = new THREE.Mesh(
                new THREE.ConeGeometry(1, 2, 32),  // Increased radial segments
                new THREE.MeshBasicMaterial({color: hex})
            );
            const arrowLine = new THREE.Mesh(
                new THREE.CylinderGeometry(0.3, 0.3, length - 2, 32),  // Increased radial segments
                new THREE.MeshBasicMaterial({color: hex})
            );
            arrowLine.position.set(position[0] / 2, position[1] / 2, position[2] / 2)
            arrowHead.position.set(position[0], position[1], position[2]);
            arrowHead.rotation.set(rotation[0], rotation[1], rotation[2]);
            arrowLine.rotation.set(rotation[0], rotation[1], rotation[2]);

            const arrowGroup = new THREE.Group();
            arrowGroup.add(arrowHead);
            arrowGroup.add(arrowLine);
            return arrowGroup
        }

        (!currentViewId || currentViewId === 1) && textObjects.push({
            textObject: new THREE.Mesh(geometry, material),
            id: 1
        });
        (!currentViewId || currentViewId === 2) && textObjects.push({
            textObject: new THREE.Mesh(geometry, material),
            id: 2
        });
        (!currentViewId || currentViewId === 3) && textObjects.push({
            textObject: new THREE.Mesh(geometry, material),
            id: 3
        });
        (!currentViewId || currentViewId === 4) && textObjects.push({
            textObject: new THREE.Mesh(geometry, material),
            id: 4
        });

        function loadXYZ(textObject) {
            const loader = new FontLoader();
            let xLabel = null
            let yLabel = null
            let zLabel = null
            loader.load('ubuntu_bold.typeface.json', function (font) {
                const textOptions = {
                    font: font,
                    size: 2,
                    height: 0.1
                };

                const textMaterialX = new THREE.MeshBasicMaterial({color: 0xececec});
                const textGeometryX = new TextGeometry('X', textOptions);
                xLabel = new THREE.Mesh(textGeometryX, textMaterialX);
                xLabel.position.set(12, 0, 0);

                const textMaterialY = new THREE.MeshBasicMaterial({color: 0xececec});
                const textGeometryY = new TextGeometry('Y', textOptions);
                yLabel = new THREE.Mesh(textGeometryY, textMaterialY);
                yLabel.position.set(0, 12, 0);

                const textMaterialZ = new THREE.MeshBasicMaterial({color: 0xececec});
                const textGeometryZ = new TextGeometry('Z', textOptions);
                zLabel = new THREE.Mesh(textGeometryZ, textMaterialZ);
                zLabel.position.set(0, 0, 12);

                textObject.add(xLabel)
                textObject.add(yLabel)
                textObject.add(zLabel)
            })
        }

        function setAxisSceneConfigs(axisScene, axisSceneId) {
            const object = new THREE.Mesh(geometry, material);
            object.add(generateArrow(0xFF800E, [9, 0, 0], [0, 0, -Math.PI / 2]));
            object.add(generateArrow(0x65E870, [0, 9, 0], [0, 0, 0]));
            object.add(generateArrow(0x71C8FF, [0, 0, 9], [Math.PI / 2, 0, 0]));
            axisScene.add(object)
            if (axisSceneId === 1) {
                loadXYZ(textObjects.find(textObject => textObject.id === 1).textObject)
                axisScene.add(textObjects.find(textObject => textObject.id === 1).textObject)
            } else if (axisSceneId === 2) {
                loadXYZ(textObjects.find(textObject => textObject.id === 2).textObject)
                axisScene.add(textObjects.find(textObject => textObject.id === 2).textObject)
            } else if (axisSceneId === 3) {
                loadXYZ(textObjects.find(textObject => textObject.id === 3).textObject)
                axisScene.add(textObjects.find(textObject => textObject.id === 3).textObject)
            } else if (axisSceneId === 4) {
                loadXYZ(textObjects.find(textObject => textObject.id === 4).textObject)
                axisScene.add(textObjects.find(textObject => textObject.id === 4).textObject)
            }
        }

        axisScenes.forEach(axisScene => {
            setAxisSceneConfigs(axisScene.axisScene, axisScene.id)
        });
        const size = Math.min(mountRef.current.clientWidth, mountRef.current.clientHeight) * 0.15; // 20% of the smallest dimension

        (!currentViewId || currentViewId === 1) && axisRenderers.push({
            axisRenderer: new THREE.WebGLRenderer(),
            id: 1
        });
        (!currentViewId || currentViewId === 2) && axisRenderers.push({
            axisRenderer: new THREE.WebGLRenderer(),
            id: 2
        });
        (!currentViewId || currentViewId === 3) && axisRenderers.push({
            axisRenderer: new THREE.WebGLRenderer(),
            id: 3
        });
        (!currentViewId || currentViewId === 4) && axisRenderers.push({
            axisRenderer: new THREE.WebGLRenderer(),
            id: 4
        });

        function setAxisRendererConfigs(axisRenderer, axisRendererId) {
            axisRenderer.setClearColor(new THREE.Color(borderColor), 0);
            axisRenderer.setSize(size, size);
            mountRef.current.appendChild(axisRenderer.domElement);
            axisRenderer.domElement.style.position = 'absolute';
            if (currentViewId) {
                axisRenderer.domElement.style.left = `${halfWidth - size - 1}px`
                axisRenderer.domElement.style.top = `${halfHeight - size - 1}px`
            } else {
                if (axisRendererId === 1) {
                    axisRenderer.domElement.style.left = `${halfWidth - size - 1}px`
                    axisRenderer.domElement.style.top = `${halfHeight - size - 1}px`
                } else if (axisRendererId === 2) {
                    axisRenderer.domElement.style.left = `${halfWidth * 2 - size - 1}px`
                    axisRenderer.domElement.style.top = `${halfHeight - size - 1}px`
                } else if (axisRendererId === 3) {
                    axisRenderer.domElement.style.left = `${halfWidth - size - 1}px`
                    axisRenderer.domElement.style.top = `${halfHeight * 2 - size - 1}px`
                } else if (axisRendererId === 4) {
                    axisRenderer.domElement.style.left = `${halfWidth * 2 - size - 1}px`
                    axisRenderer.domElement.style.top = `${halfHeight * 2 - size - 1}px`
                }
            }
        }

        axisRenderers.forEach(axisRenderer => {
            setAxisRendererConfigs(axisRenderer.axisRenderer, axisRenderer.id)
        });

        (!currentViewId || currentViewId === 1) && axisCameras.push({
            axisCamera: new THREE.PerspectiveCamera(cameraData.fov + 0.5, aspect),
            id: 1
        });
        (!currentViewId || currentViewId === 2) && axisCameras.push({
            axisCamera: new THREE.PerspectiveCamera(cameraData.fov + 0.5, aspect),
            id: 2
        });
        (!currentViewId || currentViewId === 3) && axisCameras.push({
            axisCamera: new THREE.PerspectiveCamera(cameraData.fov + 0.5, aspect),
            id: 3
        });
        (!currentViewId || currentViewId === 4) && axisCameras.push({
            axisCamera: new THREE.PerspectiveCamera(cameraData.fov + 0.5, aspect),
            id: 4
        });

        function setAxisCameraConfigs(axisCamera, axisCameraId) {
            if (axisCameraId === 1) {
                axisCamera.up = cameras.find(cameraObj => cameraObj.id === 1).camera.up;
            } else if (axisCameraId === 2) {
                axisCamera.up = cameras.find(cameraObj => cameraObj.id === 2).camera.up;
                axisCamera.updateProjectionMatrix();
            } else if (axisCameraId === 3) {
                axisCamera.up = cameras.find(cameraObj => cameraObj.id === 3).camera.up;
                axisCamera.updateProjectionMatrix();
            } else if (axisCameraId === 4) {
                axisCamera.up = cameras.find(cameraObj => cameraObj.id === 4).camera.up;
                axisCamera.updateProjectionMatrix();
            }
        }

        axisCameras.forEach(axisCamera => {
            setAxisCameraConfigs(axisCamera.axisCamera, axisCamera.id)
        });


        const handleMouseDown = () => {
            isMouseDown.current = true;
        };
        const handleMouseUp = () => {
            isMouseDown.current = false;
        };
        const handleMouseMove2 = () => {
            if (!isMouseDown.current) return;
            const camera = cameras.find(cameraObj => cameraObj.id === 2).camera
            camera.up.set(0, 0, 1)
            const phi = Math.atan2(camera.position.y, camera.position.x) + 0.005;
            camera.position.x = 700 * Math.cos(phi);
            camera.position.y = 700 * Math.sin(phi);
            camera.lookAt(lookAtPosition);
        };
        const handleMouseMove3 = () => {
            if (!isMouseDown.current) return;
            const camera = cameras.find(cameraObj => cameraObj.id === 3).camera
            let angle = 0.01;
            let cosAngle = Math.cos(angle);
            let sinAngle = Math.sin(angle);
            let newUpX = camera.up.x * cosAngle - camera.up.y * sinAngle;
            let newUpY = camera.up.x * sinAngle + camera.up.y * cosAngle;
            let newUpZ = camera.up.z;
            camera.up.set(newUpX, newUpY, newUpZ);
            camera.lookAt(lookAtPosition);
            camera.updateProjectionMatrix();
        };
        const handleMouseMove4 = () => {
            if (!isMouseDown.current) return;
            const camera = cameras.find(cameraObj => cameraObj.id === 4).camera
            let angle = -0.01;
            let cosAngle = Math.cos(angle);
            let sinAngle = Math.sin(angle);
            let newUpX = camera.up.x * cosAngle - camera.up.y * sinAngle;
            let newUpY = camera.up.x * sinAngle + camera.up.y * cosAngle;
            let newUpZ = camera.up.z;
            camera.up.set(newUpX, newUpY, newUpZ);
            camera.lookAt(lookAtPosition);
            camera.updateProjectionMatrix();
        };
        const attachEventListeners = (renderer, moveFunc) => {
            renderer.domElement.addEventListener('mousedown', handleMouseDown);
            renderer.domElement.addEventListener('mousemove', moveFunc, false);
            renderer.domElement.addEventListener('mouseup', handleMouseUp);
        };
        const dblClick1 = () => {
            if (is2DView) return
            const newValue = currentViewId === 1 ? 0 : 1
            // changeCurrentViewIdFunc(newValue)
            dispatch(changeCurrentViewId({currentViewId: newValue}))
        };
        const dblClick2 = () => {
            const newValue = currentViewId === 2 ? 0 : 2
            // changeCurrentViewIdFunc(newValue)
            dispatch(changeCurrentViewId({currentViewId: newValue}))
        };
        const dblClick3 = () => {
            const newValue = currentViewId === 3 ? 0 : 3
            // changeCurrentViewIdFunc(newValue)
            dispatch(changeCurrentViewId({currentViewId: newValue}))
        };
        const dblClick4 = () => {
            const newValue = currentViewId === 4 ? 0 : 4
            // changeCurrentViewIdFunc(newValue)
            dispatch(changeCurrentViewId({currentViewId: newValue}))
        };

        function setRendererEventListener(renderer, rendererId) {
            if (rendererId === 1) {
                renderer.domElement.addEventListener('dblclick', dblClick1);
            } else if (rendererId === 2) {
                renderer.domElement.addEventListener('dblclick', dblClick2);
                attachEventListeners(renderer, handleMouseMove2)
            } else if (rendererId === 3) {
                renderer.domElement.addEventListener('dblclick', dblClick3);
                attachEventListeners(renderer, handleMouseMove3)
            } else if (rendererId === 4) {
                renderer.domElement.addEventListener('dblclick', dblClick4);
                attachEventListeners(renderer, handleMouseMove4)
            }
        }

        renderers.forEach(renderer => {
            setRendererEventListener(renderer.renderer, renderer.id)
        });


        function updateAxesCamera() {
            function updateAxisCameraConfigs(axisCamera, axisCameraId) {
                if (axisCameraId === 1) {
                    axisCamera.position.copy(cameras.find(cameraObj => cameraObj.id === 1).camera.position);
                    axisCamera.lookAt(axisScenes.find(axisSceneObj => axisSceneObj.id === 1).axisScene.position);
                } else if (axisCameraId === 2) {
                    axisCamera.position.copy(cameras.find(cameraObj => cameraObj.id === 2).camera.position);
                    axisCamera.lookAt(axisScenes.find(axisSceneObj => axisSceneObj.id === 2).axisScene.position);
                    axisCamera.rotation.copy(cameras.find(cameraObj => cameraObj.id === 2).camera.rotation)
                } else if (axisCameraId === 3) {
                    axisCamera.position.copy(cameras.find(cameraObj => cameraObj.id === 3).camera.position);
                    axisCamera.lookAt(axisScenes.find(axisSceneObj => axisSceneObj.id === 3).axisScene.position);
                } else if (axisCameraId === 4) {
                    axisCamera.position.copy(cameras.find(cameraObj => cameraObj.id === 4).camera.position);
                    axisCamera.lookAt(axisScenes.find(axisSceneObj => axisSceneObj.id === 4).axisScene.position);
                    axisCamera.rotation.copy(cameras.find(cameraObj => cameraObj.id === 4).camera.rotation)
                }
                axisCamera.updateProjectionMatrix();
            }

            axisCameras.forEach(axisCamera => {
                updateAxisCameraConfigs(axisCamera.axisCamera, axisCamera.id)
            });

            function renderAxisRenderer(axisRenderer, axisRendererId) {
                if (axisRendererId === 1) {
                    axisRenderer.render(
                        axisScenes.find(axisSceneObj => axisSceneObj.id === 1).axisScene,
                        axisCameras.find(axisCameraObj => axisCameraObj.id === 1).axisCamera);
                } else if (axisRendererId === 2) {
                    axisRenderer.render(
                        axisScenes.find(axisSceneObj => axisSceneObj.id === 2).axisScene,
                        axisCameras.find(axisCameraObj => axisCameraObj.id === 2).axisCamera);
                } else if (axisRendererId === 3) {
                    axisRenderer.render(
                        axisScenes.find(axisSceneObj => axisSceneObj.id === 3).axisScene,
                        axisCameras.find(axisCameraObj => axisCameraObj.id === 3).axisCamera);
                } else if (axisRendererId === 4) {
                    axisRenderer.render(
                        axisScenes.find(axisSceneObj => axisSceneObj.id === 4).axisScene,
                        axisCameras.find(axisCameraObj => axisCameraObj.id === 4).axisCamera);
                }
            }

            axisRenderers.forEach(axisRenderer => {
                renderAxisRenderer(axisRenderer.axisRenderer, axisRenderer.id)
            });

            function updateTextObjectFace(textObject, textObjectId) {
                if (textObjectId === 1) {
                    textObject.children.forEach(function (item) {
                        item.quaternion.copy(axisCameras.find(axisCameraObj => axisCameraObj.id === 1).axisCamera.quaternion);
                    });
                } else if (textObjectId === 2) {
                    textObject.children.forEach(function (item) {
                        item.quaternion.copy(axisCameras.find(axisCameraObj => axisCameraObj.id === 2).axisCamera.quaternion);
                    });
                } else if (textObjectId === 3) {
                    textObject.children.forEach(function (item) {
                        item.quaternion.copy(axisCameras.find(axisCameraObj => axisCameraObj.id === 3).axisCamera.quaternion);
                    });
                } else if (textObjectId === 4) {
                    textObject.children.forEach(function (item) {
                        item.quaternion.copy(axisCameras.find(axisCameraObj => axisCameraObj.id === 4).axisCamera.quaternion);
                    });
                }
            }

            textObjects.forEach(textObject => {
                updateTextObjectFace(textObject.textObject, textObject.id)
            });
        }

        // Animation loop
        const animate = () => {
            requestAnimationFrame(animate);

            controllers.forEach(controller => {
                controller.controller.update()
            });

            cameras.forEach(camera => {
                camera.camera.updateProjectionMatrix()
            });

            function renderRenderer(renderer, rendererId) {
                if (rendererId === 1) {
                    renderer.render(scene, cameras.find(cameraObj => cameraObj.id === 1).camera);
                } else if (rendererId === 2) {
                    renderer.render(scene, cameras.find(cameraObj => cameraObj.id === 2).camera);
                } else if (rendererId === 3) {
                    renderer.render(scene, cameras.find(cameraObj => cameraObj.id === 3).camera);
                } else if (rendererId === 4) {
                    renderer.render(scene, cameras.find(cameraObj => cameraObj.id === 4).camera);
                }
            }

            renderers.forEach(renderer => {
                renderRenderer(renderer.renderer, renderer.id)
            });

            updateAxesCamera();
        };

        animate();

        // Handle window resize
        const handleResize = () => {
            const width = mountRef.current.clientWidth;
            const height = mountRef.current.clientHeight;

            const halfWidth = !currentViewId ? Math.floor(width / 2) : Math.floor(width);
            const halfHeight = !currentViewId ? Math.floor(height / 2) : Math.floor(height);

            renderers.forEach(renderer => {
                renderer.renderer.setSize(halfWidth, halfHeight)
            });

            cameras.forEach(camera => {
                camera.camera.aspect = halfWidth / halfHeight;
                camera.camera.updateProjectionMatrix();
            });
        };

        window.addEventListener('resize', handleResize);

        return () => {
            cancelAnimationFrame(animate)

            function renderEventListenerDisposer(renderer, rendererId) {
                if (rendererId === 1) {
                    renderer.domElement.addEventListener('dblclick', dblClick1);
                } else if (rendererId === 2) {
                    renderer.domElement.addEventListener('dblclick', dblClick2);
                    renderer.domElement.removeEventListener('mousedown', handleMouseDown);
                    renderer.domElement.removeEventListener('mousemove', handleMouseMove2, false);
                    renderer.domElement.removeEventListener('mouseup', handleMouseUp);
                } else if (rendererId === 3) {
                    renderer.domElement.addEventListener('dblclick', dblClick3);
                    renderer.domElement.removeEventListener('mousedown', handleMouseDown);
                    renderer.domElement.removeEventListener('mousemove', handleMouseMove3, false);
                    renderer.domElement.removeEventListener('mouseup', handleMouseUp);
                } else if (rendererId === 4) {
                    renderer.domElement.addEventListener('dblclick', dblClick4);
                    renderer.domElement.removeEventListener('mousedown', handleMouseDown);
                    renderer.domElement.removeEventListener('mousemove', handleMouseMove4, false);
                    renderer.domElement.removeEventListener('mouseup', handleMouseUp);
                }
            }

            function renderDisposer(renderer) {
                renderer.renderLists.dispose()

                const gl = renderer.getContext();
                const loseContextExtension = gl.getExtension('WEBGL_lose_context');
                if (loseContextExtension) {
                    loseContextExtension.loseContext();
                } else {
                    console.error('WEBGL_lose_context extension is not supported.');
                }

                renderer.dispose()
            }

            renderers.forEach(renderer => {
                renderEventListenerDisposer(renderer.renderer, renderer.id)
                renderDisposer(renderer.renderer)
            });
            axisRenderers.forEach(axisRenderer => {
                renderDisposer(axisRenderer.axisRenderer)
            });

            if (mountRef.current) {
                renderers.forEach(renderer => {
                    mountRef.current.removeChild(renderer.renderer.domElement);
                });
                axisRenderers.forEach(axisRenderer => {
                    mountRef.current.removeChild(axisRenderer.axisRenderer.domElement);
                });
            }
            newMeshes.forEach(mesh => {
                scene.remove(mesh);
                mesh.geometry.dispose();
                mesh.material.dispose();
            });
            scene.clear()
            axisScenes.forEach(axisScene => {
                axisScene.axisScene.traverse(object => {
                    if (object.geometry) object.geometry.dispose();
                    if (object.material) {
                        if (Array.isArray(object.material)) {
                            object.material.forEach(mat => mat.dispose());
                        } else {
                            object.material.dispose();
                        }
                    }
                });
                axisScene.axisScene.clear()
            });
            THREE.Cache.clear();
            if (mountRef.current) {
                while (mountRef.current.firstChild) {
                    mountRef.current.removeChild(mountRef.current.firstChild);
                }
            }

            function generateConfigDataForSaving() {
                function areObjectsEqual(obj1, obj2) {
                    // Check if both are objects and not null
                    if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
                        return false;
                    }

                    // Check if they have the same number of keys
                    const keys1 = Object.keys(obj1);
                    const keys2 = Object.keys(obj2);
                    if (keys1.length !== keys2.length) {
                        return false;
                    }

                    // Check if all keys and values are the same
                    for (let key of keys1) {
                        if (!obj2.hasOwnProperty(key) || Math.floor(obj1[key] * 100) !== Math.floor(obj2[key] * 100)) {
                            return false;
                        }
                    }

                    return true;
                }

                const data = {}
                let dataIsSame = true
                cameras.forEach(cameraObject => {
                    const cameraId = cameraObject.id
                    const camera = cameraObject.camera
                    if (cameraId === 1) {
                        const controller = controllers.find(controllerObj => controllerObj.id === 1).controller
                        data.view1 = {
                            cameraXPosition: camera.position.x,
                            cameraYPosition: camera.position.y,
                            cameraZPosition: camera.position.z,
                            cameraXUp: camera.up.x,
                            cameraYUp: camera.up.y,
                            cameraZUp: camera.up.z,
                            controlsXPosition: controller.target.x,
                            controlsYPosition: controller.target.y,
                            controlsZPosition: controller.target.z,
                            zoom: camera.zoom,
                        }
                        if (!areObjectsEqual(data.view1, cameraData.view1)) {
                            dataIsSame = false
                        }
                    } else if (cameraId === 2) {
                        const controller = controllers.find(controllerObj => controllerObj.id === 2).controller
                        data.view2 = {
                            cameraXPosition: camera.position.x,
                            cameraYPosition: camera.position.y,
                            cameraZPosition: camera.position.z,
                            cameraXUp: camera.up.x,
                            cameraYUp: camera.up.y,
                            cameraZUp: camera.up.z,
                            controlsXPosition: controller.target.x,
                            controlsYPosition: controller.target.y,
                            controlsZPosition: controller.target.z,
                            zoom: camera.zoom,
                        }
                        if (!areObjectsEqual(data.view2, cameraData.view2)) {
                            dataIsSame = false
                        }
                    } else if (cameraId === 3) {
                        const controller = controllers.find(controllerObj => controllerObj.id === 3).controller
                        data.view3 = {
                            cameraXPosition: camera.position.x,
                            cameraYPosition: camera.position.y,
                            cameraZPosition: camera.position.z,
                            cameraXUp: camera.up.x,
                            cameraYUp: camera.up.y,
                            cameraZUp: camera.up.z,
                            controlsXPosition: controller.target.x,
                            controlsYPosition: controller.target.y,
                            controlsZPosition: controller.target.z,
                            zoom: camera.zoom,
                        }
                        if (!areObjectsEqual(data.view3, cameraData.view3)) {
                            dataIsSame = false
                        }
                    } else if (cameraId === 4) {
                        const controller = controllers.find(controllerObj => controllerObj.id === 4).controller
                        data.view4 = {
                            cameraXPosition: camera.position.x,
                            cameraYPosition: camera.position.y,
                            cameraZPosition: camera.position.z,
                            cameraXUp: camera.up.x,
                            cameraYUp: camera.up.y,
                            cameraZUp: camera.up.z,
                            controlsXPosition: controller.target.x,
                            controlsYPosition: controller.target.y,
                            controlsZPosition: controller.target.z,
                            zoom: camera.zoom,
                        }
                        if (!areObjectsEqual(data.view4, cameraData.view4)) {
                            dataIsSame = false
                        }
                    }
                });
                return {data, dataIsSame}
            }

            const {data, dataIsSame} = generateConfigDataForSaving()
            if (!dataIsSame) {
                dispatch(setCameraPosition({
                    inputState,
                    viewerState,
                    data: data
                }))
            }
            window.removeEventListener('resize', handleResize);
        };
    }, [dispatch, currentViewIdInput, inputState, viewerState, showNumbers, is2DView, toggleButtonAction, cameraData, stlFilesWithColors]);


    return (
        <MainStyledWrapper>
            <StyledWrapper ref={mountRef}/>
            {(!is2DView && !currentViewIdInput) && <TextOverlay1>Perspective</TextOverlay1>}
            {(!is2DView && !currentViewIdInput) && <TextOverlay2>Side</TextOverlay2>}
            {(!is2DView && !currentViewIdInput) && <TextOverlay3>Bottom</TextOverlay3>}
            {(!is2DView && !currentViewIdInput) && <TextOverlay4>Top</TextOverlay4>}
        </MainStyledWrapper>
    )
};

const MainStyledWrapper = styled.div`
    display: block;
    position: relative;
`

const StyledWrapper = styled.div`
    background: var(--clr-grey-2);
    text-align: center;
    justify-items: center;
    width: 72vw;
    height: 86vh;
    position: relative;

    body {
        color: black;
    }

    #content {
        position: absolute;
        top: 0;
        left: 0;
        width: 72vw;
        height: 86vh;
        //overflow: auto;
        z-index: 1;
    }

    #c {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    .list-item {
        display: inline-block;
        //border: solid 1px black;
        //width: 36vw;
        //height: 43vh;

        div {
            width: calc(36vw - 1px);
            height: calc(43vh - 1px);
        }
    }
`

const TextOverlay1 = styled.div`
    position: absolute;
    bottom: 50%;
    left: 1px;
    color: white;
    background: var(--clr-grey-5);
    font-size: 0.8rem;
    padding: 0.8vh;
`
const TextOverlay2 = styled.div`
    position: absolute;
    bottom: 50%;
    left: 50%;
    color: white;
    background: var(--clr-grey-5);
    font-size: 0.8rem;
    padding: 0.8vh;
`
const TextOverlay3 = styled.div`
    position: absolute;
    bottom: 0;
    left: 1px;
    color: white;
    background: var(--clr-grey-5);
    font-size: 0.8rem;
    padding: 0.8vh;
    //border-radius: 5px;
`
const TextOverlay4 = styled.div`
    position: absolute;
    bottom: 0;
    left: 50%;
    color: white;
    background: var(--clr-grey-5);
    font-size: 0.8rem;
    padding: 0.8vh;
`

export default MultiViewScene;

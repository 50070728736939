import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {
    COIL_NAME,
    ENDING_LAYER,
    ENDING_SLOT, OPERATION, OPERATION_NAME,
    PARENT_NAME,
    STARTING_LAYER,
    STARTING_SLOT,
    SEGMENT5_CONTROL_POINTS,
    TAG
} from "../data/connectionTable";
import {axiosBaseConfig} from "../data/url";
import {toast} from "react-toastify";

// const addSegment5ControlPoints = (coils) => {
//     return coils.map(coil => ({
//         ...coil,
//         segment5_control_points: 7
//     }));
// };
// READ DOCUMENTS FOR FEATURES FILES
const initialState = {
    formingSideCoils: [],
    formingSideStl: null,
    // statorFormingSideStl: null,
    selectedParentName: null,
    isFormingStlHasError: false,
    isFormingStlReady: false,
    toggleShowCube: true,
    formingProgressbar: 0,
    cameraStates: [
        {
            xPosition: 700,
            yPosition: 0,
            zPosition: 0,
            zoom: 0.2,
            controlsXPosition: "default-forming-view",
            controlsYPosition: 0,
            controlsZPosition: 0,
        },
        {
            xPosition: 0,
            yPosition: 700,
            zPosition: 0,
            zoom: 0.2,
            controlsXPosition: "default-forming-view",
            controlsYPosition: 0,
            controlsZPosition: 0,
        },
        {
            xPosition: 0,
            yPosition: 0,
            zPosition: 700,
            zoom: 0.2,
            controlsXPosition: "default-forming-view",
            controlsYPosition: 0,
            controlsZPosition: 0,
        },
        {
            xPosition: 0,
            yPosition: 0,
            zPosition: 700,
            zoom: 0.1,
            controlsXPosition: "default-forming-view",
            controlsYPosition: 0,
            controlsZPosition: 0,
        }
    ],
    isLoading: 0,
    // generateLoading: false,
};

export const getFormingSideCoils = createAsyncThunk(
    'formingSideCoilsSlice/getFormingSideCoils',
    async (name, thunkAPI) => {
        try {
            const endpoint = '/forming/'
            const response = await axiosBaseConfig.get(endpoint);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('can not get forming side coils');
        }
    }
);


export const putFormingSideCoil = createAsyncThunk(
    'formingSideCoilsSlice/putFormingSideCoil',
    async (name, thunkAPI) => {
        try {
            const endpoint = '/forming/'
            const formingSlice = thunkAPI.getState().formingSideCoils
            const coilIndex = formingSlice.formingSideCoils.findIndex(coil => coil.coil_name === formingSlice.selectedParentName);
            if (coilIndex !== -1) {
                const payload = {
                    [COIL_NAME]: formingSlice.formingSideCoils[coilIndex][COIL_NAME],
                    [SEGMENT5_CONTROL_POINTS] : name.newCoil[SEGMENT5_CONTROL_POINTS],
                    [STARTING_LAYER]: formingSlice.formingSideCoils[coilIndex][STARTING_LAYER],
                    [STARTING_SLOT]: formingSlice.formingSideCoils[coilIndex][STARTING_SLOT],
                    [ENDING_LAYER]: formingSlice.formingSideCoils[coilIndex][ENDING_LAYER],
                    [ENDING_SLOT]: formingSlice.formingSideCoils[coilIndex][ENDING_SLOT]
                };
                const response = await axiosBaseConfig.put(endpoint, payload);
                return response.data;
            } else {
                return thunkAPI.rejectWithValue("can not find parent coil");
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(`can not add forming side coil, status:${error.response.status}`,);
        }
    }
);

export const addFormingSideCoil = createAsyncThunk(
    'formingSideCoilsSlice/addFormingSideCoil',
    async (name, thunkAPI) => {
        try {
            const endpoint = '/forming/'
            const payload = {
                [STARTING_SLOT]: name.newCoil[STARTING_SLOT],
                [STARTING_LAYER]: name.newCoil[STARTING_LAYER],
                [ENDING_SLOT]: name.newCoil[ENDING_SLOT],
                [ENDING_LAYER]: name.newCoil[ENDING_LAYER],
                [SEGMENT5_CONTROL_POINTS] : 7
            };
            const response = await axiosBaseConfig.post(endpoint, payload);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(`can not add forming side coil, status:${error.response.status}`,);
        }
    }
);

export const deleteFormingSideCoils = createAsyncThunk(
    'formingSideCoilsSlice/deleteFormingSideCoils',
    async (name, thunkAPI) => {
        try {
            const endpoint = '/forming/'
            const response = await axiosBaseConfig.delete(endpoint);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('can not delete forming side coils');
        }
    }
);

export const changeTagFormingSideSingleCoil = createAsyncThunk(
    'formingSideCoilsSlice/changeTagFormingSideSingleCoil',
    async (name, thunkAPI) => {
        try {
            const endpoint = `/forming/${name[COIL_NAME]}/`
            const payload = {
                type: name["type"],
                value: name["value"],
            };
            const response = await axiosBaseConfig.put(endpoint, payload);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('can not change forming side coil tag');
        }
    }
);


export const deleteFormingSideSingleCoil = createAsyncThunk(
    'formingSideCoilsSlice/deleteFormingSideSingleCoil',
    async (name, thunkAPI) => {
        try {
            const endpoint = `/forming/${name[COIL_NAME]}/`
            const response = await axiosBaseConfig.delete(endpoint);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('can not delete forming side specific coil');
        }
    }
);

export const changeTagFormingSideSingleChildCoil = createAsyncThunk(
    'formingSideCoilsSlice/changeTagFormingSideSingleChildCoil',
    async (name, thunkAPI) => {
        try {
            const endpoint = `/forming/${name[PARENT_NAME]}/${name[COIL_NAME]}/`
            const payload = {
                [TAG]: name[TAG],
            };
            const response = await axiosBaseConfig.put(endpoint, payload);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('can not change forming side child coil tag');
        }
    }
);

export const deleteFormingSideSingleChildCoil = createAsyncThunk(
    'formingSideCoilsSlice/deleteFormingSideSingleChildCoil',
    async (name, thunkAPI) => {
        try {
            const endpoint = `/forming/${name[PARENT_NAME]}/${name[COIL_NAME]}/`
            const response = await axiosBaseConfig.delete(endpoint);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('can not delete forming side specific child coil');
        }
    }
);

export const formingDefinePattern = createAsyncThunk(
    'formingSideCoilsSlice/formingDefinePattern',
    async (name, thunkAPI) => {
        try {
            const endpoint = '/forming/define-pattern/'
            const payload = {
                enter_slot_shift: name.enterSlotShift,
                number_of_patterns: name.numberOfPatterns,
                parents_name: name.parentsName,
            };
            const response = await axiosBaseConfig.post(endpoint, payload);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('can not create define pattern');
        }
    }
);

export const deleteAllOperations = createAsyncThunk(
    'formingSideCoilsSlice/deleteAllOperations',
    async (name, thunkAPI) => {
        try {
            const endpoint = '/forming/operations/'
            const response = await axiosBaseConfig.delete(endpoint);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('can not delete all operations');
        }
    }
);

// Forming Side Api
export const addExtrude = createAsyncThunk(
    'formingSideCoilsSlice/addExtrude',
    async (name, thunkAPI) => {
        try {
            const endpoint = `/forming/operation/${name[PARENT_NAME]}/`
            const payload = {
                operation_type: "Extrude",
                parameters: {
                    length: {
                        initial: 13,
                        min: 0,
                        max: 0,
                        step: 1,
                        include: true
                    },
                    twist_angle: {
                        initial: 0,
                        min: 0,
                        max: 0,
                        step: 0,
                        include: true
                    }
                },
            }
            const response = await axiosBaseConfig.post(endpoint, payload);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('can not add extrude');
        }
    }
);

export const changeExtrudeParameters = createAsyncThunk(
    'formingSideCoilsSlice/changeExtrudeParameters',
    async (name, thunkAPI) => {
        try {
            const endpoint = `/forming/operation/${name[PARENT_NAME]}/${name[OPERATION_NAME]}/`
            const payload = name[OPERATION]
            const response = await axiosBaseConfig.put(endpoint, payload);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('can not change extrude');
        }
    }
);

export const addRevolve = createAsyncThunk(
    'formingSideCoilsSlice/addRevolve',
    async (name, thunkAPI) => {
        try {
            const endpoint = `/forming/operation/${name[PARENT_NAME]}/`
            const payload = {
                operation_type: "Revolve",
                parameters: {
                    pivot_angle: {
                        initial: 5,
                        min: 0,
                        max: 10,
                        step: 1,
                        include: true
                    },
                    radius: {
                        initial: 4,
                        min: 3,
                        max: 8,
                        step: 1,
                        include: true
                    },
                    revolve_angle: {
                        initial: 45,
                        min: 20,
                        max: 60,
                        step: 1,
                        include: true
                    },
                    twist_angle: {
                        initial: 0,
                        min: -30,
                        max: 30,
                        step: 1,
                        include: true
                    }
                }
            }
            const response = await axiosBaseConfig.post(endpoint, payload);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('can not add revolve');
        }
    }
);

export const changeRevolveParameters = createAsyncThunk(
    'formingSideCoilsSlice/changeRevolveParameters',
    async (name, thunkAPI) => {
        try {
            const endpoint = `/forming/operation/${name[PARENT_NAME]}/${name[OPERATION_NAME]}/`
            const payload = name[OPERATION]
            const response = await axiosBaseConfig.put(endpoint, payload);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('can not change revolve');
        }
    }
);

export const addDefaultSample = createAsyncThunk(
    'formingSideCoilsSlice/addDefaultSample',
    async (name, thunkAPI) => {
        try {
            const endpoint = `/forming/operation/default-sample/${name[PARENT_NAME]}/`
            const response = await axiosBaseConfig.get(endpoint);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('can not add default sample');
        }
    }
);

export const deleteSingleOperation = createAsyncThunk(
    'formingSideCoilsSlice/deleteSingleOperation',
    async (name, thunkAPI) => {
        try {
            const endpoint = `/forming/operation/${name[PARENT_NAME]}/${name[OPERATION_NAME]}/`
            const response = await axiosBaseConfig.delete(endpoint);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('can not delete all operations');
        }
    }
);

export const getFormingSideStl = createAsyncThunk(
    'formingSideCoilsSlice/getFormingSideStl',
    async (name, thunkAPI) => {
        try {
            const endpoint = `/forming/operation/${name[PARENT_NAME]}/`
            const response = await axiosBaseConfig.get(endpoint);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('can not get forming side stl');
        }
    }
);

// export const getStatorFormingSideStl = createAsyncThunk(
//     'formingSideCoilsSlice/getStatorFormingSideStl',
//     async (name, thunkAPI) => {
//         try {
//             const endpoint = 'forming/generate/'
//             const response = await axiosBaseConfig.get(endpoint);
//             return response.data;
//         } catch (error) {
//             return thunkAPI.rejectWithValue('can not get stator forming side stl files');
//         }
//     }
// );

export const formingUndo = createAsyncThunk(
    'formingSideCoilsSlice/formingUndo',
    async (name, thunkAPI) => {
        try {
            const endpoint = '/forming/undo/'
            const response = await axiosBaseConfig.get(endpoint);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('can not get undo forming parameters');
        }
    }
);

export const formingRedo = createAsyncThunk(
    'formingSideCoilsSlice/formingRedo',
    async (name, thunkAPI) => {
        try {
            const endpoint = '/forming/redo/'
            const response = await axiosBaseConfig.get(endpoint);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('can not get redo forming parameters');
        }
    }
);

export const formingBSpline = createAsyncThunk(
    'formingSideCoilsSlice/formingBSpline',
    async (name, thunkAPI) => {
        try {
            const endpoint = `/forming/bspline/${name[PARENT_NAME]}/`
            const response = await axiosBaseConfig.get(endpoint);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('can not get bspline data');
        }
    }
);

export const formingChangeSegmentsPoints = createAsyncThunk(
    'formingSideCoilsSlice/formingChangeSegmentsPoints',
    async (name, thunkAPI) => {
        try {
            const endpoint = `/forming/update-forming-segments/`
            const payload = name["data"]
            const response = await axiosBaseConfig.post(endpoint, payload);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('can not update forming list');
        }
    }
);

export const formingBSplineGetPoints = createAsyncThunk(
    'formingSideCoilsSlice/formingBSplineGetPoints',
    async (name, thunkAPI) => {
        try {
            const endpoint = `/forming/bspline/${name[PARENT_NAME]}`
            const payload = name["data"]
            const response = await axiosBaseConfig.post(endpoint, payload);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('can not get curve data');
        }
    }
);

export const generateFormingCoilStl = createAsyncThunk(
    'formingSideCoilsSlice/generateFormingCoilStl',
    async (name, thunkAPI) => {
        try {
            const endpoint = '/forming/get-coil-stl/'
            const response = await axiosBaseConfig.get(endpoint);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('can not start generating forming stl');
        }
    }
);

export const getGeneratedFormingSideStl = createAsyncThunk(
    'stlFiles/getGeneratedFormingSideStl',
    async (name, thunkAPI) => {
        try {
            const endpoint = '/forming/gen-coil-stl/'
            const response = await axiosBaseConfig.get(endpoint);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('can not load forming side stl');
        }
    }
);

export const completedFormingSideStl = createAsyncThunk(
    'stlFiles/completedFormingSideStl',
    async (name, thunkAPI) => {
        try {
            const endpoint = '/forming/gen-coil-stl/'
            const response = await axiosBaseConfig.delete(endpoint);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('can not handle forming side stl error');
        }
    }
);

const formingSideCoilsSlice = createSlice({
    name: 'formingSideCoils',
    initialState,
    reducers: {
        changeSelectedParentCoil: (state, {payload}) => {
            state.selectedParentName = payload.selectedParentName
        },
        changeFormingCurvePoints: (state, {payload}) => {
            const coilIndex = state.formingSideCoils.findIndex(coil => coil.coil_name === state.selectedParentName);
            if (coilIndex !== -1) {
                state.formingSideCoils[coilIndex]["segment_points"][payload.previousSegment][state.formingSideCoils[coilIndex]["segment_points"][payload.previousSegment].length - 1] = payload.value[0];
                state.formingSideCoils[coilIndex]["segment_points"][payload.segment] = payload.value;
                state.formingSideCoils[coilIndex]["segment_points"][payload.nextSegment][0] = payload.value[payload.value.length - 1];
                // state.formingSideCoils[coilIndex]["forming_height"] = state.formingSideCoils[coilIndex]["segment_points"]["segment5_control_points"]
                state.formingSideCoils[coilIndex]["forming_height"] =
                    state.formingSideCoils[coilIndex]["segment_points"]["segment5_control_points"].map(element => element[2]).reduce((max, current) => Math.max(max, current)); // Find the maximum z value
                console.log(state.formingSideCoils[coilIndex]["forming_height"])
                console.log(state.formingSideCoils[coilIndex]["segment_points"]["segment5_control_points"].map(element => element[2]).reduce((max, current) => Math.max(max, current)))
                // state.formingSideCoils[coilIndex]["segment5_control_points"] =
            }
        },
        changeZoomStates: (state, {payload}) => {
            const newCameraStates = [...state.cameraStates]
            newCameraStates[payload.stateId] = payload.data
            state.cameraStates = newCameraStates
        },
        updateFormingSideStl: (state, {payload}) => {
            debugger
            if (payload && (typeof payload === 'object')) {
                payload = Object.values(payload);
            }
            if (payload.length > 0 && payload.some(item => item && item.state === "100")) {
                state.isFormingStlHasError = true
                state.formingProgressbar = 100
            } else if (payload.length > 0 && payload.every(item => item && item.state === "done")) {
                state.isFormingStlHasError = false
                state.isFormingStlReady = true
                state.formingProgressbar = 100
            } else if (payload.length > 0) {
                state.formingProgressbar = parseInt((payload.filter(item => item && item.state === 'done').length /
                    payload.filter(item => item).length) * 100);
                state.isFormingStlHasError = false
                state.isFormingStlReady = false
            }
        },
        toggleShowCubes: (state) => {
            state.toggleShowCube = !state.toggleShowCube
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getFormingSideCoils.pending, (state) => {
                console.log("getting forming side coils")
                state.isLoading = state.isLoading + 1;
            })
            .addCase(getFormingSideCoils.fulfilled, (state, action) => {
                state.isLoading = state.isLoading - 1;
                console.log("got forming side coils")
                state.formingSideCoils = action.payload["forming_coils"];
                addFormingSideCoil(action.payload["forming_coils"])
            })
            .addCase(getFormingSideCoils.rejected, (state, action) => {
                console.log(action);
                state.isLoading = state.isLoading - 1;
            })
            .addCase(addFormingSideCoil.pending, (state) => {
                console.log("getting forming side coils")
                state.isLoading = state.isLoading + 1;
            })
            .addCase(addFormingSideCoil.fulfilled, (state, action) => {
                state.isLoading = state.isLoading - 1;
                console.log("got forming side coils")
                state.formingSideCoils = action.payload["forming_coils"];
            })
            .addCase(addFormingSideCoil.rejected, (state, action) => {
                console.log(action);
                state.isLoading = state.isLoading - 1;
                if (action.payload.includes("402")) {
                    toast.warning("layer is already taken")
                }
            })
            .addCase(deleteFormingSideCoils.pending, (state) => {
                console.log("deleting forming side coils")
                state.isLoading = state.isLoading + 1;
            })
            .addCase(deleteFormingSideCoils.fulfilled, (state, action) => {
                state.isLoading = state.isLoading - 1;
                console.log("Forming side coils deleted")
                state.formingSideCoils = action.payload["forming_coils"];
            })
            .addCase(deleteFormingSideCoils.rejected, (state, action) => {
                console.log(action);
                state.isLoading = state.isLoading - 1;
            })
            .addCase(putFormingSideCoil.pending, (state) => {
                console.log("changing forming side number of segment 5 points")
                state.isLoading = state.isLoading + 1;
            })
            .addCase(putFormingSideCoil.fulfilled, (state, action) => {
                state.isLoading = state.isLoading - 1;
                console.log("forming side number of segment 5 points Change")
                state.formingSideCoils = action.payload["forming_coils"];
            })
            .addCase(putFormingSideCoil.rejected, (state, action) => {
                console.log(action);
                state.isLoading = state.isLoading - 1;
            })
            .addCase(changeTagFormingSideSingleCoil.pending, (state) => {
                console.log("changing forming side coil tag")
                state.isLoading = state.isLoading + 1;
            })
            .addCase(changeTagFormingSideSingleCoil.fulfilled, (state, action) => {
                state.isLoading = state.isLoading - 1;
                console.log("Forming side coil tag Change")
                state.formingSideCoils = action.payload["forming_coils"];
            })
            .addCase(changeTagFormingSideSingleCoil.rejected, (state, action) => {
                console.log(action);
                state.isLoading = state.isLoading - 1;
            })
            .addCase(deleteFormingSideSingleCoil.pending, (state) => {
                console.log("deleting forming side single coil")
                state.isLoading = state.isLoading + 1;
            })
            .addCase(deleteFormingSideSingleCoil.fulfilled, (state, action) => {
                state.isLoading = state.isLoading - 1;
                console.log("Forming side single coil deleted")
                state.formingSideCoils = action.payload["forming_coils"];
            })
            .addCase(deleteFormingSideSingleCoil.rejected, (state, action) => {
                console.log(action);
                state.isLoading = state.isLoading - 1;
            })
            .addCase(changeTagFormingSideSingleChildCoil.pending, (state) => {
                console.log("changing forming side child coil tag")
                state.isLoading = state.isLoading + 1;
            })
            .addCase(changeTagFormingSideSingleChildCoil.fulfilled, (state, action) => {
                state.isLoading = state.isLoading - 1;
                console.log("Forming side coil tag child Change")
                state.formingSideCoils = action.payload["forming_coils"];
            })
            .addCase(changeTagFormingSideSingleChildCoil.rejected, (state, action) => {
                console.log(action);
                state.isLoading = state.isLoading - 1;
            })
            .addCase(deleteFormingSideSingleChildCoil.pending, (state) => {
                console.log("deleting forming side single child coil")
                state.isLoading = state.isLoading + 1;
            })
            .addCase(deleteFormingSideSingleChildCoil.fulfilled, (state, action) => {
                state.isLoading = state.isLoading - 1;
                console.log("Forming side single coil child deleted")
                state.formingSideCoils = action.payload["forming_coils"];
            })
            .addCase(deleteFormingSideSingleChildCoil.rejected, (state, action) => {
                console.log(action);
                state.isLoading = state.isLoading - 1;
            })
            .addCase(formingDefinePattern.pending, (state) => {
                console.log("creating child based on define pattern")
                state.isLoading = state.isLoading + 1;
            })
            .addCase(formingDefinePattern.fulfilled, (state, action) => {
                state.isLoading = state.isLoading - 1;
                console.log("children created")
                state.formingSideCoils = action.payload["forming_coils"];
            })
            .addCase(formingDefinePattern.rejected, (state, action) => {
                console.log(action);
                state.isLoading = state.isLoading - 1;
            })
            .addCase(addExtrude.pending, (state) => {
                console.log("Adding extrude")
                state.isLoading = state.isLoading + 1;
            })
            .addCase(addExtrude.fulfilled, (state, action) => {
                state.isLoading = state.isLoading - 1;
                console.log("Extrude added")
                state.formingSideCoils = action.payload["forming_coils"];
            })
            .addCase(addExtrude.rejected, (state, action) => {
                console.log(action);
                state.isLoading = state.isLoading - 1;
            })
            .addCase(changeExtrudeParameters.pending, (state) => {
                console.log("Changing extrude")
                state.isLoading = state.isLoading + 1;
            })
            .addCase(changeExtrudeParameters.fulfilled, (state, action) => {
                state.isLoading = state.isLoading - 1;
                console.log("Extrude changed")
                state.formingSideCoils = action.payload["forming_coils"];
            })
            .addCase(changeExtrudeParameters.rejected, (state, action) => {
                console.log(action);
                state.isLoading = state.isLoading - 1;
            })
            .addCase(addRevolve.pending, (state) => {
                console.log("Adding revolve")
                state.isLoading = state.isLoading + 1;
            })
            .addCase(addRevolve.fulfilled, (state, action) => {
                state.isLoading = state.isLoading - 1;
                console.log("Revolve added")
                state.formingSideCoils = action.payload["forming_coils"];
            })
            .addCase(addRevolve.rejected, (state, action) => {
                console.log(action);
                state.isLoading = state.isLoading - 1;
            })
            .addCase(changeRevolveParameters.pending, (state) => {
                console.log("Changing revolve")
                state.isLoading = state.isLoading + 1;
            })
            .addCase(changeRevolveParameters.fulfilled, (state, action) => {
                state.isLoading = state.isLoading - 1;
                console.log("Revolve changed")
                state.formingSideCoils = action.payload["forming_coils"];
            })
            .addCase(changeRevolveParameters.rejected, (state, action) => {
                console.log(action);
                state.isLoading = state.isLoading - 1;
            })
            .addCase(addDefaultSample.pending, (state) => {
                console.log("Adding default sample")
                state.isLoading = state.isLoading + 1;
            })
            .addCase(addDefaultSample.fulfilled, (state, action) => {
                state.isLoading = state.isLoading - 1;
                console.log("Default sample added")
                state.formingSideCoils = action.payload["forming_coils"];
            })
            .addCase(addDefaultSample.rejected, (state, action) => {
                console.log(action);
                state.isLoading = state.isLoading - 1;
            })
            .addCase(deleteAllOperations.pending, (state) => {
                console.log("Deleting all operations")
                state.isLoading = state.isLoading + 1;
            })
            .addCase(deleteAllOperations.fulfilled, (state, action) => {
                state.isLoading = state.isLoading - 1;
                console.log("All operations deleted")
                state.formingSideCoils = action.payload["forming_coils"];
            })
            .addCase(deleteAllOperations.rejected, (state, action) => {
                console.log(action);
                state.isLoading = state.isLoading - 1;
            })
            .addCase(deleteSingleOperation.pending, (state) => {
                console.log("Deleting single operation")
                state.isLoading = state.isLoading + 1;
            })
            .addCase(deleteSingleOperation.fulfilled, (state, action) => {
                state.isLoading = state.isLoading - 1;
                console.log("Single operation deleted")
                state.formingSideCoils = action.payload["forming_coils"];
            })
            .addCase(deleteSingleOperation.rejected, (state, action) => {
                console.log(action);
                state.isLoading = state.isLoading - 1;
            })
            .addCase(getFormingSideStl.pending, (state) => {
                console.log("Getting forming side stl")
                state.isLoading = state.isLoading + 1;
            })
            .addCase(getFormingSideStl.fulfilled, (state, action) => {
                state.isLoading = state.isLoading - 1;
                console.log("Got forming side stl")
                state.formingSideStl = action.payload["forming_side_stl"];
            })
            .addCase(getFormingSideStl.rejected, (state, action) => {
                console.log(action);
                state.isLoading = state.isLoading - 1;
            })
            // .addCase(getStatorFormingSideStl.pending, (state) => {
            //     console.log("Getting stator forming side stl files")
            //     state.generateLoading = true;
            // })
            // .addCase(getStatorFormingSideStl.fulfilled, (state, action) => {
            //     console.log("Got stator forming side stl files")
            //     toast.success("Generating forming side finished")
            //     state.generateLoading = false;
            //     state.statorFormingSideStl = action.payload["stator_forming_side_stl"].map(obj => atob(obj));
            // })
            // .addCase(getStatorFormingSideStl.rejected, (state, action) => {
            //     console.log(action);
            //     toast.error("All coils must have operation (you need at least one coil)")
            //     state.generateLoading = false;
            // })
            .addCase(generateFormingCoilStl.pending, (state) => {
                console.log("Generating forming stl")
                state.isLoading = state.isLoading + 2;
            })
            .addCase(generateFormingCoilStl.fulfilled, (state) => {
                state.isLoading = state.isLoading - 1;
                console.log("Generating forming stl started")
                // state.formingSideCoils = action.payload["forming_coils"];
            })
            .addCase(generateFormingCoilStl.rejected, (state, action) => {
                console.log(action);
                state.isLoading = state.isLoading - 1;
            })
            .addCase(getGeneratedFormingSideStl.pending, () => {
                console.log("getting forming side stl")
                // state.isLoading = state.isLoading + 1;
            })
            .addCase(getGeneratedFormingSideStl.fulfilled, (state, action) => {
                console.log("Got forming side stl")
                state.isLoading = state.isLoading - 1;

                state.formingSideStl = action.payload["forming_side_stl"];
            })
            .addCase(getGeneratedFormingSideStl.rejected, (state, action) => {
                console.log(action);
                state.isLoading = state.isLoading - 1;
            })
            .addCase(completedFormingSideStl.pending, () => {
                // state.isLoading = state.isLoading + 1;
                console.log("Error detected for forming side stl")
            })
            .addCase(completedFormingSideStl.fulfilled, (state) => {
                toast.warning("Can not generate forming side stl")
                state.isLoading = state.isLoading - 1;
                state.isFormingStlHasError = false
                state.isFormingStlReady = false
            })
            .addCase(completedFormingSideStl.rejected, (state) => {
                state.isLoading = state.isLoading - 1;
                state.isFormingStlHasError = false
                state.isFormingStlReady = false
            })
            .addCase(formingUndo.pending, (state) => {
                console.log("Getting undo forming parameters")
                state.isLoading = state.isLoading + 1;
            })
            .addCase(formingUndo.fulfilled, (state, action) => {
                state.isLoading = state.isLoading - 1;
                console.log("Got undo forming parameters")
                state.formingSideCoils = action.payload["forming_coils"];
            })
            .addCase(formingUndo.rejected, (state, action) => {
                console.log(action);
                state.isLoading = state.isLoading - 1;
            })
            .addCase(formingRedo.pending, (state) => {
                console.log("Getting redo forming parameters")
                state.isLoading = state.isLoading + 1;
            })
            .addCase(formingRedo.fulfilled, (state, action) => {
                state.isLoading = state.isLoading - 1;
                console.log("Got redo forming parameters")
                state.formingSideCoils = action.payload["forming_coils"];
            })
            .addCase(formingRedo.rejected, (state, action) => {
                console.log(action);
                state.isLoading = state.isLoading - 1;
            })
            .addCase(formingBSpline.pending, (state) => {
                console.log("Getting forming bspline data")
                state.isLoading = state.isLoading + 1;
            })
            .addCase(formingBSpline.fulfilled, (state, action) => {
                state.isLoading = state.isLoading - 1;
                console.log("Got forming bspline data")
                state.formingSideCoils = action.payload["forming_coils"];
            })
            .addCase(formingBSpline.rejected, (state, action) => {
                console.log(action);
                state.isLoading = state.isLoading - 1;
            })
            .addCase(formingBSplineGetPoints.pending, (state) => {
                console.log("Getting forming curve data")
                state.isLoading = state.isLoading + 1;
            })
            .addCase(formingBSplineGetPoints.fulfilled, (state, action) => {
                state.isLoading = state.isLoading - 1;
                console.log("Got forming curve data")
                state.formingSideCoils = action.payload["forming_coils"];
            })
            .addCase(formingBSplineGetPoints.rejected, (state, action) => {
                console.log(action);
                state.isLoading = state.isLoading - 1;
            })
            .addCase(formingChangeSegmentsPoints.pending, (state) => {
                console.log("Updating forming list")
                state.isLoading = state.isLoading + 1;
            })
            .addCase(formingChangeSegmentsPoints.fulfilled, (state, action) => {
                state.isLoading = state.isLoading - 1;
                console.log("Forming list updated")
                state.formingSideCoils = action.payload["forming_coils"];
            })
            .addCase(formingChangeSegmentsPoints.rejected, (state, action) => {
                console.log(action);
                state.isLoading = state.isLoading - 1;
            });
    },
});

export const {
    changeSelectedParentCoil,
    changeFormingCurvePoints,
    changeZoomStates,
    updateFormingSideStl,
    toggleShowCubes
} = formingSideCoilsSlice.actions

export default formingSideCoilsSlice.reducer;

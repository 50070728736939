import axios from "axios";

// const backendUrl = 'http://localhost:8000/' //localhost django
// const backendUrl = 'http://192.168.8.127:8000/' //my ip
// const backendUrl = 'http://localhost:5000/' //localhost
// const backendUrl = 'http://192.168.8.101:5000/' //fereshteh
const backendUrl = 'https://elb.coilworks.co.uk/' //main server

const axiosBaseConfig = axios.create({
    baseURL: backendUrl,
    headers: {
        'Authorization': localStorage.getItem('access_token') ? `Bearer ${localStorage.getItem('access_token')}` : null
    }
});


const updateAuthorizationHeader = () => {
    axiosBaseConfig.defaults.headers['Authorization'] = localStorage.getItem('access_token') ? `Bearer ${localStorage.getItem('access_token')}` : null
};


axiosBaseConfig.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem("access_token")
            updateAuthorizationHeader()
            if (window.location.pathname !== `/login`) {
                window.location.reload();
            }
        }
        return Promise.reject(error);
    }
);

export { axiosBaseConfig, updateAuthorizationHeader };
import {useDispatch, useSelector} from "react-redux";
import {
    setStlParameters, getAllStlFiles
} from "../../../features/stlFilesSlice";
import {useEffect, useState} from "react";
import {restoreDefaultIcon} from "../../../icons/inputLayout";
import {TextInput, DropdownInput} from "../components";
import {
    LAMINATION_AREA_INPUT,
    LAMINATION_THICKNESS_INPUT,
    LAMINATION_THICKNESS_INPUT_OPTIONS,
    NUMBER_OF_LAMINATION_INPUT,
    NUMBER_OF_SLOT_INPUT,
    STACK_HEIGHT_INPUT,
    STATOR_BORE_DIAMETER_INPUT,
    STATOR_OUTER_DIAMETER_INPUT, statorDefaultValues, WIRE_DEPTH_INPUT
} from "../../../data/statorInputs";
import {
    numberOfSlotGif, stackHeightGif,
    statorBoreDiameterGif,
    statorOuterDiameterGif
} from "../../../icons/inputLayout/gif/statorDesign";
import {
    VALIDATE_CORE_BACK_LESS_THAN_STATIC,
    VALIDATE_NUMBER_OF_SLOT_MUST_BE_MULTIPLE_OF_THREE,
    VALIDATE_SLOT_DEPTH_FORMULA,
    VALIDATE_SPAN_BIGGER_THAN_NUMBER_OF_SLOT,
    VALIDATE_STATOR_OUTER_DIAMETER_BIGGER_THAN_STATOR_BORE_DIAMETER
} from "../../../data/validatonErrors";
import {SPAN_INPUT} from "../../../data/windingSideInputs";
import {WarningDialog} from "../../dialogs";
import {changeSelectedParentCoil, deleteFormingSideCoils} from "../../../features/formingSideCoilsSlice";
import {deleteWeldingSideCoils} from "../../../features/weldingSideCoilsSlice";


const StatorDesignInputs = () => {
    const dispatch = useDispatch();
    const [validationError, setValidationError] = useState("")
    const stlParameters = useSelector((store) => store.stlParameters);
    const {
        number_of_slot,
        stator_bore_diameter,
        stator_outer_diameter,
        stack_height,
        slot_depth,
        lamination_thickness
    } = useSelector((store) => store.stlParameters);
    const [numberOfSlotInput, setNumberOfSlotInput] = useState(stlParameters[NUMBER_OF_SLOT_INPUT])
    const [statorBoreDiameterInput, setStatorBoreDiameterInput] = useState(stlParameters[STATOR_BORE_DIAMETER_INPUT])
    const [statorOuterDiameterInput, setStatorOuterDiameterInput] = useState(stlParameters[STATOR_OUTER_DIAMETER_INPUT])
    const [stackHeightInput, setStackHeightInput] = useState(stlParameters[STACK_HEIGHT_INPUT])
    const [laminationThicknessOptions, setLaminationThicknessOptions] = useState(LAMINATION_THICKNESS_INPUT_OPTIONS);
    const [laminationThicknessValue, setLaminationThicknessValue] = useState(stlParameters[LAMINATION_THICKNESS_INPUT]);

    // we set inputs from redux values
    useEffect(() => {
        dispatch(setStlParameters({
            [NUMBER_OF_LAMINATION_INPUT]:
                parseFloat(stack_height / lamination_thickness).toFixed(2),
        }))
        setNumberOfSlotInput(number_of_slot)
        setStatorBoreDiameterInput(stator_bore_diameter)
        setStatorOuterDiameterInput(stator_outer_diameter)
        setStackHeightInput(stack_height)
        setLaminationThicknessValue(lamination_thickness)
        console.log("stator parameters changed")
    }, [dispatch, number_of_slot,
        stator_bore_diameter,
        stator_outer_diameter,
        stack_height,
        lamination_thickness])

    // we check validations here
    const validations = (numberOfSlot, statorBoreDiameter, statorOuterDiameter) => {
        if (numberOfSlot % 3 !== 0) {
            return VALIDATE_NUMBER_OF_SLOT_MUST_BE_MULTIPLE_OF_THREE
        }
        if (stlParameters[SPAN_INPUT] > numberOfSlot) {
            return VALIDATE_SPAN_BIGGER_THAN_NUMBER_OF_SLOT
        }
        if (statorBoreDiameter >= statorOuterDiameter) {
            return VALIDATE_STATOR_OUTER_DIAMETER_BIGGER_THAN_STATOR_BORE_DIAMETER
        }
        if (statorBoreDiameter + (2 * stlParameters[WIRE_DEPTH_INPUT]) > statorOuterDiameter) {
            return VALIDATE_SLOT_DEPTH_FORMULA
        }
        if (statorOuterDiameter / 2
            - parseFloat(slot_depth)
            - statorBoreDiameter / 2 <= 2) {
            return VALIDATE_CORE_BACK_LESS_THAN_STATIC
        }
        return false
    }

    // we send new inputs to backend here and update stl files
    const setParameters = (numberOfSlot, statorBoreDiameter, statorOuterDiameter, stackHeight, laminationThickness) => {
        const checkValidations =
            validations(numberOfSlot, statorBoreDiameter, statorOuterDiameter)
        if (checkValidations) {
            setValidationError(checkValidations)
            return
        }
        // here we check if user change number of slot, we remove all users forming and welding coils
        if (numberOfSlot !== stlParameters[NUMBER_OF_SLOT_INPUT]) {
            dispatch(deleteFormingSideCoils())
            dispatch(deleteWeldingSideCoils())
            dispatch(changeSelectedParentCoil({selectedParentName: null}))
        }
        dispatch(setStlParameters({
            [NUMBER_OF_SLOT_INPUT]: numberOfSlot,
            [STATOR_BORE_DIAMETER_INPUT]: statorBoreDiameter,
            [STATOR_OUTER_DIAMETER_INPUT]: statorOuterDiameter,
            [STACK_HEIGHT_INPUT]: stackHeight,
            [LAMINATION_THICKNESS_INPUT]: laminationThickness,
            [NUMBER_OF_LAMINATION_INPUT]: parseFloat(stackHeight / laminationThickness).toFixed(2),
        }))
        dispatch(getAllStlFiles())
    }

    // we call setParameters when user pressed enter
    const handleOnEnterPressed = (event) => {
        if (event.key === 'Enter') {
            setParameters(parseInt(numberOfSlotInput),
                parseFloat(statorBoreDiameterInput),
                parseFloat(statorOuterDiameterInput),
                parseFloat(stackHeightInput),
                parseFloat(laminationThicknessValue))
        }
        // event.stopPropagation();
    }


    // here we append users new option to dropdown options, then we call setParameters
    const addLaminationThicknessOption = (event) => {
        if (event.key === 'Enter') {
            const newOptions = laminationThicknessOptions.concat(parseFloat(laminationThicknessValue));
            console.log("before unique")
            console.log({newOptions})
            const uniqueOptions = Array.from(new Set(newOptions));
            console.log("after unique")
            console.log({uniqueOptions})
            const sortedOptions = uniqueOptions.sort((a, b) => a - b);
            setLaminationThicknessOptions(sortedOptions)
            setParameters(parseInt(numberOfSlotInput),
                parseFloat(statorBoreDiameterInput),
                parseFloat(statorOuterDiameterInput),
                parseFloat(stackHeightInput),
                parseFloat(laminationThicknessValue))
        }
    }

    // we call setParameters after user select a dropdown value
    const laminationThicknessItemSelected = (event) => {
        setLaminationThicknessValue(event)
        setParameters(parseInt(numberOfSlotInput),
            parseFloat(statorBoreDiameterInput),
            parseFloat(statorOuterDiameterInput),
            parseFloat(stackHeightInput),
            parseFloat(event))
    }

    return (
        <>
            {validationError && <WarningDialog message={validationError} closeFunc={() => {
                setValidationError("")
            }}/>}
            <div className="title">
                <span>Properties</span>
                <div className="restore-default-component" onClick={() => {
                    // restore default change inputs and redux values based on values in src/data/statorInputs.js
                    // setParameters(statorDefaultValues[NUMBER_OF_SLOT_INPUT],
                    //     statorDefaultValues[STATOR_BORE_DIAMETER_INPUT],
                    //     statorDefaultValues[STATOR_OUTER_DIAMETER_INPUT],
                    //     statorDefaultValues[STACK_HEIGHT_INPUT],
                    //     statorDefaultValues[LAMINATION_THICKNESS_INPUT])
                    setNumberOfSlotInput(statorDefaultValues[NUMBER_OF_SLOT_INPUT])
                    setStatorBoreDiameterInput(statorDefaultValues[STATOR_BORE_DIAMETER_INPUT])
                    setStatorOuterDiameterInput(statorDefaultValues[STATOR_OUTER_DIAMETER_INPUT])
                    setStackHeightInput(statorDefaultValues[STACK_HEIGHT_INPUT])
                    setLaminationThicknessValue(statorDefaultValues[LAMINATION_THICKNESS_INPUT])
                }}>
                    <span className="restore-default">Restore Default</span>
                    <img src={restoreDefaultIcon} alt="restoreDefaultIcon"/>
                </div>
            </div>
            <div className="inputs">
                <form>
                    <TextInput id={NUMBER_OF_SLOT_INPUT} text="Number of Slot" gifFile={numberOfSlotGif}
                               value={numberOfSlotInput} onChangeFunc={setNumberOfSlotInput}
                               inputType={'int'} unitText="No."
                               onEnterPressedFunc={handleOnEnterPressed}/>
                    <TextInput id={STATOR_BORE_DIAMETER_INPUT} text="Stator Bore Diameter"
                               gifFile={statorBoreDiameterGif} value={statorBoreDiameterInput}
                               inputType={'float'} unitText="mm"
                               onChangeFunc={setStatorBoreDiameterInput} onEnterPressedFunc={handleOnEnterPressed}/>
                    <TextInput id={STATOR_OUTER_DIAMETER_INPUT} text="Stator Outer Diameter"
                               gifFile={statorOuterDiameterGif} value={statorOuterDiameterInput}
                               inputType={'float'} unitText="mm"
                               onChangeFunc={setStatorOuterDiameterInput} onEnterPressedFunc={handleOnEnterPressed}/>
                    <TextInput id={STACK_HEIGHT_INPUT} text="Stack Height" gifFile={stackHeightGif}
                               value={stackHeightInput} onChangeFunc={setStackHeightInput}
                               inputType={'float'} unitText="mm"
                               onEnterPressedFunc={handleOnEnterPressed}/>
                    <DropdownInput id={LAMINATION_THICKNESS_INPUT} text="Lamination Thickness"
                                   value={laminationThicknessValue} onChangeFunc={setLaminationThicknessValue}
                                   itemSelectedFunc={laminationThicknessItemSelected}
                                   inputType={'float'} unitText="mm"
                                   options={laminationThicknessOptions} addOptionFunc={addLaminationThicknessOption}/>
                    <TextInput id={NUMBER_OF_LAMINATION_INPUT} text="Number Of Lamination"
                               value={stlParameters[NUMBER_OF_LAMINATION_INPUT]} readOnly={true} unitText="No."/>
                    <TextInput id={LAMINATION_AREA_INPUT} text="Lamination Area"
                               value={stlParameters[LAMINATION_AREA_INPUT]} readOnly={true} unitText="mm²"/>
                </form>
            </div>
        </>
    );
};

export default StatorDesignInputs;

// NAVBAR
export const NAVBAR_STATOR = 'STATOR'
export const NAVBAR_WINDING_SIDE = 'WINDING_SIDE'
export const NAVBAR_CONNECTION_TABLE = 'CONNECTION_TABLE'
export const NAVBAR_FORMING_SIDE = 'FORMING_SIDE'
export const NAVBAR_OPTIMISATION = 'OPTIMISATION'

// INPUT SIDE
export const INPUT_STATOR_DESIGN = 'STATOR_DESIGN'
export const INPUT_STATOR_SLOT_DESIGN = 'STATOR_SLOT_DESIGN'
// export const INPUT_WIRE_DESIGN = 'WIRE_DESIGN'
export const INPUT_WELDING_SIDE_DESIGN = 'WELDING_SIDE_DESIGN'
export const INPUT_CONNECTION_TABLE_FORMING_SIDE_DESIGN = 'CONNECTION_TABLE_FORMING_SIDE_DESIGN'
export const INPUT_CONNECTION_TABLE_WELDING_SIDE_DESIGN = 'CONNECTION_TABLE_WELDING_SIDE_DESIGN'
export const INPUT_FORMING_SIDE = 'FORMING_SIDE'
export const INPUT_OPTIMISATION_OPTIONS = 'OPTIMISATION_OPTIONS'
export const INPUT_ANIMATION_OPTIONS = 'ANIMATION_OPTIONS'
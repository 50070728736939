// Wire Design
export const WIRE_INSULATION_THICKNESS_INPUT = 'wire_insulation_thickness'
export const WIRE_INSULATION_THICKNESS_INPUT_OPTIONS = [0.12, 0.14, 0.16, 0.18]
export const WIRE_FILLET_RADIUS_INPUT = 'wire_fillet_radius'
export const WIRE_FILLET_RADIUS_INPUT_OPTIONS = [0.2, 0.3, 0.4, 0.65]


// Welding Side Design
export const SPAN_INPUT = 'span'
export const WIDENING_GAP_INPUT = 'widening_gap'
export const STATOR_WIRE_OVERHANG_INPUT = 'stator_wire_overhang'
export const ISOLATED_WIRE_END_LENGTH_INPUT = 'isolated_wire_end_length'
export const STRIPPED_WIRE_LENGTH_INPUT = 'stripped_wire_length'
export const WELDING_WIRE_HEIGHT_INPUT = 'welding_wire_height'
export const COIL_HEIGHT_INPUT = 'coil_height'


// Default Values
export const windingSideDefaultValues = {
    [SPAN_INPUT]: 4,
    [WIRE_INSULATION_THICKNESS_INPUT]: WIRE_INSULATION_THICKNESS_INPUT_OPTIONS[0],
    [WIRE_FILLET_RADIUS_INPUT]: WIRE_FILLET_RADIUS_INPUT_OPTIONS[0],
    [WIDENING_GAP_INPUT]: 2,
    [STATOR_WIRE_OVERHANG_INPUT]: 2,
    [ISOLATED_WIRE_END_LENGTH_INPUT]: 2,
    [STRIPPED_WIRE_LENGTH_INPUT]: 8,
    [COIL_HEIGHT_INPUT]: 33,
    [WELDING_WIRE_HEIGHT_INPUT]: 30.98,
};
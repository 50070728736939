import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {axiosBaseConfig, updateAuthorizationHeader} from "../data/url";

// READ DOCUMENTS FOR FEATURES FILES
const initialState = {
    userToken: localStorage.getItem('access_token') ? localStorage.getItem('access_token') : null,
    username: localStorage.getItem('username') ? localStorage.getItem('username') : null,
    password: localStorage.getItem('password') ? localStorage.getItem('password') : null,
}

export const getUser = createAsyncThunk(
    'userSlice/getUser',
    async (name, thunkAPI) => {
        try {
            const endpoint = '/login/'
            const payload = {
                username: name["username"],
                password: name["password"],
            }
            const response = await axiosBaseConfig.post(endpoint, payload);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('can check user id');
        }
    }
);

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        updateUsernameAndPassword: (state, {payload}) => {
            state.username = payload.username.toLowerCase()
            state.password = payload.password
            localStorage.setItem("username", payload.username.toLowerCase())
            localStorage.setItem("password", payload.password)
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUser.pending, () => {
                console.log("Checking user id")
            })
            .addCase(getUser.fulfilled, (state, action) => {
                localStorage.setItem("access_token", action.payload["access"])
                updateAuthorizationHeader()
                state.userToken = action.payload["access"];
            })
            .addCase(getUser.rejected, (state, action) => {
                state.userToken = false;
                console.log(action);
            });
    },
});

export const {updateUsernameAndPassword} = userSlice.actions

export default userSlice.reducer;
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {
    COIL_NAME,
    ENDING_LAYER,
    ENDING_SLOT,
    PARENT_NAME,
    STARTING_LAYER,
    STARTING_SLOT,
    TAG
} from "../data/connectionTable";
import {axiosBaseConfig} from "../data/url";

// READ DOCUMENTS FOR FEATURES FILES
const initialState = {
    weldingSideCoils: [],
    isLoading: 0
};
export const getWeldingSideCoils = createAsyncThunk(
    'weldingSideCoilsSlice/getWeldingSideCoils',
    async (name, thunkAPI) => {
        try {
            const endpoint = '/welding/'
            const response = await axiosBaseConfig.get(endpoint);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('can not get welding side coils');
        }
    }
);

export const addWeldingSideCoil = createAsyncThunk(
    'weldingSideCoilsSlice/addWeldingSideCoil',
    async (name, thunkAPI) => {
        try {
            const endpoint = '/welding/'
            const payload = {
                [STARTING_SLOT]: name.newCoil[STARTING_SLOT],
                [STARTING_LAYER]: name.newCoil[STARTING_LAYER],
                [ENDING_SLOT]: name.newCoil[ENDING_SLOT],
                [ENDING_LAYER]: name.newCoil[ENDING_LAYER],
            };
            const response = await axiosBaseConfig.post(endpoint, payload);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('can not add welding side coil');
        }
    }
);

export const deleteWeldingSideCoils = createAsyncThunk(
    'weldingSideCoilsSlice/deleteWeldingSideCoils',
    async (name, thunkAPI) => {
        try {
            const endpoint = '/welding/'
            const response = await axiosBaseConfig.delete(endpoint);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('can not delete welding side coils');
        }
    }
);

export const changeTagWeldingSideSingleCoil = createAsyncThunk(
    'weldingSideCoilsSlice/changeTagWeldingSideSingleCoil',
    async (name, thunkAPI) => {
        try {
            const endpoint = `/welding/${name[COIL_NAME]}/`
            const payload = {
                [TAG]: name["value"],
            };
            const response = await axiosBaseConfig.put(endpoint, payload);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('can not change welding side coil tag');
        }
    }
);

export const deleteWeldingSideSingleCoil = createAsyncThunk(
    'weldingSideCoilsSlice/deleteWeldingSideSingleCoil',
    async (name, thunkAPI) => {
        try {
            const endpoint = `/welding/${name[COIL_NAME]}/`
            const response = await axiosBaseConfig.delete(endpoint);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('can not delete welding side specific coil');
        }
    }
);

export const changeTagWeldingSideSingleChildCoil = createAsyncThunk(
    'weldingSideCoilsSlice/changeTagWeldingSideSingleChildCoil',
    async (name, thunkAPI) => {
        try {
            const endpoint = `/welding/${name[PARENT_NAME]}/${name[COIL_NAME]}/`
            const payload = {
                [TAG]: name["value"],
            };
            const response = await axiosBaseConfig.put(endpoint, payload);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('can not change welding side child coil tag');
        }
    }
);

export const deleteWeldingSideSingleChildCoil = createAsyncThunk(
    'weldingSideCoilsSlice/deleteWeldingSideSingleChildCoil',
    async (name, thunkAPI) => {
        try {
            const endpoint = `/welding/${name[PARENT_NAME]}/${name[COIL_NAME]}/`
            const response = await axiosBaseConfig.delete(endpoint);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('can not delete welding side specific child coil');
        }
    }
);

export const weldingDefinePattern = createAsyncThunk(
    'weldingSideCoilsSlice/weldingDefinePattern',
    async (name, thunkAPI) => {
        try {
            console.log(name)
            const endpoint = '/welding/define-pattern/'
            const payload = {
                enter_slot_shift: name.enterSlotShift,
                number_of_patterns: name.numberOfPatterns,
                parents_name: name.parentsName,
            };
            const response = await axiosBaseConfig.post(endpoint, payload);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('can not create define pattern');
        }
    }
);

export const weldingUndo = createAsyncThunk(
    'weldingSideCoilsSlice/weldingUndo',
    async (name, thunkAPI) => {
        try {
            const endpoint = '/welding/undo/'
            const response = await axiosBaseConfig.get(endpoint);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('can not get undo welding parameters');
        }
    }
);

export const weldingRedo = createAsyncThunk(
    'weldingSideCoilsSlice/weldingRedo',
    async (name, thunkAPI) => {
        try {
            const endpoint = '/welding/redo/'
            const response = await axiosBaseConfig.get(endpoint);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('can not get redo welding parameters');
        }
    }
);


const weldingSideCoilsSlice = createSlice({
    name: 'weldingSideCoils',
    initialState,
    reducers: {
        //     addWeldingSideCoil: (state, {payload}) => {
        //         state.weldingSideCoils.push(payload.newCoil)
        //     },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getWeldingSideCoils.pending, (state) => {
                console.log("getting welding side coils")
                state.isLoading = state.isLoading + 1;
            })
            .addCase(getWeldingSideCoils.fulfilled, (state, action) => {
                state.isLoading = state.isLoading - 1;
                console.log("got welding side coils")
                state.weldingSideCoils = action.payload["welding_coils"];
            })
            .addCase(getWeldingSideCoils.rejected, (state, action) => {
                console.log(action);
                state.isLoading = state.isLoading - 1;
            })
            .addCase(addWeldingSideCoil.pending, (state) => {
                console.log("getting welding side coils")
                state.isLoading = state.isLoading + 1;
            })
            .addCase(addWeldingSideCoil.fulfilled, (state, action) => {
                state.isLoading = state.isLoading - 1;
                console.log("got welding side coils")
                state.weldingSideCoils = action.payload["welding_coils"];
            })
            .addCase(addWeldingSideCoil.rejected, (state, action) => {
                console.log(action);
                state.isLoading = state.isLoading - 1;
            })
            .addCase(deleteWeldingSideCoils.pending, (state) => {
                console.log("deleting welding side coils")
                state.isLoading = state.isLoading + 1;
            })
            .addCase(deleteWeldingSideCoils.fulfilled, (state, action) => {
                state.isLoading = state.isLoading - 1;
                console.log("Welding side coils deleted")
                state.weldingSideCoils = action.payload["welding_coils"];
            })
            .addCase(deleteWeldingSideCoils.rejected, (state, action) => {
                console.log(action);
                state.isLoading = state.isLoading - 1;
            })
            .addCase(changeTagWeldingSideSingleCoil.pending, (state) => {
                console.log("changing welding side coil tag")
                state.isLoading = state.isLoading + 1;
            })
            .addCase(changeTagWeldingSideSingleCoil.fulfilled, (state, action) => {
                state.isLoading = state.isLoading - 1;
                console.log("Welding side coil tag Change")
                state.weldingSideCoils = action.payload["welding_coils"];
            })
            .addCase(changeTagWeldingSideSingleCoil.rejected, (state, action) => {
                console.log(action);
                state.isLoading = state.isLoading - 1;
            })
            .addCase(deleteWeldingSideSingleCoil.pending, (state) => {
                console.log("deleting welding side single coil")
                state.isLoading = state.isLoading + 1;
            })
            .addCase(deleteWeldingSideSingleCoil.fulfilled, (state, action) => {
                state.isLoading = state.isLoading - 1;
                console.log("Welding side single coil deleted")
                state.weldingSideCoils = action.payload["welding_coils"];
            })
            .addCase(deleteWeldingSideSingleCoil.rejected, (state, action) => {
                console.log(action);
                state.isLoading = state.isLoading - 1;
            })
            .addCase(changeTagWeldingSideSingleChildCoil.pending, (state) => {
                console.log("changing welding side child coil tag")
                state.isLoading = state.isLoading + 1;
            })
            .addCase(changeTagWeldingSideSingleChildCoil.fulfilled, (state, action) => {
                state.isLoading = state.isLoading - 1;
                console.log("Welding side coil tag child Change")
                state.weldingSideCoils = action.payload["welding_coils"];
            })
            .addCase(changeTagWeldingSideSingleChildCoil.rejected, (state, action) => {
                console.log(action);
                state.isLoading = state.isLoading - 1;
            })
            .addCase(deleteWeldingSideSingleChildCoil.pending, (state) => {
                console.log("deleting welding side single child coil")
                state.isLoading = state.isLoading + 1;
            })
            .addCase(deleteWeldingSideSingleChildCoil.fulfilled, (state, action) => {
                state.isLoading = state.isLoading - 1;
                console.log("Welding side single coil child deleted")
                state.weldingSideCoils = action.payload["welding_coils"];
            })
            .addCase(deleteWeldingSideSingleChildCoil.rejected, (state, action) => {
                console.log(action);
                state.isLoading = state.isLoading - 1;
            })
            .addCase(weldingDefinePattern.pending, (state) => {
                console.log("creating child based on define pattern")
                state.isLoading = state.isLoading + 1;
            })
            .addCase(weldingDefinePattern.fulfilled, (state, action) => {
                state.isLoading = state.isLoading - 1;
                console.log("children created")
                state.weldingSideCoils = action.payload["welding_coils"];
            })
            .addCase(weldingDefinePattern.rejected, (state, action) => {
                console.log(action);
                state.isLoading = state.isLoading - 1;
            })
            .addCase(weldingUndo.pending, (state) => {
                console.log("Getting undo welding parameters")
                state.isLoading = state.isLoading + 1;
            })
            .addCase(weldingUndo.fulfilled, (state, action) => {
                state.isLoading = state.isLoading - 1;
                console.log("Got undo welding parameters")
                state.weldingSideCoils = action.payload["welding_coils"];
            })
            .addCase(weldingUndo.rejected, (state, action) => {
                console.log(action);
                state.isLoading = state.isLoading - 1;
            })
            .addCase(weldingRedo.pending, (state) => {
                console.log("Getting redo welding parameters")
                state.isLoading = state.isLoading + 1;
            })
            .addCase(weldingRedo.fulfilled, (state, action) => {
                state.isLoading = state.isLoading - 1;
                console.log("Got redo welding parameters")
                state.weldingSideCoils = action.payload["welding_coils"];
            })
            .addCase(weldingRedo.rejected, (state, action) => {
                console.log(action);
                state.isLoading = state.isLoading - 1;
            });
    },
});

export default weldingSideCoilsSlice.reducer;

import styled from 'styled-components'
import {
    OptimisationInputs,
    ConnectionTableInputs,
    FormingSideInputs,
    StatorInputs,
    WindingSideInputs,
    GuideLayout,
} from "../fields/inputLayout";
import {useSelector} from "react-redux";
import {
    NAVBAR_CONNECTION_TABLE,
    NAVBAR_FORMING_SIDE,
    NAVBAR_OPTIMISATION,
    NAVBAR_STATOR,
    NAVBAR_WINDING_SIDE
} from "../data/states";

const InputLayout = () => {
    const {navbarState} = useSelector((store) => store.globalItems);
    const {showGuideLayout} = useSelector((store) => store.guideLayout);

    // here we render inputLayout based on navbarState
    const generateInputs = () => {
        if (navbarState === NAVBAR_STATOR) {
            return <StatorInputs/>
        }
        if (navbarState === NAVBAR_WINDING_SIDE) {
            return <WindingSideInputs/>
        }
        if (navbarState === NAVBAR_CONNECTION_TABLE) {
            return <ConnectionTableInputs/>
        }
        if (navbarState === NAVBAR_FORMING_SIDE) {
            return <FormingSideInputs/>
        }
        if (navbarState === NAVBAR_OPTIMISATION) {
            return <OptimisationInputs/>
        }
    }
    return (
        <StyledWrapper height={showGuideLayout ? "33vh" : "69vh"}>
            <div className="all-inputs">
                {generateInputs()}
            </div>
            {(navbarState === NAVBAR_STATOR || navbarState === NAVBAR_WINDING_SIDE) && (
                <div className="guide-layout">
                    <GuideLayout/>
                </div>
            )}
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    background: var(--clr-grey-4);
    border: solid 1px var(--clr-grey-1);
    border-right: solid 1px var(--clr-grey-4);
    width: calc(23vw - 2px);
    color: var(--clr-grey-9);

    .coming-soon {
        font-size: 30px;
        padding-top: 20vh;
    }

    .all-inputs {
        padding: 1.1vh;

        .header-input {
            text-align: left;

            img {
                width: 1.85vh;
                height: 1.85vh;
                margin-right: auto;
                margin-top: -0.09vh;
            }

            span {
                vertical-align: top;
                margin-left: 0.3vw;
                font-size: var(--font-size-3);
            }
        }

        .buttons {
            margin-top: 1vh;
            text-align: left;

            button {
                margin: 0.5vw 0.9vh 0.5vw 0;
                color: var(--clr-grey-9);
                border: none;
                height: 3vh;
                cursor: pointer;
                border-radius: 6px;
            }
        }

        .title {
            padding-top: 0.9vh;
            font-size: var(--font-size-4);
            display: flex;
            justify-content: space-between;
            text-align: left;

            img {
                width: 1.5vh;
                height: 1.5vh;
            }

            span {
                vertical-align: top;
            }

            .restore-default-component {
                cursor: pointer;
            }

            .restore-default {
                padding-right: 0.2vw;
                color: var(--clr-grey-8)
            }
        }

        .inputs {
            margin-top: 1.85vh;
            padding-right: 1.5vw;
            border-bottom: solid 2px var(--clr-grey-5);
            display: flex;
            flex-direction: column;
            font-size: 14px;
            overflow: auto;
            scrollbar-width: thin;
            scrollbar-color: var(--clr-primary-dark) transparent;
            height: ${(props) => props.height || ''};

        }
    }

    .guide-layout {
        padding: 0 0.6vw 0.6vw;
        margin-bottom: -10vh;

        .header-layout {
            display: flex;
            justify-content: space-between;

            .header-input {
                text-align: left;

                img {
                    margin-top: -0.09vh;
                    width: 0.9vw;
                    height: 1.7vh;
                    margin-right: auto;
                }

                span {
                    vertical-align: top;
                    margin-left: 0.25vw;
                    font-size: var(--font-size-4);
                }
            }

            .show-hide-icon {
                margin-top: 0.1vh;
                width: 1.2vh;
                height: 1.2vh;
                cursor: pointer;
            }
        }

        .title {
            color: var(--clr-orange);
            opacity: 1;
            animation-name: fadeInOpacity;
            animation-iteration-count: 1;
            animation-timing-function: ease-in;
            animation-duration: 1s;
        }

        @keyframes fadeInOpacity {
            0% {
                opacity: 0;
            }
            100% {
                opacity: 1;
            }
        }

        .field-name {
            font-size: var(--font-size-3);
            opacity: 1;
            animation-name: fadeInOpacity;
            animation-iteration-count: 1;
            animation-timing-function: ease-in;
            animation-duration: 1s;
        }

        .gif-component {
            padding-top: 0.45vh;

            .gif {
                margin-top: 0.5vh;
                margin-bottom: 0.8vh;
                width: 28vh;
                height: 28vh;
            }

            .description {
                font-size: var(--font-size-5);
                opacity: 1;
                animation-name: fadeInOpacity;
                animation-iteration-count: 1;
                animation-timing-function: ease-in;
                animation-duration: 1s;
            }
        }
    }
`
export default InputLayout;

import styled from 'styled-components'


const MenuItem = (props) => {
    const {name, options, isOpen, onClick, handleMouseLeave, optionFunctions} = props;

    return (
        <StyledWrapper>
            <li onClick={() => onClick(name)}>
                {name}
                <ul style={{display: isOpen ? 'block' : 'none'}} onMouseLeave={handleMouseLeave}>
                    {options.map((item, index) => {
                        return <li key={index}
                                   onClick={optionFunctions ? optionFunctions[index] :
                                       () => console.log(`${name} - ${item} clicked`)}>{item}</li>;
                    })}
                </ul>
            </li>
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-left: 1.4vw;
    color: var(--clr-grey-9);
    font-size: var(--font-size-3);

    li {
        list-style: none;
        margin: 0;
        padding: 0;
        position: relative;
        cursor: pointer;
    }

    ul {
        display: none;
        position: absolute;
        background-color: var(--clr-grey-1);
        color: var(--clr-grey-9);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
        border-radius: 6px;
        z-index: 1;
        list-style: none;
        padding: 0.2vh;
        width: auto;
        border: solid 1px var(--clr-grey-1);

        li {
            padding: 0.55vh 0.5vw;
            border-radius: 4px;
            display: block;
            width: 8vw;
            font-size: var(--font-size-4);

            &:hover {
                background: var(--clr-primary-dark);
            }
        }
    }
`
export default MenuItem;

import {useState} from 'react';
import styled from 'styled-components'
import {MenuItem} from "../fields/toolbar";
import {menuItems} from '../data/toolBar'
import {HelpDialog, ExportDialog} from "../fields/toolbar";
import {updateAuthorizationHeader} from "../data/url";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {appLogo, projectIcon} from "../icons/toolbar";
import {hideExportDialogFunc, showExportDialogFunc} from "../features/exportSlice";

const ToolBar = ({toggleFullScreenFunc}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {currentProject} = useSelector((store) => store.projects);
    const {showExportDialog} = useSelector((store) => store.export);
    const {fileMenu, editMenu, motorTypesMenu, exportMenu, helpMenu} = menuItems
    const [openMenu, setOpenMenu] = useState(null);
    const [openHelpDialog, setOpenHelpDialog] = useState(false);

    // here we open a menu dropdown when user click on it (we close it if the menu is already open)
    const handleMenuClick = (menuName) => {
        if (menuName === "Motor Types") {
            toast.warning("This feature will be coming soon ...")
        }
        setOpenMenu(openMenu === menuName ? null : menuName);
    };

    // we close menu dropdown when user mouse leaves
    const handleMouseLeave = () => {
        setOpenMenu(null)
    }

    // functions for fileMenu icon
    const fileMenuFunctions = [
        () => console.log("File - New Project clicked"),
        () => console.log("File - Open clicked"),
        () => console.log("File - Open Recent Project clicked"),
        () => console.log("File - Import clicked"),
        () => {
            navigate('/projects')
        },
        () => console.log("File - Save As clicked"),
        () => {
            localStorage.removeItem("access_token")
            localStorage.removeItem("username")
            localStorage.removeItem("password")
            updateAuthorizationHeader()
            window.location.reload();
        },
    ]

    const exportMenuFunctions = [() => dispatch(showExportDialogFunc())]

    // functions for helpMenu icon
    const helpMenuFunctions = [() => setOpenHelpDialog(true)]

    return (
        <StyledWrapper>
            <div className="toolbar-items">
                <img src={appLogo} alt="appLogo"/>
                <MenuItem name={fileMenu[0]} options={fileMenu[1]} isOpen={openMenu === fileMenu[0]}
                          onClick={handleMenuClick} handleMouseLeave={handleMouseLeave}
                          optionFunctions={fileMenuFunctions}/>
                <MenuItem name={editMenu[0]} options={editMenu[1]} isOpen={openMenu === editMenu[0]}
                          onClick={handleMenuClick} handleMouseLeave={handleMouseLeave}/>
                <MenuItem name={motorTypesMenu[0]} options={motorTypesMenu[1]} isOpen={openMenu === motorTypesMenu[0]}
                          onClick={handleMenuClick} handleMouseLeave={handleMouseLeave}/>
                <MenuItem name={exportMenu[0]} options={exportMenu[1]} isOpen={openMenu === exportMenu[0]}
                          onClick={handleMenuClick} handleMouseLeave={handleMouseLeave}
                          optionFunctions={exportMenuFunctions}/>
                <MenuItem name={helpMenu[0]} options={helpMenu[1]} isOpen={openMenu === helpMenu[0]}
                          onClick={handleMenuClick} handleMouseLeave={handleMouseLeave}
                          optionFunctions={helpMenuFunctions}/>
            </div>
            <div className="double-click" onDoubleClick={toggleFullScreenFunc}/>
            <div className="project-name">
                <p>{currentProject}</p>
                <img src={projectIcon} alt="projectIcon"/>
            </div>
            {showExportDialog && <ExportDialog closeFunc={() => dispatch(hideExportDialogFunc())}/>}
            {openHelpDialog && <HelpDialog closeFunc={() => setOpenHelpDialog(false)}/>}
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    background: var(--clr-grey-1);
    border: solid 1px var(--clr-grey-1);
    width: calc(99vw - 2px);
    height: calc(4vh - 6px);
    padding-left: 1vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .toolbar-items {
        display: flex;

        img {
            width: 2vh;
            padding-right: 1.5vw;
            margin-top: 0.8vh;
            margin-bottom: 0.8vh;
            border-right: solid 2px var(--clr-grey-3);
        }
    }
    
    .double-click{
        height: 3vh;
        width: 70vw;
    }

    .project-name {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        font-size: var(--font-size-1);
        padding-right: 1vw;
        color: var(--clr-orange);

        p {
            font-size: var(--font-size-2);
        }

        img {
            margin-left: 0.25vw;
            height: 1.75vh;
        }
    }
`
export default ToolBar;

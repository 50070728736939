import {
    fitScreenIcon,
    formingSideGenerateIcon,
} from "../../icons/actionbar";
import {
    changeZoomStates,
    generateFormingCoilStl,
} from "../../features/formingSideCoilsSlice";
import {useDispatch, useSelector} from "react-redux";
import {VIEWER_STATE_STANDARD} from "../../data/Viewer";



const FormingSideIcons = ({fitScreenFunc}) => {
    const dispatch = useDispatch();
    const {viewerState} = useSelector((store) => store.viewer);

    // if viewerState !==  VIEWER_STATE_STANDARD, our buttons functions will change cause we render Viewer
    return (
        <>
            {/*{isIconAvailable && viewerState !== VIEWER_STATE_STANDARD &&*/}
            {/*    <img src={isometricViewIcon} alt="isometricView"*/}
            {/*         title="Isometric View"*/}
            {/*         onClick={() => {*/}
            {/*             dispatch(toggleParallelView());*/}
            {/*         }}/>}*/}
            {/*{isIconAvailable &&*/}
            {/*    <img src={yzViewIcon} alt="yzView"*/}
            {/*         title="Y-Z View"*/}
            {/*         onClick={() => {*/}
            {/*             if (viewerState !== VIEWER_STATE_STANDARD) {*/}
            {/*                 dispatch(setYZView());*/}
            {/*             } else {*/}
            {/*                 const data = {*/}
            {/*                     xPosition: 700,*/}
            {/*                     yPosition: 0,*/}
            {/*                     zPosition: 0,*/}
            {/*                     zoom: 0.1,*/}
            {/*                     controlsXPosition: 0,*/}
            {/*                     controlsYPosition: 0,*/}
            {/*                     controlsZPosition: 0,*/}
            {/*                 }*/}
            {/*                 dispatch(changeZoomStates({*/}
            {/*                     stateId: 3,*/}
            {/*                     data: data*/}
            {/*                 }))*/}
            {/*             }*/}
            {/*         }}/>}*/}
            {/*{isIconAvailable && <img src={xzViewIcon} alt="xzView"*/}
            {/*                         title="X-Z View"*/}
            {/*                         onClick={() => {*/}
            {/*                             if (viewerState !== VIEWER_STATE_STANDARD) {*/}
            {/*                                 dispatch(setXZView());*/}
            {/*                             } else {*/}
            {/*                                 const data = {*/}
            {/*                                     xPosition: 0,*/}
            {/*                                     yPosition: 700,*/}
            {/*                                     zPosition: 0,*/}
            {/*                                     zoom: 0.1,*/}
            {/*                                     controlsXPosition: 0,*/}
            {/*                                     controlsYPosition: 0,*/}
            {/*                                     controlsZPosition: 0,*/}
            {/*                                 }*/}
            {/*                                 dispatch(changeZoomStates({*/}
            {/*                                     stateId: 3,*/}
            {/*                                     data: data*/}
            {/*                                 }))*/}
            {/*                             }*/}
            {/*                         }}/>}*/}
            {/*{isIconAvailable && <img src={xyViewIcon} alt="xyView"*/}
            {/*                         title="X-Y View"*/}
            {/*                         onClick={() => {*/}
            {/*                             if (viewerState !== VIEWER_STATE_STANDARD) {*/}
            {/*                                 dispatch(setXYView());*/}
            {/*                             } else {*/}
            {/*                                 const data = {*/}
            {/*                                     xPosition: 0,*/}
            {/*                                     yPosition: 0,*/}
            {/*                                     zPosition: 700,*/}
            {/*                                     zoom: 0.1,*/}
            {/*                                     controlsXPosition: 0,*/}
            {/*                                     controlsYPosition: 0,*/}
            {/*                                     controlsZPosition: 0,*/}
            {/*                                 }*/}
            {/*                                 dispatch(changeZoomStates({*/}
            {/*                                     stateId: 3,*/}
            {/*                                     data: data*/}
            {/*                                 }))*/}
            {/*                             }*/}
            {/*                         }}/>}*/}
            <img src={fitScreenIcon} alt="fitScreen"
                 title="Fit Screen"
                 onClick={() => {
                     if (viewerState !== VIEWER_STATE_STANDARD) {
                         fitScreenFunc();
                     } else {
                         const data = {
                             xPosition: 0,
                             yPosition: 0,
                             zPosition: 700,
                             zoom: 0.1,
                             controlsXPosition: 0,
                             controlsYPosition: 0,
                             controlsZPosition: 0,
                         }
                         dispatch(changeZoomStates({
                             stateId: 3,
                             data: data
                         }))
                     }
                 }}/>
            <hr/>
            {viewerState === VIEWER_STATE_STANDARD && <button className="forming-side-button" onClick={() => {
                dispatch(generateFormingCoilStl())
            }}>
                <span>Generate</span>
                <img src={formingSideGenerateIcon} alt="formingSideGenerateIcon"/>
            </button>}
        </>
    );
};


export default FormingSideIcons;

import styled from "styled-components";
import {withoutChildIcon, closedChildren, openedChildren, removeIcon} from "../../../icons/inputLayout";
import {useState} from "react";
import {ChildrenTreeBox} from "./index";
import {tagData} from "../../../data/mockDataCoils";
import {useDispatch} from "react-redux";
import {COIL_NAME, TAG} from "../../../data/connectionTable";

const ParentsTreeBox = ({
                            coilData,
                            deleteParentCoil,
                            changeParentTagCoil,
                            deleteChildCoil,
                            changeChildTagCoil,
                            showBusbars
                        }) => {
    const {
        coil_name: coilName,
        starting_slot: startingSlot,
        starting_layer: startingLayer,
        ending_slot: endingSlot,
        ending_layer: endingLayer,
        tag,
        coil_children: coilChildren
    } = coilData
    const dispatch = useDispatch();
    const [openChildren, setOpenChildren] = useState(false);

    // we handle busbars dropdown color style in this function
    const checkBasbarsBackground = () => {
        if (tag && tag.includes("A")) {
            return {background: "#f59e3d"}
        }
        if (tag && tag.includes("B")) {
            return {background: "#ea5547"}
        }
        if (tag && tag.includes("C")) {
            return {background: "#2e58bf"}
        }
        return {background: "#2b2b2b"}
    }

    return (
        <>
            <StyledWrapper>
                <div className="parent">
                    <div className="coil-name">
                        {coilChildren.length === 0 ?
                            <img src={withoutChildIcon} alt="withoutChildIcon"/> : openChildren ?
                                <img className="clickable" src={openedChildren} alt="openedChildren" onClick={() => {
                                    setOpenChildren(false)
                                }}/> :
                                <img className="clickable" src={closedChildren} alt="closedChildren"
                                     onClick={() => setOpenChildren(true)}/>}
                        <span>{"Coil " + coilName}</span>
                    </div>
                    <div className="boxes">
                        {showBusbars ?
                            <select style={checkBasbarsBackground(tag)} className="busbars" value={tag ? tag : "None"}
                                    onChange={(e) => {
                                        dispatch(changeParentTagCoil({[COIL_NAME]: coilName,
                                            type: TAG,
                                            value: e.target.value}))
                                    }}>
                                {tagData.map((option, index) => {
                                    return <option value={option} key={index}>{option}</option>
                                })}
                            </select> : <div className="disable-busbars"/>}
                        <div className="starting-box">
                            <span>{startingSlot} : {startingLayer}</span>
                        </div>
                        <div className="ending-box">
                            <span>{endingSlot} : {endingLayer}</span>
                        </div>
                        <div className="remove-icon" onClick={() => {
                            dispatch(deleteParentCoil({[COIL_NAME]: coilName}))
                        }}>
                            <img className="clickable" src={removeIcon} alt="closedChildren"/>
                        </div>
                    </div>
                </div>
                {openChildren && coilChildren.map((child, index) => {
                    return <ChildrenTreeBox key={index} coilData={{...child, parent_name: coilName}}
                                            deleteChildCoil={deleteChildCoil} changeChildTagCoil={changeChildTagCoil}
                                            showBusbars={showBusbars}/>
                })}
            </StyledWrapper>
        </>
    );
};

const StyledWrapper = styled.div`
    .parent {
        background: #494a4f;
        margin-top: 2px;
        margin-bottom: 2px;
        padding-right: 1vw;
        padding-left: 1vw;
        height: 5vh;
        border-radius: 7px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .coil-name {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: left;
            width: 4vw;

            span {
                font-size: var(--font-size-2);
                font-family: Ubuntu-Light, serif;
                margin-left: 0.5vw;
            }
        }

        .boxes {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 13vw;
        }
    }


    .clickable {
        cursor: pointer;
    }


    img {
        width: 1vh;
        height: 1vh;
    }

    .busbars {
        width: 3.5vw;
        height: 2vh;
        border: none;
        border-radius: 5px;
        color: var(--clr-grey-9);
        font-size: var(--font-size-3);
        padding-left: 1vh;
        outline: none;
        background-color: var(--clr-grey-3);
        cursor: pointer;
        font-family: Ubuntu-Light, serif;
    }

    .disable-busbars {
        width: 3.5vw;
        height: 2vh;
    }

    .starting-box {
        font-family: Ubuntu-Light, serif;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: var(--clr-green);
        font-size: var(--font-size-3);
        width: 3vw;
        height: 1.8vh;
        margin-left: 1vw;
        border-radius: 5px;
    }

    .ending-box {
        font-family: Ubuntu-Light, serif;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: var(--clr-pink-dark);
        font-size: var(--font-size-3);
        height: 1.8vh;
        width: 3vw;
        border-radius: 5px;
    }

    .remove-icon {
        margin-left: 0.5vw;

        img {
            width: 1.1vh;
            height: 1.1vh;
        }
    }
`


export default ParentsTreeBox;

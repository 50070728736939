import styled from 'styled-components'
import {useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import * as THREE from 'three';
import {STLLoader, TrackballControls} from "three/addons";
import {toast} from "react-toastify";
import {TextGeometry} from 'three/addons/geometries/TextGeometry.js';
import {FontLoader} from 'three/addons/loaders/FontLoader.js';
import {NURBSCurve} from 'three/addons/curves/NURBSCurve.js';
import {DragControls} from 'three/addons/controls/DragControls.js';
import {changeFormingCurvePoints} from "../features/formingSideCoilsSlice";


const FormingSideViewer = ({
                               stateId,
                               toggleFullScreenFunc,
                               componentWidth,
                               componentHeight,
                               topPosition,
                               leftPosition,
                               cameraData,
                               changeZoomFunc,
                               toggleShowCube,
                               rotation = 0,
                               isFullScreen
                           }) => {
    const dispatch = useDispatch();
    const refContainer = useRef(null);
    const {
        statorStl, stack_height, number_of_slot, stator_outer_diameter, slot_depth, slot_width, stator_bore_diameter
    } = useSelector((store) => store.stlParameters);
    const {formingSideCoils, selectedParentName} = useSelector((store) => store.formingSideCoils);
    const currentFormingCoil = formingSideCoils.find(forming_coil => forming_coil.coil_name === selectedParentName);
    const {
        segment2_control_points: formingBSplineSegment2,
        segment3_control_points: formingBSplineSegment3,
        segment3_modify_points: formingModifyPointsSegment3,
        segment4_control_points: formingBSplineSegment4,
        segment5_control_points: formingBSplineSegment5,
        segment6_control_points: formingBSplineSegment6,
        segment7_control_points: formingBSplineSegment7,
        segment7_modify_points: formingModifyPointsSegment7,
        segment8_control_points: formingBSplineSegment8,
    } = currentFormingCoil["segment_points"]
    const dynamicPartsColor = 0xFEFDED
    const staticPartsColor = 0xFA7070
    const cubesColor = 0x525fe1


    // we generate segment 2,4,5,6,8 curves with this function
    const createBSplineCurveFunc = (controlPoints, degree, knotVector, color = staticPartsColor, numPoints = 200) => {
        const points = controlPoints.map(point => new THREE.Vector3(point[0], point[1], point[2]));
        const curve = new NURBSCurve(degree, knotVector, points);
        const pointsForCurve = curve.getPoints(numPoints);

        // const shape = new THREE.Shape().setFromPoints(pointsForCurve);
        // // shape.moveTo( 0,0 );
        // // shape.lineTo( 0, 8 );
        // // shape.lineTo( 8, 8 );
        // // shape.lineTo( 8, 0 );
        // // shape.lineTo( 0, 0 );
        // const extrudeSettings = {
        //     // steps: 2,
        //     // depth: 2,
        //     // // bevelEnabled: true,
        //     // bevelThickness: 0,
        //     // bevelSize: 1,
        //     // bevelOffset: 0,
        //     // bevelSegments: 1
        // };
        // const geometry = new THREE.ExtrudeGeometry( shape, extrudeSettings );

        // const geometry = new THREE.BufferGeometry().setFromPoints(pointsForCurve);
        // const material = new THREE.LineBasicMaterial({color: color});
        // return new THREE.Line(geometry, material);

        const path = new THREE.CatmullRomCurve3(pointsForCurve);
        const tubeRadius = 0.25;  // Radius of the tube
        const radialSegments = 8;  // Number of segmented faces around the circumference of the tube
        const tubularSegments = 64;  // Number of segmented faces along the tube
        const closed = false;  // Whether the tube is closed or not
        const tubeGeometry = new THREE.TubeGeometry(path, tubularSegments, tubeRadius, radialSegments, closed);
        const material = new THREE.MeshBasicMaterial({color: color});
        return new THREE.Mesh(tubeGeometry, material);

        // const path = new THREE.CatmullRomCurve3(pointsForCurve);
        // const rectangleShape = new THREE.Shape();
        // const width = 1.8;
        // const height = 0.3;
        // rectangleShape.moveTo(-width / 2, -height / 2);
        // rectangleShape.lineTo(-width / 2, height / 2);
        // rectangleShape.lineTo(width / 2, height / 2);
        // rectangleShape.lineTo(width / 2, -height / 2);
        // rectangleShape.lineTo(-width / 2, -height / 2);
        // const extrudeSettings = {
        //     steps: 100,
        //     bevelEnabled: false,
        //     extrudePath: path
        // };
        // const extrudedGeometry = new THREE.ExtrudeGeometry(rectangleShape, extrudeSettings);
        // const material = new THREE.MeshBasicMaterial({ color: color });
        // const mesh = new THREE.Mesh(extrudedGeometry, material);
        // return mesh;
    }

    // we generate segment 3,7 curves with this function
    function generateCurve(points) {
        if (points.length !== 0) {
            // const dictPoints = points.map(point => {
            //     return {x: point[0], y: point[1], z: point[2]};
            // });
            // const geometryCurvePoints = new THREE.BufferGeometry().setFromPoints(dictPoints);
            // const materialCurvePoints = new THREE.LineBasicMaterial({color: dynamicPartsColor});
            // return new THREE.Line(geometryCurvePoints, materialCurvePoints);
            const pointsAsVector3 = points.map(point => new THREE.Vector3(point[0], point[1], point[2]));

            const path = new THREE.CatmullRomCurve3(pointsAsVector3);
            const tubeRadius = 0.25;  // Radius of the tube
            const radialSegments = 8;  // Number of segmented faces around the circumference of the tube
            const tubularSegments = 64;  // Number of segmented faces along the tube
            const closed = false;  // Whether the tube is closed or not
            const tubeGeometry = new THREE.TubeGeometry(path, tubularSegments, tubeRadius, radialSegments, closed);
            const material = new THREE.MeshBasicMaterial({color: dynamicPartsColor});
            return new THREE.Mesh(tubeGeometry, material);
        }
    }


    useEffect(() => {
        let knotVectorSegment5 = null
        let Segment5ControlPointsNumber = currentFormingCoil["segment5_control_points"]
        if (Segment5ControlPointsNumber === 7){
             knotVectorSegment5 = [0, 0, 0, 0, 1, 2, 3, 4, 4, 4, 4];
        }
        if (Segment5ControlPointsNumber === 5){
            knotVectorSegment5 = [0,0,0,0,1,2,2,2,2];
       }
        if (Segment5ControlPointsNumber === 6){
            knotVectorSegment5 = [0,0,0,0,1,2,3,3,3,3];
         }

        if (Segment5ControlPointsNumber === 4) {
            knotVectorSegment5 = [0, 0, 0, 0, 1, 1, 1, 1];
        }
        const knotVector = [0, 0, 0, 0, 1, 1, 1, 1];
        // knotVectorSegment5 = [0, 0, 0, 0, 1, 2, 3, 4, 4, 4, 4];

        // knotVectorSegment5 = [0, 0, 0, 0, 1, 1, 1, 1];

        // const knotVectorSegment5 = [0, 0, 0, 0, 1,1,1,1];
        // const knotVectorSegment5 = [0, 0, 0, 0, 1, 2, 3, 4, 4, 4, 4];
        // const knotVectorSegment5 = (formingBSplineSegment5.length === 5) ? [0, 0, 0, 0, 1, 2, 2, 2, 2] : [0, 0, 0, 0, 1, 1, 1, 1];
        let camera = null
        let controls = null
        let group = null
        let renderer = null
        let animationFunc = null
        let axesRenderer = null

        // we create curve here
        const loadBSpline = () => {
            let scene = new THREE.Scene();
            const aspect = refContainer.current.clientWidth / refContainer.current.clientHeight;
            camera = new THREE.OrthographicCamera(-10 * aspect, 10 * aspect, 10, -10);
            renderer = new THREE.WebGLRenderer({ antialias: true });
            renderer.setSize(refContainer.current.clientWidth, refContainer.current.clientHeight);
            const backgroundColor = getComputedStyle(document.documentElement).getPropertyValue('--clr-grey-7').trim();
            renderer.setClearColor(new THREE.Color(backgroundColor));


            group = new THREE.Group();
            let start_slot = currentFormingCoil["starting_slot"]
            let end_slot = currentFormingCoil["ending_slot"]
            let guess1 = Math.abs(currentFormingCoil["starting_slot"] - currentFormingCoil["ending_slot"]);
            let guess2 = number_of_slot - Math.abs(currentFormingCoil["starting_slot"] - currentFormingCoil["ending_slot"]);
            let min_guess = Math.min(guess1, guess2);
            let rotate_angle;
            // group.rotation.z = (currentFormingCoil["starting_slot"] - 1) * (360 / number_of_slot) * (Math.PI / 180)

            if (min_guess === guess1) {
                let tetha_start = 360 / number_of_slot * (start_slot - 1);
                let tetha_end = 360 / number_of_slot * (end_slot - 1);
                rotate_angle = Math.min(tetha_start, tetha_end);
            } else if (min_guess === guess2) {
                let tetha_start = 360 / number_of_slot * (start_slot - 1);
                let tetha_end = 360 / number_of_slot * (end_slot - 1);
                rotate_angle = Math.max(tetha_start, tetha_end);
            }

            group.rotation.z = rotate_angle * (Math.PI / 180)
            // group.rotation.z = (currentFormingCoil["starting_slot"] - 1) * (360 / number_of_slot) * (Math.PI / 180)

            // we rotate top left and top right curves for better ux
            if (stateId === 0) {
                group.rotation.x = rotation * (Math.PI / 180)
            } else if (stateId === 1) {
                let numerator = (formingBSplineSegment2[0][0] - formingBSplineSegment8[formingBSplineSegment8.length - 1][0])
                let denominator = (formingBSplineSegment2[0][1] - formingBSplineSegment8[formingBSplineSegment8.length - 1][1])
                group.rotation.y = rotation * (Math.PI / 180)
                group.rotation.z = Math.atan(numerator / denominator)
            }
            scene.add(group);

            // here we generate control points
            function generateCubes(points, cubeColor) {
                let resultDraggablePoints = []
                // bigger sphere
                points.map((point, index) => {
                    const geometry = new THREE.SphereGeometry(0.7, 32, 32)
                    const material = new THREE.MeshBasicMaterial({color: cubeColor, transparent: true, opacity: 0.25});
                    const mesh = new THREE.Mesh(geometry, material);
                    mesh.scale.set(1, 1, 1);
                    mesh.castShadow = true;
                    mesh.receiveShadow = true;
                    mesh.position.set(point[0], point[1], point[2])
                    mesh.userData.index = index
                    if (toggleShowCube) {
                        group.add(mesh);
                        resultDraggablePoints.push(mesh);
                    }
                    return null
                });
                // smaller sphere
                points.map((point, index) => {
                    const geometry = new THREE.SphereGeometry(0.45, 32, 32)
                    const material = new THREE.MeshBasicMaterial({color: cubeColor});
                    const mesh = new THREE.Mesh(geometry, material);
                    mesh.scale.set(1, 1, 1);
                    mesh.castShadow = true;
                    mesh.receiveShadow = true;
                    mesh.position.set(point[0], point[1], point[2])
                    mesh.userData.index = index
                    if (toggleShowCube) {
                        group.add(mesh);
                        // resultDraggablePoints.push(mesh);
                    }
                    return null
                });
                return resultDraggablePoints
            }

            // let dragItemsSegment2 = generateCubes(formingBSplineSegment2, 0x0000ff)
            // let dragItemsSegment8 = generateCubes(formingBSplineSegment8, 0x0000ff)
            // let dragItemsSegment4 = generateCubes(formingBSplineSegment4, 0x0000ff)
            // let dragItemsSegment6 = generateCubes(formingBSplineSegment6, 0x0000ff)
            let dragItemsSegment3 = generateCubes(formingBSplineSegment3, cubesColor)
            let dragItemsSegment7 = generateCubes(formingBSplineSegment7, cubesColor)
            let dragItemsSegment5 = generateCubes(formingBSplineSegment5, cubesColor)

            let bSplineCurveSegment2 = createBSplineCurveFunc(formingBSplineSegment2, 3, knotVector)
            group.add(bSplineCurveSegment2);
            let bSplineCurveSegment8 = createBSplineCurveFunc(formingBSplineSegment8, 3, knotVector)
            group.add(bSplineCurveSegment8);
            let bSplineCurveSegment4 = createBSplineCurveFunc(formingBSplineSegment4, 3, knotVector)
            group.add(bSplineCurveSegment4)
            let bSplineCurveSegment6 = createBSplineCurveFunc(formingBSplineSegment6, 3, knotVector)
            group.add(bSplineCurveSegment6);
            let bSplineCurveSegment5 = createBSplineCurveFunc(formingBSplineSegment5, 3, knotVectorSegment5, dynamicPartsColor)
            group.add(bSplineCurveSegment5);
            let bSplineCurveSegment3 = generateCurve(formingModifyPointsSegment3)
            group.add(bSplineCurveSegment3);
            let bSplineCurveSegment7 = generateCurve(formingModifyPointsSegment7)
            group.add(bSplineCurveSegment7);

            // let bSplineCurveSegment2 = null
            // let bSplineCurveSegment3 = null
            // let bSplineCurveSegment4 = null
            // let bSplineCurveSegment5 = null
            // let bSplineCurveSegment6 = null
            // let bSplineCurveSegment7 = null
            // let bSplineCurveSegment8 = null
            //
            // const forCount = canRotate ? 3 : 1;
            // for (let i = 0; i < forCount; i++) {
            //     const degrees = i * (360 / number_of_slot)
            //     bSplineCurveSegment2 = createBSplineCurveFunc(formingBSplineSegment2, 3, knotVector)
            //     // bSplineCurveSegment2.rotation.z = degrees * (Math.PI / 180);
            //     bSplineCurveSegment2.position.y += 3*i
            //     group.add(bSplineCurveSegment2);
            //
            //     bSplineCurveSegment8 = createBSplineCurveFunc(formingBSplineSegment8, 3, knotVector)
            //     // bSplineCurveSegment8.rotation.z = degrees * (Math.PI / 180);
            //     bSplineCurveSegment8.position.y += 3*i;
            //     group.add(bSplineCurveSegment8);
            //
            //     bSplineCurveSegment4 = createBSplineCurveFunc(formingBSplineSegment4, 3, knotVector)
            //     // bSplineCurveSegment4.rotation.z = degrees * (Math.PI / 180);
            //     bSplineCurveSegment4.position.y += 3*i;
            //     group.add(bSplineCurveSegment4);
            //
            //     bSplineCurveSegment6 = createBSplineCurveFunc(formingBSplineSegment6, 3, knotVector)
            //     // bSplineCurveSegment6.rotation.z = degrees * (Math.PI / 180);
            //     bSplineCurveSegment6.position.y += 3*i;
            //     group.add(bSplineCurveSegment6);
            //
            //     bSplineCurveSegment5 = createBSplineCurveFunc(formingBSplineSegment5, 3, knotVectorSegment5, dynamicPartsColor)
            //     // bSplineCurveSegment5.rotation.z = degrees * (Math.PI / 180);
            //     // bSplineCurveSegment5.position.x -= 10*i;
            //     bSplineCurveSegment5.position.y += 3*i;
            //     group.add(bSplineCurveSegment5);
            //
            //     bSplineCurveSegment3 = generateCurve(formingModifyPointsSegment3)
            //     // bSplineCurveSegment3.rotation.z = degrees * (Math.PI / 180);
            //     bSplineCurveSegment3.position.y += 3*i;
            //     group.add(bSplineCurveSegment3);
            //
            //     bSplineCurveSegment7 = generateCurve(formingModifyPointsSegment7)
            //     // bSplineCurveSegment7.rotation.z = degrees * (Math.PI / 180);
            //     bSplineCurveSegment7.position.y += 3*i;
            //     group.add(bSplineCurveSegment7);
            // }

            // const geometrySegment3 = new THREE.BufferGeometry().setFromPoints(controlPointsSegment3);
            // const materialSegment3 = new THREE.LineBasicMaterial({color: staticPartsColor});
            // let segment3 = new THREE.Line(geometrySegment3, materialSegment3);
            // group.add(segment3);

            // const geometrySegment7 = new THREE.BufferGeometry().setFromPoints(controlPointsSegment3);
            // const materialSegment7 = new THREE.LineBasicMaterial({color: staticPartsColor});
            // let segment7 = new THREE.Line(geometrySegment7, materialSegment7);
            // group.add(segment7);

            // group.position.set(-formingBSplineSegment2[2].x + 20, -formingBSplineSegment2[2].y + 25, -formingBSplineSegment2[2].z)

            // controls = new TrackballControls(camera, renderer.domElement);
            // controls.rotateSpeed = 3.0;
            // controls.zoomSpeed = 1.2;
            // controls.panSpeed = 0.8;
            // controls.noPan = stateId !== 3;
            // controls.noRotate = stateId !== 3;



            camera.position.set(cameraData["xPosition"], cameraData["yPosition"], cameraData["zPosition"])
            camera.zoom = cameraData["zoom"]
            // camera.zoom = 0.3
            camera.updateProjectionMatrix();

            let axesCamera = null;
            let axesScene = null;
            let textsObject = null;
            let xLabel = null
            let yLabel = null
            let zLabel = null

            // only bottom right view has this function
            // this will add stator numbers and axes view


            if (isFullScreen) {
                showAxes('fixed', '92vw', '78.5vh');
                if (stateId === 3) {
                    showStator();
                    showNumbers();
                }
            } else {
                switch (stateId) {
                    case 0:
                        showAxes('fixed', '57vw', '37vh');
                        break;
                    case 1:
                        showAxes('fixed', '92vw', '32vh');
                        break;
                    case 2:
                        showAxes('fixed', '55vw', '79vh');
                        break;
                    case 3:
                        showAxes('fixed', '92vw', '78.5vh');
                        showStator();
                        showNumbers();
                        break;
                    default:
                        break;
                }

            }

            function showStator() {
                const statorLoader = new STLLoader();
                const geometry = statorLoader.parse(statorStl);
                let group = new THREE.Group();
                scene.add(group);
                const material = new THREE.MeshMatcapMaterial({color: 0xffffff});
                material.side = THREE.DoubleSide;
                const mesh = new THREE.Mesh(geometry, material);
                mesh.scale.set(1, 1, 1);
                mesh.castShadow = true;
                mesh.receiveShadow = true;
                mesh.position.z -= stack_height;
                scene.add(mesh);
            }

            function showNumbers() {
                const textMaterial = new THREE.MeshBasicMaterial({color: 0x757575});
                const numberDistance = ((stator_bore_diameter + stator_outer_diameter + (2 * slot_depth)) / 4) - (slot_width * 0.325);
                const groupTopNumbers = new THREE.Group();
                const groupBottomNumbers = new THREE.Group();
                const mesh = new THREE.Mesh();
                mesh.scale.set(1, 1, 1);
                mesh.castShadow = true;
                mesh.receiveShadow = true;
                mesh.position.z -= stack_height;

                scene.add(mesh);

                const loader = new FontLoader();
                loader.load('ubuntu_bold.typeface.json', function (font) {
                    for (let i = 1; i <= number_of_slot; i++) {
                        const theta = (i * 360 / number_of_slot); // degrees
                        const x = numberDistance * Math.cos(THREE.MathUtils.degToRad(theta));
                        const y = numberDistance * Math.sin(THREE.MathUtils.degToRad(theta));
                        const textGeometry = new TextGeometry(i.toString().padStart(2, '0'), {
                            font: font, size: 3.5, height: 0.01,
                        });
                        const textMeshTop = new THREE.Mesh(textGeometry, textMaterial);
                        textMeshTop.position.set(x, y, 0);
                        textMeshTop.rotation.z = THREE.MathUtils.degToRad(theta) + Math.PI + Math.PI / 2; // optional rotation adjustment
                        textMeshTop.position.z += stack_height;
                        groupTopNumbers.add(textMeshTop);

                        const textMeshBottom = new THREE.Mesh(textGeometry, textMaterial);
                        textMeshBottom.position.set(x, y, 0);
                        textMeshBottom.rotation.z = -THREE.MathUtils.degToRad(theta) + Math.PI / 2; // optional rotation adjustment
                        textMeshBottom.rotation.y = Math.PI; // optional rotation adjustment
                        groupBottomNumbers.add(textMeshBottom);
                    }
                })
                groupTopNumbers.rotation.z = THREE.MathUtils.degToRad(2 - (360 / number_of_slot))
                groupBottomNumbers.rotation.z = THREE.MathUtils.degToRad(-2 - (360 / number_of_slot))
                mesh.add(groupTopNumbers)
                mesh.add(groupBottomNumbers)
            }

            function showAxes(position, left, top) {
                axesRenderer = new THREE.WebGLRenderer({ antialias: true });
                axesRenderer.setClearColor(new THREE.Color(backgroundColor), 0);
                const size = Math.min(window.innerWidth, window.innerHeight) * 0.18; // 20% of the smallest dimension
                axesRenderer.setSize(size, size);

                axesRenderer.domElement.style.position = position;
                axesRenderer.domElement.style.left = left; // Adjust as needed
                axesRenderer.domElement.style.top = top;


                axesScene = new THREE.Scene();
                axesCamera = new THREE.PerspectiveCamera(3, aspect);
                axesCamera.up = camera.up; // Ensure the axes camera up vector matches the main camera to keep orientation consistent

                const sphereGeometry = new THREE.SphereGeometry(1, 64, 32);
                const sphereMaterial = new THREE.MeshBasicMaterial({color: 0xbcbcbc});
                const object = new THREE.Mesh(sphereGeometry, sphereMaterial);

                // const arrowPos = new THREE.Vector3(0, 0, 0);
                // object.add(new THREE.ArrowHelper(new THREE.Vector3(1, 0, 0), arrowPos, 9, 0xf09436, 2, 2));
                // object.add(new THREE.ArrowHelper(new THREE.Vector3(0, 1, 0), arrowPos, 9, 0x96e686, 2, 2));
                // object.add(new THREE.ArrowHelper(new THREE.Vector3(0, 0, 1), arrowPos, 9, 0x95ccfb, 2, 2));

                function generateArrow(color, position, rotation) {
                    const length = 9;
                    const hex = color;

                    const arrowHead = new THREE.Mesh(new THREE.ConeGeometry(1, 2, 32),  // Increased radial segments
                        new THREE.MeshBasicMaterial({color: hex}));
                    const arrowLine = new THREE.Mesh(new THREE.CylinderGeometry(0.3, 0.3, length - 2, 32),  // Increased radial segments
                        new THREE.MeshBasicMaterial({color: hex}));
                    arrowLine.position.set(position[0] / 2, position[1] / 2, position[2] / 2)
                    arrowHead.position.set(position[0], position[1], position[2]);
                    arrowHead.rotation.set(rotation[0], rotation[1], rotation[2]);
                    arrowLine.rotation.set(rotation[0], rotation[1], rotation[2]);

                    const arrowGroup = new THREE.Group();
                    arrowGroup.add(arrowHead);
                    arrowGroup.add(arrowLine);
                    return arrowGroup
                }


                textsObject = new THREE.Mesh(sphereGeometry, sphereMaterial);
                const loader = new FontLoader();

                loader.load('ubuntu_bold.typeface.json', function (font) {
                    const textOptions = {
                        font: font, size: 2, height: 0.1
                    };

                    const textMaterialX = new THREE.MeshBasicMaterial({color: dynamicPartsColor});
                    const textGeometryX = new TextGeometry('X', textOptions);
                    xLabel = new THREE.Mesh(textGeometryX, textMaterialX);
                    xLabel.quaternion.copy(axesCamera.quaternion)
                    xLabel.position.set(12, -1, 1);


                    const textMaterialY = new THREE.MeshBasicMaterial({color: dynamicPartsColor});
                    const textGeometryY = new TextGeometry('Y', textOptions);
                    yLabel = new THREE.Mesh(textGeometryY, textMaterialY);
                    yLabel.quaternion.copy(axesCamera.quaternion)
                    yLabel.position.set(-1, 12, -1);


                    const textMaterialZ = new THREE.MeshBasicMaterial({color: dynamicPartsColor});
                    const textGeometryZ = new TextGeometry('Z', textOptions);
                    zLabel = new THREE.Mesh(textGeometryZ, textMaterialZ);
                    zLabel.quaternion.copy(axesCamera.quaternion)
                    zLabel.position.set(1, 1, 12);


                    if (stateId === 0) {
                        object.add(generateArrow(0x65E870, [0, 9, 0], [0, 0, 0]))
                        object.add(generateArrow(0x71C8FF, [0, 0, 9], [Math.PI / 2, 0, 0]))

                        textsObject.add(yLabel);
                        textsObject.add(zLabel);
                    } else if (stateId === 1) {
                        object.add(generateArrow(0xFF800E, [9, 0, 0], [0, 0, -Math.PI / 2]))
                        object.add(generateArrow(0x71C8FF, [0, 0, 9], [Math.PI / 2, 0, 0]))

                        textsObject.add(xLabel);
                        textsObject.add(zLabel);
                    } else if (stateId === 2) {
                        object.add(generateArrow(0xFF800E, [9, 0, 0], [0, 0, -Math.PI / 2]))
                        object.add(generateArrow(0x65E870, [0, 9, 0], [0, 0, 0]))

                        textsObject.add(xLabel);
                        textsObject.add(yLabel);
                    } else {
                        object.add(generateArrow(0xFF800E, [9, 0, 0], [0, 0, -Math.PI / 2]))
                        object.add(generateArrow(0x65E870, [0, 9, 0], [0, 0, 0]))
                        object.add(generateArrow(0x71C8FF, [0, 0, 9], [Math.PI / 2, 0, 0]))

                        textsObject.add(xLabel);
                        textsObject.add(yLabel);
                        textsObject.add(zLabel);
                    }

                })

                axesScene.add(object);
                axesScene.add(textsObject);
            }

            function updateAxesCamera() {
                axesCamera.position.copy(camera.position);
                axesCamera.position.sub(camera.getWorldDirection(new THREE.Vector3()).multiplyScalar(10));
                axesCamera.lookAt(axesScene.position);
                textsObject.children.forEach(function (item) {
                    item.quaternion.copy(axesCamera.quaternion);
                });

                if (stateId === 3) {
                    if (zLabel !== null) {
                        xLabel.position.set(12, -1, camera.position.x / 700);
                        yLabel.position.set(-1, 12, camera.position.y / 700);
                        zLabel.position.set(-1 * camera.position.z / 700, -1, 12);
                    }
                }


            }

            // here we remove all containers children and add this new one
            while (refContainer.current.firstChild) {
                refContainer.current.removeChild(refContainer.current.firstChild);
            }
            refContainer.current && refContainer.current.appendChild(renderer.domElement);
            if (axesRenderer !== null) {
                refContainer.current && refContainer.current.appendChild(axesRenderer.domElement);
            }

            const boundingBox = new THREE.Box3();

            // here we set the curve center of the view
            scene.traverse(function (object) {
                if (object.isMesh) {
                    object.geometry.computeBoundingBox();
                    const bbox = object.geometry.boundingBox.clone();
                    bbox.applyMatrix4(object.matrixWorld);
                    boundingBox.union(bbox);
                }
            });

            const center = new THREE.Vector3();
            boundingBox.getCenter(center);

            /**
             * fixed position of 2Ds view
             */



            // } else {
            //     controls.target.set(cameraData["controlsXPosition"], cameraData["controlsYPosition"], cameraData["controlsZPosition"])
            // }

            controls = new TrackballControls(camera, renderer.domElement);
            controls.rotateSpeed = 3.0;
            controls.zoomSpeed = 1.2;
            controls.panSpeed = 0.8;
            controls.noPan = stateId !== 3;
            controls.noRotate = stateId !== 3;

            // in this function we handle drag actions
            function manageDragActon(dragItems, controlPoints, segmentName, previousSegmentName, nextSegmentName, currentBSpline, currentKnotVector, color, showCurve = true) {
                const dragControlsSegment = new DragControls(dragItems, camera, renderer.domElement);
                dragControlsSegment.addEventListener('dragstart', function () {
                    controls.enabled = false;
                });
                dragControlsSegment.addEventListener('drag', function (event) {
                    if (showCurve) {
                        const updatedPoints = [...controlPoints];
                        updatedPoints[event.object.userData.index] = [event.object.position.x, event.object.position.y, event.object.position.z]
                        group.remove(currentBSpline);
                        currentBSpline = createBSplineCurveFunc(updatedPoints, 3, currentKnotVector, color)
                        group.add(currentBSpline);
                    }
                });
                dragControlsSegment.addEventListener('dragend', function (event) {
                    controls.enabled = true;
                    const updatedPoints = [...controlPoints];
                    updatedPoints[event.object.userData.index] = [event.object.position.x, event.object.position.y, event.object.position.z]
                    dispatch(changeFormingCurvePoints({
                        segment: segmentName,
                        previousSegment: previousSegmentName,
                        nextSegment: nextSegmentName,
                        value: updatedPoints,
                    }))
                });
            }

            // manageDragActon(dragItemsSegment2, formingBSplineSegment2, "segment2_control_points", bSplineCurveSegment2, knotVector, staticPartsColor)
            // manageDragActon(dragItemsSegment8, formingBSplineSegment8, "segment8_control_points", bSplineCurveSegment8, knotVector, staticPartsColor)
            manageDragActon(dragItemsSegment3, formingBSplineSegment3, "segment3_control_points", "segment2_control_points", "segment4_control_points", null, knotVector, dynamicPartsColor, false)
            manageDragActon(dragItemsSegment7, formingBSplineSegment7, "segment7_control_points", "segment6_control_points", "segment8_control_points", null, knotVector, dynamicPartsColor, false)
            // manageDragActon(dragItemsSegment4, formingBSplineSegment4, "segment4_control_points", bSplineCurveSegment4, knotVector, staticPartsColor)
            // manageDragActon(dragItemsSegment6, formingBSplineSegment6, "segment6_control_points", bSplineCurveSegment6, knotVector, staticPartsColor)
            manageDragActon(dragItemsSegment5, formingBSplineSegment5, "segment5_control_points", "segment4_control_points", "segment6_control_points", bSplineCurveSegment5, knotVectorSegment5, dynamicPartsColor)

            if (stateId === 0) {
                scene.position.x = -center.x;
                scene.position.y = -center.z;
                scene.position.z = center.y;
                // controls.target.set(center.x, center.z, -center.y)
            } else if (stateId === 1) {
                scene.position.x = 3 * center.z;
                scene.position.y = center.y;
                scene.position.z = center.x;
                // controls.target.set(-3 * center.z, -center.y, -center.x)
            } else if (stateId === 2) {
                scene.position.x = -center.x;
                scene.position.y = -center.y;
                scene.position.z = -center.z;
                // controls.target.set(center.x, center.y, center.z)
            } else {
                scene.position.z = stack_height / 2;
                // controls.target.z = -stack_height / 2;
            }
            if ((cameraData["controlsXPosition"] !== "default-forming-view")) {
                controls.target.set(cameraData["controlsXPosition"], cameraData["controlsYPosition"], cameraData["controlsZPosition"])
            }

            // this is the most important function to render view, with this user can move camera and controller
            let animate = function () {
                animationFunc = requestAnimationFrame(animate);
                updateAxesCamera();
                renderer.render(scene, camera);
                axesRenderer.render(axesScene, axesCamera);
                controls.update();
            };
            animate();
        };

        // we call loadBSpline here
        const generateBSpline = () => {
            try {
                loadBSpline();
            } catch (e) {
                console.log(e)
                if (!formingSideCoils.length) {
                    toast.error("you need 1 forming coil")
                } else {
                    toast.error("Input is wrong")
                }
            }
        }
        generateBSpline()

        // this will save viewer states after resize
        function handleResize() {
            if (camera && controls && group && renderer && animationFunc) {
                const data = {
                    xPosition: camera.position.x,
                    yPosition: camera.position.y,
                    zPosition: camera.position.z,
                    zoom: camera.zoom,
                    controlsXPosition: controls.target.x,
                    controlsYPosition: controls.target.y,
                    controlsZPosition: controls.target.z,
                }
                changeZoomFunc(stateId, data)
                renderer.dispose()
                controls.dispose()
                cancelAnimationFrame(animationFunc)
                generateBSpline()
            }
        }

        window.addEventListener('resize', handleResize);

        return () => {
            const gl = axesRenderer.getContext();
            const loseContextExtension = gl.getExtension('WEBGL_lose_context');
            if (loseContextExtension) {
                loseContextExtension.loseContext();
            } else {
                console.error('WEBGL_lose_context extension is not supported. Forming');
            }

            const glAxis = axesRenderer.getContext();
            const AxesLoseContextExtension = glAxis.getExtension('WEBGL_lose_context');
            if (AxesLoseContextExtension) {
                AxesLoseContextExtension.loseContext();
            } else {
                console.error('WEBGL_lose_context extension is not supported. AForming');
            }

            renderer.dispose()
            controls.dispose();
            cancelAnimationFrame(animationFunc)
            // we save viewer states when component terminated
            if (camera && controls && group && renderer && animationFunc) {
                const data = {
                    xPosition: camera.position.x,
                    yPosition: camera.position.y,
                    zPosition: camera.position.z,
                    zoom: camera.zoom,
                    controlsXPosition: controls.target.x,
                    controlsYPosition: controls.target.y,
                    controlsZPosition: controls.target.z,
                }
                changeZoomFunc(stateId, data)
            }
            window.removeEventListener('resize', handleResize);
        };
    }, [dispatch, formingBSplineSegment2, formingBSplineSegment3, formingBSplineSegment4, formingBSplineSegment5, formingBSplineSegment6, formingBSplineSegment7, formingBSplineSegment8, toggleShowCube, formingModifyPointsSegment3, formingModifyPointsSegment7, changeZoomFunc, stateId, cameraData, rotation, statorStl, stack_height, number_of_slot, formingSideCoils]);

    return (<StyledWrapper isFullScreen={isFullScreen} top_position={topPosition} left_position={leftPosition}
                           component_width={componentWidth} component_height={componentHeight}
                           ref={refContainer} onDoubleClick={() => toggleFullScreenFunc(stateId)}/>)
};

const StyledWrapper = styled.div`
    position: absolute;
    top: ${(props) => props.top_position || '0'};
    left: ${(props) => props.left_position || '0'};
    border: solid 1px #5e6162;
    background: var(--clr-grey-7);
    text-align: center;
    justify-items: center;
    width: ${(props) => props.component_width || '36vw'};
    height: calc(${(props) => props.component_height || '43vh'} - 2px);
`
export default FormingSideViewer;
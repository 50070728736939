import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {
    statorDefaultValues,
    NUMBER_OF_SLOT_INPUT,
    SFR1_INPUT,
    SFR2_INPUT,
    SFR3_INPUT,
    SFR4_INPUT,
    SLOT_DEPTH_INPUT,
    NUMBER_OF_LAYERS_INPUT,
    SLOT_OPENING_INPUT,
    SLOT_WIDTH_INPUT,
    STACK_HEIGHT_INPUT,
    STATOR_BORE_DIAMETER_INPUT,
    STATOR_OUTER_DIAMETER_INPUT,
    TOOTH_TIP_ANGLE_INPUT,
    TOOTH_TIP_INPUT,
    LAMINATION_THICKNESS_INPUT,
    WIRE_DEPTH_INPUT,
    WIRE_WIDTH_INPUT,
    NUMBER_OF_LAMINATION_INPUT,
    LAMINATION_AREA_INPUT,
    CORE_BACK_INPUT,
    PAPER_LENGTH_INPUT,
    SLOT_AREA_INPUT,
    FILL_FACTOR_INPUT,
    PAPER_THICKNESS_INPUT,
    PAPER_OVERHANG_LENGTH_INPUT,
    PAPER_OVERHANG_LENGTH_FORMING_INPUT,
    PAPER_OVERHANG_LENGTH_WELDING_INPUT,
    PAPER_OVERLAP_LENGTH_INPUT,
    SFR_INPUT,
    PAPER_TO_WIRE_CLEARANCE_INPUT,
    WIRE_TO_WIRE_CLEARANCE_INPUT,
    SLOT_OR_WIRE_EQUAL_SLOT,
    SAME_OR_DIFFERENT_SAME,
    AUTO_OR_MANUAL_AUTO,
    PAPER_OVERLAP_RIGHT,
    SLOT_MODE_SEMI,
    OVERLAP_LEFT_OR_RIGHT_INPUT,
    OVERHANG_SAME_OR_DIFFERENT_SAME,
    WIRE_DEPTH_WITHOUT_INSULATION_INPUT,
    WIRE_WIDTH_WITHOUT_INSULATION_INPUT
} from "../data/statorInputs";
import {
    COIL_HEIGHT_INPUT,
    ISOLATED_WIRE_END_LENGTH_INPUT,
    SPAN_INPUT,
    STATOR_WIRE_OVERHANG_INPUT,
    STRIPPED_WIRE_LENGTH_INPUT,
    WELDING_WIRE_HEIGHT_INPUT,
    WIDENING_GAP_INPUT,
    windingSideDefaultValues,
    WIRE_FILLET_RADIUS_INPUT,
    WIRE_INSULATION_THICKNESS_INPUT,
} from "../data/windingSideInputs";
import {axiosBaseConfig} from "../data/url";
import {toast} from "react-toastify";

// READ DOCUMENTS FOR FEATURES FILES
const initialState = {
    parametersLoaded: false,
    isLoading: 0,
    // isStatorReady: false,
    // isStatorHasError: false,
    isWeldingReady: false,
    isWeldingHasError: false,
    isOptimisationReady: false,
    isOptimisationHasError: false,
    canPushOptimisationButton: true,
    weldingProgressbar: 0,
    optimisationProgressbar: 0,
    optimisationLoading: false,

    statorStl: null,
    statorStlLamination: null,
    // statorLaminationStl: null,
    statorSlotStl: null,
    papers3DStl: null,
    papers2DStl: null,
    // windingStl: null,
    wireSlotStl: null,
    wireInsulationSlotStl: null,
    weldingSideStl: null,
    weldingOptimisedStl: null,
    formingSample: null,

    // stator
    [NUMBER_OF_SLOT_INPUT]: statorDefaultValues[NUMBER_OF_SLOT_INPUT],
    [STATOR_BORE_DIAMETER_INPUT]: statorDefaultValues[STATOR_BORE_DIAMETER_INPUT],
    [STATOR_OUTER_DIAMETER_INPUT]: statorDefaultValues[STATOR_OUTER_DIAMETER_INPUT],
    [STACK_HEIGHT_INPUT]: statorDefaultValues[STACK_HEIGHT_INPUT],
    [LAMINATION_THICKNESS_INPUT]: statorDefaultValues[LAMINATION_THICKNESS_INPUT],
    [NUMBER_OF_LAMINATION_INPUT]: statorDefaultValues[NUMBER_OF_LAMINATION_INPUT],
    [LAMINATION_AREA_INPUT]: statorDefaultValues[LAMINATION_AREA_INPUT],
    [NUMBER_OF_LAYERS_INPUT]: statorDefaultValues[NUMBER_OF_LAYERS_INPUT],
    [SLOT_OPENING_INPUT]: statorDefaultValues[SLOT_OPENING_INPUT],
    [TOOTH_TIP_INPUT]: statorDefaultValues[TOOTH_TIP_INPUT],
    slotOrWire: SLOT_OR_WIRE_EQUAL_SLOT,
    [SLOT_DEPTH_INPUT]: statorDefaultValues[SLOT_DEPTH_INPUT],
    [SLOT_WIDTH_INPUT]: statorDefaultValues[SLOT_WIDTH_INPUT],
    [WIRE_DEPTH_INPUT]: statorDefaultValues[WIRE_DEPTH_INPUT],
    [WIRE_DEPTH_WITHOUT_INSULATION_INPUT]: statorDefaultValues[WIRE_DEPTH_WITHOUT_INSULATION_INPUT],
    [WIRE_WIDTH_WITHOUT_INSULATION_INPUT]: statorDefaultValues[WIRE_WIDTH_WITHOUT_INSULATION_INPUT],
    [CORE_BACK_INPUT]: statorDefaultValues[CORE_BACK_INPUT],
    [PAPER_LENGTH_INPUT]: statorDefaultValues[PAPER_LENGTH_INPUT],
    [SLOT_AREA_INPUT]: statorDefaultValues[SLOT_AREA_INPUT],
    [FILL_FACTOR_INPUT]: statorDefaultValues[FILL_FACTOR_INPUT],
    [TOOTH_TIP_ANGLE_INPUT]: statorDefaultValues[TOOTH_TIP_ANGLE_INPUT],
    [PAPER_THICKNESS_INPUT]: statorDefaultValues[PAPER_THICKNESS_INPUT],
    overhangSameOrDifferent: [OVERHANG_SAME_OR_DIFFERENT_SAME],
    [PAPER_OVERHANG_LENGTH_INPUT]: statorDefaultValues[PAPER_OVERHANG_LENGTH_INPUT],
    [PAPER_OVERHANG_LENGTH_FORMING_INPUT]: statorDefaultValues[PAPER_OVERHANG_LENGTH_FORMING_INPUT],
    [PAPER_OVERHANG_LENGTH_WELDING_INPUT]: statorDefaultValues[PAPER_OVERHANG_LENGTH_WELDING_INPUT],
    overlapLeftOrRight: PAPER_OVERLAP_RIGHT,
    slotModeSemiOrNot: SLOT_MODE_SEMI,
    autoOrManual: AUTO_OR_MANUAL_AUTO,
    [PAPER_OVERLAP_LENGTH_INPUT]: statorDefaultValues[PAPER_OVERLAP_LENGTH_INPUT],
    sameOrDifferent: SAME_OR_DIFFERENT_SAME,
    [SFR_INPUT]: statorDefaultValues[SFR_INPUT],
    [SFR1_INPUT]: statorDefaultValues[SFR1_INPUT],
    [SFR2_INPUT]: statorDefaultValues[SFR2_INPUT],
    [SFR3_INPUT]: statorDefaultValues[SFR3_INPUT],
    [SFR4_INPUT]: statorDefaultValues[SFR4_INPUT],
    [PAPER_TO_WIRE_CLEARANCE_INPUT]: statorDefaultValues[PAPER_TO_WIRE_CLEARANCE_INPUT],
    [WIRE_TO_WIRE_CLEARANCE_INPUT]: statorDefaultValues[WIRE_TO_WIRE_CLEARANCE_INPUT],

    // windingSide
    [SPAN_INPUT]: windingSideDefaultValues[SPAN_INPUT],
    [WIRE_INSULATION_THICKNESS_INPUT]: windingSideDefaultValues[WIRE_INSULATION_THICKNESS_INPUT],
    [WIRE_FILLET_RADIUS_INPUT]: windingSideDefaultValues[WIRE_FILLET_RADIUS_INPUT],
    [WIDENING_GAP_INPUT]: windingSideDefaultValues[WIDENING_GAP_INPUT],
    [STATOR_WIRE_OVERHANG_INPUT]: windingSideDefaultValues[STATOR_WIRE_OVERHANG_INPUT],
    [ISOLATED_WIRE_END_LENGTH_INPUT]: windingSideDefaultValues[ISOLATED_WIRE_END_LENGTH_INPUT],
    [STRIPPED_WIRE_LENGTH_INPUT]: windingSideDefaultValues[STRIPPED_WIRE_LENGTH_INPUT],
    [COIL_HEIGHT_INPUT]: windingSideDefaultValues[COIL_HEIGHT_INPUT],
    [WELDING_WIRE_HEIGHT_INPUT]: windingSideDefaultValues[WELDING_WIRE_HEIGHT_INPUT],
};

// export const getStatorLaminationStl = createAsyncThunk(
//     'stlFiles/getStatorLaminationStl',
//     async (name, thunkAPI) => {
//         try {
//             const endpoint = '/stator2d-stl/'
//             const statorParameters = thunkAPI.getState().stlParameters
//             const payload = {
//                 [NUMBER_OF_SLOT_INPUT]: statorParameters[NUMBER_OF_SLOT_INPUT],
//                 [STATOR_BORE_DIAMETER_INPUT]: statorParameters[STATOR_BORE_DIAMETER_INPUT],
//                 [STATOR_OUTER_DIAMETER_INPUT]: statorParameters[STATOR_OUTER_DIAMETER_INPUT],
//                 [STACK_HEIGHT_INPUT]: statorParameters[STACK_HEIGHT_INPUT],
//
//                 [SLOT_OPENING_INPUT]: statorParameters[SLOT_OPENING_INPUT],
//                 [TOOTH_TIP_INPUT]: statorParameters[TOOTH_TIP_INPUT],
//                 [SLOT_DEPTH_INPUT]: statorParameters[SLOT_DEPTH_INPUT],
//                 [SLOT_WIDTH_INPUT]: statorParameters[SLOT_WIDTH_INPUT],
//                 [TOOTH_TIP_ANGLE_INPUT]: statorParameters[TOOTH_TIP_ANGLE_INPUT],
//                 [PAPER_THICKNESS_INPUT]: statorParameters[PAPER_THICKNESS_INPUT],
//                 [PAPER_OVERHANG_LENGTH_INPUT]: statorParameters[PAPER_OVERHANG_LENGTH_INPUT],
//                 [SFR1_INPUT]: statorParameters[SFR1_INPUT],
//                 [SFR2_INPUT]: statorParameters[SFR2_INPUT],
//                 [SFR3_INPUT]: statorParameters[SFR3_INPUT],
//                 [SFR4_INPUT]: statorParameters[SFR4_INPUT],
//                 [PAPER_TO_WIRE_CLEARANCE_INPUT]: statorParameters[PAPER_TO_WIRE_CLEARANCE_INPUT],
//                 [WIRE_TO_WIRE_CLEARANCE_INPUT]: statorParameters[WIRE_TO_WIRE_CLEARANCE_INPUT],
//
//                 [NUMBER_OF_LAYERS_INPUT]: statorParameters[NUMBER_OF_LAYERS_INPUT],
//                 [SPAN_INPUT]: statorParameters[SPAN_INPUT],
//
//                 [WIRE_INSULATION_THICKNESS_INPUT]: statorParameters[WIRE_INSULATION_THICKNESS_INPUT],
//                 [WIRE_FILLET_RADIUS_INPUT]: statorParameters[WIRE_FILLET_RADIUS_INPUT],
//
//                 [WIDENING_GAP_INPUT]: statorParameters[WIDENING_GAP_INPUT],
//                 [STATOR_WIRE_OVERHANG_INPUT]: statorParameters[STATOR_WIRE_OVERHANG_INPUT],
//                 [ISOLATED_WIRE_END_LENGTH_INPUT]: statorParameters[ISOLATED_WIRE_END_LENGTH_INPUT],
//                 [STRIPPED_WIRE_LENGTH_INPUT]: statorParameters[STRIPPED_WIRE_LENGTH_INPUT],
//                 [COIL_HEIGHT_INPUT]: statorParameters[COIL_HEIGHT_INPUT],
//             };
//             const response = await axiosBaseConfig.post(endpoint, payload);
//             return response.data;
//         } catch (error) {
//             return thunkAPI.rejectWithValue('can not load stator lamination stl file');
//         }
//     }
// );

// export const getStatorSlotStl = createAsyncThunk(
//     'stlFiles/getStatorSlotStl',
//     async (name, thunkAPI) => {
//         try {
//             const endpoint = '/stator-slot-stl/'
//             const statorParameters = thunkAPI.getState().stlParameters
//             const payload = {
//                 [NUMBER_OF_SLOT_INPUT]: statorParameters[NUMBER_OF_SLOT_INPUT],
//                 [STATOR_BORE_DIAMETER_INPUT]: statorParameters[STATOR_BORE_DIAMETER_INPUT],
//                 [STATOR_OUTER_DIAMETER_INPUT]: statorParameters[STATOR_OUTER_DIAMETER_INPUT],
//                 [STACK_HEIGHT_INPUT]: statorParameters[STACK_HEIGHT_INPUT],
//
//                 [SLOT_OPENING_INPUT]: statorParameters[SLOT_OPENING_INPUT],
//                 [TOOTH_TIP_INPUT]: statorParameters[TOOTH_TIP_INPUT],
//                 [SLOT_DEPTH_INPUT]: statorParameters[SLOT_DEPTH_INPUT],
//                 [SLOT_WIDTH_INPUT]: statorParameters[SLOT_WIDTH_INPUT],
//                 [TOOTH_TIP_ANGLE_INPUT]: statorParameters[TOOTH_TIP_ANGLE_INPUT],
//                 [PAPER_THICKNESS_INPUT]: statorParameters[PAPER_THICKNESS_INPUT],
//                 [PAPER_OVERHANG_LENGTH_INPUT]: statorParameters[PAPER_OVERHANG_LENGTH_INPUT],
//                 [SFR1_INPUT]: statorParameters[SFR1_INPUT],
//                 [SFR2_INPUT]: statorParameters[SFR2_INPUT],
//                 [SFR3_INPUT]: statorParameters[SFR3_INPUT],
//                 [SFR4_INPUT]: statorParameters[SFR4_INPUT],
//                 [PAPER_TO_WIRE_CLEARANCE_INPUT]: statorParameters[PAPER_TO_WIRE_CLEARANCE_INPUT],
//                 [WIRE_TO_WIRE_CLEARANCE_INPUT]: statorParameters[WIRE_TO_WIRE_CLEARANCE_INPUT],
//
//                 [NUMBER_OF_LAYERS_INPUT]: statorParameters[NUMBER_OF_LAYERS_INPUT],
//                 [SPAN_INPUT]: statorParameters[SPAN_INPUT],
//
//                 [WIRE_INSULATION_THICKNESS_INPUT]: statorParameters[WIRE_INSULATION_THICKNESS_INPUT],
//                 [WIRE_FILLET_RADIUS_INPUT]: statorParameters[WIRE_FILLET_RADIUS_INPUT],
//
//                 [WIDENING_GAP_INPUT]: statorParameters[WIDENING_GAP_INPUT],
//                 [STATOR_WIRE_OVERHANG_INPUT]: statorParameters[STATOR_WIRE_OVERHANG_INPUT],
//                 [ISOLATED_WIRE_END_LENGTH_INPUT]: statorParameters[ISOLATED_WIRE_END_LENGTH_INPUT],
//                 [STRIPPED_WIRE_LENGTH_INPUT]: statorParameters[STRIPPED_WIRE_LENGTH_INPUT],
//                 [COIL_HEIGHT_INPUT]: statorParameters[COIL_HEIGHT_INPUT],
//             };
//             const response = await axiosBaseConfig.post(endpoint, payload);
//             return response.data;
//         } catch (error) {
//             return thunkAPI.rejectWithValue('can not stator slot stl file');
//         }
//     }
// );

// export const getPapers3DStl = createAsyncThunk(
//     'stlFiles/getPapers3DStl',
//     async (name, thunkAPI) => {
//         try {
//             const endpoint = '/papers-stl/'
//             const statorParameters = thunkAPI.getState().stlParameters
//             const payload = {
//                 [NUMBER_OF_SLOT_INPUT]: statorParameters[NUMBER_OF_SLOT_INPUT],
//                 [STATOR_BORE_DIAMETER_INPUT]: statorParameters[STATOR_BORE_DIAMETER_INPUT],
//                 [STATOR_OUTER_DIAMETER_INPUT]: statorParameters[STATOR_OUTER_DIAMETER_INPUT],
//                 [STACK_HEIGHT_INPUT]: statorParameters[STACK_HEIGHT_INPUT],
//
//                 [SLOT_OPENING_INPUT]: statorParameters[SLOT_OPENING_INPUT],
//                 [TOOTH_TIP_INPUT]: statorParameters[TOOTH_TIP_INPUT],
//                 [SLOT_DEPTH_INPUT]: statorParameters[SLOT_DEPTH_INPUT],
//                 [SLOT_WIDTH_INPUT]: statorParameters[SLOT_WIDTH_INPUT],
//                 [TOOTH_TIP_ANGLE_INPUT]: statorParameters[TOOTH_TIP_ANGLE_INPUT],
//                 [PAPER_THICKNESS_INPUT]: statorParameters[PAPER_THICKNESS_INPUT],
//                 [PAPER_OVERHANG_LENGTH_INPUT]: statorParameters[PAPER_OVERHANG_LENGTH_INPUT],
//                 [SFR1_INPUT]: statorParameters[SFR1_INPUT],
//                 [SFR2_INPUT]: statorParameters[SFR2_INPUT],
//                 [SFR3_INPUT]: statorParameters[SFR3_INPUT],
//                 [SFR4_INPUT]: statorParameters[SFR4_INPUT],
//                 [PAPER_TO_WIRE_CLEARANCE_INPUT]: statorParameters[PAPER_TO_WIRE_CLEARANCE_INPUT],
//                 [WIRE_TO_WIRE_CLEARANCE_INPUT]: statorParameters[WIRE_TO_WIRE_CLEARANCE_INPUT],
//
//                 [NUMBER_OF_LAYERS_INPUT]: statorParameters[NUMBER_OF_LAYERS_INPUT],
//                 [SPAN_INPUT]: statorParameters[SPAN_INPUT],
//
//                 [WIRE_INSULATION_THICKNESS_INPUT]: statorParameters[WIRE_INSULATION_THICKNESS_INPUT],
//                 [WIRE_FILLET_RADIUS_INPUT]: statorParameters[WIRE_FILLET_RADIUS_INPUT],
//
//                 [WIDENING_GAP_INPUT]: statorParameters[WIDENING_GAP_INPUT],
//                 [STATOR_WIRE_OVERHANG_INPUT]: statorParameters[STATOR_WIRE_OVERHANG_INPUT],
//                 [ISOLATED_WIRE_END_LENGTH_INPUT]: statorParameters[ISOLATED_WIRE_END_LENGTH_INPUT],
//                 [STRIPPED_WIRE_LENGTH_INPUT]: statorParameters[STRIPPED_WIRE_LENGTH_INPUT],
//                 [COIL_HEIGHT_INPUT]: statorParameters[COIL_HEIGHT_INPUT],
//             };
//             const response = await axiosBaseConfig.post(endpoint, payload);
//             return response.data.files.map(obj => atob(obj));
//             // return base64Response.data.files;
//         } catch (error) {
//             return thunkAPI.rejectWithValue('can not load papers 3d stl files');
//         }
//     }
// );

// export const getPapers2DStl = createAsyncThunk(
//     'stlFiles/getPapers2DStl',
//     async (name, thunkAPI) => {
//         try {
//             const endpoint = '/papers2d-stl/'
//             const statorParameters = thunkAPI.getState().stlParameters
//             const payload = {
//                 [NUMBER_OF_SLOT_INPUT]: statorParameters[NUMBER_OF_SLOT_INPUT],
//                 [STATOR_BORE_DIAMETER_INPUT]: statorParameters[STATOR_BORE_DIAMETER_INPUT],
//                 [STATOR_OUTER_DIAMETER_INPUT]: statorParameters[STATOR_OUTER_DIAMETER_INPUT],
//                 [STACK_HEIGHT_INPUT]: statorParameters[STACK_HEIGHT_INPUT],
//
//                 [SLOT_OPENING_INPUT]: statorParameters[SLOT_OPENING_INPUT],
//                 [TOOTH_TIP_INPUT]: statorParameters[TOOTH_TIP_INPUT],
//                 [SLOT_DEPTH_INPUT]: statorParameters[SLOT_DEPTH_INPUT],
//                 [SLOT_WIDTH_INPUT]: statorParameters[SLOT_WIDTH_INPUT],
//                 [TOOTH_TIP_ANGLE_INPUT]: statorParameters[TOOTH_TIP_ANGLE_INPUT],
//                 [PAPER_THICKNESS_INPUT]: statorParameters[PAPER_THICKNESS_INPUT],
//                 [PAPER_OVERHANG_LENGTH_INPUT]: statorParameters[PAPER_OVERHANG_LENGTH_INPUT],
//                 [SFR1_INPUT]: statorParameters[SFR1_INPUT],
//                 [SFR2_INPUT]: statorParameters[SFR2_INPUT],
//                 [SFR3_INPUT]: statorParameters[SFR3_INPUT],
//                 [SFR4_INPUT]: statorParameters[SFR4_INPUT],
//                 [PAPER_TO_WIRE_CLEARANCE_INPUT]: statorParameters[PAPER_TO_WIRE_CLEARANCE_INPUT],
//                 [WIRE_TO_WIRE_CLEARANCE_INPUT]: statorParameters[WIRE_TO_WIRE_CLEARANCE_INPUT],
//
//                 [NUMBER_OF_LAYERS_INPUT]: statorParameters[NUMBER_OF_LAYERS_INPUT],
//                 [SPAN_INPUT]: statorParameters[SPAN_INPUT],
//
//                 [WIRE_INSULATION_THICKNESS_INPUT]: statorParameters[WIRE_INSULATION_THICKNESS_INPUT],
//                 [WIRE_FILLET_RADIUS_INPUT]: statorParameters[WIRE_FILLET_RADIUS_INPUT],
//
//                 [WIDENING_GAP_INPUT]: statorParameters[WIDENING_GAP_INPUT],
//                 [STATOR_WIRE_OVERHANG_INPUT]: statorParameters[STATOR_WIRE_OVERHANG_INPUT],
//                 [ISOLATED_WIRE_END_LENGTH_INPUT]: statorParameters[ISOLATED_WIRE_END_LENGTH_INPUT],
//                 [STRIPPED_WIRE_LENGTH_INPUT]: statorParameters[STRIPPED_WIRE_LENGTH_INPUT],
//                 [COIL_HEIGHT_INPUT]: statorParameters[COIL_HEIGHT_INPUT],
//             };
//             const response = await axiosBaseConfig.post(endpoint, payload);
//             return response.data.files.map(obj => atob(obj));
//         } catch (error) {
//             return thunkAPI.rejectWithValue('can not load papers 2d stl files');
//         }
//     }
// );

// export const getWindingStl = createAsyncThunk(
//     'stlFiles/getWindingStl',
//     async (name, thunkAPI) => {
//         try {
//             const endpoint = '/winding-stl/'
//             const statorParameters = thunkAPI.getState().stlParameters
//             const payload = {
//                 [NUMBER_OF_SLOT_INPUT]: statorParameters[NUMBER_OF_SLOT_INPUT],
//                 [STATOR_BORE_DIAMETER_INPUT]: statorParameters[STATOR_BORE_DIAMETER_INPUT],
//                 [STATOR_OUTER_DIAMETER_INPUT]: statorParameters[STATOR_OUTER_DIAMETER_INPUT],
//                 [STACK_HEIGHT_INPUT]: statorParameters[STACK_HEIGHT_INPUT],
//
//                 [SLOT_OPENING_INPUT]: statorParameters[SLOT_OPENING_INPUT],
//                 [TOOTH_TIP_INPUT]: statorParameters[TOOTH_TIP_INPUT],
//                 [SLOT_DEPTH_INPUT]: statorParameters[SLOT_DEPTH_INPUT],
//                 [SLOT_WIDTH_INPUT]: statorParameters[SLOT_WIDTH_INPUT],
//                 [TOOTH_TIP_ANGLE_INPUT]: statorParameters[TOOTH_TIP_ANGLE_INPUT],
//                 [PAPER_THICKNESS_INPUT]: statorParameters[PAPER_THICKNESS_INPUT],
//                 [PAPER_OVERHANG_LENGTH_INPUT]: statorParameters[PAPER_OVERHANG_LENGTH_INPUT],
//                 [SFR1_INPUT]: statorParameters[SFR1_INPUT],
//                 [SFR2_INPUT]: statorParameters[SFR2_INPUT],
//                 [SFR3_INPUT]: statorParameters[SFR3_INPUT],
//                 [SFR4_INPUT]: statorParameters[SFR4_INPUT],
//                 [PAPER_TO_WIRE_CLEARANCE_INPUT]: statorParameters[PAPER_TO_WIRE_CLEARANCE_INPUT],
//                 [WIRE_TO_WIRE_CLEARANCE_INPUT]: statorParameters[WIRE_TO_WIRE_CLEARANCE_INPUT],
//
//                 [NUMBER_OF_LAYERS_INPUT]: statorParameters[NUMBER_OF_LAYERS_INPUT],
//                 [SPAN_INPUT]: statorParameters[SPAN_INPUT],
//
//                 [WIRE_INSULATION_THICKNESS_INPUT]: statorParameters[WIRE_INSULATION_THICKNESS_INPUT],
//                 [WIRE_FILLET_RADIUS_INPUT]: statorParameters[WIRE_FILLET_RADIUS_INPUT],
//
//                 [WIDENING_GAP_INPUT]: statorParameters[WIDENING_GAP_INPUT],
//                 [STATOR_WIRE_OVERHANG_INPUT]: statorParameters[STATOR_WIRE_OVERHANG_INPUT],
//                 [ISOLATED_WIRE_END_LENGTH_INPUT]: statorParameters[ISOLATED_WIRE_END_LENGTH_INPUT],
//                 [STRIPPED_WIRE_LENGTH_INPUT]: statorParameters[STRIPPED_WIRE_LENGTH_INPUT],
//                 [COIL_HEIGHT_INPUT]: statorParameters[COIL_HEIGHT_INPUT],
//             };
//             const response = await axiosBaseConfig.post(endpoint, payload);
//             return response.data;
//         } catch (error) {
//             return thunkAPI.rejectWithValue('can not load winding stl file');
//         }
//     }
// );

// export const getWireSlotStl = createAsyncThunk(
//     'stlFiles/getWireSlotStl',
//     async (name, thunkAPI) => {
//         try {
//             const endpoint = '/wire-slot-stl/'
//             const statorParameters = thunkAPI.getState().stlParameters
//             const payload = {
//                 [NUMBER_OF_SLOT_INPUT]: statorParameters[NUMBER_OF_SLOT_INPUT],
//                 [STATOR_BORE_DIAMETER_INPUT]: statorParameters[STATOR_BORE_DIAMETER_INPUT],
//                 [STATOR_OUTER_DIAMETER_INPUT]: statorParameters[STATOR_OUTER_DIAMETER_INPUT],
//                 [STACK_HEIGHT_INPUT]: statorParameters[STACK_HEIGHT_INPUT],
//
//                 [SLOT_OPENING_INPUT]: statorParameters[SLOT_OPENING_INPUT],
//                 [TOOTH_TIP_INPUT]: statorParameters[TOOTH_TIP_INPUT],
//                 [SLOT_DEPTH_INPUT]: statorParameters[SLOT_DEPTH_INPUT],
//                 [SLOT_WIDTH_INPUT]: statorParameters[SLOT_WIDTH_INPUT],
//                 [TOOTH_TIP_ANGLE_INPUT]: statorParameters[TOOTH_TIP_ANGLE_INPUT],
//                 [PAPER_THICKNESS_INPUT]: statorParameters[PAPER_THICKNESS_INPUT],
//                 [PAPER_OVERHANG_LENGTH_INPUT]: statorParameters[PAPER_OVERHANG_LENGTH_INPUT],
//                 [SFR1_INPUT]: statorParameters[SFR1_INPUT],
//                 [SFR2_INPUT]: statorParameters[SFR2_INPUT],
//                 [SFR3_INPUT]: statorParameters[SFR3_INPUT],
//                 [SFR4_INPUT]: statorParameters[SFR4_INPUT],
//                 [PAPER_TO_WIRE_CLEARANCE_INPUT]: statorParameters[PAPER_TO_WIRE_CLEARANCE_INPUT],
//                 [WIRE_TO_WIRE_CLEARANCE_INPUT]: statorParameters[WIRE_TO_WIRE_CLEARANCE_INPUT],
//
//                 [NUMBER_OF_LAYERS_INPUT]: statorParameters[NUMBER_OF_LAYERS_INPUT],
//                 [SPAN_INPUT]: statorParameters[SPAN_INPUT],
//
//                 [WIRE_INSULATION_THICKNESS_INPUT]: statorParameters[WIRE_INSULATION_THICKNESS_INPUT],
//                 [WIRE_FILLET_RADIUS_INPUT]: statorParameters[WIRE_FILLET_RADIUS_INPUT],
//
//                 [WIDENING_GAP_INPUT]: statorParameters[WIDENING_GAP_INPUT],
//                 [STATOR_WIRE_OVERHANG_INPUT]: statorParameters[STATOR_WIRE_OVERHANG_INPUT],
//                 [ISOLATED_WIRE_END_LENGTH_INPUT]: statorParameters[ISOLATED_WIRE_END_LENGTH_INPUT],
//                 [STRIPPED_WIRE_LENGTH_INPUT]: statorParameters[STRIPPED_WIRE_LENGTH_INPUT],
//                 [COIL_HEIGHT_INPUT]: statorParameters[COIL_HEIGHT_INPUT],
//             };
//             const response = await axiosBaseConfig.post(endpoint, payload);
//             return response.data;
//         } catch (error) {
//             return thunkAPI.rejectWithValue('can not wire slot stl file');
//         }
//     }
// );

// export const getWireInsulationSlotStl = createAsyncThunk(
//     'stlFiles/getWireInsulationSlotStl',
//     async (name, thunkAPI) => {
//         try {
//             const endpoint = '/wire-insulation-slot-stl/'
//             const statorParameters = thunkAPI.getState().stlParameters
//             const payload = {
//                 [NUMBER_OF_SLOT_INPUT]: statorParameters[NUMBER_OF_SLOT_INPUT],
//                 [STATOR_BORE_DIAMETER_INPUT]: statorParameters[STATOR_BORE_DIAMETER_INPUT],
//                 [STATOR_OUTER_DIAMETER_INPUT]: statorParameters[STATOR_OUTER_DIAMETER_INPUT],
//                 [STACK_HEIGHT_INPUT]: statorParameters[STACK_HEIGHT_INPUT],
//
//                 [SLOT_OPENING_INPUT]: statorParameters[SLOT_OPENING_INPUT],
//                 [TOOTH_TIP_INPUT]: statorParameters[TOOTH_TIP_INPUT],
//                 [SLOT_DEPTH_INPUT]: statorParameters[SLOT_DEPTH_INPUT],
//                 [SLOT_WIDTH_INPUT]: statorParameters[SLOT_WIDTH_INPUT],
//                 [TOOTH_TIP_ANGLE_INPUT]: statorParameters[TOOTH_TIP_ANGLE_INPUT],
//                 [PAPER_THICKNESS_INPUT]: statorParameters[PAPER_THICKNESS_INPUT],
//                 [PAPER_OVERHANG_LENGTH_INPUT]: statorParameters[PAPER_OVERHANG_LENGTH_INPUT],
//                 [SFR1_INPUT]: statorParameters[SFR1_INPUT],
//                 [SFR2_INPUT]: statorParameters[SFR2_INPUT],
//                 [SFR3_INPUT]: statorParameters[SFR3_INPUT],
//                 [SFR4_INPUT]: statorParameters[SFR4_INPUT],
//                 [PAPER_TO_WIRE_CLEARANCE_INPUT]: statorParameters[PAPER_TO_WIRE_CLEARANCE_INPUT],
//                 [WIRE_TO_WIRE_CLEARANCE_INPUT]: statorParameters[WIRE_TO_WIRE_CLEARANCE_INPUT],
//
//                 [NUMBER_OF_LAYERS_INPUT]: statorParameters[NUMBER_OF_LAYERS_INPUT],
//                 [SPAN_INPUT]: statorParameters[SPAN_INPUT],
//
//                 [WIRE_INSULATION_THICKNESS_INPUT]: statorParameters[WIRE_INSULATION_THICKNESS_INPUT],
//                 [WIRE_FILLET_RADIUS_INPUT]: statorParameters[WIRE_FILLET_RADIUS_INPUT],
//
//                 [WIDENING_GAP_INPUT]: statorParameters[WIDENING_GAP_INPUT],
//                 [STATOR_WIRE_OVERHANG_INPUT]: statorParameters[STATOR_WIRE_OVERHANG_INPUT],
//                 [ISOLATED_WIRE_END_LENGTH_INPUT]: statorParameters[ISOLATED_WIRE_END_LENGTH_INPUT],
//                 [STRIPPED_WIRE_LENGTH_INPUT]: statorParameters[STRIPPED_WIRE_LENGTH_INPUT],
//                 [COIL_HEIGHT_INPUT]: statorParameters[COIL_HEIGHT_INPUT],
//             };
//             const response = await axiosBaseConfig.post(endpoint, payload);
//             return response.data;
//         } catch (error) {
//             return thunkAPI.rejectWithValue('can not wire insulation slot stl file');
//         }
//     }
// );

export const getAllStlParameters = createAsyncThunk(
    'stlFiles/getAllStlParameters',
    async (name, thunkAPI) => {
        try {
            const endpoint = '/design/'
            const response = await axiosBaseConfig.get(endpoint);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('can not load stl parameters');
        }
    }
);

export const getAllStlFiles = createAsyncThunk(
    'stlFiles/getAllStlFiles',
    async (name, thunkAPI) => {
        try {
            const endpoint = '/design/'
            const statorParameters = thunkAPI.getState().stlParameters
            const payload = {
                [NUMBER_OF_SLOT_INPUT]: statorParameters[NUMBER_OF_SLOT_INPUT],
                [STATOR_BORE_DIAMETER_INPUT]: statorParameters[STATOR_BORE_DIAMETER_INPUT],
                [STATOR_OUTER_DIAMETER_INPUT]: statorParameters[STATOR_OUTER_DIAMETER_INPUT],
                [STACK_HEIGHT_INPUT]: statorParameters[STACK_HEIGHT_INPUT],
                [LAMINATION_THICKNESS_INPUT]: statorParameters[LAMINATION_THICKNESS_INPUT],

                [SLOT_OPENING_INPUT]: statorParameters[SLOT_OPENING_INPUT],
                [TOOTH_TIP_INPUT]: statorParameters[TOOTH_TIP_INPUT],
                [SLOT_DEPTH_INPUT]: statorParameters[SLOT_DEPTH_INPUT],
                [SLOT_WIDTH_INPUT]: statorParameters[SLOT_WIDTH_INPUT],
                [WIRE_DEPTH_INPUT]: statorParameters[WIRE_DEPTH_INPUT],
                [WIRE_WIDTH_INPUT]: statorParameters[WIRE_WIDTH_INPUT],
                [TOOTH_TIP_ANGLE_INPUT]: statorParameters[TOOTH_TIP_ANGLE_INPUT],
                [PAPER_THICKNESS_INPUT]: statorParameters[PAPER_THICKNESS_INPUT],
                [OVERLAP_LEFT_OR_RIGHT_INPUT]: statorParameters.overlapLeftOrRight,
                [PAPER_OVERHANG_LENGTH_INPUT]: statorParameters[PAPER_OVERHANG_LENGTH_INPUT],
                [PAPER_OVERHANG_LENGTH_FORMING_INPUT]: statorParameters[PAPER_OVERHANG_LENGTH_FORMING_INPUT],
                [PAPER_OVERHANG_LENGTH_WELDING_INPUT]: statorParameters[PAPER_OVERHANG_LENGTH_WELDING_INPUT],
                [PAPER_OVERLAP_LENGTH_INPUT]: statorParameters[PAPER_OVERLAP_LENGTH_INPUT],
                [SFR1_INPUT]: statorParameters[SFR1_INPUT],
                [SFR2_INPUT]: statorParameters[SFR2_INPUT],
                [SFR3_INPUT]: statorParameters[SFR3_INPUT],
                [SFR4_INPUT]: statorParameters[SFR4_INPUT],
                [PAPER_TO_WIRE_CLEARANCE_INPUT]: statorParameters[PAPER_TO_WIRE_CLEARANCE_INPUT],
                [WIRE_TO_WIRE_CLEARANCE_INPUT]: statorParameters[WIRE_TO_WIRE_CLEARANCE_INPUT],

                [NUMBER_OF_LAYERS_INPUT]: statorParameters[NUMBER_OF_LAYERS_INPUT],
                [SPAN_INPUT]: statorParameters[SPAN_INPUT],

                [WIRE_INSULATION_THICKNESS_INPUT]: statorParameters[WIRE_INSULATION_THICKNESS_INPUT],
                [WIRE_FILLET_RADIUS_INPUT]: statorParameters[WIRE_FILLET_RADIUS_INPUT],

                [WIDENING_GAP_INPUT]: statorParameters[WIDENING_GAP_INPUT],
                [STATOR_WIRE_OVERHANG_INPUT]: statorParameters[STATOR_WIRE_OVERHANG_INPUT],
                [ISOLATED_WIRE_END_LENGTH_INPUT]: statorParameters[ISOLATED_WIRE_END_LENGTH_INPUT],
                [STRIPPED_WIRE_LENGTH_INPUT]: statorParameters[STRIPPED_WIRE_LENGTH_INPUT],
                [COIL_HEIGHT_INPUT]: statorParameters[COIL_HEIGHT_INPUT],
            };
            const response = await axiosBaseConfig.post(endpoint, payload);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('can not load stl files');
        }
    }
);

export const getStatorStl = createAsyncThunk(
    'stlFiles/getStatorStl',
    async (name, thunkAPI) => {
        try {
            const endpoint = '/design/stator-stl/'
            const response = await axiosBaseConfig.get(endpoint);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('can not load stator stl');
        }
    }
);

export const completedStatorStl = createAsyncThunk(
    'stlFiles/completedStatorStl',
    async (name, thunkAPI) => {
        try {
            const endpoint = '/design/stator-stl/'
            const response = await axiosBaseConfig.delete(endpoint);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('can not handle stator stl error');
        }
    }
);

export const getWeldingStl = createAsyncThunk(
    'stlFiles/getWeldingStl',
    async (name, thunkAPI) => {
        try {
            const endpoint = '/welding/welding-stl/'
            const response = await axiosBaseConfig.get(endpoint);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('can not load welding stl');
        }
    }
);

export const completedWeldingStl = createAsyncThunk(
    'stlFiles/completedWeldingStl',
    async (name, thunkAPI) => {
        try {
            const endpoint = '/welding/welding-stl/'
            const response = await axiosBaseConfig.delete(endpoint);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('can not handle welding stl error');
        }
    }
);

export const generateWeldingStl = createAsyncThunk(
    'stlFiles/generateWeldingStl',
    async (name, thunkAPI) => {
        try {
            const endpoint = '/welding/generate-welding-stl/'
            const response = await axiosBaseConfig.get(endpoint);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('can not generate welding stl');
        }
    }
);

export const getWeldingOptimisedStl = createAsyncThunk(
    'stlFiles/getWeldingOptimisedStl',
    async (name, thunkAPI) => {
        try {
            const endpoint = '/welding/optimise/'
            const response = await axiosBaseConfig.get(endpoint);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('can not load welding optimised stl');
        }
    }
);

export const getWeldingOptimisedStlFinished = createAsyncThunk(
    'stlFiles/getWeldingOptimisedStlFinished',
    async (name, thunkAPI) => {
        try {
            const endpoint = '/welding/optimise-get-stl/'
            const response = await axiosBaseConfig.get(endpoint);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('can not get welding optimised stl');
        }
    }
);

export const globalUndo = createAsyncThunk(
    'stlFiles/globalUndo',
    async (name, thunkAPI) => {
        try {
            const endpoint = '/design/undo/'
            const response = await axiosBaseConfig.get(endpoint);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('can not get undo global parameters');
        }
    }
);

export const globalRedo = createAsyncThunk(
    'stlFiles/globalRedo',
    async (name, thunkAPI) => {
        try {
            const endpoint = '/design/redo/'
            const response = await axiosBaseConfig.get(endpoint);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('can not get redo parameters');
        }
    }
);

const stlFilesSlice = createSlice({
    name: 'stlFiles',
    initialState,
    reducers: {
        setStlParameters: (state, {payload}) => {
            return {...state, ...payload}
        },
        setParametersLoadedFalse: (state) => {
            state.parametersLoaded = false
        },
        updateWeldingStl: (state, {payload}) => {
            if (payload.length > 0 && payload.some(item => item && item.state === "100")) {
                state.isWeldingHasError = true
                state.weldingProgressbar = 100
            } else if (payload.length > 0 && payload.every(item => item && item.state === "done")) {
                state.isWeldingHasError = false
                state.isWeldingReady = true
                state.weldingProgressbar = 100
            } else if (payload.length > 0) {
                state.weldingProgressbar = parseInt((payload.filter(item => item && item.state === 'done').length /
                    payload.filter(item => item).length) * 100);
                state.isWeldingHasError = false
                state.isWeldingReady = false
            }
        },
        updateOptimisationStl: (state, {payload}) => {
            if (payload.state === "100") {
                state.isOptimisationHasError = true
            } else if (payload.state === "done") {
                state.isOptimisationHasError = false
                state.isOptimisationReady = true
                state.optimisationProgressbar = null
            } else if (payload.state === "pending") {
                console.log(payload)
                console.log(payload.height)
                state.optimisationProgressbar = payload.height
            }
        },
        updateStatorStl: (state, {payload}) => {
            if (payload.state === "100") {
                state.isStatorHasError = true
            } else if (payload.state === "done") {
                state.isStatorHasError = false
                state.isStatorReady = true
            } else {
                state.isStatorHasError = false
                state.isStatorReady = false
            }
        },
        makeAllReadyStlFilesFalse : (state) => {
                state.isWeldingReady = false
                state.isOptimisationReady = false
                state.isStatorReady = false
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getStatorStl.pending, () => {
                console.log("getting stator stl")
            })
            .addCase(getStatorStl.fulfilled, (state, action) => {
                console.log("Got stator stl")
                state.isLoading = state.isLoading - 1;
                state.statorStl = atob(action.payload["stator_stl"]);
            })
            .addCase(getStatorStl.rejected, (state, action) => {
                console.log(action);
                state.isLoading = state.isLoading - 1;
            })
            .addCase(completedStatorStl.pending, () => {
                console.log("Error detected for stator stl")
            })
            .addCase(completedStatorStl.fulfilled, (state) => {
                toast.warning("Can not generate stator stl")
                state.isLoading = state.isLoading - 1;
                state.isStatorHasError = false
                state.isStatorReady = false
            })
            .addCase(completedStatorStl.rejected, (state) => {
                state.isLoading = state.isLoading - 1;
                state.isStatorHasError = false
                state.isStatorReady = false
            })
            // .addCase(getStatorLaminationStl.pending, (state) => {
            //     console.log("getting stator lamination stl")
            //     state.isLoading = state.isLoading + 1;
            // })
            // .addCase(getStatorLaminationStl.fulfilled, (state, action) => {
            //     state.isLoading = state.isLoading - 1;
            //     console.log("got stator lamination stl")
            //     state.statorLaminationStl = action.payload;
            // })
            // .addCase(getStatorLaminationStl.rejected, (state, action) => {
            //     console.log(action);
            //     state.isLoading = state.isLoading - 1;
            // })
            // .addCase(getPapers3DStl.pending, (state) => {
            //     console.log("getting 3d papers stl")
            //     state.isLoading = state.isLoading + 1;
            // })
            // .addCase(getPapers3DStl.fulfilled, (state, action) => {
            //     console.log("got 3d papers stl")
            //     state.isLoading = state.isLoading - 1;
            //     state.papers3DStl = action.payload;
            // })
            // .addCase(getPapers3DStl.rejected, (state, action) => {
            //     console.log(action);
            //     state.isLoading = state.isLoading - 1;
            // })
            // .addCase(getPapers2DStl.pending, (state) => {
            //     console.log("getting 2d papers stl")
            //     state.isLoading = state.isLoading + 1;
            // })
            // .addCase(getPapers2DStl.fulfilled, (state, action) => {
            //     console.log("got 2d papers stl")
            //     state.isLoading = state.isLoading - 1;
            //     state.papers2DStl = action.payload;
            // })
            // .addCase(getPapers2DStl.rejected, (state, action) => {
            //     console.log(action);
            //     state.isLoading = state.isLoading - 1;
            // })
            // .addCase(getWindingStl.pending, (state) => {
            //     console.log("getting winding stl")
            //     state.isLoading = state.isLoading + 1;
            // })
            // .addCase(getWindingStl.fulfilled, (state, action) => {
            //     console.log("got winding stl")
            //     state.isLoading = state.isLoading - 1;
            //     state.windingStl = action.payload;
            // })
            // .addCase(getWindingStl.rejected, (state, action) => {
            //     console.log(action);
            //     state.isLoading = state.isLoading - 1;
            // })
            // .addCase(getStatorSlotStl.pending, (state) => {
            //     console.log("getting stator slot stl")
            //     state.isLoading = state.isLoading + 1;
            // })
            // .addCase(getStatorSlotStl.fulfilled, (state, action) => {
            //     console.log("got stator slot stl")
            //     state.isLoading = state.isLoading - 1;
            //     state.statorSlotStl = action.payload;
            // })
            // .addCase(getStatorSlotStl.rejected, (state, action) => {
            //     console.log(action);
            //     state.isLoading = state.isLoading - 1;
            // })
            // .addCase(getWireSlotStl.pending, (state) => {
            //     console.log("getting wire slot stl")
            //     state.isLoading = state.isLoading + 1;
            // })
            // .addCase(getWireSlotStl.fulfilled, (state, action) => {
            //     console.log("got wire slot stl")
            //     state.isLoading = state.isLoading - 1;
            //     state.wireSlotStl = action.payload;
            // })
            // .addCase(getWireSlotStl.rejected, (state, action) => {
            //     console.log(action);
            //     state.isLoading = state.isLoading - 1;
            // })
            // .addCase(getWireInsulationSlotStl.pending, (state) => {
            //     console.log("getting wire insulation slot stl")
            //     state.isLoading = state.isLoading + 1;
            // })
            // .addCase(getWireInsulationSlotStl.fulfilled, (state, action) => {
            //     console.log("got wire insulation slot stl")
            //     state.isLoading = state.isLoading - 1;
            //     state.wireInsulationSlotStl = action.payload;
            // })
            // .addCase(getWireInsulationSlotStl.rejected, (state, action) => {
            //     console.log(action);
            //     state.isLoading = state.isLoading - 1;
            // })
            .addCase(getAllStlParameters.pending, (state) => {
                state.isLoading = state.isLoading + 1;
                console.log("loading stl parameters")
            })
            .addCase(getAllStlParameters.fulfilled, (state, action) => {
                console.log("stl parameters loaded")
                state.isLoading = state.isLoading - 1;
                state[NUMBER_OF_SLOT_INPUT] = action.payload[NUMBER_OF_SLOT_INPUT]
                state[STATOR_BORE_DIAMETER_INPUT] = action.payload[STATOR_BORE_DIAMETER_INPUT]
                state[STATOR_OUTER_DIAMETER_INPUT] = action.payload[STATOR_OUTER_DIAMETER_INPUT]
                state[STACK_HEIGHT_INPUT] = action.payload[STACK_HEIGHT_INPUT]
                state[LAMINATION_THICKNESS_INPUT] = action.payload[LAMINATION_THICKNESS_INPUT]
                state[SLOT_OPENING_INPUT] = action.payload[SLOT_OPENING_INPUT]
                state[TOOTH_TIP_INPUT] = action.payload[TOOTH_TIP_INPUT]
                state[SLOT_DEPTH_INPUT] = action.payload[SLOT_DEPTH_INPUT]
                state[SLOT_WIDTH_INPUT] = action.payload[SLOT_WIDTH_INPUT]
                state[WIRE_DEPTH_INPUT] = action.payload[WIRE_DEPTH_INPUT]
                state[WIRE_WIDTH_INPUT] = action.payload[WIRE_WIDTH_INPUT]
                state[WIRE_DEPTH_WITHOUT_INSULATION_INPUT] = action.payload[WIRE_DEPTH_INPUT] - (2 * action.payload[WIRE_INSULATION_THICKNESS_INPUT])
                state[WIRE_WIDTH_WITHOUT_INSULATION_INPUT] = action.payload[WIRE_WIDTH_INPUT] - (2 * action.payload[WIRE_INSULATION_THICKNESS_INPUT])
                state[TOOTH_TIP_ANGLE_INPUT] = action.payload[TOOTH_TIP_ANGLE_INPUT]
                state[PAPER_THICKNESS_INPUT] = action.payload[PAPER_THICKNESS_INPUT]
                state.overlapLeftOrRight = action.payload[OVERLAP_LEFT_OR_RIGHT_INPUT]
                state[PAPER_OVERHANG_LENGTH_INPUT] = action.payload[PAPER_OVERHANG_LENGTH_INPUT]
                state[PAPER_OVERHANG_LENGTH_FORMING_INPUT] = action.payload[PAPER_OVERHANG_LENGTH_FORMING_INPUT]
                state[PAPER_OVERHANG_LENGTH_WELDING_INPUT] = action.payload[PAPER_OVERHANG_LENGTH_WELDING_INPUT]
                state[PAPER_OVERLAP_LENGTH_INPUT] = action.payload[PAPER_OVERLAP_LENGTH_INPUT]
                state[SFR1_INPUT] = action.payload[SFR1_INPUT]
                state[SFR2_INPUT] = action.payload[SFR2_INPUT]
                state[SFR3_INPUT] = action.payload[SFR3_INPUT]
                state[SFR4_INPUT] = action.payload[SFR4_INPUT]
                state[PAPER_TO_WIRE_CLEARANCE_INPUT] = action.payload[PAPER_TO_WIRE_CLEARANCE_INPUT]
                state[WIRE_TO_WIRE_CLEARANCE_INPUT] = action.payload[WIRE_TO_WIRE_CLEARANCE_INPUT]
                state[NUMBER_OF_LAYERS_INPUT] = action.payload[NUMBER_OF_LAYERS_INPUT]
                state[SPAN_INPUT] = action.payload[SPAN_INPUT]
                state[WIRE_INSULATION_THICKNESS_INPUT] = action.payload[WIRE_INSULATION_THICKNESS_INPUT]
                state[WIRE_FILLET_RADIUS_INPUT] = action.payload[WIRE_FILLET_RADIUS_INPUT]
                state[WIDENING_GAP_INPUT] = action.payload[WIDENING_GAP_INPUT]
                state[STATOR_WIRE_OVERHANG_INPUT] = action.payload[STATOR_WIRE_OVERHANG_INPUT]
                state[ISOLATED_WIRE_END_LENGTH_INPUT] = action.payload[ISOLATED_WIRE_END_LENGTH_INPUT]
                state[STRIPPED_WIRE_LENGTH_INPUT] = action.payload[STRIPPED_WIRE_LENGTH_INPUT]
                state[COIL_HEIGHT_INPUT] = action.payload[COIL_HEIGHT_INPUT]
                state.parametersLoaded = true
            })
            .addCase(getAllStlParameters.rejected, (state, action) => {
                console.log(action);
                state.isLoading = state.isLoading - 1;
            })
            .addCase(getAllStlFiles.pending, (state) => {
                console.log("getting all stl files")
                state.isLoading = state.isLoading + 3;
            })
            .addCase(getAllStlFiles.fulfilled, (state, action) => {
                console.log("Got all stl files")
                state.isLoading = state.isLoading - 1;
                // state.statorStl = atob(action.payload["stator_stl"]);
                // state.statorLaminationStl = atob(action.payload["stator_lamination_stl"]);
                // state.windingStl = atob(action.payload["winding_stl"]);
                state.statorSlotStl = atob(action.payload["stator_slot_stl"]);
                state.wireSlotStl = atob(action.payload["wire_slot_stl"]);
                state.papers3DStl = atob(action.payload["papers_3d_stl"]);
                state.formingSample = action.payload["forming_stl"].map(obj => atob(obj));
                state.papers2DStl = atob(action.payload["papers_2d_stl"]);
                state.wireInsulationSlotStl = atob(action.payload["wire_insulation_slot_stl"]);
                // state.weldingSideStl = atob(action.payload["welding_side_stl"]);
            })
            .addCase(getAllStlFiles.rejected, (state, action) => {
                console.log(action);
                state.isLoading = state.isLoading - 1;
            })
            .addCase(generateWeldingStl.pending, (state) => {
                state.isLoading = state.isLoading + 1;
                console.log("Generating welding stl")
            })
            .addCase(generateWeldingStl.fulfilled, (state) => {
                console.log("welding stl Generated")
                state.isLoading = state.isLoading - 1;
            })
            .addCase(generateWeldingStl.rejected, (state, action) => {
                console.log(action);
                state.isLoading = state.isLoading - 1;
            })
            .addCase(getWeldingStl.pending, () => {
                console.log("Getting welding stl")
            })
            .addCase(getWeldingStl.fulfilled, (state, action) => {
                console.log("Got welding stl")
                state.isLoading = state.isLoading - 1;
                state.weldingSideStl = action.payload["welding_stl"].map(obj => atob(obj));
            })
            .addCase(getWeldingStl.rejected, (state, action) => {
                console.log(action);
                state.isLoading = state.isLoading - 1;
            })
            .addCase(completedWeldingStl.pending, () => {
                console.log("Error detected for welding stl")
            })
            .addCase(completedWeldingStl.fulfilled, (state) => {
                toast.warning("Can not generate welding stl")
                state.isLoading = state.isLoading - 1;
                state.isWeldingHasError = false
                state.isWeldingReady = false
            })
            .addCase(completedWeldingStl.rejected, (state) => {
                state.isLoading = state.isLoading - 1;
                state.isWeldingHasError = false
                state.isWeldingReady = false
            })
            .addCase(getWeldingOptimisedStl.pending, (state) => {
                console.log("Getting welding optimised stl")
                state.canPushOptimisationButton = false
                state.optimisationLoading = true;
            })
            .addCase(getWeldingOptimisedStl.fulfilled, (state) => {
                console.log("Got welding optimised stl")
                // toast.success("Optimisation finished")
                state.optimisationLoading = false;
            })
            .addCase(getWeldingOptimisedStl.rejected, (state, action) => {
                console.log(action);
                state.canPushOptimisationButton = true
                state.optimisationLoading = false;
            })
            .addCase(getWeldingOptimisedStlFinished.pending, (state) => {
                console.log("Getting welding optimised stl")
                state.optimisationLoading = true;
            })
            .addCase(getWeldingOptimisedStlFinished.fulfilled, (state, action) => {
                console.log("Got welding optimised stl")
                toast.success("Optimisation finished")
                state.optimisationLoading = false;
                state.isOptimisationHasError = false
                state.isOptimisationReady = false
                state.canPushOptimisationButton = true
                state.weldingOptimisedStl = atob(action.payload["welding_optimised_stl"]);
            })
            .addCase(getWeldingOptimisedStlFinished.rejected, (state, action) => {
                console.log(action);
                state.isOptimisationHasError = false
                state.isOptimisationReady = false
                state.canPushOptimisationButton = true
                state.optimisationLoading = false;
            })
            .addCase(globalUndo.pending, (state) => {
                state.isLoading = state.isLoading + 1;
                console.log("Getting undo global parameters")
            })
            .addCase(globalUndo.fulfilled, (state, action) => {
                console.log("Got undo global parameters")
                console.log(action)
                state.isLoading = state.isLoading - 1;
                state[NUMBER_OF_SLOT_INPUT] = action.payload["current_parameters"][NUMBER_OF_SLOT_INPUT]
                state[STATOR_BORE_DIAMETER_INPUT] = action.payload["current_parameters"][STATOR_BORE_DIAMETER_INPUT]
                state[STATOR_OUTER_DIAMETER_INPUT] = action.payload["current_parameters"][STATOR_OUTER_DIAMETER_INPUT]
                state[STACK_HEIGHT_INPUT] = action.payload["current_parameters"][STACK_HEIGHT_INPUT]
                state[LAMINATION_THICKNESS_INPUT] = action.payload["current_parameters"][LAMINATION_THICKNESS_INPUT]
                state[SLOT_OPENING_INPUT] = action.payload["current_parameters"][SLOT_OPENING_INPUT]
                state[TOOTH_TIP_INPUT] = action.payload["current_parameters"][TOOTH_TIP_INPUT]
                state[SLOT_DEPTH_INPUT] = action.payload["current_parameters"][SLOT_DEPTH_INPUT]
                state[SLOT_WIDTH_INPUT] = action.payload["current_parameters"][SLOT_WIDTH_INPUT]
                state[WIRE_DEPTH_INPUT] = action.payload["current_parameters"][WIRE_DEPTH_INPUT]
                state[WIRE_WIDTH_INPUT] = action.payload["current_parameters"][WIRE_WIDTH_INPUT]
                state[WIRE_DEPTH_WITHOUT_INSULATION_INPUT] = action.payload[WIRE_DEPTH_INPUT] - (2 * action.payload[WIRE_INSULATION_THICKNESS_INPUT])
                state[WIRE_WIDTH_WITHOUT_INSULATION_INPUT] = action.payload[WIRE_WIDTH_INPUT] - (2 * action.payload[WIRE_INSULATION_THICKNESS_INPUT])
                state[TOOTH_TIP_ANGLE_INPUT] = action.payload["current_parameters"][TOOTH_TIP_ANGLE_INPUT]
                state[PAPER_THICKNESS_INPUT] = action.payload["current_parameters"][PAPER_THICKNESS_INPUT]
                state.overlapLeftOrRight = action.payload["current_parameters"][OVERLAP_LEFT_OR_RIGHT_INPUT]
                state[PAPER_OVERHANG_LENGTH_INPUT] = action.payload["current_parameters"][PAPER_OVERHANG_LENGTH_INPUT]
                state[PAPER_OVERHANG_LENGTH_FORMING_INPUT] = action.payload["current_parameters"][PAPER_OVERHANG_LENGTH_FORMING_INPUT]
                state[PAPER_OVERHANG_LENGTH_WELDING_INPUT] = action.payload["current_parameters"][PAPER_OVERHANG_LENGTH_WELDING_INPUT]
                state[PAPER_OVERLAP_LENGTH_INPUT] = action.payload["current_parameters"][PAPER_OVERLAP_LENGTH_INPUT]
                state[SFR1_INPUT] = action.payload["current_parameters"][SFR1_INPUT]
                state[SFR2_INPUT] = action.payload["current_parameters"][SFR2_INPUT]
                state[SFR3_INPUT] = action.payload["current_parameters"][SFR3_INPUT]
                state[SFR4_INPUT] = action.payload["current_parameters"][SFR4_INPUT]
                state[PAPER_TO_WIRE_CLEARANCE_INPUT] = action.payload["current_parameters"][PAPER_TO_WIRE_CLEARANCE_INPUT]
                state[WIRE_TO_WIRE_CLEARANCE_INPUT] = action.payload["current_parameters"][WIRE_TO_WIRE_CLEARANCE_INPUT]
                state[NUMBER_OF_LAYERS_INPUT] = action.payload["current_parameters"][NUMBER_OF_LAYERS_INPUT]
                state[SPAN_INPUT] = action.payload["current_parameters"][SPAN_INPUT]
                state[WIRE_INSULATION_THICKNESS_INPUT] = action.payload["current_parameters"][WIRE_INSULATION_THICKNESS_INPUT]
                state[WIRE_FILLET_RADIUS_INPUT] = action.payload["current_parameters"][WIRE_FILLET_RADIUS_INPUT]
                state[WIDENING_GAP_INPUT] = action.payload["current_parameters"][WIDENING_GAP_INPUT]
                state[STATOR_WIRE_OVERHANG_INPUT] = action.payload["current_parameters"][STATOR_WIRE_OVERHANG_INPUT]
                state[ISOLATED_WIRE_END_LENGTH_INPUT] = action.payload["current_parameters"][ISOLATED_WIRE_END_LENGTH_INPUT]
                state[STRIPPED_WIRE_LENGTH_INPUT] = action.payload["current_parameters"][STRIPPED_WIRE_LENGTH_INPUT]
                state[COIL_HEIGHT_INPUT] = action.payload["current_parameters"][COIL_HEIGHT_INPUT]
                // state.statorStl = atob(action.payload["stator_stl"]);
                // state.statorLaminationStl = atob(action.payload["stator_lamination_stl"]);
                // state.windingStl = atob(action.payload["winding_stl"]);
                state.statorSlotStl = atob(action.payload["stator_slot_stl"]);
                state.wireSlotStl = atob(action.payload["wire_slot_stl"]);
                state.papers3DStl = atob(action.payload["papers_3d_stl"]);
                state.papers2DStl = atob(action.payload["papers_2d_stl"]);
                state.wireInsulationSlotStl = atob(action.payload["wire_insulation_slot_stl"]);
                state.weldingSideStl = atob(action.payload["welding_side_stl"]);
                state.parametersLoaded = true
            })
            .addCase(globalUndo.rejected, (state, action) => {
                console.log(action);
                state.isLoading = state.isLoading - 1;
            })
            .addCase(globalRedo.pending, (state) => {
                state.isLoading = state.isLoading + 1;
                console.log("Getting redo global parameters")
            })
            .addCase(globalRedo.fulfilled, (state, action) => {
                console.log("Got redo global parameters")
                state.isLoading = state.isLoading - 1;
                console.log(action)
                state[NUMBER_OF_SLOT_INPUT] = action.payload["current_parameters"][NUMBER_OF_SLOT_INPUT]
                state[STATOR_BORE_DIAMETER_INPUT] = action.payload["current_parameters"][STATOR_BORE_DIAMETER_INPUT]
                state[STATOR_OUTER_DIAMETER_INPUT] = action.payload["current_parameters"][STATOR_OUTER_DIAMETER_INPUT]
                state[STACK_HEIGHT_INPUT] = action.payload["current_parameters"][STACK_HEIGHT_INPUT]
                state[LAMINATION_THICKNESS_INPUT] = action.payload["current_parameters"][LAMINATION_THICKNESS_INPUT]
                state[SLOT_OPENING_INPUT] = action.payload["current_parameters"][SLOT_OPENING_INPUT]
                state[TOOTH_TIP_INPUT] = action.payload["current_parameters"][TOOTH_TIP_INPUT]
                state[SLOT_DEPTH_INPUT] = action.payload["current_parameters"][SLOT_DEPTH_INPUT]
                state[SLOT_WIDTH_INPUT] = action.payload["current_parameters"][SLOT_WIDTH_INPUT]
                state[WIRE_DEPTH_INPUT] = action.payload["current_parameters"][WIRE_DEPTH_INPUT]
                state[WIRE_WIDTH_INPUT] = action.payload["current_parameters"][WIRE_WIDTH_INPUT]
                state[WIRE_DEPTH_WITHOUT_INSULATION_INPUT] = action.payload[WIRE_DEPTH_INPUT] - (2 * action.payload[WIRE_INSULATION_THICKNESS_INPUT])
                state[WIRE_WIDTH_WITHOUT_INSULATION_INPUT] = action.payload[WIRE_WIDTH_INPUT] - (2 * action.payload[WIRE_INSULATION_THICKNESS_INPUT])
                state[TOOTH_TIP_ANGLE_INPUT] = action.payload["current_parameters"][TOOTH_TIP_ANGLE_INPUT]
                state[PAPER_THICKNESS_INPUT] = action.payload["current_parameters"][PAPER_THICKNESS_INPUT]
                state.overlapLeftOrRight = action.payload["current_parameters"][OVERLAP_LEFT_OR_RIGHT_INPUT]
                state[PAPER_OVERHANG_LENGTH_INPUT] = action.payload["current_parameters"][PAPER_OVERHANG_LENGTH_INPUT]
                state[PAPER_OVERHANG_LENGTH_FORMING_INPUT] = action.payload["current_parameters"][PAPER_OVERHANG_LENGTH_FORMING_INPUT]
                state[PAPER_OVERHANG_LENGTH_WELDING_INPUT] = action.payload["current_parameters"][PAPER_OVERHANG_LENGTH_WELDING_INPUT]
                state[PAPER_OVERLAP_LENGTH_INPUT] = action.payload["current_parameters"][PAPER_OVERLAP_LENGTH_INPUT]
                state[SFR1_INPUT] = action.payload["current_parameters"][SFR1_INPUT]
                state[SFR2_INPUT] = action.payload["current_parameters"][SFR2_INPUT]
                state[SFR3_INPUT] = action.payload["current_parameters"][SFR3_INPUT]
                state[SFR4_INPUT] = action.payload["current_parameters"][SFR4_INPUT]
                state[PAPER_TO_WIRE_CLEARANCE_INPUT] = action.payload["current_parameters"][PAPER_TO_WIRE_CLEARANCE_INPUT]
                state[WIRE_TO_WIRE_CLEARANCE_INPUT] = action.payload["current_parameters"][WIRE_TO_WIRE_CLEARANCE_INPUT]
                state[NUMBER_OF_LAYERS_INPUT] = action.payload["current_parameters"][NUMBER_OF_LAYERS_INPUT]
                state[SPAN_INPUT] = action.payload["current_parameters"][SPAN_INPUT]
                state[WIRE_INSULATION_THICKNESS_INPUT] = action.payload["current_parameters"][WIRE_INSULATION_THICKNESS_INPUT]
                state[WIRE_FILLET_RADIUS_INPUT] = action.payload["current_parameters"][WIRE_FILLET_RADIUS_INPUT]
                state[WIDENING_GAP_INPUT] = action.payload["current_parameters"][WIDENING_GAP_INPUT]
                state[STATOR_WIRE_OVERHANG_INPUT] = action.payload["current_parameters"][STATOR_WIRE_OVERHANG_INPUT]
                state[ISOLATED_WIRE_END_LENGTH_INPUT] = action.payload["current_parameters"][ISOLATED_WIRE_END_LENGTH_INPUT]
                state[STRIPPED_WIRE_LENGTH_INPUT] = action.payload["current_parameters"][STRIPPED_WIRE_LENGTH_INPUT]
                state[COIL_HEIGHT_INPUT] = action.payload["current_parameters"][COIL_HEIGHT_INPUT]
                // state.statorStl = atob(action.payload["stator_stl"]);
                // state.statorLaminationStl = atob(action.payload["stator_lamination_stl"]);
                // state.windingStl = atob(action.payload["winding_stl"]);
                state.statorSlotStl = atob(action.payload["stator_slot_stl"]);
                state.wireSlotStl = atob(action.payload["wire_slot_stl"]);
                state.papers3DStl = atob(action.payload["papers_3d_stl"]);
                state.papers2DStl = atob(action.payload["papers_2d_stl"]);
                state.wireInsulationSlotStl = atob(action.payload["wire_insulation_slot_stl"]);
                state.weldingSideStl = atob(action.payload["welding_side_stl"]);
                state.parametersLoaded = true
            })
            .addCase(globalRedo.rejected, (state, action) => {
                console.log(action);
                state.isLoading = state.isLoading - 1;
            });
    },
});

export const {
    setStlParameters,
    setParametersLoadedFalse,
    updateWeldingStl,
    updateOptimisationStl,
    makeAllReadyStlFilesFalse,
    updateStatorStl
} = stlFilesSlice.actions;

export default stlFilesSlice.reducer;

import styled from 'styled-components'
import {useDispatch, useSelector} from "react-redux";
import {openIcon, importIcon, saveIcon} from "../icons/actionbar";
import {
    StatorIcons,
    WindingSideIcons,
    ConnectionTableIcons,
    FormingSideIcons,
    OptimisationIcons
} from "../fields/actionbar";
import {
    NAVBAR_STATOR,
    NAVBAR_WINDING_SIDE,
    NAVBAR_CONNECTION_TABLE,
    NAVBAR_FORMING_SIDE,
    NAVBAR_OPTIMISATION,
    INPUT_STATOR_DESIGN,
    INPUT_WELDING_SIDE_DESIGN,
    INPUT_OPTIMISATION_OPTIONS,
    INPUT_STATOR_SLOT_DESIGN,
    // INPUT_WIRE_DESIGN,
    INPUT_FORMING_SIDE
} from "../data/states";
import {
    VIEWER_STATE_ASSEMBLY, VIEWER_STATE_FORMING_SIDE, VIEWER_STATE_SINGLE_SLOT,
    VIEWER_STATE_STANDARD,
    VIEWER_STATE_STATOR_CORE_SOLID,
    VIEWER_STATE_WELDING_SIDE
} from "../data/Viewer";
import {
    setFormingSideDefaultView,
    setOptimisationDefaultView,
    setStateAssemblyDefaultView,
    setStateFormingSideDefaultView,
    setStateSingleSlotDefaultView, setStateStatorCoreSolidDefaultView,
    setStateWeldingDefaultView,
    setStatorDefaultView,
    setStatorSlotDefaultView, setWindingDefaultView
} from "../features/cameraSlice";

const ActionBar = ({toggleFullScreenFunc}) => {
    const dispatch = useDispatch();
    const {navbarState} = useSelector((store) => store.globalItems);
    const {inputState} = useSelector((store) => store.globalItems);
    const {viewerState} = useSelector((store) => store.viewer);

    // here we check if we should add parallel, yz, xz, zy buttons in action bar
    const isIconAvailable = () => {
        if (viewerState === VIEWER_STATE_STANDARD) {
            if (inputState === INPUT_STATOR_DESIGN) {
                return true
            } else if (inputState === INPUT_WELDING_SIDE_DESIGN) {
                return true
            } else if (inputState === INPUT_OPTIMISATION_OPTIONS) {
                return true
            } else if (inputState === INPUT_FORMING_SIDE) {
                return true
            }
        } else if (viewerState === VIEWER_STATE_STATOR_CORE_SOLID) {
            return true
        } else if (viewerState === VIEWER_STATE_WELDING_SIDE) {
            return true
        } else if (viewerState === VIEWER_STATE_FORMING_SIDE) {
            return true
        } else if (viewerState === VIEWER_STATE_ASSEMBLY) {
            return true
        }
        return false
    }

    // we find needed default view based on viewerState and inputState and pass it to actionbar
    const fitScreenFunc = () => {
        if (viewerState === VIEWER_STATE_STANDARD) {
            if (inputState === INPUT_STATOR_DESIGN) {
                return dispatch(setStatorDefaultView())
            }
            if (inputState === INPUT_STATOR_SLOT_DESIGN) {
                return dispatch(setStatorSlotDefaultView())
            }
            // if (inputState === INPUT_WIRE_DESIGN) {
            //     return dispatch(setWireDesignDefaultView())
            // }
            if (inputState === INPUT_WELDING_SIDE_DESIGN) {
                return dispatch(setWindingDefaultView())
            }
            if (inputState === INPUT_FORMING_SIDE) {
                return dispatch(setFormingSideDefaultView())
            }
            if (inputState === INPUT_OPTIMISATION_OPTIONS) {
                return dispatch(setOptimisationDefaultView())
            }
        }
        // if (viewerState === VIEWER_STATE_STATOR_LAMINATION) {
        //     return dispatch(setStateStatorLaminationDefaultView())
        // }
        if (viewerState === VIEWER_STATE_STATOR_CORE_SOLID) {
            return dispatch(setStateStatorCoreSolidDefaultView())
        }
        if (viewerState === VIEWER_STATE_SINGLE_SLOT) {
            return dispatch(setStateSingleSlotDefaultView())
        }
        // if (viewerState === VIEWER_STATE_WINDING) {
        //     return dispatch(setStateWindingDefaultView())
        // }
        if (viewerState === VIEWER_STATE_WELDING_SIDE) {
            return dispatch(setStateWeldingDefaultView())
        }
        if (viewerState === VIEWER_STATE_FORMING_SIDE) {
            return dispatch(setStateFormingSideDefaultView())
        }
        if (viewerState === VIEWER_STATE_ASSEMBLY) {
            return dispatch(setStateAssemblyDefaultView())
        }
    }

    // we render these components to add needed icons
    const extraIcons = () => {
        if (navbarState === NAVBAR_STATOR) {
            return <StatorIcons isIconAvailable={isIconAvailable()} fitScreenFunc={fitScreenFunc}/>
        }
        if (navbarState === NAVBAR_WINDING_SIDE) {
            return <WindingSideIcons isIconAvailable={isIconAvailable()} fitScreenFunc={fitScreenFunc}/>
        }
        if (navbarState === NAVBAR_CONNECTION_TABLE) {
            return <ConnectionTableIcons/>
        }
        if (navbarState === NAVBAR_FORMING_SIDE) {
            return <FormingSideIcons isIconAvailable={isIconAvailable()} fitScreenFunc={fitScreenFunc}/>
        }
        if (navbarState === NAVBAR_OPTIMISATION) {
            return <OptimisationIcons isIconAvailable={isIconAvailable()} fitScreenFunc={fitScreenFunc}/>
        }
    }

    return (
        <StyledWrapper>
            <img src={openIcon} alt="open"
                 onClick={() => {
                     console.log("openIcon clicked");
                 }}/>
            <img src={importIcon} alt="import"
                 onClick={() => {
                     console.log("importIcon clicked");
                 }}/>
            <img src={saveIcon} alt="save"
                 onClick={() => {
                     console.log("saveIcon clicked");
                 }}/>
            <hr/>
            {extraIcons()}
            <div className="double-click" onDoubleClick={toggleFullScreenFunc}/>
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    background: var(--clr-grey-4);
    border-top: solid 1px var(--clr-grey-1);
    border-bottom: solid 1px var(--clr-grey-1);
    border-right: solid 1px var(--clr-grey-4);
    padding-left: 1vw;
    text-align: left;
    width: calc(99vw - 1px);
    height: 4vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;

    .double-click {
        height: 3vh;
        width: 80vw;
        margin-left: 1vw;
    }

    img {
        width: 2.8vh;
        height: 2.8vh;
        padding: 0.5vh 0.4vw 0.4vw;
        cursor: pointer;
    }

    hr {
        color: var(--clr-grey-1);
        margin: 0.5vw;
        height: 2vh;
    }

    button {
        background: var(--clr-primary);
        color: var(--clr-grey-9);
        border: none;
        border-radius: 6px;
        height: 3.25vh;
        width: 8.5vw;
        cursor: pointer;
    }

    .optimisation-button {
        padding-top: 0.2vh;
        padding-left: 0.5vw;
        text-align: left;
        position: relative;

        img {
            position: absolute;
            top: 0.25vh;
            left: 74%;
            padding-top: 0.6vh;
            width: 1vw;
            height: 1.85vh;
        }

        span {
            vertical-align: top;
            font-size: var(--font-size-1);
        }
    }

    .welding-side-button {
        margin-left: 0.6vw;
        padding-top: 0.1vh;
        padding-left: 1.4vw;
        width: 13vw;
        text-align: left;
        position: relative;

        img {
            position: absolute;
            top: 0.2vh;
            left: 76%;
            padding-top: 0.6vh;
            width: 1vw;
            height: 1.85vh;
        }

        span {
            vertical-align: top;
            font-size: var(--font-size-1);
        }
    }

    .forming-side-button {
        margin-left: 0.6vw;
        padding-top: 0.1vh;
        padding-left: 1.4vw;
        text-align: left;
        position: relative;

        img {
            position: absolute;
            top: 0.2vh;
            left: 65%;
            padding-top: 0.6vh;
            width: 1vw;
            height: 1.85vh;
        }

        span {
            vertical-align: top;
            font-size: var(--font-size-1);
        }
    }
`
export default ActionBar;

import {addCoilIcon, definePatternIcon, deleteIcon} from "../../../icons/inputLayout";
import {AddCoil, DefinePattern, ParentsTreeBox} from "./index";
import {useDispatch, useSelector} from "react-redux";
import {
    addFormingSideCoil, changeSelectedParentCoil, changeTagFormingSideSingleChildCoil, changeTagFormingSideSingleCoil,
    deleteFormingSideCoils, deleteFormingSideSingleChildCoil, deleteFormingSideSingleCoil,
    formingDefinePattern
} from "../../../features/formingSideCoilsSlice";
import {closeAddCoil, closeDefinePattern, openAddCoil, openDefinePattern} from "../../../features/connectionTableSlice";


const FormingSideInputs = () => {
    const dispatch = useDispatch();
    const {formingSideCoils} = useSelector((store) => store.formingSideCoils);
    const {tagSide, showDefinePattern, showAddCoil} = useSelector((store) => store.connectionTable);
    return (
        <>
            <div className="pattern-coil-buttons">
                <button className="define-pattern-btn"
                        onClick={() => {
                            dispatch(openDefinePattern());
                        }}><span>Define Pattern </span><img src={definePatternIcon} alt="restoreDefaultIcon"/>
                </button>
                <button className="add-coil-btn"
                        onClick={() => {
                            dispatch(openAddCoil());
                        }}><span>Add Coil </span><img src={addCoilIcon} alt="restoreDefaultIcon"/>
                </button>
            </div>
            <div className="title">
                <span>Coils Hierarchy</span>
                <div className="restore-default-component" onClick={() => {
                    dispatch(deleteFormingSideCoils());
                    dispatch(changeSelectedParentCoil({selectedParentName: null}))
                }}>
                    <span className="restore-default">Delete All Coils</span>
                    <img src={deleteIcon} alt="deleteAllCoils"/>
                </div>
            </div>
            <div className="titles">
                <span className="coil-name">Coil Name</span>
                <div className="other-info">
                    <span className="busbars" style={{color: tagSide === 'forming' ? "" : "var(--clr-grey-4)"}}>BusBars</span>
                    <span className="start-slot">Start Slot</span>
                    <span className="end-slot">End Slot</span>
                </div>
            </div>
            <div className="coils">
                {formingSideCoils.map((parent, index) => {
                    return <ParentsTreeBox key={index} coilData={parent} deleteParentCoil={deleteFormingSideSingleCoil}
                                           changeParentTagCoil={changeTagFormingSideSingleCoil}
                                           deleteChildCoil={deleteFormingSideSingleChildCoil}
                                           changeChildTagCoil={changeTagFormingSideSingleChildCoil}
                                           showBusbars={tagSide === 'forming'}/>
                })}
                {showDefinePattern && <DefinePattern coilData={formingSideCoils} exitFunc={closeDefinePattern}
                                                     definePatternFunc={formingDefinePattern}/>}
                {showAddCoil && <AddCoil exitFunc={closeAddCoil} addCoilFunc={addFormingSideCoil}/>}
            </div>
        </>
    );
};

export default FormingSideInputs;

import styled from "styled-components";
import {navIcon} from "../../icons/inputLayout";
import {useDispatch, useSelector} from "react-redux";
import {
    INPUT_OPTIMISATION_OPTIONS,
    INPUT_ANIMATION_OPTIONS,
} from "../../data/states";
import {changeInputState} from "../../features/globalSlice";
import {AnimationOptionsInputs, OptimisationOptionsInputs} from "./optimisation";


const OptimisationInputs = () => {
    const dispatch = useDispatch();
    const {inputState} = useSelector((store) => store.globalItems);

    // style the buttons based on inputState
    const checkInputState = (fieldState) => {
        if (fieldState === inputState) {
            return {background: "var(--clr-primary-dark)"}
        }
        return {background: "var(--clr-grey-5)"}
    }

    // generate needed component based on inputState
    const inputFields = () => {
        if (inputState === INPUT_OPTIMISATION_OPTIONS) {
            return <OptimisationOptionsInputs/>
        }
        if (inputState === INPUT_ANIMATION_OPTIONS) {
            return <AnimationOptionsInputs/>
        }
    }

    return (
        <StyledWrapper>
            <div className="header-input">
                <img src={navIcon} alt="navIcon"/>
                <span>Optimisation</span>
            </div>
            <div className="optimisation-buttons">
                <button className="optimisation-option-btn" style={checkInputState(INPUT_OPTIMISATION_OPTIONS)}
                        onClick={() => {
                            dispatch(changeInputState({inputState: INPUT_OPTIMISATION_OPTIONS}));
                        }}>Optimisation Options
                </button>
                <button className="animation-option-btn" style={checkInputState(INPUT_ANIMATION_OPTIONS)}
                        onClick={() => {
                            dispatch(changeInputState({inputState: INPUT_ANIMATION_OPTIONS}));
                        }}>Animation Options
                </button>
            </div>
            <div className="options">
                {inputFields()}
            </div>
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    .optimisation-buttons {
        margin-top: 1vh;
        text-align: left;
        display: grid;
        grid-template-columns: 1fr 1fr;

        button {
            color: var(--clr-grey-9);
            border: none;
            height: 3.5vh;
            border-radius: 6px;
            cursor: pointer;

            &:hover {
                font-family: Ubuntu-Bold, serif;
            }
        }

        .optimisation-option-btn {
            margin-right: 0.25vw;
            font-size: var(--font-size-4)
        }

        .animation-option-btn {
            margin-left: 0.25vw;
            font-size: var(--font-size-4)
        }
    }

    .options {
        .label {
            display: flex;
            align-items: center;
            padding-top: 1vh;
            padding-bottom: 1vh;
            margin-top: 0.5vh;
            margin-bottom: 0.5vh;
            cursor: pointer;
            transition: .3s;
        }

        .radio-input {
            position: absolute;
            left: 0;
            top: 0;
            width: 1px;
            height: 1px;
            opacity: 0;
            z-index: -1;
        }

        .radio-design {
            width: 20px;
            height: 20px;
            border-radius: 7px;
            background: var(--clr-primary-light);
            position: relative;
        }

        .radio-design::before {
            content: '';
            display: inline-block;
            width: inherit;
            height: inherit;
            border-radius: 7px;
            background: var(--clr-grey-1);
            transform: scale(1.1);
            transition: .3s;
        }

        .radio-input:checked + .radio-design::before {
            transform: scale(0);
        }

        .label-text {
            color: var(--clr-grey-9);
            margin-left: 0.7vw;
            font-size: 14px;
            transition: .3s;
        }
    }
`

export default OptimisationInputs;

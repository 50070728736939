import styled from 'styled-components'
import {consoleIcon, fullscreenIcon} from "../icons/consolebar";
import {useDispatch, useSelector} from "react-redux";
import {updateProgressBarInfo} from "../features/consoleLogSlice";
import {useEffect} from "react";
import {showExportDialogFunc} from "../features/exportSlice";

const ConsoleBar = ({toggleFullScreenFunc}) => {
    const dispatch = useDispatch()
    const {weldingProgressbar, optimisationProgressbar} = useSelector((store) => store.stlParameters);
    const {formingProgressbar} = useSelector((store) => store.formingSideCoils);
    const {progressBarTitle, progressBarPercent} = useSelector((store) => store.consoleLog);

    // we change progress bar value and title based on optimisationProgressbar
    useEffect(() => {
        if (!optimisationProgressbar) {
            dispatch(updateProgressBarInfo({
                progress_bar_title: "",
                progress_bar_percent: 0
            }))
        } else {
            dispatch(updateProgressBarInfo({
                progress_bar_title: `Coil Height: ${optimisationProgressbar}`,
                progress_bar_percent: optimisationProgressbar
            }))
        }
    }, [dispatch, optimisationProgressbar])

    // we change progress bar value and title based on weldingProgressbar (check src/features/stlFilesSlice.js in documents)
    useEffect(() => {
        if (weldingProgressbar === 100 || weldingProgressbar === 0) {
            dispatch(updateProgressBarInfo({
                progress_bar_title: "",
                progress_bar_percent: 0
            }))
        } else {
            dispatch(updateProgressBarInfo({
                progress_bar_title: `Generating ${weldingProgressbar}%`,
                progress_bar_percent: weldingProgressbar
            }))
        }
    }, [dispatch, weldingProgressbar])

    // we change progress bar value and title based on formingProgressbar (check src/features/formingSideCoilsSlice.js in documents)
    useEffect(() => {
        if (formingProgressbar === 100 || formingProgressbar === 0) {
            dispatch(updateProgressBarInfo({
                progress_bar_title: "",
                progress_bar_percent: 0
            }))
        } else {
            dispatch(updateProgressBarInfo({
                progress_bar_title: `Generating ${formingProgressbar}%`,
                progress_bar_percent: formingProgressbar
            }))
        }
    }, [dispatch, formingProgressbar])

    return (
        <StyledWrapper>
            <button onClick={() => {
                // dispatch(toggleShowConsoleWindow());
                dispatch(showExportDialogFunc());
            }}>
                <img src={consoleIcon} alt="consoleIcon"/>
                <span>Export</span>
                {/*<span>Console</span>*/}
            </button>

            <div className="arrow">
                <div className="arrow-status complete" style={{width: `${progressBarPercent}%`}}/>
                <div className="progressbar-text">
                    <span>{progressBarTitle}</span>
                </div>
            </div>

            <div className="right-fields">
                <img className="fullscreen-btn" src={fullscreenIcon} alt="consoleIcon" onClick={toggleFullScreenFunc}/>
                <div className="site-url">
                    <a href="https://www.coilworks.co.uk" target="_blank" rel="noreferrer">www.coilworks.co.uk</a>
                </div>
            </div>
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    background: var(--clr-grey-4);
    border: solid 1px var(--clr-grey-4);
    width: calc(100vw - 2px);
    height: 3vh;
    display: flex;

    button {
        margin-left: 5vw;
        background: var(--clr-brown);
        color: var(--clr-grey-9);
        border: none;
        height: inherit;
        width: 7vw;
        padding-top: 0.5vh;
        cursor: pointer;

        span {
            font-size: var(--font-size-3);
            vertical-align: top;
            margin-left: 0.25vw;
        }

        img {
            height: 1.7vh;
        }
    }

    .arrow {
        background: var(--clr-grey-1);
        position: relative;
        width: 15.5vw;
        height: 2.6vh;
        margin-left: 0.3vw;
        margin-top: 0.2vh;
        text-align: center;
    }

    .arrow-status {
        background-color: var(--clr-primary);
        border-color: var(--clr-grey-9);
        width: 0;
        color: var(--clr-grey-9);
        height: 100%;
    }

    .complete {
        border-radius: 0;
    }

    .progressbar-text {
        color: var(--clr-grey-7);
        margin-top: -2.2vh;
    }

    .fullscreen-btn {
        margin-left: auto;
    }

    .right-fields {
        margin-left: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: center;

        img {
            height: 2.5vh;
            cursor: pointer;
        }

        .site-url {
            height: 3vh;
            display: flex;
            align-items: center;
            align-content: center;
            border: none;
            justify-content: center;
            width: 8vw;
            background: var(--clr-grey-1);
            font-size: var(--font-size-4);

            a {
                color: var(--clr-grey-7);
                text-decoration: none;
            }
        }
    }
`
export default ConsoleBar;

import {deleteIcon} from "../../../icons/inputLayout";
import {AddCoil, DefinePattern, ParentsTreeBox} from "./index";
import {useDispatch, useSelector} from "react-redux";
import {
    addWeldingSideCoil, changeTagWeldingSideSingleChildCoil, changeTagWeldingSideSingleCoil,
    deleteWeldingSideCoils, deleteWeldingSideSingleChildCoil, deleteWeldingSideSingleCoil,
    weldingDefinePattern
} from "../../../features/weldingSideCoilsSlice";
import {closeAddCoil, closeDefinePattern} from "../../../features/connectionTableSlice";
import {getAllStlFiles} from "../../../features/stlFilesSlice";
import {toast} from "react-toastify";

const WeldingSideInputs = () => {
    const dispatch = useDispatch();
    const {weldingSideCoils} = useSelector((store) => store.weldingSideCoils);
    const {tagSide, showDefinePattern, showAddCoil} = useSelector((store) => store.connectionTable);
    const {number_of_slot} = useSelector((store) => store.stlParameters);

    // this function calculate span based on weldingSideCoils
    const calculateSpan = () => {
        if (weldingSideCoils.length === 2) {
            let span = weldingSideCoils[0].starting_slot - weldingSideCoils[0].ending_slot;

            if (span > 0) {
                if (span > number_of_slot / 2) {
                    span = (number_of_slot - weldingSideCoils[0].starting_slot + weldingSideCoils[0].ending_slot);
                }
            } else {
                if (-span > number_of_slot / 2) {
                    span = (number_of_slot - weldingSideCoils[0].ending_slot + weldingSideCoils[0].starting_slot);
                } else {
                    span = -span;
                }
            }
            return span * 2
        } else {
            return ""
        }
    }

    return (
        <>
            <div className="pattern-coil-buttons">
                <button className="define-pattern-btn">
                    <span style={{marginLeft: 0}}>Span: {calculateSpan()}</span>
                </button>
                <button className="add-coil-btn"
                        onClick={() => {
                            if (weldingSideCoils.length === 2) {
                                dispatch(getAllStlFiles())
                            } else {
                                toast.warning("You must add welding coil first")
                            }
                        }}><span style={{marginLeft: 0}}>Generate Welding</span>
                </button>
            </div>
            <div className="title">
                <span>Coils Hierarchy</span>
                <div className="restore-default-component" onClick={() => {
                    dispatch(deleteWeldingSideCoils())
                }}>
                    <span className="restore-default">Delete All Coils</span>
                    <img src={deleteIcon} alt="restoreDefaultIcon"/>
                </div>
            </div>
            <div className="titles">
                <span className="coil-name">Coil Name</span>
                <div className="other-info">
                    <span className="busbars" style={{color: tagSide === 'welding' ? "" : "var(--clr-grey-4)"}}>BusBars</span>
                    <span className="start-slot">Start Slot</span>
                    <span className="end-slot">End Slot</span>
                </div>
            </div>
            <div className="coils">
                {weldingSideCoils.map((parent, index) => {
                    return <ParentsTreeBox key={index} coilData={parent} deleteParentCoil={deleteWeldingSideSingleCoil}
                                           changeParentTagCoil={changeTagWeldingSideSingleCoil}
                                           deleteChildCoil={deleteWeldingSideSingleChildCoil}
                                           changeChildTagCoil={changeTagWeldingSideSingleChildCoil}
                                           showBusbars={tagSide === 'welding'}/>
                })}
                {showDefinePattern && <DefinePattern coilData={weldingSideCoils} exitFunc={closeDefinePattern}
                                                     definePatternFunc={weldingDefinePattern}/>}
                {showAddCoil && <AddCoil exitFunc={closeAddCoil} addCoilFunc={addWeldingSideCoil}/>}
            </div>
        </>
    );
};

export default WeldingSideInputs;

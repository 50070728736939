import styled from "styled-components";
import Lottie from "lottie-react";
import {loadingGif} from "../../icons/global";

const LoadingDialog = () => {
    return (
        <>
            <div className="modal-container-background"/>
            <div className="modal-container">
                <StyledWrapper>
                    <div className="lottie-gif">
                        <Lottie
                            animationData={loadingGif}
                            loop={true}
                            autoplay={true}
                        />
                    </div>
                </StyledWrapper>
            </div>
        </>
    );
};

const StyledWrapper = styled.div`
    background: var(--clr-grey-2);
    width: 10vw;
    //height: 10vw;
    color: var(--clr-grey-8);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;

    .lottie-gif {
        width: 10vw;
    }
`

export default LoadingDialog;
import styled from "styled-components";
import {useSelector} from "react-redux";


const LayerDropDownInput = ({currentLayer, setCurrentLayer}) => {
    const {number_of_layers,} = useSelector((store) => store.stlParameters);
    const dropDownOptions = Array.from({length: number_of_layers}, (_, i) => i + 1);

    return (
        <StyledWrapper>
            <div>
                <p className="layer-number-input">Select Layer Number:</p>
                <span>only available for single coil and one layer</span>
            </div>
            <select value={currentLayer}
                    onChange={(e) => setCurrentLayer(e.target.value)}
            >
                {dropDownOptions.map((option, index) => {
                    return <option value={option} key={index}>{option}</option>
                })}
            </select>
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    margin-bottom: 1vh;

    .layer-number-input {
        margin-bottom: 0;
        width: 14vw;
    }

    span {
        font-size: var(--font-size-5);
        color: var(--clr-grey-8);
    }

    select {
        width: 5vw;
        height: 3vh;
        padding-left: 1vh;
        background-color: var(--clr-grey-5);
        border: none;
        color: var(--clr-grey-9);
        border-radius: 1vh;
    }
`

export default LayerDropDownInput;
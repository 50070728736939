import {
    definePatternIcon,
    addCoilIcon,
    busbarsIcon,
    setBusbarsIcon,
} from "../../icons/actionbar";
import {useDispatch} from "react-redux";
import {
    openAddCoil,
    openBusbarsDialog, openDefinePattern,
    openNumberOfPhasesDialog
} from "../../features/connectionTableSlice";


const ConnectionTableIcons = () => {
    const dispatch = useDispatch();

    return (
        <>
            <img src={definePatternIcon} alt="definePattern"
                 title="Define Pattern"
                 onClick={() => {
                     dispatch(openDefinePattern());
                 }}/>
            <img src={addCoilIcon} alt="addCoil"
                 title="Add Coil"
                 onClick={() => {
                     dispatch(openAddCoil());
                 }}/>
            <img src={busbarsIcon} alt="busbars"
                 title="Busbars"
                 onClick={() => {
                     dispatch(openBusbarsDialog())
                 }}/>
            <img src={setBusbarsIcon} alt="setBusbars"
                 title="Set Busbars"
                 onClick={() => {
                     dispatch(openNumberOfPhasesDialog())
                 }}/>
            <hr/>
            {/*<button className="welding-side-button" onClick={() => {*/}
            {/*    if (validateWeldingCoils()) {*/}
            {/*        dispatch(getAllStlFiles())*/}
            {/*    } else {*/}
            {/*        toast.warning("You must add one welding coil for each layers")*/}
            {/*    }*/}
            {/*}}>*/}
            {/*    <span>Generate Welding</span>*/}
            {/*    <img src={formingSideGenerateIcon} alt="formingSideGenerateIcon"/>*/}
            {/*</button>*/}
        </>
    );
};


export default ConnectionTableIcons;

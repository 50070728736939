import {LoadingDialog} from "../fields/dialogs";
import styled from "styled-components";
import FormingSideViewer from "./FormingSideViewer";
import {useDispatch, useSelector} from "react-redux";
import {
    changeZoomStates,
} from "../features/formingSideCoilsSlice";
import {useState} from "react";
import {ViewerState} from "./index";

const FormingSideViewerMain = () => {
    const dispatch = useDispatch();
    const {
        isLoading,
        cameraStates,
        toggleShowCube,
        selectedParentName,
        formingSideCoils
    } = useSelector((store) => store.formingSideCoils);
    const currentFormingCoil = formingSideCoils.find(forming_coil => forming_coil.coil_name === selectedParentName);
    const [fullScreenStateID, setFullScreenStateID] = useState(null);

    // we send this function to save viewer states (check src/features/formingSideCoils.js in document)
    const changeZoomFunc = (stateId, data) => {
        if (JSON.stringify({...cameraStates[stateId]}) !== JSON.stringify({...data})) {
            dispatch(changeZoomStates({
                stateId: stateId,
                data: data
            }))
        }
    }

    // with this function we change a viewer that user bubbleClicked on it full screen
    const toggleFullScreen = (stateId) => {
        if (fullScreenStateID === null) {
            setFullScreenStateID(stateId)
            return
        }
        setFullScreenStateID(null)
    }

    if (!currentFormingCoil) {
        return <></>
    }

    return (
        <StyledWrapper>
            {isLoading !== 0 && <LoadingDialog/>}
            {(fullScreenStateID === null || fullScreenStateID === 0) &&
                <ViewWrapper>
                    <FormingSideViewer stateId={0}
                                       topPosition={"0vh"}
                                       leftPosition={"0vw"}
                                       toggleShowCube={toggleShowCube}
                                       cameraData={cameraStates[0]}
                                       changeZoomFunc={changeZoomFunc}
                                       rotation={270}
                                       toggleFullScreenFunc={toggleFullScreen}
                                       componentWidth={fullScreenStateID === 0 ? "72vw" : "36vw"}
                                       componentHeight={fullScreenStateID === 0 ? "86vh" : "43vh"}
                                       isFullScreen={fullScreenStateID === 0}/>
                    {fullScreenStateID === null && <TextOverlay1>Front</TextOverlay1>}
                </ViewWrapper>}

            {(fullScreenStateID === null || fullScreenStateID === 1) &&
                <ViewWrapper>
                    <FormingSideViewer stateId={1}
                                       topPosition={"0vh"}
                                       leftPosition={fullScreenStateID === 1 ? "0vw" : "36vw"}
                                       toggleShowCube={toggleShowCube}
                                       cameraData={cameraStates[1]}
                                       changeZoomFunc={changeZoomFunc} rotation={180}
                                       toggleFullScreenFunc={toggleFullScreen}
                                       componentWidth={fullScreenStateID === 1 ? "72vw" : "36vw"}
                                       componentHeight={fullScreenStateID === 1 ? "86vh" : "43vh"}
                                       isFullScreen={fullScreenStateID === 1}/>
                    {fullScreenStateID === null && <TextOverlay2>Right</TextOverlay2>}
                </ViewWrapper>}

            {(fullScreenStateID === null || fullScreenStateID === 2) &&
                <ViewWrapper>
                    <FormingSideViewer stateId={2}
                                       topPosition={fullScreenStateID === 2 ? "0vh" : "43vh"}
                                       leftPosition={"0vw"}
                                       toggleShowCube={toggleShowCube}
                                       cameraData={cameraStates[2]}
                                       changeZoomFunc={changeZoomFunc}
                                       toggleFullScreenFunc={toggleFullScreen}
                                       componentWidth={fullScreenStateID === 2 ? "72vw" : "36vw"}
                                       componentHeight={fullScreenStateID === 2 ? "86vh" : "43vh"}
                                       isFullScreen={fullScreenStateID === 2}/>
                    {fullScreenStateID === null && <TextOverlay3>Top</TextOverlay3>}
                </ViewWrapper>}

            {(fullScreenStateID === null || fullScreenStateID === 3) &&
                <ViewWrapper>
                    <FormingSideViewer stateId={3}
                                       topPosition={fullScreenStateID === 3 ? "0vh" : "43vh"}
                                       leftPosition={fullScreenStateID === 3 ? "0vw" : "36vw"}
                                       toggleShowCube={toggleShowCube}
                                       cameraData={cameraStates[3]}
                                       changeZoomFunc={changeZoomFunc}
                                       toggleFullScreenFunc={toggleFullScreen}
                                       componentWidth={fullScreenStateID === 3 ? "72vw" : "36vw"}
                                       componentHeight={fullScreenStateID === 3 ? "86vh" : "43vh"}
                                       isFullScreen={fullScreenStateID === 3}/>
                    {fullScreenStateID === null && <TextOverlay4>Perspective</TextOverlay4>}
                </ViewWrapper>}
            <div className="viewer-state">
                <ViewerState shouldChangeViewerToDefaultState={false}/>
            </div>
        </StyledWrapper>
    )
}

const StyledWrapper = styled.div`
    position: relative;
    display: block;
    background: var(--clr-grey-7);
    text-align: center;
    justify-items: center;
    width: 72vw;
    height: 86vh;

    .viewer-state {
        position: absolute;
        top: 86vh;
        left: 0;
    }
`
const ViewWrapper = styled.div`
    //position: relative;
    display: inline-block;
    width: 36vw;
    height: 43vh;

    &:nth-child(1) {
        width: ${props => (props.fullScreenStateID === 0 ? "72vw" : "36vw")};
        height: ${props => (props.fullScreenStateID === 0 ? "86vh" : "43vh")};
    }

    &:nth-child(2) {
        left: ${props => (props.fullScreenStateID === 1 ? "0vw" : "36vw")};
        width: ${props => (props.fullScreenStateID === 1 ? "72vw" : "36vw")};
        height: ${props => (props.fullScreenStateID === 1 ? "86vh" : "43vh")};
    }

    &:nth-child(3) {
        top: ${props => (props.fullScreenStateID === 2 ? "0vh" : "43vh")};
        width: ${props => (props.fullScreenStateID === 2 ? "72vw" : "36vw")};
        height: ${props => (props.fullScreenStateID === 2 ? "86vh" : "43vh")};
    }

    &:nth-child(4) {
        top: ${props => (props.fullScreenStateID === 3 ? "0vh" : "43vh")};
        left: ${props => (props.fullScreenStateID === 3 ? "0vw" : "36vw")};
        width: ${props => (props.fullScreenStateID === 3 ? "72vw" : "36vw")};
        height: ${props => (props.fullScreenStateID === 3 ? "86vh" : "43vh")};
    }
`
const TextOverlay1 = styled.div`
    position: absolute;
    //bottom: 800px;
    bottom: 50%;
    color: white;
    background: rgba(0, 0, 0, 0.5);
    font-size: 0.8rem;
    padding: 0.8vh;
`
const TextOverlay2 = styled.div`
    position: absolute;
    bottom: 50%;
    //left: 10px;
    color: white;
    background: rgba(0, 0, 0, 0.5);
    font-size: 0.8rem;
    padding: 0.8vh;
`
const TextOverlay3 = styled.div`
    position: absolute;
    bottom: 0%;
    //left: 10px;
    color: white;
    background: rgba(0, 0, 0, 0.5);
    font-size: 0.8rem;
    padding: 0.8vh;
    //border-radius: 5px;
`
const TextOverlay4 = styled.div`
    position: absolute;
    bottom: 00px;
    //left: 10px;
    color: white;
    background: rgba(0, 0, 0, 0.5);
    font-size: 0.8rem;
    padding: 0.8vh;
`
export default FormingSideViewerMain;

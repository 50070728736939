import {closedChildren, openedChildren} from "../../../icons/inputLayout";
import {useState} from "react";
import {useDispatch} from "react-redux";
import {
    changeFormingCurvePoints, changeTagFormingSideSingleCoil, putFormingSideCoil,
} from "../../../features/formingSideCoilsSlice";
import PointsLine from "./PointsLine";
import {
    COIL_NAME,
    SEGMENT3_ROTATION,
    SEGMENT5_CONTROL_POINTS,
    SEGMENT7_ROTATION,
} from "../../../data/connectionTable";

const SegmentBox = ({
                        labelIndex, formingBSplineSegment, formingBSplineSegmentText,
                        formingBSplinePreviousSegmentText, formingBSplineNextSegmentText, numberOfSegment5Points,
                        selectedParentName, segment3Rotation, segment7Rotation, readOnly
                    }) => {
    const dispatch = useDispatch();
    const [openInputs, setOpenInputs] = useState(false);
    const [localSegment3Rotation, setLocalSegment3Rotation] = useState(segment3Rotation);
    const [localSegment7Rotation, setLocalSegment7Rotation] = useState(segment7Rotation);

    // we pass changed point to redux in handleControlPointChange (), so FormingSideViewer can update
    const handleControlPointChange = (arrayIndex, vectorIndex, value, controlPoints, previousSegmentName, segmentName, nextSegmentName) => {
        let updatedPoints = [...controlPoints];
        updatedPoints[arrayIndex] = [...updatedPoints[arrayIndex]];
        updatedPoints[arrayIndex][vectorIndex] = value === "" ? 0 : parseFloat(value);
        dispatch(changeFormingCurvePoints({
            segment: segmentName,
            previousSegment: previousSegmentName,
            nextSegment: nextSegmentName,
            value: updatedPoints,
        }));
    };


    return (
        <div>
            <div className="coil-name" style={openInputs ? {} : {
                marginBottom: "2px",
                borderBottomRightRadius: "7px",
                borderBottomLeftRadius: "7px"
            }}>
                <div>
                    {openInputs ?
                        <img className="clickable" src={openedChildren} alt="openedChildren" onClick={() => {
                            setOpenInputs(false)
                        }}/> :
                        <img className="clickable" src={closedChildren} alt="closedChildren"
                             onClick={() => {
                                 setOpenInputs(true)
                             }}/>}
                    <span className="extrude-label">{"Segment " + labelIndex}</span>
                </div>
            </div>
            {openInputs && <div className="input-lines">
                <div className="column-labels">
                    <div className="parameters">
                        <span>x</span>
                        <span>y</span>
                        <span>z</span>
                    </div>
                </div>
                {formingBSplineSegment.map((point, index) => (
                    <PointsLine key={index} index={index} point={point} formingBSplineSegment={formingBSplineSegment}
                                formingBSplinePreviousSegmentText={formingBSplinePreviousSegmentText}
                                formingBSplineSegmentText={formingBSplineSegmentText}
                                formingBSplineNextSegmentText={formingBSplineNextSegmentText}
                                handleControlPointChange={handleControlPointChange} readOnly={readOnly}/>
                ))}
                {labelIndex === 3 && <div>
                    <div className="rotation-line">
                        <span>Rotation: </span>
                        <input
                            type="text"
                            value={localSegment3Rotation}
                            onChange={(e) => setLocalSegment3Rotation(e.target.value)}
                            onBlur={(e) => {
                                const value = e.target.value
                                const finalValue = value === "" ? 0 : parseFloat(value)
                                dispatch(changeTagFormingSideSingleCoil({
                                    [COIL_NAME]: selectedParentName,
                                    type: SEGMENT3_ROTATION,
                                    value: finalValue
                                }))
                            }}
                            readOnly={readOnly}
                        />
                    </div>
                </div>}
                {labelIndex === 5 && <div>
                    <div className="rotation-line">
                        <span>NoP: </span>
                        <select
                            id="segment5ControlPoints"
                            className="busbars"
                            name="segment5ControlPoints"
                            value={numberOfSegment5Points}
                            onChange={
                                (e) => {
                                    const newValue = Number(e.target.value);
                                    const payload = {
                                        newCoil: {
                                            [SEGMENT5_CONTROL_POINTS]: newValue,
                                        }
                                    };
                                    dispatch(putFormingSideCoil(payload));
                                }
                            }>
                            {[4, 5, 6, 7].map((num) => (
                                <option disabled={readOnly} key={num} value={num}>
                                    {num}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>}
                {labelIndex === 7 && <div>
                    <div className="rotation-line">
                        <span>Rotation: </span>
                        <input
                            type="text"
                            value={localSegment7Rotation}
                            onChange={(e) => setLocalSegment7Rotation(e.target.value)}
                            onBlur={(e) => {
                                const value = e.target.value
                                const finalValue = value === "" ? 0 : parseFloat(value)
                                dispatch(changeTagFormingSideSingleCoil({
                                    [COIL_NAME]: selectedParentName,
                                    type: SEGMENT7_ROTATION,
                                    value: finalValue
                                }))
                            }}
                            readOnly={readOnly}
                        />
                    </div>
                </div>}
            </div>}
        </div>
    );
};


export default SegmentBox;

import {axiosBaseConfig} from "../data/url";
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {CONSOLE_MESSAGE, CONSOLE_MESSAGE_TYPE, CONSOLE_STATE_ALL_LOGS, CONSOLE_TYPE_ALL} from "../data/console";

// READ DOCUMENTS FOR FEATURES FILES
const initialState = {
    consoleLog: [],
    progressBarTitle: "",
    progressBarPercent: 0,
    consoleState: CONSOLE_STATE_ALL_LOGS,
    consoleType: CONSOLE_TYPE_ALL,
    showConsoleWindow: false
};

export const sendLog = createAsyncThunk(
    'consoleLogSlice/sendLog',
    async (name, thunkAPI) => {
        try {
            const endpoint = '/log/'
            const payload = {
                [CONSOLE_MESSAGE]: name[CONSOLE_MESSAGE],
                [CONSOLE_MESSAGE_TYPE]: name[CONSOLE_MESSAGE_TYPE],
            }
            const response = await axiosBaseConfig.post(endpoint, payload);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('can not send console log');
        }
    }
);

export const deleteAllLogs = createAsyncThunk(
    'consoleLogSlice/deleteAllLogs',
    async (name, thunkAPI) => {
        try {
            const endpoint = '/log/'
            const response = await axiosBaseConfig.delete(endpoint);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('can not delete console logs');
        }
    }
);

const consoleLogSlice = createSlice({
    name: 'consoleLog',
    initialState,
    reducers: {
        toggleShowConsoleWindow: (state) => {
            state.showConsoleWindow = !state.showConsoleWindow
        },
        hideConsoleWindow: (state) => {
            state.showConsoleWindow = false
        },
        unHideConsoleWindow: (state) => {
            state.showConsoleWindow = true
        },
        changeConsoleState: (state, {payload}) => {
            state.consoleState = payload.consoleState
        },
        changeConsoleType: (state, {payload}) => {
            state.consoleType = payload.consoleType
        },
        updateLogs: (state, {payload}) => {
            state.consoleLog = payload.consoleLog
        },
        updateProgressBarInfo: (state, {payload}) => {
            state.progressBarTitle = payload["progress_bar_title"]
            state.progressBarPercent = payload["progress_bar_percent"]
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(sendLog.pending, () => {
                console.log("Sending console log")
            })
            .addCase(sendLog.fulfilled, () => {
                console.log("Console log sent")
            })
            .addCase(sendLog.rejected, (state, action) => {
                console.log(action);
            })
            .addCase(deleteAllLogs.pending, () => {
                console.log("Deleting console log")
            })
            .addCase(deleteAllLogs.fulfilled, () => {
                console.log("Console logs deleted")
            })
            .addCase(deleteAllLogs.rejected, (state, action) => {
                console.log(action);
            });
    },
});

export const {
    toggleShowConsoleWindow,
    hideConsoleWindow,
    unHideConsoleWindow,
    changeConsoleState,
    changeConsoleType,
    updateLogs,
    updateProgressBarInfo
} = consoleLogSlice.actions

export default consoleLogSlice.reducer;

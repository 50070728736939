import styled from "styled-components";
import {navIcon} from "../../icons/inputLayout";
import {
    INPUT_CONNECTION_TABLE_FORMING_SIDE_DESIGN, INPUT_CONNECTION_TABLE_WELDING_SIDE_DESIGN,
} from "../../data/states";
import {changeInputState} from "../../features/globalSlice";
import {useDispatch, useSelector} from "react-redux";
import {BusbarsSide, FormingSideInputs, NumberOfPhases, WeldingSideInputs} from "./connectionTable";
import {useEffect} from "react";
import {getFormingSideCoils} from "../../features/formingSideCoilsSlice";
import {getWeldingSideCoils} from "../../features/weldingSideCoilsSlice";

const ConnectionTableInputs = () => {
    const dispatch = useDispatch();
    const {inputState} = useSelector((store) => store.globalItems);
    const {showBusbarsDialog, showNumberOfPhasesDialog} = useSelector((store) => store.connectionTable);

    // we style buttons based on inputState
    const checkInputState = (fieldState) => {
        if (fieldState === inputState) {
            return {borderBottom: "solid 3px var(--clr-primary-dark)"}
        }
        return {borderBottom: "solid 3px var(--clr-grey-5)"}
    }

    // we get forming and welding coils from backend
    useEffect(() => {
        dispatch(getFormingSideCoils());
        dispatch(getWeldingSideCoils());
    }, [dispatch])

    // we call needed component based in inputState
    const inputFields = () => {
        if (inputState === INPUT_CONNECTION_TABLE_FORMING_SIDE_DESIGN) {
            return <FormingSideInputs/>
        }
        if (inputState === INPUT_CONNECTION_TABLE_WELDING_SIDE_DESIGN) {
            return <WeldingSideInputs/>
        }
    }
    return (
        <>
            {showBusbarsDialog && <BusbarsSide/>}
            {showNumberOfPhasesDialog && !showBusbarsDialog && <NumberOfPhases/>}
            <StyledWrapper>
                <div className="header-input">
                    <img src={navIcon} alt="navIcon"/>
                    <span>CONNECTION TABLE</span>
                </div>
                <div className="connection-table-buttons">
                    <button style={checkInputState(INPUT_CONNECTION_TABLE_FORMING_SIDE_DESIGN)}
                            onClick={() => {
                                dispatch(changeInputState({inputState: INPUT_CONNECTION_TABLE_FORMING_SIDE_DESIGN}));
                            }}>Forming Side
                    </button>
                    <button style={checkInputState(INPUT_CONNECTION_TABLE_WELDING_SIDE_DESIGN)}
                            onClick={() => {
                                dispatch(changeInputState({inputState: INPUT_CONNECTION_TABLE_WELDING_SIDE_DESIGN}));
                            }}>Welding Side
                    </button>
                </div>
                {inputFields()}
            </StyledWrapper>
        </>
    );
};

const StyledWrapper = styled.div`
    .connection-table-buttons {
        margin-top: 1vh;
        text-align: left;
        display: grid;
        grid-template-columns: 1fr 1fr;

        button {
            background: transparent;
            font-size: var(--font-size-2);
            color: var(--clr-grey-9);
            border: none;
            height: 3vh;
            cursor: pointer;

            &:hover {
                font-weight: bold;
            }
        }
    }

    .pattern-coil-buttons {
        margin-top: 1.5vh;
        margin-bottom: 0.5vh;
        text-align: left;
        display: grid;
        grid-template-columns: 1fr 1fr;

        button {
            position: relative;
            font-size: 13px;
            border: none;
            border-radius: 6px;
            color: var(--clr-grey-9);
            height: 3.5vh;
            margin-top: 0.2vh;
            margin-bottom: 0.5vh;
            cursor: pointer;

            &:hover {
                font-weight: bold;
            }


            img {
                position: absolute;
                width: 1.5vh;
                height: 1.5vh;
                margin-top: 0.05vh;
                margin-left: 5px;
            }

            span {
                margin-left: -1.7vh;
            }
        }

        .define-pattern-btn {
            background: transparent;
            border: solid 1px var(--clr-grey-6);
            margin-right: 0.25vw;
        }

        .add-coil-btn {
            background: var(--clr-primary);
            margin-left: 0.25vw;
        }

        img {
            margin-top: -10px;
        }
    }

    .coils {
        padding-right: 0.5vw;
        height: 71vh;
        overflow: auto;
        scrollbar-width: thin;
        scrollbar-color: var(--clr-primary-dark) transparent;
    }

    .titles {
        margin-right: 3vw;
        margin-left: 1.5vw;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        color: var(--clr-grey-6);
        font-size: var(--font-size-4);
        font-family: Ubuntu-Light, serif;


        .coil-name {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: left;
            width: 4vw;
        }

        .other-info {
            margin-left: 1.5vw;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 13vw;
            .busbars {
                width: 3.5vw;
                padding-left: 1vh;
            }

            .start-slot {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 3vw;
                height: 1.8vh;
                margin-left: 1vw;
            }

            .end-slot {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: 1.8vh;
                width: 3vw;
            }
        }
    }
`

export default ConnectionTableInputs;

import styled from "styled-components";
import {deleteIcon, navIcon} from "../../icons/inputLayout";
import {CoilTreeBox} from "./formingSide";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    changeSelectedParentCoil,
    deleteAllOperations,
    formingChangeSegmentsPoints,
    getFormingSideCoils,
    toggleShowCubes
} from "../../features/formingSideCoilsSlice";
import {toast} from "react-toastify";
import {changeNavbarState} from "../../features/globalSlice";
import {NAVBAR_CONNECTION_TABLE} from "../../data/states";


const FormingSideInputs = () => {
    const dispatch = useDispatch();
    const {formingSideCoils, selectedParentName} = useSelector((store) => store.formingSideCoils);
    const currentFormingCoil = formingSideCoils.find(forming_coil => forming_coil.coil_name === selectedParentName);


    useEffect(() => {
        if (currentFormingCoil !== undefined) {
            // dispatch(formingBSpline({[PARENT_NAME]: selectedParentName}))

        // if user did not select a parent coil, we set first forming coil as the selected one
        } else if (formingSideCoils.length > 0) {
            // dispatch(formingBSpline({[PARENT_NAME]: formingSideCoils[0]["coil_name"]}))
            dispatch(changeSelectedParentCoil({selectedParentName: formingSideCoils[0]["coil_name"]}))
        }
        // we check if there is no forming coils, we pass user to the connection table tab
        else {
            dispatch(changeNavbarState({navbarState: NAVBAR_CONNECTION_TABLE}));
            toast.warning("please select coil")
        }
    }, [dispatch, formingSideCoils])

    // useEffect(() => {
    //     if (selectedParentName) {
    //         dispatch(getFormingSideStl({[PARENT_NAME]: selectedParentName}))
    //     }
    // }, [dispatch, selectedParentName, formingSideCoils])

    // we get forming coils when we render this page
    useEffect(() => {
        dispatch(getFormingSideCoils());
    }, [dispatch])

    // if there is no parent selected, we render nothing
    if (!currentFormingCoil) {
        return <></>
    }

    return (
        <StyledWrapper>
            <div className="header-input">
                <img src={navIcon} alt="navIcon"/>
                <span>FORMING SIDE DESIGN</span>
            </div>
            <div className="forming-side-buttons">
                <button className="add-extrude-btn"
                        onClick={() => {
                            dispatch(formingChangeSegmentsPoints({
                                data: formingSideCoils
                            }))
                        }}>Submit
                </button>
                <button className="add-revolve-btn"
                        onClick={() => {
                            dispatch(toggleShowCubes())
                        }}>Toggle Cube
                </button>
            </div>
            <div className="title">
                <span>Coils Forming</span>
                <div className="restore-default-component" onClick={() => {
                    dispatch(deleteAllOperations())
                }}>
                    <span className="restore-default">Delete All</span>
                    <img src={deleteIcon} alt="deleteIcon"/>
                </div>
            </div>
            <div className="titles">
                <span className="coil-name">Coil Name</span>
                <div className="other-info">
                    <span className="busbars">Height</span>
                    <span className="start-slot">Start Slot</span>
                    <span className="end-slot">End Slot</span>
                </div>
            </div>
            <div className="coils-and-details">
                {formingSideCoils.map((coil, index) => {
                    return <CoilTreeBox key={index} coilData={coil}/>
                })}
            </div>
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    .forming-side-buttons {
        margin-top: 1vh;
        text-align: left;
        display: grid;
        grid-template-columns: 1fr 1fr;

        button {
            background: var(--clr-primary);
            color: var(--clr-grey-9);
            border: none;
            height: 3.5vh;
            border-radius: 6px;
            cursor: pointer;
            font-size: var(--font-size-4);
            font-family: Ubuntu-Light, serif;
            margin-bottom: 0.5vh;

            &:hover {
                font-family: Ubuntu-Bold, serif;
            }
        }

        .add-extrude-btn {
            margin-right: 0.25vw;
        }

        .add-revolve-btn {
            margin-left: 0.25vw;
        }
    }

    .column-labels {
        font-size: 12px;
        color: var(--clr-grey-6);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .parameters {
            margin-left: 3.75vw;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 17vw;

            span {
                width: 4.5vw;
            }
        }
    }

    .coils-and-details {
        padding-right: 0.5vw;
        height: 75vh;
        overflow: auto;
        scrollbar-width: thin;
        scrollbar-color: var(--clr-primary-dark) transparent;
    }

    .titles {
        margin-right: 1.5vw;
        margin-left: 1.5vw;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        color: var(--clr-grey-6);
        font-size: var(--font-size-4);
        font-family: Ubuntu-Light, serif;


        .coil-name {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: left;
            width: 4vw;
        }

        .other-info {
            margin-left: 1.5vw;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 13vw;
            .busbars {
                width: 4.5vw;
                padding-left: 1vh;
            }

            .start-slot {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 3vw;
                height: 1.8vh;
                margin-left: 1vw;
            }

            .end-slot {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: 1.8vh;
                width: 3vw;
            }
        }
    }
`

export default FormingSideInputs;

import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getAllStlFiles, setStlParameters} from "../../../features/stlFilesSlice";
import {restoreDefaultIcon} from "../../../icons/inputLayout";
import {DropdownInput, TextInput, TowButtonInput} from "../components";
import {
    AUTO_OR_MANUAL_AUTO,
    AUTO_OR_MANUAL_MANUAL,
    CORE_BACK_INPUT,
    FILL_FACTOR_INPUT,
    NUMBER_OF_LAYERS_INPUT,
    NUMBER_OF_LAYERS_INPUT_OPTIONS,
    PAPER_LENGTH_INPUT,
    PAPER_OVERLAP_LEFT,
    PAPER_OVERLAP_LENGTH_INPUT,
    PAPER_OVERLAP_RIGHT,
    PAPER_THICKNESS_INPUT,
    PAPER_TO_WIRE_CLEARANCE_INPUT,
    SAME_OR_DIFFERENT_DIFFERENT,
    SAME_OR_DIFFERENT_SAME,
    SFR1_INPUT,
    SFR2_INPUT,
    SFR3_INPUT,
    SFR4_INPUT,
    SFR_INPUT,
    SLOT_AREA_INPUT,
    SLOT_DEPTH_INPUT,
    SLOT_MODE_SEMI,
    SLOT_OPENING_INPUT,
    SLOT_OR_WIRE_EQUAL_SLOT,
    SLOT_OR_WIRE_EQUAL_WIRE,
    SLOT_WIDTH_INPUT,
    STATOR_BORE_DIAMETER_INPUT,
    STATOR_OUTER_DIAMETER_INPUT,
    statorDefaultValues,
    TOOTH_TIP_ANGLE_INPUT,
    TOOTH_TIP_INPUT,
    WIRE_DEPTH_INPUT,
    WIRE_DEPTH_WITHOUT_INSULATION_INPUT,
    WIRE_TO_WIRE_CLEARANCE_INPUT,
    WIRE_WIDTH_INPUT,
    WIRE_WIDTH_WITHOUT_INSULATION_INPUT,
} from "../../../data/statorInputs";
import {
    numberOfLayerGif,
    sfr1Gif,
    sfr2Gif,
    sfr3Gif,
    sfr4Gif,
    slotDepthGif,
    slotOpeningGif,
    slotWidthGif,
    toothTipAngleGif,
    toothTipGif
} from "../../../icons/inputLayout/gif/statorSlotDesign";
import {
    VALIDATE_CORE_BACK_LESS_THAN_STATIC,
    VALIDATE_NUMBER_OF_LAYERS_MUST_BE_MULTIPLE_OF_TWO,
    VALIDATE_PAPER_OVERLAP_LENGTH_MAX,
    VALIDATE_PAPER_OVERLAP_LENGTH_MIN,
    VALIDATE_PAPER_THICKNESS_BIGGER_THAN_SFR1,
    VALIDATE_PAPER_THICKNESS_RANGE,
    VALIDATE_PAPER_TO_WIRE_CLEARANCE,
    VALIDATE_SFR1_BIGGER_THAN_SLOT_WIDTH,
    VALIDATE_SFR2_BIGGER_THAN_SLOT_WIDTH,
    VALIDATE_SFR3_BIGGER_THAN_SLOT_WIDTH,
    VALIDATE_SFR4_BIGGER_THAN_SLOT_WIDTH,
    VALIDATE_SLOT_DEPTH_FORMULA,
    VALIDATE_SLOT_OPENING_BIGGER_SLOT_WIDTH,
    VALIDATE_TOOTH_TIP_ANGLE,
    VALIDATE_TOOTH_TIP_BIGGER_THAN_SLOT_WIDTH,
    VALIDATE_TOOTH_TIP_SFR3_AND_SFR4,
    VALIDATE_WIRE_INSULATION_RANGE, VALIDATE_WIRE_INSULATION_THICKNESS_BIGGER_THAN_WIRE_FILLET_RADIUS
} from "../../../data/validatonErrors";
import {WarningDialog} from "../../dialogs";
import {changeSelectedParentCoil, deleteFormingSideCoils} from "../../../features/formingSideCoilsSlice";
import {deleteWeldingSideCoils} from "../../../features/weldingSideCoilsSlice";
import {toast} from "react-toastify";
import {
    windingSideDefaultValues,
    WIRE_FILLET_RADIUS_INPUT,
    WIRE_FILLET_RADIUS_INPUT_OPTIONS,
    WIRE_INSULATION_THICKNESS_INPUT,
    WIRE_INSULATION_THICKNESS_INPUT_OPTIONS
} from "../../../data/windingSideInputs";
import {wireFilletRadiusGif, wireInsulationThicknessGif} from "../../../icons/inputLayout/gif/wireDesign";

const StatorSlotDesignInputs = () => {
    const dispatch = useDispatch();
    const [validationError, setValidationError] = useState("")
    const {
        slotOrWire,
        autoOrManual,
        sameOrDifferent,
        overlapLeftOrRight,
        slotModeSemiOrNot
    } = useSelector((store) => store.stlParameters);
    const stlParameters = useSelector((store) => store.stlParameters);
    const {
        number_of_layers,
        number_of_slot,
        slot_opening,
        tooth_tip,
        slot_depth,
        slot_width,
        wire_depth,
        wire_width,
        wire_depth_without_insulation,
        wire_width_without_insulation,
        tooth_tip_angle,
        paper_thickness,
        paper_overlap_length,
        sfr,
        sfr1,
        sfr2,
        sfr3,
        sfr4,
        paper_to_wire_clearance,
        wire_to_wire_clearance,
        stator_outer_diameter,
        stator_bore_diameter
    } = useSelector((store) => store.stlParameters);
    const [numberOfLayersOptions, setNumberOfLayersOptions] = useState(NUMBER_OF_LAYERS_INPUT_OPTIONS);
    const [numberOfLayersValue, setNumberOfLayersValue] = useState(stlParameters[NUMBER_OF_LAYERS_INPUT]);
    const [slotOpeningInput, setSlotOpeningInput] = useState(stlParameters[SLOT_OPENING_INPUT])
    const [toothTipInput, setToothTipInput] = useState(stlParameters[TOOTH_TIP_INPUT])
    const [slotDepthInput, setSlotDepthInput] = useState(stlParameters[SLOT_DEPTH_INPUT])
    const [slotWidthInput, setSlotWidthInput] = useState(stlParameters[SLOT_WIDTH_INPUT])
    const [wireDepthInput, setWireDepthInput] = useState(stlParameters[WIRE_DEPTH_INPUT])
    const [wireWidthInput, setWireWidthInput] = useState(stlParameters[WIRE_WIDTH_INPUT])
    const [wireDepthWithoutInsulationInput, setWireDepthWithoutInsulationInput] = useState(stlParameters[WIRE_DEPTH_WITHOUT_INSULATION_INPUT])
    const [wireWidthWithoutInsulationInput, setWireWidthWithoutInsulationInput] = useState(stlParameters[WIRE_WIDTH_WITHOUT_INSULATION_INPUT])
    const [toothTipAngleInput, setToothTipAngleInput] = useState(stlParameters[TOOTH_TIP_ANGLE_INPUT])
    const [paperThicknessInput, setPaperThicknessInput] = useState(stlParameters[PAPER_THICKNESS_INPUT])
    const [paperOverlapLengthInput, setPaperOverlapLengthInput] = useState(stlParameters[PAPER_OVERLAP_LENGTH_INPUT])
    const {wire_insulation_thickness, wire_fillet_radius} = useSelector((store) => store.stlParameters);
    const [wireInsulationThicknessOptions, setWireInsulationThicknessOptions] = useState(WIRE_INSULATION_THICKNESS_INPUT_OPTIONS);
    const [wireInsulationThicknessValue, setWireInsulationThicknessValue] = useState(stlParameters[WIRE_INSULATION_THICKNESS_INPUT]);
    const [wireFilletRadiusOptions, setWireFilletRadiusOptions] = useState(WIRE_FILLET_RADIUS_INPUT_OPTIONS);
    const [wireFilletRadiusValue, setWireFilletRadiusValue] = useState(stlParameters[WIRE_FILLET_RADIUS_INPUT]);
    const [sfrInput, setSfrInput] = useState(stlParameters[SFR_INPUT])
    const [sfr1Input, setSfr1Input] = useState(stlParameters[SFR1_INPUT])
    const [sfr2Input, setSfr2Input] = useState(stlParameters[SFR2_INPUT])
    const [sfr3Input, setSfr3Input] = useState(stlParameters[SFR3_INPUT])
    const [sfr4Input, setSfr4Input] = useState(stlParameters[SFR4_INPUT])
    const [paperToWireClearanceInput, setPaperToWireClearanceInput] = useState(stlParameters[PAPER_TO_WIRE_CLEARANCE_INPUT])
    const [wireToWireClearanceInput, setWireToWireClearanceInput] = useState(stlParameters[WIRE_TO_WIRE_CLEARANCE_INPUT])
    const towButtonEnableStyle = {background: "var(--clr-grey-6)", color: "var(--clr-grey-9)"}
    const towButtonDisableStyle = {background: "var(--clr-grey-5)", color: "var(--clr-grey-6)"}

    // here we calculate wireDepth and wireWidth every time that wireWidthWithoutInsulation and wireDepthWithoutInsulation changed
    useEffect(() => {
        setWireDepthInput((parseFloat(wireDepthWithoutInsulationInput) + (2 * parseFloat(wireInsulationThicknessValue))).toFixed(2))
        setWireWidthInput((parseFloat(wireWidthWithoutInsulationInput) + (2 * parseFloat(wireInsulationThicknessValue))).toFixed(2))
    }, [wireDepthWithoutInsulationInput, wireWidthWithoutInsulationInput])

    // we set inputs from redux values
    useEffect(() => {
        setNumberOfLayersValue(number_of_layers)
        setSlotOpeningInput(slot_opening)
        setToothTipInput(tooth_tip)
        setSlotDepthInput(slot_depth)
        setSlotWidthInput(slot_width)
        setWireDepthInput(wire_depth)
        setWireWidthInput(wire_width)
        setWireDepthWithoutInsulationInput(wire_depth_without_insulation)
        setWireWidthWithoutInsulationInput(wire_width_without_insulation)
        setToothTipAngleInput(tooth_tip_angle)
        setPaperThicknessInput(paper_thickness)
        setPaperOverlapLengthInput(paper_overlap_length)
        setSfrInput(sfr1)
        setSfr1Input(sfr1)
        setSfr2Input(sfr2)
        setSfr3Input(sfr3)
        setSfr4Input(sfr4)
        setPaperToWireClearanceInput(paper_to_wire_clearance)
        setWireToWireClearanceInput(wire_to_wire_clearance)
        dispatch(setStlParameters({
            sameOrDifferent: sfr1 === sfr2 && sfr2 === sfr3 && sfr3 === sfr4 ?
                SAME_OR_DIFFERENT_SAME : SAME_OR_DIFFERENT_DIFFERENT,
        }))
        console.log("stator slot parameters changed")
    }, [number_of_layers,
        slot_opening,
        tooth_tip,
        slot_depth,
        slot_width,
        wire_depth,
        wire_width,
        wire_depth_without_insulation,
        wire_width_without_insulation,
        tooth_tip_angle,
        paper_thickness,
        paper_overlap_length,
        sfr,
        sfr1,
        sfr2,
        sfr3,
        sfr4,
        paper_to_wire_clearance,
        wire_to_wire_clearance,
        dispatch])

    // we set inputs from redux values
    useEffect(() => {
        setWireInsulationThicknessValue(wire_insulation_thickness)
        setWireFilletRadiusValue(wire_fillet_radius)
        console.log("wire design parameters changed")
    }, [wire_insulation_thickness, wire_fillet_radius])

    // we check validations here
    const validations = (wireDepth, slotOpening, slotWidth, toothTip, toothTipAngle, paperThickness, paperOverlapLength,
                         sfr, sfr1, sfr2, sfr3, sfr4, paperToWireClearance, wireToWireClearance, numberOfLayers,
                         wireInsulationThickness, wireFilletRadius) => {
        if (numberOfLayers % 2 !== 0) {
            return VALIDATE_NUMBER_OF_LAYERS_MUST_BE_MULTIPLE_OF_TWO
        }
        if (stlParameters[STATOR_BORE_DIAMETER_INPUT] + (2 * wireDepth) > stlParameters[STATOR_OUTER_DIAMETER_INPUT]) {
            return VALIDATE_SLOT_DEPTH_FORMULA
        }
        if (slotOpening > slotWidth) {
            return VALIDATE_SLOT_OPENING_BIGGER_SLOT_WIDTH
        }
        if (toothTip > slotWidth) {
            return VALIDATE_TOOTH_TIP_BIGGER_THAN_SLOT_WIDTH
        }
        if (toothTipAngle > 90 || toothTipAngle < 0) {
            return VALIDATE_TOOTH_TIP_ANGLE
        }
        if (paperThickness > 0.5 || paperThickness < 0.05) {
            return VALIDATE_PAPER_THICKNESS_RANGE
        }
        if ((sameOrDifferent !== SAME_OR_DIFFERENT_SAME && toothTip < sfr3 + sfr4) || (sameOrDifferent === SAME_OR_DIFFERENT_SAME && toothTip < 2 * sfr)) {
            return VALIDATE_TOOTH_TIP_SFR3_AND_SFR4
        }
        if (wireInsulationThickness > 1 || wireInsulationThickness < 0.01) {
            return VALIDATE_WIRE_INSULATION_RANGE
        }
        if (paperOverlapLength > (slotWidth - (paperThickness * 2))) {
            return VALIDATE_PAPER_OVERLAP_LENGTH_MAX
        }
        if (paperOverlapLength < 0.1) {
            return VALIDATE_PAPER_OVERLAP_LENGTH_MIN
        }
        if (sfr1 > (slotWidth / 2)) {
            return VALIDATE_SFR1_BIGGER_THAN_SLOT_WIDTH
        }
        if (sfr2 > (slotWidth / 2)) {
            return VALIDATE_SFR2_BIGGER_THAN_SLOT_WIDTH
        }
        if (sfr3 > (slotWidth / 2)) {
            return VALIDATE_SFR3_BIGGER_THAN_SLOT_WIDTH
        }
        if (sfr4 > (slotWidth / 2)) {
            return VALIDATE_SFR4_BIGGER_THAN_SLOT_WIDTH
        }
        if (paperToWireClearance > 0.2 || paperToWireClearance < 0.1) {
            return VALIDATE_PAPER_TO_WIRE_CLEARANCE
        }
        // if (wireToWireClearance > 0.2 || wireToWireClearance < 0.1) {
        //     return VALIDATE_PAPER_TO_WIRE_CLEARANCE
        // }
        if ((sameOrDifferent !== SAME_OR_DIFFERENT_SAME && paperThickness >= sfr1) || (sameOrDifferent === SAME_OR_DIFFERENT_SAME && paperThickness >= sfr)) {
            return VALIDATE_PAPER_THICKNESS_BIGGER_THAN_SFR1
        }
        if (calculateCoreBack() <= 2) {
            return VALIDATE_CORE_BACK_LESS_THAN_STATIC
        }
        if (wireInsulationThickness >= wireFilletRadius) {
            return VALIDATE_WIRE_INSULATION_THICKNESS_BIGGER_THAN_WIRE_FILLET_RADIUS
        }
        return false
    }

    // we send new inputs to backend here and update stl files
    const setParameters = (slotOpening, toothTip, toothTipAngle, paperThickness,
                           paperToWireClearance, wireToWireClearance, slotDepth, slotWidth, wireDepth, wireWidth,
                           paperOverlapLength, sfr, sfr1, sfr2, sfr3, sfr4, numberOfLayers, wireInsulationThickness, wireFilletRadius) => {

        const checkValidations = validations(wireDepth, slotOpening, slotWidth, toothTip, toothTipAngle,
            paperThickness, paperOverlapLength, sfr, sfr1, sfr2, sfr3, sfr4, paperToWireClearance, wireToWireClearance,
            numberOfLayers, wireInsulationThickness, wireFilletRadius)
        if (checkValidations) {
            setValidationError(checkValidations)
            return
        }
        if (parseInt(numberOfLayers) !== stlParameters[NUMBER_OF_LAYERS_INPUT]) {
            dispatch(deleteFormingSideCoils())
            dispatch(deleteWeldingSideCoils())
            dispatch(changeSelectedParentCoil({selectedParentName: null}))
        }
        dispatch(setStlParameters({
            [NUMBER_OF_LAYERS_INPUT]: numberOfLayers,
            [SLOT_OPENING_INPUT]: slotOpening,
            [TOOTH_TIP_INPUT]: toothTip,
            [TOOTH_TIP_ANGLE_INPUT]: toothTipAngle,
            [PAPER_THICKNESS_INPUT]: paperThickness,
            [PAPER_TO_WIRE_CLEARANCE_INPUT]: paperToWireClearance,
            [WIRE_TO_WIRE_CLEARANCE_INPUT]: wireToWireClearance,
            [WIRE_INSULATION_THICKNESS_INPUT]: wireInsulationThickness,
            [WIRE_FILLET_RADIUS_INPUT]: wireFilletRadius,
        }))
        // here we calculate wireDepth and wireWidth based on slotDept and slotWidth
        if (slotOrWire === SLOT_OR_WIRE_EQUAL_SLOT) {
            // const newWireDepth = ((slotDepth - 3 * paperThickness - wireToWireClearance * (stlParameters[NUMBER_OF_LAYERS_INPUT] - 1) - 2 * paperToWireClearance) / stlParameters[NUMBER_OF_LAYERS_INPUT]).toFixed(2);
            // const newWireWidth = (slotWidth - 2 * paperThickness - 2 * paperToWireClearance).toFixed(2);
            const toothTipAngleLength = ((slotWidth - slotOpening) / 2) / Math.cos(toothTipAngle * Math.PI / 180);
            let allDegrees = {}
            for (let i = 0; i < number_of_slot; i++) {
                let angle = 360 / number_of_slot * i;
                allDegrees[i] = Math.abs(90 - angle);
            }
            let newSlotDepth = slotDepth - (
                toothTip + 1 +
                (Math.sin(toothTipAngle * Math.PI / 180) * toothTipAngleLength)
            );
            // let toothTipGap = (
            //     (slotWidth - slotOpening) *
            //     Math.tan(Math.PI * toothTipAngle / 180)
            // ) / 2

            let newWireWidth = (
                slotWidth - (2 * (paperThickness + paperToWireClearance))
            );
            let newWireDepth = (
                newSlotDepth + 1
                - 3 * paperThickness
                - wireToWireClearance * (numberOfLayers - 1)
                - 2 * paperToWireClearance
                // - numberOfLayers
                // * (2 * wire_insulation_thickness)
            ) / numberOfLayers;


            dispatch(setStlParameters({
                [SLOT_DEPTH_INPUT]: slotDepth,
                [SLOT_WIDTH_INPUT]: slotWidth,
                [WIRE_DEPTH_INPUT]: parseFloat(newWireDepth.toFixed(2)),
                [WIRE_WIDTH_INPUT]: parseFloat(newWireWidth.toFixed(2)),
                [WIRE_DEPTH_WITHOUT_INSULATION_INPUT]: parseFloat((newWireDepth - (2 * wireInsulationThickness)).toFixed(2)),
                [WIRE_WIDTH_WITHOUT_INSULATION_INPUT]: parseFloat((newWireWidth - (2 * wireInsulationThickness)).toFixed(2)),
            }))
            setWireDepthInput(newWireDepth)
            setWireWidthInput(newWireWidth)
        // here we calculate slotDepth and slotWidth based on wireDepth and wireWidth
        } else {
            const newSlotWidth = wireWidth + (
                2 * (
                    paperThickness +
                    paperToWireClearance
                )
            )
            const toothTipGap = (
                (newSlotWidth - slotOpening) *
                Math.tan(Math.PI * toothTipAngle / 180)
            ) / 2
            const newSlotDepth = (
                numberOfLayers * wireDepth +
                2 * paperToWireClearance +
                wireToWireClearance * (numberOfLayers - 1) +
                3 * paperThickness +
                toothTip +
                toothTipGap
            );
            // const newSlotDepth = (wireDepth * stlParameters[NUMBER_OF_LAYERS_INPUT] + 3 * paperThickness + wireToWireClearance * (stlParameters[NUMBER_OF_LAYERS_INPUT] - 1) + 2 * paperToWireClearance).toFixed(2);
            // const newSlotWidth = (wireWidth + 2 * paperThickness + 2 * paperToWireClearance).toFixed(2);
            dispatch(setStlParameters({
                [WIRE_DEPTH_INPUT]: parseFloat((parseFloat(wireDepthWithoutInsulationInput) + (2 * parseFloat(wireInsulationThickness))).toFixed(2)),
                [WIRE_WIDTH_INPUT]: parseFloat((parseFloat(wireWidthWithoutInsulationInput) + (2 * parseFloat(wireInsulationThickness))).toFixed(2)),
                [WIRE_DEPTH_WITHOUT_INSULATION_INPUT]: parseFloat(wireDepthWithoutInsulationInput),
                [WIRE_WIDTH_WITHOUT_INSULATION_INPUT]: parseFloat(wireWidthWithoutInsulationInput),
                [SLOT_DEPTH_INPUT]: parseFloat(newSlotDepth.toFixed(2)),
                [SLOT_WIDTH_INPUT]: parseFloat(newSlotWidth.toFixed(2)),
            }))
            setSlotDepthInput(newSlotDepth)
            setSlotWidthInput(newSlotWidth)
        }
        // we pass paper_overlap_length 1 if user set it to auto
        if (autoOrManual === AUTO_OR_MANUAL_AUTO) {
            dispatch(setStlParameters({
                [PAPER_OVERLAP_LENGTH_INPUT]: 1,
            }))
            setPaperOverlapLengthInput(1)
        } else {
            dispatch(setStlParameters({
                [PAPER_OVERLAP_LENGTH_INPUT]: paperOverlapLength,
            }))
        }
        // we set sfr1, sfr2, sfr3, sfr4 parameters to sfr value if user set same in TwoButtonInput
        if (sameOrDifferent === SAME_OR_DIFFERENT_SAME) {
            dispatch(setStlParameters({
                [SFR_INPUT]: sfr,
                [SFR1_INPUT]: sfr,
                [SFR2_INPUT]: sfr,
                [SFR3_INPUT]: sfr,
                [SFR4_INPUT]: sfr,
            }))
            setSfr1Input(sfr)
            setSfr2Input(sfr)
            setSfr3Input(sfr)
            setSfr4Input(sfr)
        } else {
            dispatch(setStlParameters({
                [SFR1_INPUT]: sfr1,
                [SFR2_INPUT]: sfr2,
                [SFR3_INPUT]: sfr3,
                [SFR4_INPUT]: sfr4,
            }))
        }
        dispatch(getAllStlFiles())
    }

    // we call setParameters when user pressed enter
    const handleOnEnterPressed = (event) => {
        if (event.key === 'Enter') {
            setParameters(parseFloat(slotOpeningInput),
                parseFloat(toothTipInput),
                parseInt(toothTipAngleInput),
                parseFloat(paperThicknessInput),
                parseFloat(paperToWireClearanceInput),
                parseFloat(wireToWireClearanceInput),
                parseFloat(slotDepthInput),
                parseFloat(slotWidthInput),
                parseFloat(wireDepthInput),
                parseFloat(wireWidthInput),
                parseFloat(paperOverlapLengthInput),
                parseFloat(sfrInput),
                parseFloat(sfr1Input),
                parseFloat(sfr2Input),
                parseFloat(sfr3Input),
                parseFloat(sfr4Input),
                parseInt(numberOfLayersValue),
                parseFloat(wireInsulationThicknessValue),
                parseFloat(wireFilletRadiusValue)
            )
        }
    }

    // we call setParameters when user change overlapSide TowButtonInput
    const overlapRightAndLeftButtonChanged = () => {
        setParameters(parseFloat(slotOpeningInput),
            parseFloat(toothTipInput),
            parseInt(toothTipAngleInput),
            parseFloat(paperThicknessInput),
            parseFloat(paperToWireClearanceInput),
            parseFloat(wireToWireClearanceInput),
            parseFloat(slotDepthInput),
            parseFloat(slotWidthInput),
            parseFloat(wireDepthInput),
            parseFloat(wireWidthInput),
            parseFloat(paperOverlapLengthInput),
            parseFloat(sfrInput),
            parseFloat(sfr1Input),
            parseFloat(sfr2Input),
            parseFloat(sfr3Input),
            parseFloat(sfr4Input),
            parseInt(numberOfLayersValue),
            parseFloat(wireInsulationThicknessValue),
            parseFloat(wireFilletRadiusValue)
        )
    }

    // here we append users new option to dropdown options, then we call setParameters
    const addNumberOfLayersOption = (event) => {
        if (event.key === 'Enter') {
            const newOptions = numberOfLayersOptions.concat(parseInt(numberOfLayersValue));
            const uniqueOptions = Array.from(new Set(newOptions));
            const sortedOptions = uniqueOptions.sort((a, b) => a - b);
            setNumberOfLayersOptions(sortedOptions)
            setParameters(parseFloat(slotOpeningInput),
                parseFloat(toothTipInput),
                parseFloat(toothTipAngleInput),
                parseFloat(paperThicknessInput),
                parseFloat(paperToWireClearanceInput),
                parseFloat(wireToWireClearanceInput),
                parseFloat(slotDepthInput),
                parseFloat(slotWidthInput),
                parseFloat(wireDepthInput),
                parseFloat(wireWidthInput),
                parseFloat(paperOverlapLengthInput),
                parseFloat(sfrInput),
                parseFloat(sfr1Input),
                parseFloat(sfr2Input),
                parseFloat(sfr3Input),
                parseFloat(sfr4Input),
                parseInt(numberOfLayersValue),
                parseFloat(wireInsulationThicknessValue),
                parseFloat(wireFilletRadiusValue)
            )
        }

    }

    // here we append users new option to dropdown options, then we call setParameters
    const addWireInsulationThicknessOption = (event) => {
        if (event.key === 'Enter') {
            const newOptions = wireInsulationThicknessOptions.concat(parseFloat(wireInsulationThicknessValue));
            const uniqueOptions = Array.from(new Set(newOptions));
            const sortedOptions = uniqueOptions.sort((a, b) => a - b);
            setWireInsulationThicknessOptions(sortedOptions)
            setParameters(parseFloat(slotOpeningInput),
                parseFloat(toothTipInput),
                parseFloat(toothTipAngleInput),
                parseFloat(paperThicknessInput),
                parseFloat(paperToWireClearanceInput),
                parseFloat(wireToWireClearanceInput),
                parseFloat(slotDepthInput),
                parseFloat(slotWidthInput),
                parseFloat(wireDepthInput),
                parseFloat(wireWidthInput),
                parseFloat(paperOverlapLengthInput),
                parseFloat(sfrInput),
                parseFloat(sfr1Input),
                parseFloat(sfr2Input),
                parseFloat(sfr3Input),
                parseFloat(sfr4Input),
                parseInt(numberOfLayersValue),
                parseFloat(wireInsulationThicknessValue),
                parseFloat(wireFilletRadiusValue)
            )
        }
    }

    // here we append users new option to dropdown options, then we call setParameters
    const addWireFilletRadiusOptions = (event) => {
        if (event.key === 'Enter') {
            const newOptions = wireFilletRadiusOptions.concat(parseFloat(wireFilletRadiusValue));
            const uniqueOptions = Array.from(new Set(newOptions));
            const sortedOptions = uniqueOptions.sort((a, b) => a - b);
            setWireFilletRadiusOptions(sortedOptions)
            setParameters(parseFloat(slotOpeningInput),
                parseFloat(toothTipInput),
                parseFloat(toothTipAngleInput),
                parseFloat(paperThicknessInput),
                parseFloat(paperToWireClearanceInput),
                parseFloat(wireToWireClearanceInput),
                parseFloat(slotDepthInput),
                parseFloat(slotWidthInput),
                parseFloat(wireDepthInput),
                parseFloat(wireWidthInput),
                parseFloat(paperOverlapLengthInput),
                parseFloat(sfrInput),
                parseFloat(sfr1Input),
                parseFloat(sfr2Input),
                parseFloat(sfr3Input),
                parseFloat(sfr4Input),
                parseInt(numberOfLayersValue),
                parseFloat(wireInsulationThicknessValue),
                parseFloat(wireFilletRadiusValue)
            )
        }
    }

    // we call setParameters after user select a dropdown value
    const numberOfLayersItemSelected = (event) => {
        setNumberOfLayersValue(event)
        setParameters(parseFloat(slotOpeningInput),
            parseFloat(toothTipInput),
            parseFloat(toothTipAngleInput),
            parseFloat(paperThicknessInput),
            parseFloat(paperToWireClearanceInput),
            parseFloat(wireToWireClearanceInput),
            parseFloat(slotDepthInput),
            parseFloat(slotWidthInput),
            parseFloat(wireDepthInput),
            parseFloat(wireWidthInput),
            parseFloat(paperOverlapLengthInput),
            parseFloat(sfrInput),
            parseFloat(sfr1Input),
            parseFloat(sfr2Input),
            parseFloat(sfr3Input),
            parseFloat(sfr4Input),
            parseInt(event),
            parseFloat(wireInsulationThicknessValue),
            parseFloat(wireFilletRadiusValue)
        )
    }

    // we call setParameters after user select a dropdown value
    const wireInsulationThicknessItemSelected = (event) => {
        setWireInsulationThicknessValue(event)
        setParameters(parseFloat(slotOpeningInput),
            parseFloat(toothTipInput),
            parseFloat(toothTipAngleInput),
            parseFloat(paperThicknessInput),
            parseFloat(paperToWireClearanceInput),
            parseFloat(wireToWireClearanceInput),
            parseFloat(slotDepthInput),
            parseFloat(slotWidthInput),
            parseFloat(wireDepthInput),
            parseFloat(wireWidthInput),
            parseFloat(paperOverlapLengthInput),
            parseFloat(sfrInput),
            parseFloat(sfr1Input),
            parseFloat(sfr2Input),
            parseFloat(sfr3Input),
            parseFloat(sfr4Input),
            parseInt(numberOfLayersValue),
            parseFloat(event),
            parseFloat(wireFilletRadiusValue)
        )
    }

    // we call setParameters after user select a dropdown value
    const wireFilletRadiusItemSelected = (event) => {
        setWireFilletRadiusValue(event)
        setParameters(parseFloat(slotOpeningInput),
            parseFloat(toothTipInput),
            parseFloat(toothTipAngleInput),
            parseFloat(paperThicknessInput),
            parseFloat(paperToWireClearanceInput),
            parseFloat(wireToWireClearanceInput),
            parseFloat(slotDepthInput),
            parseFloat(slotWidthInput),
            parseFloat(wireDepthInput),
            parseFloat(wireWidthInput),
            parseFloat(paperOverlapLengthInput),
            parseFloat(sfrInput),
            parseFloat(sfr1Input),
            parseFloat(sfr2Input),
            parseFloat(sfr3Input),
            parseFloat(sfr4Input),
            parseInt(numberOfLayersValue),
            parseFloat(wireInsulationThicknessValue),
            parseFloat(event)
        )
    }

    // this function add background to selected TowButtonInput
    const slotOrWireStyle = () => {
        if (slotOrWire === SLOT_OR_WIRE_EQUAL_SLOT) {
            return [towButtonEnableStyle, towButtonDisableStyle]
        }
        return [towButtonDisableStyle, towButtonEnableStyle]
    }
    // this function add background to selected TowButtonInput
    const autoOrManualStyle = () => {
        if (autoOrManual === AUTO_OR_MANUAL_AUTO) {
            return [towButtonEnableStyle, towButtonDisableStyle]
        }
        return [towButtonDisableStyle, towButtonEnableStyle]
    }
    // this function add background to selected TowButtonInput
    const sameOrDifferentStyle = () => {
        if (sameOrDifferent === SAME_OR_DIFFERENT_SAME) {
            return [towButtonEnableStyle, towButtonDisableStyle]
        }
        return [towButtonDisableStyle, towButtonEnableStyle]
    }
    // this function add background to selected TowButtonInput
    const leftOrRightStyle = () => {
        if (overlapLeftOrRight === PAPER_OVERLAP_LEFT) {
            return [towButtonEnableStyle, towButtonDisableStyle]
        }
        return [towButtonDisableStyle, towButtonEnableStyle]
    }
// this function add background to selected TowButtonInput
    const slotModeSemiOrNotStyle = () => {
        if (slotModeSemiOrNot === SLOT_MODE_SEMI) {
            return [towButtonEnableStyle, towButtonDisableStyle]
        }
        return [towButtonDisableStyle, towButtonEnableStyle]
    }

    // this function calculate slotDepth and slotWidth based on wireDepth and wireWidth
    // we use this function to calculate readOnly inputs
    function calculateSlotBasedOnWire() {
        const newSlotWidth = parseFloat(wireWidthInput) + (
            2 * (
                parseFloat(paperThicknessInput) +
                parseFloat(paperToWireClearanceInput)
            )
        )
        const toothTipGap = (
            (newSlotWidth - parseFloat(slotOpeningInput)) *
            Math.tan(Math.PI * parseFloat(toothTipAngleInput) / 180)
        ) / 2
        const newSlotDepth = (
            parseFloat(numberOfLayersValue) * parseFloat(wireDepthInput) +
            2 * parseFloat(paperToWireClearanceInput) +
            parseFloat(wireToWireClearanceInput) * (parseFloat(numberOfLayersValue) - 1) +
            3 * parseFloat(paperThicknessInput) +
            parseFloat(toothTipInput) +
            parseFloat(toothTipGap)
        );
        return [newSlotWidth, newSlotDepth]
    }

    // we calculate Paper Length readonly input
    function calculatePaperLength() {
        function calculatePaperLengthBasedOnWireOrSlot(slotWidth, slotDepth) {
            let toothTipGap = (
                (parseFloat(slotWidth) - parseFloat(slotOpeningInput)) *
                Math.tan(parseFloat(toothTipAngleInput) * Math.PI / 180)
            ) / 2;
            let lOut = parseFloat(slotDepth) - parseFloat(toothTipInput) - toothTipGap;
            let wOut = parseFloat(slotWidth);
            let outerPerimeter = wOut + lOut + (lOut - parseFloat(paperThicknessInput)) + wOut +
                parseFloat(paperOverlapLengthInput) - 4 * (2 - Math.PI / 2) * parseFloat(sfr1Input);
            if (sameOrDifferent === SAME_OR_DIFFERENT_SAME) {
                outerPerimeter = wOut + lOut + (lOut - parseFloat(paperThicknessInput)) + wOut +
                    parseFloat(paperOverlapLengthInput) - 4 * (2 - Math.PI / 2) * parseFloat(sfrInput);
            }
            let lInn = lOut - 2 * parseFloat(paperThicknessInput);
            let wInn = wOut - 2 * parseFloat(paperThicknessInput);
            let innerPerimeter = lInn + wInn + (lInn - parseFloat(paperThicknessInput)) + wInn +
                parseFloat(paperOverlapLengthInput) - 4 * (2 - Math.PI / 2) * (parseFloat(sfr1Input) - parseFloat(paperThicknessInput));
            if (sameOrDifferent === SAME_OR_DIFFERENT_SAME) {
                innerPerimeter = lInn + wInn + (lInn - parseFloat(paperThicknessInput)) + wInn +
                    parseFloat(paperOverlapLengthInput) - 4 * (2 - Math.PI / 2) * (parseFloat(sfrInput) - parseFloat(paperThicknessInput));
            }

            return (0.5 * (outerPerimeter + innerPerimeter)).toFixed(2);
        }
        if (slotOrWire === SLOT_OR_WIRE_EQUAL_SLOT) {
            return calculatePaperLengthBasedOnWireOrSlot(slotWidthInput, slotDepthInput)
        } else {
            const [newSlotWidth, newSlotDepth] = calculateSlotBasedOnWire()
            return calculatePaperLengthBasedOnWireOrSlot(newSlotWidth, newSlotDepth)
        }
    }

    // we calculate Core Back readonly input
    function calculateCoreBack() {
        if (slotOrWire === SLOT_OR_WIRE_EQUAL_SLOT) {
            return (stator_outer_diameter / 2
                - parseFloat(slotDepthInput)
                - stator_bore_diameter / 2).toFixed(2)
        } else {
            const [, newSlotDepth] = calculateSlotBasedOnWire()
            return (stator_outer_diameter / 2
                - parseFloat(newSlotDepth)
                - parseFloat(stator_bore_diameter) / 2).toFixed(2)
        }
    }

    // we calculate Slot Area readonly input
    function calculateSlotArea() {
        function calculateSlotAreaBasedOnWireOrSlot(slotWidth, slotDepth) {
            let tooth_tip_gap = (
                (parseFloat(slotWidth) - parseFloat(slotOpeningInput))
                * Math.tan(parseFloat(toothTipAngleInput) * Math.PI / 180)
            ) / 2
            let l_out = (
                parseFloat(slotDepth)
                - parseFloat(toothTipInput)
                - tooth_tip_gap
            )
            if (sameOrDifferent === SAME_OR_DIFFERENT_SAME) {
                return (l_out * parseFloat(slotWidth) - 4 * (parseFloat(sfrInput) ** 2) * (1 - (Math.PI / 4))).toFixed(2);
            }
            return (l_out * parseFloat(slotWidth) - 4 * (parseFloat(sfr1Input) ** 2) * (1 - (Math.PI / 4))).toFixed(2);
        }
        if (slotOrWire === SLOT_OR_WIRE_EQUAL_SLOT) {
            return calculateSlotAreaBasedOnWireOrSlot(slotWidthInput, slotDepthInput)
        } else {
            const [newSlotWidth, newSlotDepth] = calculateSlotBasedOnWire()
            return calculateSlotAreaBasedOnWireOrSlot(newSlotWidth, newSlotDepth)
        }
    }

    // we calculate Fill Factor readonly input
    function calculateFillFactor() {
        function calculateFillFactorBasedOnWireOrSlot(slotWidth, slotDepth) {
            let tooth_tip_angle_length = (
                (parseFloat(slotWidth) - parseFloat(slotOpeningInput)) / 2
            ) / Math.cos(parseFloat(toothTipAngleInput) * Math.PI / 180)

            let tooth_tip_gap = (
                (parseFloat(slotWidth) - parseFloat(slotOpeningInput))
                * Math.tan(parseFloat(toothTipAngleInput) * Math.PI / 180)
            ) / 2
            let l_out = (
                parseFloat(slotDepth)
                - parseFloat(toothTipInput)
                - tooth_tip_gap
            )
            let slot_area = l_out * parseFloat(slotWidth) - 4 * (parseFloat(sfr1Input) ** 2) * (1 - (Math.PI / 4));
            if (sameOrDifferent === SAME_OR_DIFFERENT_SAME) {
                slot_area = l_out * parseFloat(slotWidth) - 4 * (parseFloat(sfrInput) ** 2) * (1 - (Math.PI / 4));
            }
            let wire_width = (
                parseFloat(slotWidth) - (2 * (parseFloat(paperThicknessInput) + parseFloat(paperToWireClearanceInput)))
            ) - (2 * parseFloat(wireInsulationThicknessValue))

            let slot_depth_custom = parseFloat(slotDepth) - (
                parseFloat(toothTipInput)
                + (
                    Math.sin(parseFloat(toothTipAngleInput) * Math.PI / 180) * tooth_tip_angle_length
                )
            )
            let wire_height = (
                slot_depth_custom
                - 3 * parseFloat(paperThicknessInput)
                - parseFloat(wireToWireClearanceInput) * (parseFloat(numberOfLayersValue) - 1)
                - 2 * parseFloat(paperToWireClearanceInput)
                - (parseFloat(numberOfLayersValue) * 2 * parseFloat(wireInsulationThicknessValue))
            ) / parseFloat(numberOfLayersValue)
            let copper_area = wire_width * wire_height - 4 *
                (
                    parseFloat(wireFilletRadiusValue) - parseFloat(wireInsulationThicknessValue)
                ) ** 2 * (1 - (Math.PI / 4))
            // if (sameOrDifferent === SAME_OR_DIFFERENT_SAME) {
            //     copper_area = wire_width * wire_height - 4 * (parseFloat(wireFilletRadiusValue) -
            //         parseFloat(paperThicknessInput)) ** 2 * (1 - (Math.PI / 4))
            // }
            let total_copper_area = parseFloat(numberOfLayersValue) * copper_area
            return (total_copper_area / slot_area * 100).toFixed(2)
        }
        if (slotOrWire === SLOT_OR_WIRE_EQUAL_SLOT) {
            return calculateFillFactorBasedOnWireOrSlot(slotWidthInput, slotDepthInput)
        } else {
            const [newSlotWidth, newSlotDepth] = calculateSlotBasedOnWire()
            return calculateFillFactorBasedOnWireOrSlot(newSlotWidth, newSlotDepth)
        }
    }

    return (
        <>
            {validationError && <WarningDialog message={validationError} closeFunc={() => {
                setValidationError("")
            }}/>}
            <div className="title">
                <span>Properties</span>
                <div className="restore-default-component" onClick={() => {
                    // restore default change inputs and redux values based on values in src/data/statorInputs.js
                    dispatch(setStlParameters({
                        slotOrWire: SLOT_OR_WIRE_EQUAL_SLOT
                    }))
                    dispatch(setStlParameters({
                        autoOrManual: AUTO_OR_MANUAL_AUTO
                    }))
                    dispatch(setStlParameters({
                        sameOrDifferent: SAME_OR_DIFFERENT_SAME
                    }))
                    dispatch(setStlParameters({
                        overlapLeftOrRight: PAPER_OVERLAP_RIGHT
                    }))
                    dispatch(setStlParameters({
                        slotModeSemiOrNot: SLOT_MODE_SEMI
                    }))
                    // setParameters(statorDefaultValues[SLOT_OPENING_INPUT],
                    //     statorDefaultValues[TOOTH_TIP_INPUT],
                    //     statorDefaultValues[TOOTH_TIP_ANGLE_INPUT],
                    //     statorDefaultValues[PAPER_THICKNESS_INPUT],
                    //     statorDefaultValues[PAPER_TO_WIRE_CLEARANCE_INPUT],
                    //     statorDefaultValues[WIRE_TO_WIRE_CLEARANCE_INPUT],
                    //     statorDefaultValues[SLOT_DEPTH_INPUT],
                    //     statorDefaultValues[SLOT_WIDTH_INPUT],
                    //     statorDefaultValues[WIRE_DEPTH_INPUT],
                    //     statorDefaultValues[WIRE_WIDTH_INPUT],
                    //     // statorDefaultValues[WIRE_DEPTH_WITHOUT_INSULATION_INPUT],
                    //     // statorDefaultValues[WIRE_WIDTH_WITHOUT_INSULATION_INPUT],
                    //     statorDefaultValues[PAPER_OVERLAP_LENGTH_INPUT],
                    //     statorDefaultValues[SFR_INPUT],
                    //     statorDefaultValues[SFR1_INPUT],
                    //     statorDefaultValues[SFR2_INPUT],
                    //     statorDefaultValues[SFR3_INPUT],
                    //     statorDefaultValues[SFR4_INPUT],
                    //     statorDefaultValues[NUMBER_OF_LAYERS_INPUT],
                    //     windingSideDefaultValues[WIRE_INSULATION_THICKNESS_INPUT],
                    //     windingSideDefaultValues[WIRE_FILLET_RADIUS_INPUT]
                    // )
                    setSlotOpeningInput(statorDefaultValues[SLOT_OPENING_INPUT])
                    setToothTipInput(statorDefaultValues[TOOTH_TIP_INPUT])
                    setToothTipAngleInput(statorDefaultValues[TOOTH_TIP_ANGLE_INPUT])
                    setPaperThicknessInput(statorDefaultValues[PAPER_THICKNESS_INPUT])
                    setPaperToWireClearanceInput(statorDefaultValues[WIRE_TO_WIRE_CLEARANCE_INPUT])
                    setWireToWireClearanceInput(statorDefaultValues[WIRE_TO_WIRE_CLEARANCE_INPUT])
                    setSlotDepthInput(statorDefaultValues[SLOT_DEPTH_INPUT])
                    setSlotWidthInput(statorDefaultValues[SLOT_WIDTH_INPUT])
                    setWireDepthInput(statorDefaultValues[WIRE_DEPTH_INPUT])
                    setWireWidthInput(statorDefaultValues[WIRE_WIDTH_INPUT])
                    setWireDepthWithoutInsulationInput(statorDefaultValues[WIRE_DEPTH_WITHOUT_INSULATION_INPUT])
                    setWireWidthWithoutInsulationInput(statorDefaultValues[WIRE_WIDTH_WITHOUT_INSULATION_INPUT])
                    setPaperOverlapLengthInput(statorDefaultValues[PAPER_OVERLAP_LENGTH_INPUT])
                    setSfrInput(statorDefaultValues[SFR_INPUT])
                    setSfr1Input(statorDefaultValues[SFR1_INPUT])
                    setSfr1Input(statorDefaultValues[SFR2_INPUT])
                    setSfr1Input(statorDefaultValues[SFR3_INPUT])
                    setSfr1Input(statorDefaultValues[SFR4_INPUT])
                    setNumberOfLayersValue(statorDefaultValues[NUMBER_OF_LAYERS_INPUT])
                    setWireInsulationThicknessValue(windingSideDefaultValues[WIRE_INSULATION_THICKNESS_INPUT])
                    setWireFilletRadiusValue(windingSideDefaultValues[WIRE_FILLET_RADIUS_INPUT])
                }}>
                    <span className="restore-default">Restore Default</span>
                    <img src={restoreDefaultIcon} alt="restoreDefaultIcon"/>
                </div>
            </div>
            <div className="inputs">
                <form>
                    <TowButtonInput labelInput="Slot Type" firstButtonText="Semi-Open" secondButtonText="Open"
                                    firsButtonFunc={() => {
                                        dispatch(setStlParameters({
                                            slotModeSemiOrNot: SLOT_MODE_SEMI
                                        }))
                                    }}
                                    secondButtonFunc={() => {
                                        // dispatch(setStlParameters({
                                        //     slotModeSemiOrNot: SLOT_MODE_NOT_SEMI
                                        // }))
                                        toast.warning("This feature will be coming soon ...")
                                    }}
                                    getStyles={slotModeSemiOrNotStyle()}/>
                    <DropdownInput id={NUMBER_OF_LAYERS_INPUT} text="Number of Layers" value={numberOfLayersValue}
                                   inputType={'int'} unitText="No."
                                   onChangeFunc={setNumberOfLayersValue}
                                   itemSelectedFunc={numberOfLayersItemSelected}
                                   options={numberOfLayersOptions} addOptionFunc={addNumberOfLayersOption}
                                   gifFile={numberOfLayerGif}/>
                    <TextInput id={SLOT_OPENING_INPUT} text="Slot Opening" gifFile={slotOpeningGif}
                               value={slotOpeningInput} inputType={'float'} unitText="mm"
                               onChangeFunc={setSlotOpeningInput}
                               onEnterPressedFunc={handleOnEnterPressed}/>
                    <TextInput id={TOOTH_TIP_INPUT} text="Tooth Tip" gifFile={toothTipGif} value={toothTipInput}
                               inputType={'float'} unitText="mm"
                               onChangeFunc={setToothTipInput}
                               onEnterPressedFunc={handleOnEnterPressed}/>
                    <TowButtonInput labelInput="Slot Sizing" firstButtonText="Slot" secondButtonText="Wire"
                                    firsButtonFunc={() => {
                                        dispatch(setStlParameters({
                                            slotOrWire: SLOT_OR_WIRE_EQUAL_SLOT
                                        }))
                                    }}
                                    secondButtonFunc={() => {
                                        dispatch(setStlParameters({
                                            slotOrWire: SLOT_OR_WIRE_EQUAL_WIRE
                                        }))
                                    }}
                                    getStyles={slotOrWireStyle()}/>
                    {slotOrWire === 'slot' &&
                        <TextInput id={SLOT_DEPTH_INPUT} text="Slot Depth" gifFile={slotDepthGif} value={slotDepthInput}
                                   inputType={'float'} unitText="mm"
                                   onChangeFunc={setSlotDepthInput}
                                   onEnterPressedFunc={handleOnEnterPressed}/>}
                    {slotOrWire === 'slot' &&
                        <TextInput id={SLOT_WIDTH_INPUT} text="Slot Width" gifFile={slotWidthGif} value={slotWidthInput}
                                   inputType={'float'} unitText="mm"
                                   onChangeFunc={setSlotWidthInput}
                                   onEnterPressedFunc={handleOnEnterPressed}/>}
                    {slotOrWire === 'wire' &&
                        <TextInput id={WIRE_DEPTH_INPUT} text="Bare Wire Depth" value={wireDepthWithoutInsulationInput}
                                   inputType={'float'}
                                   onChangeFunc={setWireDepthWithoutInsulationInput} unitText="mm"
                                   onEnterPressedFunc={handleOnEnterPressed}/>}
                    {slotOrWire === 'wire' &&
                        <TextInput id={WIRE_WIDTH_INPUT} text="Bare Wire Width" value={wireWidthWithoutInsulationInput}
                                   inputType={'float'}
                                   onChangeFunc={setWireWidthWithoutInsulationInput} unitText="mm"
                                   onEnterPressedFunc={handleOnEnterPressed}/>}
                    <DropdownInput id={WIRE_INSULATION_THICKNESS_INPUT} text="Wire Insulation Thickness"
                                   value={wireInsulationThicknessValue} inputType={'float'}
                                   onChangeFunc={setWireInsulationThicknessValue}
                                   itemSelectedFunc={wireInsulationThicknessItemSelected}
                                   options={wireInsulationThicknessOptions} unitText="mm"
                                   addOptionFunc={addWireInsulationThicknessOption}
                                   gifFile={wireInsulationThicknessGif}/>
                    <DropdownInput id={WIRE_FILLET_RADIUS_INPUT} text="Wire Fillet Radius" value={wireFilletRadiusValue}
                                   inputType={'float'} unitText="mm"
                                   onChangeFunc={setWireFilletRadiusValue}
                                   itemSelectedFunc={wireFilletRadiusItemSelected}
                                   options={wireFilletRadiusOptions} addOptionFunc={addWireFilletRadiusOptions}
                                   gifFile={wireFilletRadiusGif}/>
                    <TextInput id="wire_depth_with_insulation" text="Wire Depth" value={wireDepthInput}
                               readOnly={true} unitText="mm"/>
                    <TextInput id="wire_width_with_insulation" text="Wire Width" value={wireWidthInput}
                               readOnly={true} unitText="mm"/>
                    <TextInput id={CORE_BACK_INPUT} text="Core Back" value={calculateCoreBack()}
                               readOnly={true} unitText="mm"/>
                    <TextInput id={PAPER_LENGTH_INPUT} text="Paper Length" value={calculatePaperLength()}
                               readOnly={true} unitText="mm"/>
                    <TextInput id={SLOT_AREA_INPUT} text="Slot Area" value={calculateSlotArea()}
                               readOnly={true} unitText="mm²"/>
                    <TextInput id={FILL_FACTOR_INPUT} text="Fill Factor" value={calculateFillFactor()}
                               readOnly={true} unitText="%"/>
                    <TextInput id={TOOTH_TIP_ANGLE_INPUT} text="Tooth Tip Angle" gifFile={toothTipAngleGif}
                               value={toothTipAngleInput} inputType={'float'} onChangeFunc={setToothTipAngleInput}
                               onEnterPressedFunc={handleOnEnterPressed} unitText="mm"/>
                    <TowButtonInput labelInput="Slot Fillet Sizing" firstButtonText="Same" secondButtonText="Different"
                                    firsButtonFunc={() => {
                                        dispatch(setStlParameters({
                                            sameOrDifferent: SAME_OR_DIFFERENT_SAME
                                        }))
                                    }}
                                    secondButtonFunc={() => {
                                        dispatch(setStlParameters({
                                            sameOrDifferent: SAME_OR_DIFFERENT_DIFFERENT
                                        }))
                                    }}
                                    getStyles={sameOrDifferentStyle()}/>
                    {sameOrDifferent === 'same' &&
                        <TextInput id={SFR_INPUT} text="Slot Fillet Radius" value={sfrInput} inputType={'float'}
                                   onChangeFunc={setSfrInput} unitText="mm"
                                   onEnterPressedFunc={handleOnEnterPressed}/>}
                    {sameOrDifferent !== 'same' &&
                        <TextInput id={SFR1_INPUT} text="Slot Fillet Radius 1" gifFile={sfr1Gif} value={sfr1Input}
                                   inputType={'float'}
                                   onChangeFunc={setSfr1Input} unitText="mm"
                                   onEnterPressedFunc={handleOnEnterPressed}/>}
                    {sameOrDifferent !== 'same' &&
                        <TextInput id={SFR2_INPUT} text="Slot Fillet Radius 2" gifFile={sfr2Gif} value={sfr2Input}
                                   inputType={'float'}
                                   onChangeFunc={setSfr2Input} unitText="mm"
                                   onEnterPressedFunc={handleOnEnterPressed}/>}
                    {sameOrDifferent !== 'same' &&
                        <TextInput id={SFR3_INPUT} text="Slot Fillet Radius 3" gifFile={sfr3Gif} value={sfr3Input}
                                   inputType={'float'}
                                   onChangeFunc={setSfr3Input} unitText="mm"
                                   onEnterPressedFunc={handleOnEnterPressed}/>}
                    {sameOrDifferent !== 'same' &&
                        <TextInput id={SFR4_INPUT} text="Slot Fillet Radius 4" gifFile={sfr4Gif} value={sfr4Input}
                                   inputType={'float'}
                                   onChangeFunc={setSfr4Input} unitText="mm"
                                   onEnterPressedFunc={handleOnEnterPressed}/>}
                    <TextInput id={PAPER_THICKNESS_INPUT} text="Paper Thickness" value={paperThicknessInput}
                               inputType={'float'} unitText="mm"
                               onChangeFunc={setPaperThicknessInput} onEnterPressedFunc={handleOnEnterPressed}/>
                    <TowButtonInput labelInput="Paper Overlap Side" firstButtonText="Left" secondButtonText="Right"
                                    firsButtonFunc={() => {
                                        dispatch(setStlParameters({
                                            overlapLeftOrRight: PAPER_OVERLAP_LEFT
                                        }))
                                        overlapRightAndLeftButtonChanged()
                                    }}
                                    secondButtonFunc={() => {
                                        dispatch(setStlParameters({
                                            overlapLeftOrRight: PAPER_OVERLAP_RIGHT
                                        }))
                                        overlapRightAndLeftButtonChanged()
                                    }}
                                    getStyles={leftOrRightStyle()}/>
                    <TowButtonInput labelInput="Paper Overlap" firstButtonText="Auto" secondButtonText="Manual"
                                    firsButtonFunc={() => {
                                        dispatch(setStlParameters({
                                            autoOrManual: AUTO_OR_MANUAL_AUTO
                                        }))
                                    }}
                                    secondButtonFunc={() => {
                                        dispatch(setStlParameters({
                                            autoOrManual: AUTO_OR_MANUAL_MANUAL
                                        }))
                                    }}
                                    getStyles={autoOrManualStyle()}/>
                    {autoOrManual !== 'auto' &&
                        <TextInput id={PAPER_OVERLAP_LENGTH_INPUT} text="Paper Overlap Length"
                                   value={paperOverlapLengthInput} inputType={'float'}
                                   onChangeFunc={setPaperOverlapLengthInput} unitText="mm"
                                   onEnterPressedFunc={handleOnEnterPressed}/>}
                    <TextInput id={PAPER_TO_WIRE_CLEARANCE_INPUT} text="Paper to Wire Clearance"
                               value={paperToWireClearanceInput} inputType={'float'} unitText="mm"
                               onChangeFunc={setPaperToWireClearanceInput}
                               onEnterPressedFunc={handleOnEnterPressed}/>
                    <TextInput id={WIRE_TO_WIRE_CLEARANCE_INPUT} text="Wire to Wire Clearance"
                               value={wireToWireClearanceInput} inputType={'float'} unitText="mm"
                               onChangeFunc={setWireToWireClearanceInput}
                               onEnterPressedFunc={handleOnEnterPressed}/>
                </form>
            </div>
        </>
    );
};

export default StatorSlotDesignInputs;

import styled from "styled-components";
import {useDispatch} from "react-redux";
import {changeTagSideParameters, closeBusbarsDialog, setTagSide} from "../../../features/connectionTableSlice";
import {useState} from "react";

const BusbarsSide = () => {
    const dispatch = useDispatch();
    const [isDragging, setIsDragging] = useState(false);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });

    // when drag started
    const handleDragStart = (e) => {
        setIsDragging(true);
        setStartPosition({
            x: e.clientX - position.x,
            y: e.clientY - position.y,
        });
        // e.dataTransfer.effectAllowed = 'move';
        e.dataTransfer.setData('text/plain', null); // for Firefox compatibility
        // e.preventDefault();
        e.dataTransfer.setData('text/plain', '');

        // Hide the default ghost image
        const transparentImg = new Image();
        transparentImg.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
        e.dataTransfer.setDragImage(transparentImg, 0, 0);
    };

    // when drag is working
    const handleDragOver = (e) => {
        e.preventDefault();
        if (isDragging) {
            const newX = e.clientX - startPosition.x;
            const newY = e.clientY - startPosition.y;
            setPosition({ x: newX, y: newY });
        }
    };

    // when drag ends
    const handleDragEnd = () => {
        setIsDragging(false);
    };

    return (
        <>
            <div className="modal-container-background"/>
            <div className="modal-container"
                 style={{
                     transform: `translate(${position.x}px, ${position.y}px)`
                 }}
                 draggable="true"
                 onDragStart={handleDragStart}
                 onDragOver={handleDragOver}
                 onDragEnd={handleDragEnd}
            >
                <StyledWrapper>
                    <div className="busbars-side-title">
                        <span>SELECT BUSBARS SIDE</span>
                    </div>
                    <div className="message-box">
                        <span>Select the side to includes busbars?</span>
                        <div className="buttons">
                            <button className="welding-side-button" onClick={() => {
                                dispatch(setTagSide({tagSide: "welding"}))
                                dispatch(changeTagSideParameters({tagSide: "welding"}))
                                dispatch(closeBusbarsDialog())
                            }}>Welding Side
                            </button>
                            <button className="forming-side-button" onClick={() => {
                                dispatch(setTagSide({tagSide: "forming"}))
                                dispatch(changeTagSideParameters({tagSide: "forming"}))
                                dispatch(closeBusbarsDialog())
                            }}>Forming Side
                            </button>
                        </div>
                    </div>
                </StyledWrapper>
            </div>
        </>
    );
};

const StyledWrapper = styled.div`
    width: 30vw;
    color: var(--clr-grey-9);

    .busbars-side-title {
        background: var(--clr-grey-1);
        height: 5vh;
        padding-right: 1vw;
        padding-left: 1vw;
        display: flex;
        flex-direction: row;
        align-items: center;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
        justify-content: space-between;

        span {
            font-size: var(--font-size-1);
        }
    }

    .message-box {
        padding: 1vh 1vw;
        background: var(--clr-grey-3);
        display: flex;
        flex-direction: column;
        justify-content: left;
        height: 100%;
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;

        span {
            text-align: left;
            font-size: var(--font-size-2);
            line-height: 30px;
        }

        .buttons {
            display: flex;
            flex-direction: row;
            justify-content: right;
            height: 100%;

            button {
                align-self: flex-end;
                width: 8vw;
                border-radius: 6px;
                color: var(--clr-grey-9);
                font-size: var(--font-size-2);
                cursor: pointer;
            }

            .welding-side-button {
                background: none;
                height: 4vh;
                border: solid 1px var(--clr-grey-6);
                margin-right: 0.5vw;

                &:hover {
                    font-family: Ubuntu-Bold, serif;
                }
            }

            .forming-side-button {
                background: var(--clr-primary);
                border: none;
                height: 4vh;

                &:hover {
                    font-family: Ubuntu-Bold, serif;
                }
            }
        }
    }
`

export default BusbarsSide;
import styled from "styled-components";

const TowButtonInput = ({labelInput, firstButtonText, secondButtonText, firsButtonFunc, secondButtonFunc, getStyles}) => {
    return (
        <StyledWrapper>
            <div className='tow-button-field'>
                <span>{labelInput}</span>
                <button className="first-button" type="button" style={getStyles[0]}
                        // onClick={firstButtonFunc}
                        onClick={firsButtonFunc}
                >{firstButtonText}
                </button>
                <button className="second-button" type="button" style={getStyles[1]}
                        // onClick={secondButtonFunc}
                        onClick={secondButtonFunc}
                >{secondButtonText}
                </button>
            </div>
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    .tow-button-field {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: end;
        margin-bottom: 0.9vh;
    }

    span {
        margin-right: 0.5vw;
        font-size: var(--font-size-5);
    }

    button {
        font-size: var(--font-size-5);
        width: 5.25vw;
        height: 2.3vh;
        border: none;
        cursor: pointer;
    }

    .first-button {
        border-right: solid 1px var(--clr-grey-2);
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    .second-button {
        border-left: solid 1px var(--clr-grey-2);
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }
`

export default TowButtonInput;

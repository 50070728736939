import styled from "styled-components";
import {closedChildren, openedChildren} from "../../../icons/inputLayout";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    changeSelectedParentCoil,
    changeTagFormingSideSingleCoil,
} from "../../../features/formingSideCoilsSlice";
import SegmentBox from "./SegmentBox";
import {COIL_NAME, FORMING_HEIGHT} from "../../../data/connectionTable";

const CoilTreeBox = ({coilData}) => {
    const {
        coil_name: coilName,
        starting_slot: startingSlot,
        starting_layer: startingLayer,
        ending_slot: endingSlot,
        ending_layer: endingLayer,
        segment5_control_points: numberOfSegment5Points,
        forming_height: formingHeight,
        segment3_rotation: segment3Rotation,
        segment7_rotation: segment7Rotation,
    } = coilData


    const {selectedParentName} = useSelector((store) => store.formingSideCoils);
    const dispatch = useDispatch()
    const [openOperations, setOpenOperations] = useState(false);
    const [localFormingHeight, setLocalFormingHeight] = useState(formingHeight);


    // here we get control points and get them to SegmentBox to show them
    const {
        // segment2_control_points: formingBSplineSegment2,
        segment3_control_points: formingBSplineSegment3,
        // segment4_control_points: formingBSplineSegment4,
        segment5_control_points: formingBSplineSegment5,
        // segment6_control_points: formingBSplineSegment6,
        segment7_control_points: formingBSplineSegment7,
        // segment8_control_points: formingBSplineSegment8,
    } = coilData["segment_points"]


    // here we check which coil selected and change its background style
    const isParentSelected = (coilName) => {
        if (coilName === selectedParentName) {
            return {background: 'var(--clr-primary-dark)'}
        }
        return {background: '#494a4f'}
    }

    useEffect(() => {
        setLocalFormingHeight(formingHeight)
    }, [formingHeight])

    return (
        <StyledWrapper>
            <div className="parent" style={isParentSelected(coilName)} onClick={() => {
                dispatch(changeSelectedParentCoil({selectedParentName: coilName}))
            }}>
                <div className="coil-name">
                    {openOperations ?
                        <img className="clickable" src={openedChildren} alt="openedChildren" onClick={() => {
                            setOpenOperations(false)
                        }}/> :
                        <img className="clickable" src={closedChildren} alt="closedChildren"
                             onClick={() => {
                                 setOpenOperations(true)
                             }}/>}
                    <span>{"Coil " + coilName}</span>
                </div>
                <div className="boxes">
                    <div className="height-input">
                        <input
                            type="text"
                            value={localFormingHeight}
                            onChange={(e) => setLocalFormingHeight(e.target.value)}
                            onBlur={(e) => {
                                const value = e.target.value
                                const finalValue = value === "" ? 0 : parseFloat(value)
                                dispatch(changeTagFormingSideSingleCoil({
                                    [COIL_NAME]: selectedParentName,
                                    type: FORMING_HEIGHT,
                                    value: finalValue
                                }))
                            }}
                            readOnly={coilName !== selectedParentName}
                        />
                    </div>
                    <div className="starting-box">
                        <span>{startingSlot} : {startingLayer}</span>
                    </div>
                    <div className="ending-box">
                        <span>{endingSlot} : {endingLayer}</span>
                    </div>
                </div>
            </div>
            <div className="detail-boxes">
                {openOperations && <div>
                    {/*<SegmentBox labelIndex={2} formingBSplineSegment={formingBSplineSegment2}*/}
                    {/*            formingBSplineSegmentText={"segment2_control_points"} readOnly={true}/>*/}
                    <SegmentBox labelIndex={3} formingBSplineSegment={formingBSplineSegment3}
                                formingBSplinePreviousSegmentText={"segment2_control_points"}
                                formingBSplineSegmentText={"segment3_control_points"}
                                formingBSplineNextSegmentText={"segment4_control_points"}
                                numberOfSegment5Points={numberOfSegment5Points}
                                selectedParentName={selectedParentName}
                                segment3Rotation={segment3Rotation}
                                segment7Rotation={segment7Rotation}
                                readOnly={coilName !== selectedParentName}/>
                    <SegmentBox labelIndex={5} formingBSplineSegment={formingBSplineSegment5}
                                formingBSplinePreviousSegmentText={"segment4_control_points"}
                                formingBSplineSegmentText={"segment5_control_points"}
                                formingBSplineNextSegmentText={"segment6_control_points"}
                                numberOfSegment5Points={numberOfSegment5Points}
                                selectedParentName={selectedParentName}
                                segment3Rotation={segment3Rotation}
                                segment7Rotation={segment7Rotation}
                                readOnly={coilName !== selectedParentName}/>
                    <SegmentBox labelIndex={7} formingBSplineSegment={formingBSplineSegment7}
                                formingBSplinePreviousSegmentText={"segment6_control_points"}
                                formingBSplineSegmentText={"segment7_control_points"}
                                formingBSplineNextSegmentText={"segment8_control_points"}
                                numberOfSegment5Points={numberOfSegment5Points}
                                selectedParentName={selectedParentName}
                                segment3Rotation={segment3Rotation}
                                segment7Rotation={segment7Rotation}
                                readOnly={coilName !== selectedParentName}/>
                </div>}


            </div>
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    .parent {
        margin-top: 2px;
        margin-bottom: 2px;
        padding-right: 1vw;
        padding-left: 1vw;
        height: 5vh;
        border-radius: 7px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        .coil-name {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: left;
            width: 4vw;

            span {
                margin-left: 0.5vw;
                font-size: var(--font-size-2);
                font-family: Ubuntu-Light, serif;
            }
        }

        .boxes {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 13vw;
        }
    }


    .clickable {
        cursor: pointer;
    }


    img {
        width: 1vh;
        height: 1vh;
    }

    .height-input {
        width: 4.5vw;
        padding-left: 1vh;

        input {
            width: 2.5vw;
            padding: 0.1vh 0.25vw;
            background: var(--clr-grey-1);
            height: 2vh;
            text-align: center;
            border-radius: 4px;
            font-size: var(--font-size-6);
            font-family: Ubuntu-Light, serif;
            background: var(--clr-grey-1);
            color: var(--clr-grey-9);
            border: none;
            outline: none;
        }
    }

    .starting-box {
        font-family: Ubuntu-Light, serif;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: var(--clr-green);
        font-size: var(--font-size-3);
        width: 3vw;
        height: 1.8vh;
        margin-left: 1vw;
        border-radius: 5px;
    }

    .ending-box {
        font-family: Ubuntu-Light, serif;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: var(--clr-pink-dark);
        font-size: var(--font-size-3);
        width: 3vw;
        height: 1.8vh;
        border-radius: 5px;
    }

    .detail-boxes {
        margin-left: 1vw;
        border-radius: 7px;

        .coil-name {
            background: #494a4f;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            height: 2vh;
            padding: 1vh 1vw;
            border-top-right-radius: 7px;
            border-top-left-radius: 7px;

            span {
                font-size: var(--font-size-2);
                font-family: Ubuntu-Light, serif;
                margin-left: 0.5vw;
            }

            .extrude-label {
                color: var(--clr-orange);
                font-size: var(--font-size-2);
                font-family: Ubuntu-Light, serif;
            }

            .revolve-label {
                color: var(--clr-pink-dark);
                font-size: var(--font-size-2);
                font-family: Ubuntu-Light, serif;
            }

            //.rotation-line {
            //    display: flex;
            //    flex-direction: row;
            //    align-items: center;
            //    width: 3vw;
            //    justify-content: space-between;
            //    
            //    span {
            //        font-size: var(--font-size-6);
            //    }
            //    input {
            //        background: var(--clr-grey-1);
            //        color: var(--clr-grey-9);
            //        border: none;
            //        outline: none;
            //        width: 3vw;
            //        height: 2vh;
            //        padding: 0.1vh 0.25vw;
            //        text-align: center;
            //        border-radius: 4px;
            //        font-size: var(--font-size-6);
            //        font-family: Ubuntu-Light, serif;
            //    }
            //}
        }

        .input-lines {
            background: #3a3b3f;
            padding: 1vh 0.5vw 1vh 1vw;
            border-bottom-right-radius: 7px;
            border-bottom-left-radius: 7px;
            margin-bottom: 2px;

            .input-line {
                margin-top: 0.5vh;
                margin-bottom: 0.5vh;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                font-size: var(--font-size-4);
                font-family: Ubuntu-Light, serif;


                .line-inputs {
                    width: 15vw;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;

                    input {
                        background: var(--clr-grey-1);
                        color: var(--clr-grey-9);
                        border: none;
                        outline: none;
                        width: 4vw;
                        height: 2vh;
                        padding: 0.1vh 0.25vw;
                        text-align: center;
                        border-radius: 4px;
                        font-size: var(--font-size-6);
                        font-family: Ubuntu-Light, serif;
                    }

                    .checkbox-input {
                        accent-color: var(--clr-primary-light);
                        cursor: pointer;

                        &:checked {
                            background: red;
                        }
                    }
                }
            }
        }

        .border-line {
            margin-top: 1vh;
            margin-left: 1vw;
            margin-right: 1vw;
            width: auto;
            height: 1px;
            background: var(--clr-grey-7);
            align-items: center;
            border-radius: 2px;
            justify-content: space-between;
        }

        .rotation-line {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-top: 1vh;
            font-size: var(--font-size-4);

            input {
                background: var(--clr-grey-1);
                color: var(--clr-grey-9);
                border: none;
                outline: none;
                width: 14.5vw;
                height: 2vh;
                padding: 0.1vh 0.25vw;
                text-align: left;
                border-radius: 4px;
                font-size: var(--font-size-6);
                font-family: Ubuntu-Light, serif;
            }

            select {
                background: var(--clr-grey-1);
                color: var(--clr-grey-9);
                border: none;
                outline: none;
                width: 15vw;
                height: 2vh;
                padding: 0.1vh 0.25vw;
                text-align: left;
                border-radius: 4px;
                font-size: var(--font-size-6);
                font-family: Ubuntu-Light, serif;
            }
        }
    }
`


export default CoilTreeBox;

import styled from "styled-components";
import {
    FormingExport,
    MainButtons,
    WeldingExport,
    DownloadHistoryWindow,
    PaperExport,
    StatorExport,
    AssemblyExport
} from "./exportDialogComponents";
import {
    ASSEMBLY_EXPORT,
    FORMING_EXPORT,
    PAPER_EXPORT,
    STATOR_EXPORT,
    WELDING_EXPORT
} from "../../data/exportStates";
import {useEffect, useState} from "react";
import {exportGetFormingData, getCurrentTime} from "../../features/exportSlice";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";

const ExportDialog = ({closeFunc}) => {
    const dispatch = useDispatch();
    const [currentState, setCurrentState] = useState(STATOR_EXPORT)
    const {formingCoilsData} = useSelector((store) => store.export);

    const validateCurrentState = (newState) => {
        if (newState === FORMING_EXPORT && !formingCoilsData.length) {
            toast.error("You should add at least one forming coil first")
        }
        else {
            setCurrentState(newState)
        }
    }

    const generateInputs = () => {
        if (currentState === STATOR_EXPORT) {
            return <StatorExport closeFunc={closeFunc}/>
        }
        if (currentState === WELDING_EXPORT) {
            return <WeldingExport closeFunc={closeFunc}/>
        }
        if (currentState === FORMING_EXPORT) {
            return <FormingExport closeFunc={closeFunc}/>
        }
        if (currentState === ASSEMBLY_EXPORT) {
            return <AssemblyExport closeFunc={closeFunc}/>
        }
        if (currentState === PAPER_EXPORT) {
            return <PaperExport closeFunc={closeFunc}/>
        }
    }

    useEffect(() => {
        dispatch(getCurrentTime())
        dispatch(exportGetFormingData())
    }, [dispatch])

    return (
        <>
            <div className="modal-container-background"/>
            <div className="modal-container">
                <StyledWrapper>
                    <div className="close-btn">
                        <div className="dialog-title">
                            <span className="restore-default">EXPORT MANAGER</span>
                        </div>
                    </div>
                    <MainButtons currentState={currentState} changeCurrentStateFunc={validateCurrentState}/>
                    <div className="all-inputs">
                        {generateInputs()}
                    </div>
                    <DownloadHistoryWindow currentState={currentState}/>
                </StyledWrapper>
            </div>
        </>
    );
};

const StyledWrapper = styled.div`
    width: 50vw;
    height: 80vh;
    border-radius: 10px;
    background: var(--clr-grey-2);

    .close-btn {
        background: var(--clr-grey-1);
        height: 4vh;
        padding-right: 1vw;
        padding-left: 1vw;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        color: var(--clr-grey-9);

        .dialog-title {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: left;

            span {
                //margin-left: 0.25vw;
                font-family: Ubuntu-Light, serif;
                font-size: var(--font-size-2);
                padding-bottom: 0.2vh;
                font-weight: bold;
            }

            .title-icon {
                width: 2vh;
                height: 2vh;
            }
        }


        .close-icon {
            width: 1.5vh;
            height: 1.5vh;
            border: solid 1px var(--clr-grey-5);
            margin-left: 10vw;
            cursor: pointer;
        }
    }

    .all-inputs {
        height: 38vh;

        .input-part {
            height: 32vh;
        }
        
        .buttons-part {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }
        
        .generate-button {
            margin-top: 0.5vh;
            width: 20%;
            height: 4vh;
            border: none;
            color: var(--clr-grey-9);
            background: var(--clr-primary);
            border-radius: 6px;
            cursor: pointer;
        }
        
        .exit-button {
            margin-top: 0.5vh;
            width: 20%;
            height: 4vh;
            background: var(--clr-grey-3);
            border-radius: 6px;
            cursor: pointer;
            background: var(--clr-grey-2);
            color: var(--clr-grey-9);
            border: solid 1px var(--clr-grey-5);
        }
    }
`

export default ExportDialog;
import {Navigate, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {checkCurrentProject} from "../features/projectsSlice";

const PrivateRoute = ({children}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {userToken, username} = useSelector((store) => store.user);

    // here we check current project of user, if there is no current project, we send user to projects page
    useEffect(() => {
        dispatch(checkCurrentProject()).then(response => {
            if (response.error) {
                navigate('/projects')
            }
        })
    }, [dispatch, navigate])

    // here we check user token, if there is no token, we send user to login page
    if (!userToken || !username) {
        return <Navigate to='/login'/>
    }
    return children
};

export default PrivateRoute
import styled from 'styled-components'
import {stator, windingSide, connectionTable, formingSide, optimisation} from "../icons/navbar";
import {appLogo} from "../icons/global";
import {useDispatch, useSelector} from "react-redux";
import {changeNavbarState} from "../features/globalSlice";
import {
    NAVBAR_STATOR,
    NAVBAR_WINDING_SIDE,
    NAVBAR_CONNECTION_TABLE,
    NAVBAR_FORMING_SIDE,
    NAVBAR_OPTIMISATION
} from "../data/states";
import {changeViewerState} from "../features/viewerSlice";
import {VIEWER_STATE_STANDARD} from "../data/Viewer";
import {toast} from "react-toastify";

const NavBar = () => {
    const dispatch = useDispatch();
    const {navbarState} = useSelector((store) => store.globalItems);

    // style the navbar icons based on navbarState
    const checkNavbarState = (fieldState) => {
        if (fieldState === navbarState) {
            return {background: "var(--clr-primary-light)", border: "solid 1px var(--clr-primary-light)"}
        }
        return {}
    }
    return (
        <StyledWrapper>
            <img src={appLogo} alt="appLogo" className="logo"/>
            <div>
                <span>COILWORKS</span>
            </div>
            <div className="buttons">
                <img src={stator} alt="stator"
                     title="Stator"
                     style={checkNavbarState(NAVBAR_STATOR)}
                     onClick={() => {
                         dispatch(changeViewerState({viewerState: VIEWER_STATE_STANDARD}))
                         dispatch(changeNavbarState({navbarState: NAVBAR_STATOR}));
                     }}/>
                <img src={windingSide} alt="windingSide"
                     title="Winding Side"
                     style={checkNavbarState(NAVBAR_WINDING_SIDE)}
                     onClick={() => {
                         dispatch(changeViewerState({viewerState: VIEWER_STATE_STANDARD}))
                         dispatch(changeNavbarState({navbarState: NAVBAR_WINDING_SIDE}));
                     }}/>
                <img src={connectionTable} alt="connectionTable"
                     title="Connection Table"
                     style={checkNavbarState(NAVBAR_CONNECTION_TABLE)}
                     onClick={() => {
                         dispatch(changeNavbarState({navbarState: NAVBAR_CONNECTION_TABLE}));
                     }}/>
                <img src={formingSide} alt="formingSide"
                     title="Forming Side"
                     style={checkNavbarState(NAVBAR_FORMING_SIDE)}
                     onClick={() => {
                         dispatch(changeViewerState({viewerState: VIEWER_STATE_STANDARD}))
                         dispatch(changeNavbarState({navbarState: NAVBAR_FORMING_SIDE}));
                     }}/>
                <img src={optimisation} alt="optimisation"
                     title="Optimisation"
                     style={checkNavbarState(NAVBAR_OPTIMISATION)}
                     onClick={() => {
                         toast.warning("This feature will be coming soon ...")
                         // dispatch(changeNavbarState({navbarState: NAVBAR_OPTIMISATION}));
                     }}/>
            </div>
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    background: var(--clr-grey-4);
    text-align: center;
    justify-items: center;
    width: 5vw;

    img {
        width: 5.5vh;
        height: 5.5vh;
    }

    span {
        color: var(--clr-grey-9);
        font-size: var(--font-size-6);
    }

    .logo {
        padding-top: 3.7vh;
        padding-bottom: 0.45vh;
    }

    .buttons {
        padding-top: 10.2vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;

        img {
            width: 3.7vh;
            height: 3.7vh;
            border: solid 2px var(--clr-grey-6);
            border-radius: 30%;
            padding: 0.9vh;
            margin-bottom: 2.3vh;
            cursor: pointer;
        }
    }
`
export default NavBar;

import styled from "styled-components";
import {RadioInput} from "./index";
import {useState} from "react";
import {useDispatch} from "react-redux";
import {exportStator} from "../../../features/exportSlice";


const StatorExport = ({closeFunc}) => {
    const dispatch = useDispatch();
    const [optionState, setOptionState] = useState("lamination")

    const optionsList = [{
        title: "Lamination",
        value: "lamination",
    }, {
        title: "Stator With Number",
        value: "stator_w_number",
    }, {
        title: "Stator Without Number",
        value: "stator_n_number",
    }]

    return (
        <StyledWrapper>
            <div className="input-part">
                <p>Please select an option: </p>
                <RadioInput name="options" optionsList={optionsList} optionState={optionState}
                            setOptionState={setOptionState}/>
            </div>
            <div className="buttons-part">
                <button className="generate-button" onClick={() => {
                    dispatch(exportStator({
                        exportWireType: optionState,
                        exportExtensionType: "step"
                    }))
                }}
                >Export
                </button>
                <button className="exit-button" onClick={() => {
                    closeFunc()
                }}
                >Exit
                </button>
            </div>
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    padding: 2vh 1vw;

    p {
        color: var(--clr-grey-8);
        margin-bottom: 2vh;
    }

    .radio-description {
        margin-top: -1.5vh;
        font-size: var(--font-size-5);
        color: var(--clr-grey-8);
    }
`

export default StatorExport;
import styled from 'styled-components'
import {clearLogIcon, consoleIcon} from "../icons/consolebar";
import {closeBlock} from "../icons/global";
import {useDispatch, useSelector} from "react-redux";
import {
    changeConsoleState,
    changeConsoleType,
    deleteAllLogs,
    hideConsoleWindow
} from "../features/consoleLogSlice";
import {
    CONSOLE_MESSAGE,
    CONSOLE_MESSAGE_TYPE,
    CONSOLE_MESSAGE_TYPE_ERROR,
    CONSOLE_MESSAGE_TYPE_OPT_INFO,
    CONSOLE_MESSAGE_TYPE_SUCCESS,
    CONSOLE_STATE_ALL_LOGS,
    CONSOLE_STATE_CONSOLE_LOGS,
    CONSOLE_STATE_OPTIMISATION_LOGS,
    CONSOLE_TYPE_ALL,
    CONSOLE_TYPE_ERROR,
    CONSOLE_TYPE_SUCCESS
} from "../data/console";

const ConsoleWindow = () => {
    const dispatch = useDispatch()
    const {consoleState, consoleType, consoleLog} = useSelector((store) => store.consoleLog);

    // style ConsoleWindow buttons based on fieldState
    const checkInputState = (fieldState) => {
        if (fieldState === consoleState) {
            return {background: "var(--clr-primary-dark)"}
        }
        return {background: "var(--clr-grey-5)"}
    }

    // this function filter logs based on consoleState and consoleType
    const filterLogs = (log) => {
        if (consoleState === CONSOLE_STATE_ALL_LOGS || consoleState === CONSOLE_STATE_CONSOLE_LOGS) {
            if (consoleType === CONSOLE_TYPE_ALL) {
                return log[CONSOLE_MESSAGE_TYPE] !== CONSOLE_MESSAGE_TYPE_OPT_INFO;
            }
            if (consoleType === CONSOLE_TYPE_SUCCESS) {
                return log[CONSOLE_MESSAGE_TYPE] === CONSOLE_MESSAGE_TYPE_SUCCESS;
            }
            if (consoleType === CONSOLE_TYPE_ERROR) {
                return log[CONSOLE_MESSAGE_TYPE] === CONSOLE_MESSAGE_TYPE_ERROR;
            }
        } else if (consoleState === CONSOLE_STATE_OPTIMISATION_LOGS) {
            return log[CONSOLE_MESSAGE_TYPE] === CONSOLE_MESSAGE_TYPE_OPT_INFO;
        }
    }

    return (
        <StyledWrapper>
            <div className="top-bar">
                <div className="header">
                    <div>
                        <img src={consoleIcon} alt="consoleIcon"/>
                        <span>CONSOLE</span>
                    </div>
                    <img className="close-btn" src={closeBlock} alt="closeBlock" onClick={() => {
                        dispatch(hideConsoleWindow());
                    }}/>
                </div>
                <div className="buttons">
                    <div className="console-mode-buttons">
                        <button className="optimisation-option-btn" style={checkInputState(CONSOLE_STATE_ALL_LOGS)}
                                onClick={() => {
                                    dispatch(changeConsoleState({consoleState: CONSOLE_STATE_ALL_LOGS}))
                                }}>All Log
                        </button>
                        <button className="animation-option-btn" style={checkInputState(CONSOLE_STATE_CONSOLE_LOGS)}
                                onClick={() => {
                                    dispatch(changeConsoleState({consoleState: CONSOLE_STATE_CONSOLE_LOGS}))
                                }}>Console Log
                        </button>
                        <button className="animation-option-btn"
                                style={checkInputState(CONSOLE_STATE_OPTIMISATION_LOGS)}
                                onClick={() => {
                                    dispatch(changeConsoleState({consoleState: CONSOLE_STATE_OPTIMISATION_LOGS}))
                                }}>Optimisation Log
                        </button>
                    </div>
                    <div className="select-type">
                        <select onChange={(e) => {
                            dispatch(changeConsoleType({consoleType: e.target.value}))
                        }} value={consoleType}>
                            <option value={CONSOLE_TYPE_ALL}>All</option>
                            <option value={CONSOLE_TYPE_SUCCESS}>Success</option>
                            <option value={CONSOLE_TYPE_ERROR}>Error</option>
                        </select>
                    </div>
                </div>
                <div className="title">
                    <span>{consoleState}</span>
                    <img src={clearLogIcon} alt="clearLogIcon" onClick={() => dispatch(deleteAllLogs())}/>
                </div>
            </div>
            <div className="log-window">
                {
                    consoleLog.map((log, index) => (
                    filterLogs(log) && <p key={index}>{log[CONSOLE_MESSAGE]}</p>
                ))}
            </div>
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    position: fixed;
    bottom: calc(3vh + 2px);
    left: calc(5vw);
    width: 40vw;
    height: 40vh;
    border: solid 1px var(--clr-grey-5);

    .top-bar {
        background: var(--clr-grey-4);
        padding: 1vh;
        height: 9vh;

        .header {
            text-align: left;
            display: flex;
            justify-content: space-between;

            span {
                vertical-align: top;
                margin-left: 0.25vw;
                font-size: 14px;
                color: var(--clr-grey-9);
                font-weight: bold;
            }

            img {
                width: 0.9vw;
                height: 1.7vh;
            }

            .close-btn {
                cursor: pointer;
            }
        }

        .buttons {
            display: flex;
            justify-content: space-between;

            .console-mode-buttons {
                button {
                    margin-top: 1vh;
                    margin-right: 0.5vw;
                    padding-left: 1vw;
                    padding-right: 1vw;
                    height: 3vh;
                    border: none;
                    border-radius: 6px;
                    color: var(--clr-grey-9);
                    cursor: pointer;
                }
            }

            .select-type {
                select {
                    margin-top: 1vh;
                    padding-left: 0.5vw;
                    width: 15vh;
                    height: 3vh;
                    background-color: var(--clr-grey-5);
                    border: none;
                    border-radius: 6px;
                    color: var(--clr-grey-9);
                    cursor: pointer;
                }
            }
        }

        .title {
            margin-top: 1vh;
            display: flex;
            justify-content: space-between;

            span {
                color: var(--clr-grey-7);
                font-size: 14px;
            }

            img {
                height: 16px;
                cursor: pointer;
            }
        }
    }

    .log-window {
        background: var(--clr-grey-1);
        height: 27vh;
        padding: 1vh;
        overflow: auto;

        p {
            width: 100%;
            word-break: break-word;
            text-align: justify;
            font-size: 12px;
            color: var(--clr-grey-8);
            padding-bottom: 0.4vh;
        }
    }
`
export default ConsoleWindow;

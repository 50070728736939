import styled from "styled-components";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    changeNumberOfPhasesAndParallelPathParameters,
    closeNumberOfPhasesDialog,
    setNumberOfPhasesAndParallelPath
} from "../../../features/connectionTableSlice";

const NumberOfPhases = () => {
    const dispatch = useDispatch();
    const {
        numberOfPhases: numberOfPhasesStore,
        numberOfParallelPath: numberOfParallelPathStore
    } = useSelector((store) => store.connectionTable);
    const [numberOfPhases, setNumberOfPhases] = useState(numberOfPhasesStore);
    const [numberOfParallelPath, setNumberOfParallelPath] = useState(numberOfParallelPathStore);
    const [isDragging, setIsDragging] = useState(false);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });

    // when drag started
    const handleDragStart = (e) => {
        setIsDragging(true);
        setStartPosition({
            x: e.clientX - position.x,
            y: e.clientY - position.y,
        });
        // e.dataTransfer.effectAllowed = 'move';
        e.dataTransfer.setData('text/plain', null); // for Firefox compatibility
        // e.preventDefault();
        e.dataTransfer.setData('text/plain', '');

        // Hide the default ghost image
        const transparentImg = new Image();
        transparentImg.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
        e.dataTransfer.setDragImage(transparentImg, 0, 0);
    };

    // when drag is working
    const handleDragOver = (e) => {
        e.preventDefault();
        if (isDragging) {
            const newX = e.clientX - startPosition.x;
            const newY = e.clientY - startPosition.y;
            setPosition({ x: newX, y: newY });
        }
    };

    // when drag ends
    const handleDragEnd = () => {
        setIsDragging(false);
    };

    return (
        <>
            <div className="modal-container-background"/>
            <div className="modal-container"
                 style={{
                     transform: `translate(${position.x}px, ${position.y}px)`
                 }}
                 draggable="true"
                 onDragStart={handleDragStart}
                 onDragOver={handleDragOver}
                 onDragEnd={handleDragEnd}
            >
                <StyledWrapper>
                    <div className="number-of-phases-title">
                        <span>NUMBER OF PHASES</span>
                    </div>
                    <div className="input-box">
                        <div className="number-of-phases">
                            <span>Number of Phases</span>
                            <input type="text" id="number_of_phases" name="number_of_phases" value={numberOfPhases}
                                   onChange={(e) => setNumberOfPhases(e.target.value ? parseInt(e.target.value) : 0)}/>
                            <button className="cancel-button" onClick={() => {
                                dispatch(closeNumberOfPhasesDialog())
                            }}>Cancel
                            </button>
                        </div>
                        <div className="number-of-parallel-path">
                            <span>Number of Parallel Path</span>
                            <input type="text" id="number_of_parallelPath" name="number_of_parallelPath"
                                   value={numberOfParallelPath}
                                   onChange={(e) => setNumberOfParallelPath(e.target.value ? parseInt(e.target.value) : 0)}/>
                            <button className="set-button" onClick={() => {
                                dispatch(setNumberOfPhasesAndParallelPath({
                                    numberOfPhases: numberOfPhases,
                                    numberOfParallelPath: numberOfParallelPath
                                }))
                                dispatch(changeNumberOfPhasesAndParallelPathParameters({
                                    numberOfPhases: numberOfPhases,
                                    numberOfParallelPath: numberOfParallelPath
                                }))
                                dispatch(closeNumberOfPhasesDialog())
                            }}>Set
                            </button>
                        </div>
                    </div>
                </StyledWrapper>
            </div>
        </>
    );
};

const StyledWrapper = styled.div`
    width: 30vw;
    color: var(--clr-grey-9);

    .number-of-phases-title {
        background: var(--clr-grey-1);
        height: 5vh;
        padding-right: 1vw;
        padding-left: 1vw;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;

        span {
            font-size: var(--font-size-1);
        }
    }

    .input-box {
        padding: 2vh 1vw;
        background: var(--clr-grey-3);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 100%;
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;

        span {
            text-align: left;
            line-height: 30px;
            font-size: var(--font-size-2);
        }

        input {
            background: var(--clr-grey-1);
            color: var(--clr-grey-9);
            margin-top: 1vh;
            height: 3.5vh;
            border: none;
            border-radius: 10px;
            padding-left: 1vh;
            outline: none;
            font-size: var(--font-size-2);
        }

        button {
            height: 4vh;
            margin-top: 2vh;
            border-radius: 6px;
            color: var(--clr-grey-9);
            cursor: pointer;
            font-size: var(--font-size-2);
        }

        .number-of-phases {
            display: flex;
            flex-direction: column;
            justify-content: left;
            width: 100%;
            margin-right: 0.5vw;

            .cancel-button {
                background: none;
                border: solid 1px var(--clr-grey-6);

                &:hover {
                    font-family: Ubuntu-Bold, serif;
                }
            }
        }

        .number-of-parallel-path {
            display: flex;
            flex-direction: column;
            justify-content: left;
            width: 100%;
            margin-left: 0.5vw;

            .set-button {
                background: var(--clr-primary);
                border: none;

                &:hover {
                    font-family: Ubuntu-Bold, serif;
                }
            }
        }
    }
`

export default NumberOfPhases;
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {axiosBaseConfig} from "../data/url";

// READ DOCUMENTS FOR FEATURES FILES
const initialState = {
    projectsList: [],
    currentProject: false,
    isLoading: 0
};

export const getUserProjectsList = createAsyncThunk(
    'projectsSlice/getUserProjectsList',
    async (name, thunkAPI) => {
        try {
            const endpoint = '/projects/'
            const response = await axiosBaseConfig.get(endpoint);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('can not get user projects');
        }
    }
);

export const createNewProject = createAsyncThunk(
    'projectsSlice/postNewProject',
    async (name, thunkAPI) => {
        try {
            const endpoint = '/projects/'
            const payload = {
                "project_name": name.projectName
            };
            const response = await axiosBaseConfig.post(endpoint, payload);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('can not create new project');
        }
    }
);

export const checkCurrentProject = createAsyncThunk(
    'projectsSlice/checkCurrentProject',
    async (name, thunkAPI) => {
        try {
            const endpoint = '/projects/current-project/'
            const response = await axiosBaseConfig.get(endpoint);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('can not get current project');
        }
    }
);

export const changeCurrentProject = createAsyncThunk(
    'projectsSlice/changeCurrentProject',
    async (name, thunkAPI) => {
        try {
            const endpoint = '/projects/'
            const payload = {
                "project_name": name.projectName
            };
            const response = await axiosBaseConfig.patch(endpoint, payload);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('can not change current project');
        }
    }
);

export const deleteProject = createAsyncThunk(
    'projectsSlice/deleteProject',
    async (name, thunkAPI) => {
        try {
            const endpoint = `/projects/${name.projectName}/`
            const response = await axiosBaseConfig.delete(endpoint);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('can not delete project');
        }
    }
);


const projectsSlice = createSlice({
    name: 'projects',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUserProjectsList.pending, (state) => {
                console.log("getting user projects")
                state.isLoading = state.isLoading + 1;
            })
            .addCase(getUserProjectsList.fulfilled, (state, action) => {
                state.isLoading = state.isLoading - 1;
                console.log("got user projects")
                state.projectsList = action.payload["projects"];
            })
            .addCase(getUserProjectsList.rejected, (state, action) => {
                console.log(action);
                state.isLoading = state.isLoading - 1;
            })
            .addCase(createNewProject.pending, (state) => {
                console.log("creating new project")
                state.isLoading = state.isLoading + 1;
            })
            .addCase(createNewProject.fulfilled, (state, action) => {
                state.isLoading = state.isLoading - 1;
                state.currentProject = action.payload["current_project"];
                console.log("new project created")
            })
            .addCase(createNewProject.rejected, (state, action) => {
                console.log(action);
                state.currentProject = false
                state.isLoading = state.isLoading - 1;
            })
            .addCase(checkCurrentProject.pending, (state) => {
                console.log("getting current project")
                state.isLoading = state.isLoading + 1;
            })
            .addCase(checkCurrentProject.fulfilled, (state, action) => {
                state.isLoading = state.isLoading - 1;
                state.currentProject = action.payload["current_project"];
                console.log("got current project")
            })
            .addCase(checkCurrentProject.rejected, (state, action) => {
                console.log(action);
                state.currentProject = false
                state.isLoading = state.isLoading - 1;
            })
            .addCase(changeCurrentProject.pending, (state) => {
                console.log("changing current project")
                state.isLoading = state.isLoading + 1;
            })
            .addCase(changeCurrentProject.fulfilled, (state, action) => {
                state.isLoading = state.isLoading - 1;
                state.currentProject = action.payload["current_project"];
                console.log("current project changed")
            })
            .addCase(changeCurrentProject.rejected, (state, action) => {
                console.log(action);
                state.currentProject = false
                state.isLoading = state.isLoading - 1;
            })
            .addCase(deleteProject.pending, (state) => {
                console.log("deleting current project")
                state.isLoading = state.isLoading + 1;
            })
            .addCase(deleteProject.fulfilled, (state, action) => {
                state.isLoading = state.isLoading - 1;
                state.projectsList = action.payload["projects"]
                console.log("current project deleted")
            })
            .addCase(deleteProject.rejected, (state, action) => {
                console.log(action);
                state.isLoading = state.isLoading - 1;
            })
    },
});


export default projectsSlice.reducer;
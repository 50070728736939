import styled from "styled-components";
import {navIcon} from "../../icons/inputLayout";
import {
    INPUT_STATOR_DESIGN,
    INPUT_STATOR_SLOT_DESIGN,
} from "../../data/states";
import {changeInputState} from "../../features/globalSlice";
import {useDispatch, useSelector} from "react-redux";
import {StatorDesignInputs, StatorSlotDesignInputs} from "./stator";

const StatorInputs = () => {
    const dispatch = useDispatch();
    const {inputState} = useSelector((store) => store.globalItems);

    // style the buttons based on inputState
    const checkInputState = (fieldState) => {
        if (fieldState === inputState) {
            return {background: "var(--clr-primary-dark)"}
        }
        return {background: "var(--clr-grey-5)"}
    }

    // we call needed component based in inputState
    const inputFields = () => {
        if (inputState === INPUT_STATOR_DESIGN) {
            return <StatorDesignInputs/>
        }
        if (inputState === INPUT_STATOR_SLOT_DESIGN) {
            return <StatorSlotDesignInputs/>
        }
    }

    return (
        <StyledWrapper>
            <div className="header-input">
                <img src={navIcon} alt="navIcon"/>
                <span>STATOR DESIGN</span>
            </div>
            <div className="buttons">
                <button className="stator-design" style={checkInputState(INPUT_STATOR_DESIGN)}
                        onClick={() => {
                            dispatch(changeInputState({inputState: INPUT_STATOR_DESIGN}));
                        }}>Stator Design
                </button>
                <button className="stator-slot-design" style={checkInputState(INPUT_STATOR_SLOT_DESIGN)}
                        onClick={() => {
                            dispatch(changeInputState({inputState: INPUT_STATOR_SLOT_DESIGN}));
                        }}>Stator Slot Design
                </button>
            </div>
            {inputFields()}
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    .stator-design {
        width: 7vw;
        font-size: var(--font-size-4)
    }

    .stator-slot-design {
        width: 8vw;
        font-size: var(--font-size-4)
    }
`

export default StatorInputs;

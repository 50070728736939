import {
    fitScreenIcon,
    optimisationIcon
} from "../../icons/actionbar";
import {useDispatch, useSelector} from "react-redux";
import {getWeldingOptimisedStl} from "../../features/stlFilesSlice";
import {toast} from "react-toastify";


const OptimisationIcons = ({fitScreenFunc}) => {
    const {canPushOptimisationButton} = useSelector((store) => store.stlParameters);
    const {weldingSideCoils} = useSelector((store) => store.weldingSideCoils);
    const dispatch = useDispatch();

    return (
        <>
            {/*{isIconAvailable && <img src={isometricViewIcon} alt="isometricView"*/}
            {/*                  title="Isometric View"*/}
            {/*                  onClick={() => {*/}
            {/*                      dispatch(toggleParallelView());*/}
            {/*                  }}/>}*/}
            {/*{isIconAvailable && <img src={yzViewIcon} alt="yzView"*/}
            {/*                  title="Y-Z View"*/}
            {/*                  onClick={() => {*/}
            {/*                      dispatch(setYZView());*/}
            {/*                  }}/>}*/}
            {/*{isIconAvailable && <img src={xzViewIcon} alt="xzView"*/}
            {/*                  title="X-Z View"*/}
            {/*                  onClick={() => {*/}
            {/*                      dispatch(setXZView());*/}
            {/*                  }}/>}*/}
            {/*{isIconAvailable && <img src={xyViewIcon} alt="xyView"*/}
            {/*                  title="X-Y View"*/}
            {/*                  onClick={() => {*/}
            {/*                      dispatch(setXYView());*/}
            {/*                  }}/>}*/}
            <img src={fitScreenIcon} alt="fitScreen"
                 title="Fit Screen"
                 onClick={() => {
                     fitScreenFunc();
                 }}/>
            <hr/>
            <button className="optimisation-button" onClick={() => {
                if (canPushOptimisationButton) {
                    if (weldingSideCoils.length !== 2) {
                        toast.error("you should add welding coil first")
                        return
                    }
                    dispatch(getWeldingOptimisedStl())
                    toast.success("Optimisation started")
                } else {
                    toast.warning("Optimisation in progress")
                }
            }}>
                <span>Optimisation</span>
                <img src={optimisationIcon} alt="optimisationIcon"/>
            </button>

        </>
    );
};


export default OptimisationIcons;

import styled from "styled-components";
import {
    definePatternArrowDown,
    definePatternArrowUp,
    definePatternTitleIcon
} from "../../../icons/inputLayout";
import {useState} from "react";
import {useDispatch} from "react-redux";

const DefinePattern = ({coilData, exitFunc, definePatternFunc}) => {
    const dispatch = useDispatch();
    const [enterSlotShift, setEnterSlotShift] = useState(0);
    const [numberOfPatterns, setNumberOfPatterns] = useState(0);
    const [selectedCoils, setSelectedCoils] = useState([]);
    const [isDragging, setIsDragging] = useState(false);
    const [position, setPosition] = useState({x: 0, y: 0});
    const [startPosition, setStartPosition] = useState({x: 0, y: 0});

    // when drag started
    const handleDragStart = (e) => {
        setIsDragging(true);
        setStartPosition({
            x: e.clientX - position.x,
            y: e.clientY - position.y,
        });
        // e.dataTransfer.effectAllowed = 'move';
        e.dataTransfer.setData('text/plain', null); // for Firefox compatibility
        // e.preventDefault();
        e.dataTransfer.setData('text/plain', '');

        // Hide the default ghost image
        const transparentImg = new Image();
        transparentImg.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
        e.dataTransfer.setDragImage(transparentImg, 0, 0);
    };

    // when drag is working
    const handleDragOver = (e) => {
        e.preventDefault();
        if (isDragging) {
            const newX = e.clientX - startPosition.x;
            const newY = e.clientY - startPosition.y;
            setPosition({x: newX, y: newY});
        }
    };

    // when drag ends
    const handleDragEnd = () => {
        setIsDragging(false);
    };

    return (
        <>
            <div className="modal-container-background"/>
            <div className="modal-container"
                 style={{
                     transform: `translate(${position.x}px, ${position.y}px)`
                 }}
                 draggable="true"
                 onDragStart={handleDragStart}
                 onDragOver={handleDragOver}
                 onDragEnd={handleDragEnd}
            >
                <div/>
                <StyledWrapper>
                    <div className="define-pattern-title">
                        <div className="dialog-title">
                            <img className="title-icon" src={definePatternTitleIcon} alt="definePatternTitleIcon"/>
                            <span className="restore-default">DEFINE PATTERN TO COIL</span>
                        </div>
                    </div>
                    <div className='define-pattern-body'>
                        <span>Select Coil Number</span>
                        <div className="input-coils">
                            {coilData.map((coil, index) => {
                                return <span
                                    style={selectedCoils.includes(coil.coil_name) ? {background: "#5053a4"} : {background: "transparent"}}
                                    key={index} onClick={() => {
                                    if (selectedCoils.includes(coil.coil_name)) {
                                        const newList = selectedCoils.filter(e => e !== coil.coil_name)
                                        setSelectedCoils(newList)
                                    } else {
                                        const newList = [...selectedCoils, coil.coil_name]
                                        setSelectedCoils(newList)
                                    }
                                }}>{coil.coil_name}</span>
                            })}
                        </div>
                        <div className="define-pattern-inputs">
                            <div className="input-part">
                                <span>Enter Slot Shift</span>
                                <div className="increase-decrease-input">
                                    <div className="increase-decrease-buttons">
                                        <img className="increase" src={definePatternArrowUp}
                                             alt="definePatternTitleIcon"
                                             onClick={() => {
                                                 setEnterSlotShift(enterSlotShift + 1)
                                             }}/>
                                        <img className="decrease" src={definePatternArrowDown}
                                             alt="definePatternTitleIcon" onClick={() => {
                                            setEnterSlotShift(enterSlotShift - 1 < 0 ? 0 : enterSlotShift - 1)
                                        }}/>
                                    </div>
                                    <input className="text-input" type="text" id="enterSlotShift" name="enterSlotShift"
                                           value={enterSlotShift} onChange={(e) => {
                                        setEnterSlotShift(e.target.value ? parseInt(e.target.value) : 0)
                                    }}/>
                                </div>
                            </div>
                            <div className="input-part">
                                <span>Number Of Patterns</span>
                                <div className="increase-decrease-input">
                                    <div className="increase-decrease-buttons">
                                        <img className="increase" src={definePatternArrowUp}
                                             alt="definePatternTitleIcon"
                                             onClick={() => {
                                                 setNumberOfPatterns(numberOfPatterns + 1)
                                             }}/>
                                        <img className="decrease" src={definePatternArrowDown}
                                             alt="definePatternTitleIcon" onClick={() => {
                                            setNumberOfPatterns(numberOfPatterns - 1 < 0 ? 0 : numberOfPatterns - 1)
                                        }}/>
                                    </div>
                                    <input className="text-input" type="text" id="numberOfPatterns"
                                           name="numberOfPatterns"
                                           value={numberOfPatterns} onChange={(e) => {
                                        setNumberOfPatterns(e.target.value ? parseInt(e.target.value) : 0)
                                    }}/>
                                </div>
                            </div>
                        </div>
                        <div className="define-pattern-buttons">
                            <button className="cancel" onClick={() => {
                                dispatch(exitFunc())
                            }}>Cancel
                            </button>
                            <button className="add-coils" onClick={() => {
                                dispatch(definePatternFunc({
                                    enterSlotShift: enterSlotShift,
                                    numberOfPatterns: numberOfPatterns,
                                    parentsName: selectedCoils
                                }))
                                dispatch(exitFunc())
                            }}>Add Coils
                            </button>
                        </div>
                    </div>
                </StyledWrapper>
            </div>
        </>
    );
};

const StyledWrapper = styled.div`
    width: 25vw;
    font-family: Ubuntu-Light, serif;

    .define-pattern-title {
        background: var(--clr-grey-1);
        height: 4vh;
        padding-right: 0.75vw;
        padding-left: 0.75vw;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;

        .dialog-title {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: left;

            span {
                margin-left: 0.25vw;
                font-family: Ubuntu-Light, serif;
                font-size: var(--font-size-2);
                padding-bottom: 0.2vh;
            }

            .title-icon {
                width: 2vh;
                height: 2vh;
            }
        }


        .close-icon {
            width: 1.5vh;
            height: 1.5vh;
            border: solid 1px var(--clr-grey-5);
            margin-left: 10vw;
            cursor: pointer;
        }
    }

    .define-pattern-body {
        background: var(--clr-grey-2);
        height: 52vh;
        padding: 1.5vh;
        text-align: left;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;

        span {
            font-family: Ubuntu-Light, serif;
            font-size: var(--font-size-2);
        }

        .input-coils {
            background: var(--clr-grey-3);
            height: 35vh;
            padding: 1vh;
            margin-top: 1vh;
            margin-bottom: 1vh;
            display: flex;
            flex-direction: column;
            overflow: auto;
            border-radius: 8px;

            span {
                margin-bottom: 0.1vh;
                cursor: pointer;
                border-radius: 4px;
                padding: 0.2vh 0.2vh 0.2vh 0.5vw;
            }
        }
    }

    .define-pattern-inputs {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;


        .input-part {
            width: 48%;
            height: 7vh;

            span {
                font-size: var(--font-size-4);
            }

            input {
                width: 96%;
                height: 2.5vh;
                border: none;
                outline: none;
            }

            .increase-decrease-input {
                position: relative;
                margin-top: 1vh;
                margin-bottom: 1vh;
                background-color: var(--clr-grey-2);
                border: none;
                border-radius: 6px;
                color: var(--clr-grey-9);

                .increase-decrease-buttons {
                    position: absolute;
                    top: 0;
                    right: 0;
                    font-size: 14px;
                    border: none;
                    width: 2vh;
                    margin: 0;
                    //height: 60%;
                    border-radius: 6px;
                    color: var(--clr-grey-9);
                    display: grid;

                    img {
                        width: 1.5vh;
                        height: 1.5vh;
                        cursor: pointer;
                    }

                    .increase {
                        margin-top: -0.1vh;
                        border-top-right-radius: 4px;
                        border-top-left-radius: 4px;
                    }

                    .decrease {
                        margin-top: -0.3vh;
                        border-bottom-right-radius: 4px;
                        border-bottom-left-radius: 4px;
                    }
                }

                .text-input {
                    background: var(--clr-grey-1);
                    color: var(--clr-grey-9);
                    border-radius: 6px;
                    padding-left: 0.5vw;
                    font-family: Ubuntu-Light, serif;
                    font-size: var(--font-size-4);
                }
            }
        }

        .number-of-patterns {
            width: 48%;

        }
    }

    .define-pattern-buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 0.5vh;

        button {
            width: 48%;
            height: 3.5vh;
            border-radius: 8px;
            cursor: pointer;
            font-family: Ubuntu-Light, serif;
            font-size: var(--font-size-3);

            &:hover {
                font-family: Ubuntu-Bold, serif;
            }
        }

        .cancel {
            background: var(--clr-grey-2);
            color: var(--clr-grey-9);
            border: solid 1px var(--clr-grey-5);
        }

        .add-coils {
            background: var(--clr-primary);
            color: var(--clr-grey-9);
            border: none;
        }
    }
`


export default DefinePattern;

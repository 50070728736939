import styled from "styled-components";
import {useSelector} from "react-redux";


const DownloadHistoryWindow = ({currentState}) => {
    const {allExportDetails, currentTime, intervalHours} = useSelector((store) => store.export);

    const TimeDifference = (timestamp2) => {
        // Calculate the difference in seconds
        const differenceInSeconds = currentTime - timestamp2;

        // Convert the difference from seconds to hours
        return differenceInSeconds / 3600 < intervalHours;
    };

    return (
        <StyledWrapper>
            <p className="title">Download History</p>
            <div className="log-window">
                <div className="log-title">
                    <p className="link">Link</p>
                    <p className="date">Date</p>
                    <p className="status">Status</p>
                </div>
                <div className="log-details">
                    {Object.values(allExportDetails)
                        .filter(log => log.export_type === currentState.toLowerCase())
                        .sort((a, b) => {
                            if (!a.creation_time) return 1;
                            if (!b.creation_time) return -1;
                            return new Date(a.creation_time) - new Date(b.creation_time);
                        }).reverse().map((log, index) => {
                            const isValid = TimeDifference(log.creation_time)
                            return <div className="log-title" key={index}>
                                {isValid && log.link ?
                                    <a className="link" href={log.link[0]} target="_blank" rel="noreferrer">
                                        {log.description}
                                    </a> :
                                    <p style={{color: !isValid && log.link ? "red" : ""}}
                                       className="link">{log.description}</p>}
                                {log.creation_time ?
                                    <p style={{color: !isValid && log.link ? "red" : ""}}
                                       className="date">{new Date(log.creation_time * 1000).toLocaleString()}</p> :
                                    <p style={{color: !isValid && log.link ? "red" : ""}} className="date">-</p>
                                }
                                {!isValid && log.link ?
                                    <p style={{color: "red"}} className="status">expired</p> :
                                    <p className="status">{log.state}</p>
                                }
                            </div>
                        })}
                </div>
            </div>
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    padding: 2vh 1vw;
    color: var(--clr-grey-9);

    .title {
        margin-bottom: 1vh;
    }

    .log-window {
        background: var(--clr-grey-5);
        height: 23vh;
        border-radius: 0.5vh;
        padding: 1vh 1vw;

        .log-title {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 1vh;

            p {
                font-size: var(--font-size-2);
                color: var(--clr-grey-8);
            }

            a {
                color: var(--clr-grey-9); /* Normal link color */
                font-size: var(--font-size-2);
                text-decoration: none;
            }

            a:visited {
                color: var(--clr-grey-7); /* Visited link color */
            }

            a:hover {
                color: var(--clr-primary-light); /* Hovered link color */
            }

            .link {
                width: 60%;
                font-size: var(--font-size-2);
            }

            .date {
                width: 20%;
                font-size: var(--font-size-2);
            }

            .status {
                width: 20%;
                font-size: var(--font-size-2);
            }
        }

        .log-details {
            display: flex;
            height: 20vh;
            overflow: auto;
            flex-direction: column;

            p {
                width: 100%;
                word-break: break-word;
                text-align: justify;
                font-size: 12px;
                color: var(--clr-grey-9);
                padding-bottom: 0.4vh;
            }
        }
    }
`

export default DownloadHistoryWindow;
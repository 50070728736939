import styled from 'styled-components'
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import {NUMBER_OF_SLOT_INPUT, NUMBER_OF_LAYERS_INPUT} from "../../data/statorInputs";
import {Chart, ArcElement} from 'chart.js';
import {Doughnut} from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(ArcElement);
Chart.register(ChartDataLabels);


const ConnectionTableViewerForming = ({coils, addCoilFunction}) => {
    const defaultCoil = {
        coil_name: "UI Coil",
        starting_slot: null,
        starting_layer: null,
        ending_slot: null,
        ending_layer: null,
        tag: null,
        coil_children: null,
        // segment5_control_points_choice : 7
    }
    const numberOfSlot = useSelector((store) => store.stlParameters[NUMBER_OF_SLOT_INPUT]);
    const numberOfLayers = useSelector((store) => store.stlParameters[NUMBER_OF_LAYERS_INPUT]);
    const dispatch = useDispatch();
    const [totalMatrix, setTotalMatrix] = useState([])
    const [coilSelected, setCoilSelected] = useState(defaultCoil)
    const [data, setData] = useState({
        labels: [],
        datasets: []
    })


    useEffect(() => {
        // this function will fill a 2d matrix [numberOfLayers][numberOfSlot] based on forming coils
        const generateConnectionTableMatrix = (defaultValue, parentStartingValue, parentEndingValue, childStartingValue, childEndingValue, selectedCoilValue, aTagColor, bTagColor, cTagColor, checkTags) => {
            const result = Array.from({length: numberOfLayers}, (_) => Array.from({length: numberOfSlot}, (_) => defaultValue));
            // eslint-disable-next-line array-callback-return
            coils.map((parentCoil) => {
                if (checkTags && parentCoil.tag && !parentCoil.tag.startsWith("F") && !parentCoil.tag.startsWith("N")) {
                    if (parentCoil.tag.startsWith("A")) {
                        result[parentCoil.starting_layer - 1][numberOfSlot - parentCoil.starting_slot] = aTagColor
                        result[parentCoil.ending_layer - 1][numberOfSlot - parentCoil.ending_slot] = aTagColor
                    } else if (parentCoil.tag.startsWith("B")) {
                        result[parentCoil.starting_layer - 1][numberOfSlot - parentCoil.starting_slot] = bTagColor
                        result[parentCoil.ending_layer - 1][numberOfSlot - parentCoil.ending_slot] = bTagColor
                    } else if (parentCoil.tag.startsWith("C")) {
                        result[parentCoil.starting_layer - 1][numberOfSlot - parentCoil.starting_slot] = cTagColor
                        result[parentCoil.ending_layer - 1][numberOfSlot - parentCoil.ending_slot] = cTagColor
                    } else {
                        result[parentCoil.starting_layer - 1][numberOfSlot - parentCoil.starting_slot] = parentStartingValue
                        result[parentCoil.ending_layer - 1][parentCoil.ending_slot - 2] = parentEndingValue
                    }
                } else {
                    result[parentCoil.starting_layer - 1][numberOfSlot - parentCoil.starting_slot] = parentStartingValue
                    result[parentCoil.ending_layer - 1][numberOfSlot - parentCoil.ending_slot] = parentEndingValue
                }
                // eslint-disable-next-line array-callback-return
                parentCoil.coil_children && parentCoil.coil_children.map((childCoil) => {
                    if (checkTags && childCoil.tag && !childCoil.tag.startsWith("F")) {
                        if (childCoil.tag.startsWith("A")) {
                            result[childCoil.starting_layer - 1][numberOfSlot - childCoil.starting_slot] = aTagColor
                            result[childCoil.ending_layer - 1][numberOfSlot - childCoil.ending_slot] = aTagColor
                        } else if (childCoil.tag.startsWith("B")) {
                            result[childCoil.starting_layer - 1][numberOfSlot - childCoil.starting_slot] = bTagColor
                            result[childCoil.ending_layer - 1][numberOfSlot - childCoil.ending_slot] = bTagColor
                        } else if (childCoil.tag.startsWith("C")) {
                            result[childCoil.starting_layer - 1][numberOfSlot - childCoil.starting_slot] = cTagColor
                            result[childCoil.ending_layer - 1][numberOfSlot - childCoil.ending_slot] = cTagColor
                        } else {
                            result[childCoil.starting_layer - 1][numberOfSlot - childCoil.starting_slot] = childStartingValue
                            result[childCoil.ending_layer - 1][numberOfSlot - childCoil.ending_slot] = childEndingValue
                        }
                    } else {
                        result[childCoil.starting_layer - 1][numberOfSlot - childCoil.starting_slot] = childStartingValue
                        result[childCoil.ending_layer - 1][numberOfSlot - childCoil.ending_slot] = childEndingValue
                    }
                })
            })
            if (coilSelected.starting_layer !== null && coilSelected.starting_slot !== null) {
                result[coilSelected.starting_layer - 1][numberOfSlot - coilSelected.starting_slot] = selectedCoilValue
            }
            return result
        }
        // we use Total Matrix to which pieces are taken and which one available for user to select it
        setTotalMatrix(generateConnectionTableMatrix(0, 1, 1, 1, 1, 2, null, null, null, false))
        // we use arrayOfColorArrays to know each pieces color
        const arrayOfColorArrays = generateConnectionTableMatrix("#bcbcbc", "#1b7039", "#bb3434", "#68b984", "#ef6262", "#4942E4", "#f59e3d", "#ea5547", "#2e58bf", true)
        // we use arrayOfColorArrays to know each pieces hover color
        const arrayOfHoverColorArrays = generateConnectionTableMatrix("#757575", "#0d381c", "#691d1d", "#3f7250", "#a94646", "#2e2a8c", "#9c6a2a", "#b50c00", "#161880", true)


        // here we generate data of doughnut chart
        const generateData = () => {
            const labels = Array.from({length: numberOfSlot}, (_, index) => index + 1);
            const datasets = [];
            const fontSizeOuter = 26 - (14 * (numberOfSlot / 100))
            const fontSize = 18 - (14 * (numberOfLayers / 18))
            // this is the first layer data, we add slot numbers here
            datasets.push({
                data: Array(numberOfSlot).fill(numberOfLayers),
                backgroundColor: arrayOfColorArrays[numberOfLayers - 1],
                hoverBackgroundColor: arrayOfHoverColorArrays[numberOfLayers - 1],
                borderColor: '#1e1e1e',
                datalabels: {
                    labels: {
                        index: {
                            color: '#1e1e1e',
                            font: {
                                size: fontSizeOuter,
                            },
                            formatter: (val, ctx) => (numberOfSlot - ctx.chart.data.labels[ctx.dataIndex] + 1),
                            align: 'end',
                            anchor: 'end',
                            padding: 10,
                        },
                        value: {
                            color: '#bcbcbc',
                            borderWidth: 2,
                            borderRadius: 4,
                            padding: 0,
                            display: true,
                            font: {
                                size: fontSize,
                            },
                        },
                    },
                }
            });
            // this is other layers data
            for (let i = 1; i < numberOfLayers; i++) {
                datasets.push({
                    data: Array(numberOfSlot).fill(numberOfLayers - i),
                    backgroundColor: arrayOfColorArrays[numberOfLayers - i - 1],
                    hoverBackgroundColor: arrayOfHoverColorArrays[numberOfLayers - i - 1],
                    borderColor: '#1e1e1e',
                    datalabels: {
                        display: true,
                        color: '#bcbcbc',
                        font: {
                            size: fontSize,
                        },
                    },
                });
            }
            return {
                labels: labels,
                datasets: datasets
            }
        }
        setData(generateData)
    }, [numberOfSlot, numberOfLayers, coils, coilSelected.starting_slot, coilSelected.starting_layer]);

    // we call this function when user click on a piece
    const handlePieceClick = (clickedPiece) => {
        // this is when coil is already taken
        if (totalMatrix[numberOfLayers - clickedPiece.datasetIndex - 1][clickedPiece.index] === 1) {
            toast.error("Coil Is Unselectable")
            return
        }
        // this is when user select a piece that already selected, so we unselect the piece
        if (totalMatrix[numberOfLayers - clickedPiece.datasetIndex - 1][clickedPiece.index] === 2) {
            setCoilSelected(defaultCoil)
            return
        }
        // this is when this is the start of coil
        if (coilSelected.starting_slot === null && coilSelected.starting_layer === null) {
            setCoilSelected((currentState) => {
                return {
                    ...currentState,
                    starting_slot: numberOfSlot - clickedPiece.index,
                    starting_layer: numberOfLayers - clickedPiece.datasetIndex,
                    // segment5_control_points : coilSelected.segment5_control_points
                }
            })
        // this is when this is the end of coil, so we add coil to forming coil
        } else {
            const newCoil = {
                ...coilSelected,
                ending_slot: numberOfSlot - clickedPiece.index,
                ending_layer: numberOfLayers - clickedPiece.datasetIndex,
                // segment5_control_points : c
            }
            dispatch(addCoilFunction({newCoil: newCoil}));
            setCoilSelected(defaultCoil)
        }
    };

    const options = {
        rotation: 90 + (180 / numberOfSlot),
        layout: {
            padding: {
                top: 50,
                bottom: 50,
                right: 50,
                left: 50
            },
        },
        animation: {
            animateRotate: false,
        },
        cutout: '50%',
        onClick: (_, elements) => {
            if (elements.length > 0) {
                const clickedPiece = elements[0];
                handlePieceClick(clickedPiece);
            }
        },
        plugins: {
            datalabels: {
                display: true,
            },
        },
    };

    return (
        <StyledWrapper>
            <div className="pie-chart">
                <Doughnut id="doughnut-chart" data={data} options={options}/>
            </div>
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    background: var(--clr-grey-7);
    text-align: center;
    justify-items: center;
    width: 72vw;
    height: 89vh;
    position: relative;

    .pie-chart {
        position: absolute;
        top: 7vh;
        left: calc((72vw - 75vh) / 2);
        width: 75vh;
    }


`
export default ConnectionTableViewerForming;

import styled from "styled-components";
import {RadioInput} from "./index";
import {useState} from "react";
import {useDispatch} from "react-redux";
import {exportPaper} from "../../../features/exportSlice";



const PaperExport = ({closeFunc}) => {
    const dispatch = useDispatch();
    const [optionState, setOptionState] = useState("single_coil")
    const optionsList = [{
        title: "Single Paper",
        value: "single_coil",
    }, {
        title: "Full Paper",
        value: "one_layer",
    }]

    return (
        <StyledWrapper>
            <div className="input-part">
                <p>Please select an option: </p>
                <RadioInput name="options" optionsList={optionsList} optionState={optionState}
                            setOptionState={setOptionState}/>
            </div>
            <div className="buttons-part">
                <button className="generate-button" onClick={() => {
                    dispatch(exportPaper({
                        exportWireType: optionState,
                        exportExtensionType: "step"
                    }))
                }}
                >Export
                </button>
                <button className="exit-button" onClick={() => {
                    closeFunc()
                }}
                >Exit
                </button>
            </div>
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    padding: 2vh 1vw;

    p {
        color: var(--clr-grey-8);
        margin-bottom: 2vh;
    }
`

export default PaperExport;
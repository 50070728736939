import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {
    VIEWER_STATE_ASSEMBLY,
    VIEWER_STATE_FORMING_SIDE,
    VIEWER_STATE_SINGLE_SLOT,
    VIEWER_STATE_STANDARD,
    VIEWER_STATE_STATOR_CORE_SOLID,
    VIEWER_STATE_WELDING_SIDE
} from "../data/Viewer";
import {changeViewerState} from "../features/viewerSlice";
import {toast} from "react-toastify";

const ViewerState = () => {
    const dispatch = useDispatch();
    const {viewerState} = useSelector((store) => store.viewer);
    // const {inputState} = useSelector((store) => store.globalItems);
    // const {
    //     statorStl,
    //     // statorLaminationStl,
    //     statorSlotStl,
    //     papers2DStl,
    //     // windingStl,
    //     wireSlotStl,
    //     wireInsulationSlotStl,
    //     weldingSideStl,
    //     weldingOptimisedStl,
    //     statorStlLamination
    // } = useSelector((store) => store.stlParameters);
    const {
        formingSideStl,
        // statorFormingSideStl,
        // formingSideCoils,
        // weldingSideCoils,
    } = useSelector((store) => store.formingSideCoils);


    // style of ViewerState icons based on viewerState
    const checkViewerState = (fieldState) => {
        if ([VIEWER_STATE_FORMING_SIDE, VIEWER_STATE_ASSEMBLY].includes(fieldState)) {
            if (!formingSideStl)
                return {color: "var(--clr-grey-6)"}
        }
        if (fieldState === viewerState) {
            return {fontWeight: "bold", color: "var(--clr-primary-light)"}
        }
    }

    // here we load camera states based on viewerState and inoutState after any change in stl files and input state
    // useEffect(() => {
    //     if (inputState !== INPUT_FORMING_SIDE) {
    //         dispatch(changeViewerState({viewerState: VIEWER_STATE_STANDARD}))
    //
    //         if (inputState === INPUT_STATOR_DESIGN) {
    //             dispatch(statorDesignView())
    //         } else if (inputState === INPUT_STATOR_SLOT_DESIGN) {
    //             dispatch(statorSlotDesignView())
    //             // } else if (inputState === INPUT_WIRE_DESIGN) {
    //             //     dispatch(wireDesignView())
    //         } else if (inputState === INPUT_WELDING_SIDE_DESIGN) {
    //             dispatch(weldingSideDesignView())
    //         } else if (inputState === INPUT_FORMING_SIDE) {
    //             dispatch(formingSideView())
    //         } else if (inputState === INPUT_OPTIMISATION_OPTIONS) {
    //             dispatch(optimisationOptionsView())
    //         }
    //     }
    // }, [dispatch,
    //     inputState,
    //     statorStl,
    //     // statorLaminationStl,
    //     statorStlLamination,
    //     statorSlotStl,
    //     papers2DStl,
    //     // windingStl,
    //     wireSlotStl,
    //     wireInsulationSlotStl,
    //     weldingSideStl,
    //     weldingOptimisedStl,
    //     formingSideStl,
    //     // statorFormingSideStl,
    //     formingSideCoils,
    //     weldingSideCoils])

    return (
        <StyledWrapper>
            <p>Plots:</p>
            {/*by clicking on any icon in ViewerState we load needed view*/}
            <button style={checkViewerState(VIEWER_STATE_STANDARD)}
                    onClick={() => {
                        dispatch(changeViewerState({viewerState: VIEWER_STATE_STANDARD}))
                        // if (inputState === INPUT_STATOR_DESIGN) {
                        //     dispatch(statorDesignView())
                        // } else if (inputState === INPUT_STATOR_SLOT_DESIGN) {
                        //     dispatch(statorSlotDesignView())
                        //     // } else if (inputState === INPUT_WIRE_DESIGN) {
                        //     //     dispatch(wireDesignView())
                        // } else if (inputState === INPUT_WELDING_SIDE_DESIGN) {
                        //     dispatch(weldingSideDesignView())
                        // } else if (inputState === INPUT_FORMING_SIDE) {
                        //     dispatch(formingSideView())
                        // } else if (inputState === INPUT_OPTIMISATION_OPTIONS) {
                        //     dispatch(optimisationOptionsView())
                        // }
                    }}>Default
            </button>
            {/*<button style={checkViewerState(VIEWER_STATE_STATOR_LAMINATION)}*/}
            {/*        onClick={() => {*/}
            {/*            dispatch(stateStatorLaminationView())*/}
            {/*            dispatch(changeViewerState({viewerState: VIEWER_STATE_STATOR_LAMINATION}))*/}
            {/*        }}>Stator Lamination*/}
            {/*</button>*/}
            <button style={checkViewerState(VIEWER_STATE_STATOR_CORE_SOLID)}
                    onClick={() => {
                        // dispatch(stateStatorCoreSolidView())
                        dispatch(changeViewerState({viewerState: VIEWER_STATE_STATOR_CORE_SOLID}))
                    }}>Stator Core
            </button>
            {/*<button style={checkViewerState(VIEWER_STATE_STATOR_CORE_LAMINATED)}*/}
            {/*        onClick={() => {*/}
            {/*            dispatch(stateStatorCoreLaminatedView())*/}
            {/*            dispatch(changeViewerState({viewerState: VIEWER_STATE_STATOR_CORE_LAMINATED}))*/}
            {/*        }}>Stator Core (Laminated)*/}
            {/*</button>*/}
            <button style={checkViewerState(VIEWER_STATE_SINGLE_SLOT)}
                    onClick={() => {
                        // dispatch(stateSingleSlotView())
                        dispatch(changeViewerState({viewerState: VIEWER_STATE_SINGLE_SLOT}))
                    }}>Single Slot
            </button>
            {/*<button style={checkViewerState(VIEWER_STATE_WINDING)}*/}
            {/*        onClick={() => {*/}
            {/*            dispatch(stateWindingView())*/}
            {/*            dispatch(changeViewerState({viewerState: VIEWER_STATE_WINDING}))*/}
            {/*        }}>Winding*/}
            {/*</button>*/}
            <button style={checkViewerState(VIEWER_STATE_WELDING_SIDE)}
                    onClick={() => {
                        // dispatch(stateWeldingSideView())
                        dispatch(changeViewerState({viewerState: VIEWER_STATE_WELDING_SIDE}))
                    }}>Welding Side
            </button>
            <button style={checkViewerState(VIEWER_STATE_FORMING_SIDE)}
                    onClick={() => {
                        if (formingSideStl) {
                            // dispatch(stateFormingSideView())
                            dispatch(changeViewerState({viewerState: VIEWER_STATE_FORMING_SIDE}))
                        } else {
                            toast.warning("forming stl is not generated")
                        }
                    }}>Forming Side
            </button>
            <button style={checkViewerState(VIEWER_STATE_ASSEMBLY)}
                    onClick={() => {
                        if (formingSideStl) {
                            // dispatch(stateAssemblyView())
                            dispatch(changeViewerState({viewerState: VIEWER_STATE_ASSEMBLY}))
                        } else {
                            toast.warning("forming stl is not generated")
                        }
                    }}>Assembly
            </button>
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    width: calc(70vw - 1px);
    height: calc(3vh - 2px);
    padding-right: 1vw;
    padding-left: 1vw;
    background: var(--clr-grey-3);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 1px var(--clr-grey-1);
    border-top: solid 1px var(--clr-grey-1);
    border-left: solid 1px var(--clr-grey-1);

    p {
        color: var(--clr-grey-7);
        font-size: var(--font-size-3);
    }

    button {
        font-size: var(--font-size-5);
        background: transparent;
        border: none;
        color: var(--clr-grey-9);
        padding-right: 0.7vw;
        padding-left: 0.7vw;
        height: 80%;
        cursor: pointer;
    }
`

export default ViewerState;
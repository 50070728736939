import styled from "styled-components";


const LayerDropDownInput = ({name, optionsList, optionState, setOptionState}) => {

    return (
        <StyledWrapper>
            {/*{optionsList.map((title, index) => {*/}
            {/*    return <label key={index} className="custom-radio">*/}
            {/*        <input type="radio" id={title.value} name={name} value={title.value}*/}
            {/*               checked={title.value === optionState}*/}
            {/*               onChange={(e) => {*/}
            {/*                   setOptionState(e.target.value)*/}
            {/*               }}/>*/}
            {/*        <span className="radio-btn">{title.title}</span>*/}
            {/*    </label>*/}
            {/*})}*/}

            <div className="options">
                {optionsList.map((title, index) => {
                    return <label key={index} className="label">
                        <input className="radio-input" type="radio" id={title.value} name={name} value={title.value}
                               checked={title.value === optionState}
                               onChange={(e) => {
                                   setOptionState(e.target.value)
                               }}/>
                        <div className="radio-design"></div>
                        <div className="label-text">{title.title}</div>
                    </label>
                })}
            </div>
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    color: var(--clr-grey-8);

    .custom-radio {
        width: 12vw;

        span {
            margin-left: 0.5vw;
        }
    }

    .options {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 2vh;
        
        .label {
            display: flex;
            align-items: center;
            cursor: pointer;
            transition: .3s;
            width: 13vw;
        }

        .radio-input {
            position: absolute;
            left: 0;
            top: 0;
            width: 1px;
            height: 1px;
            opacity: 0;
            z-index: -1;
        }

        .radio-design {
            width: 20px;
            height: 20px;
            border-radius: 7px;
            background: var(--clr-primary-light);
            position: relative;
        }

        .radio-design::before {
            content: '';
            display: inline-block;
            width: inherit;
            height: inherit;
            border-radius: 7px;
            background: var(--clr-grey-5);
            transform: scale(1.1);
            transition: .3s;
        }

        .radio-input:checked + .radio-design::before {
            transform: scale(0);
        }

        .label-text {
            color: var(--clr-grey-9);
            margin-left: 0.7vw;
            font-size: 14px;
            transition: .3s;
        }
    }

`

export default LayerDropDownInput;
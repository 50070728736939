// console states
export const CONSOLE_STATE_ALL_LOGS = 'All Logs'
export const CONSOLE_STATE_CONSOLE_LOGS = 'Console Logs'
export const CONSOLE_STATE_OPTIMISATION_LOGS = 'Optimisation Logs'

// message types
export const CONSOLE_TYPE_ALL = 'all-type'
export const CONSOLE_TYPE_SUCCESS = 'success-type'
export const CONSOLE_TYPE_ERROR = 'error-type'

// every log has message and type
export const CONSOLE_MESSAGE = 'message'
export const CONSOLE_MESSAGE_TYPE = 'type'

// message fields
export const CONSOLE_MESSAGE_TYPE_ERROR = 'error'
export const CONSOLE_MESSAGE_TYPE_INFO = 'info'
export const CONSOLE_MESSAGE_TYPE_SUCCESS = 'success'
export const CONSOLE_MESSAGE_TYPE_OPT_INFO = 'opt-info'
import {guideIcon} from "../../icons/inputLayout";
import {closeBlock, openBlock} from "../../icons/global";
import {useDispatch, useSelector} from "react-redux";
import {NAVBAR_STATOR, NAVBAR_WINDING_SIDE} from "../../data/states";
import {unHideGuideLayout, hideGuideLayout, changeGif} from "../../features/guideLayoutSlice";
import {useEffect} from "react";

const GuideLayout = () => {
    const {navbarState, inputState} = useSelector((store) => store.globalItems);
    const {showGuideLayout, currentGif, currentGifTitle} = useSelector((store) => store.guideLayout);
    const dispatch = useDispatch();

    // we set gif and gif title when we start rendering GuideLayout
    useEffect(() => {
        dispatch(changeGif({currentGif: null, currentGifTitle: null}));
    }, [dispatch, inputState]);

    return (
        <>
            <div className="header-layout">
                <div className="header-input">
                    <img src={guideIcon} alt="guideIcon"/>
                    <span>GUIDE</span>
                </div>
                {showGuideLayout ? <img onClick={() => {
                        dispatch(hideGuideLayout());
                    }} className="show-hide-icon" src={closeBlock} alt="closeBlock"/> :
                    <img onClick={() => {
                        dispatch(unHideGuideLayout());
                    }} className="show-hide-icon" src={openBlock} alt="openBlock"/>}
            </div>
            {showGuideLayout && currentGif &&
                <div>
                    <div className="title">
                        {navbarState === NAVBAR_STATOR && <span>Stator View</span>}
                        {navbarState === NAVBAR_WINDING_SIDE && <span>Winding View</span>}
                    </div>
                    <div className="gif-component">
                        <p className="field-name">{currentGifTitle}</p>
                        <img className="gif" src={currentGif} alt="guideGif"/>
                        {navbarState === NAVBAR_STATOR &&
                            <p className="description">Stator: Stator is the stationary component found in electric
                                motors
                                and
                                generators</p>}
                        {navbarState === NAVBAR_WINDING_SIDE &&
                            <p className="description">Winding Side: Coils are used as components of circuits, and to
                                provide
                                the magnetic field of motors, transformers, and generators.</p>}
                    </div>
                </div>}
        </>
    );
};

export default GuideLayout;

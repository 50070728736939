import styled from "styled-components";
import {navIcon} from "../../icons/inputLayout";
import {
    INPUT_WELDING_SIDE_DESIGN
} from "../../data/states";
import {useSelector} from "react-redux";
import {WeldingSideDesignInputs} from "./windingSide";

const WindingSideInputs = () => {
    const {inputState} = useSelector((store) => store.globalItems);
    // const checkInputState = (fieldState) => {
    //     if (fieldState === inputState) {
    //         return {background: "var(--clr-primary-dark)"}
    //     }
    //     return {background: "var(--clr-grey-5)"}
    // }

    // we call needed component based in inputState
    const inputFields = () => {
        // if (inputState === INPUT_WIRE_DESIGN) {
        //     return <WireDesignInputs/>
        // }
        if (inputState === INPUT_WELDING_SIDE_DESIGN) {
            return <WeldingSideDesignInputs/>
        }
    }

    return (
        <StyledWrapper>
            <div className="header-input">
                <img src={navIcon} alt="navIcon"/>
                <span>WELDING SIDE DESIGN</span>
            </div>
            {/*<div className="buttons">*/}
            {/*    <button className="wire-design" style={checkInputState(INPUT_WIRE_DESIGN)}*/}
            {/*            onClick={() => {*/}
            {/*                dispatch(changeInputState({inputState: INPUT_WIRE_DESIGN}));*/}
            {/*            }}>Wire Design*/}
            {/*    </button>*/}
            {/*    <button className="welding-side-design" style={checkInputState(INPUT_WELDING_SIDE_DESIGN)}*/}
            {/*            onClick={() => {*/}
            {/*                dispatch(changeInputState({inputState: INPUT_WELDING_SIDE_DESIGN}));*/}
            {/*            }}>Welding Side Design*/}
            {/*    </button>*/}
            {/*</div>*/}
            {inputFields()}
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    .wire-design {
        width: 5vw;
        font-size: var(--font-size-4)
    }

    .welding-side-design {
        width: 7.5vw;
        font-size: var(--font-size-4)
    }
`

export default WindingSideInputs;

import styled from "styled-components";
import {withoutChildIcon, removeIcon} from "../../../icons/inputLayout";
import {tagData} from "../../../data/mockDataCoils";
import {useDispatch} from "react-redux";
import {COIL_NAME, PARENT_NAME, TAG} from "../../../data/connectionTable";

const ChildrenTreeBox = ({coilData, deleteChildCoil, changeChildTagCoil, showBusbars}) => {
    const {
        parent_name: parentName,
        coil_name: coilName,
        starting_slot: startingSlot,
        starting_layer: startingLayer,
        ending_slot: endingSlot,
        ending_layer: endingLayer,
        tag,
    } = coilData
    const dispatch = useDispatch();

    // we handle busbars dropdown color style in this function
    const checkBasbarsBackground = () => {
        if (tag && tag.includes("A")) {
            return {background: "#f59e3d"}
        }
        if (tag && tag.includes("B")) {
            return {background: "#ea5547"}
        }
        if (tag && tag.includes("C")) {
            return {background: "#2e58bf"}
        }
        return {background: "#2b2b2b"}
    }

    return (
        <>
            <StyledWrapper>
                <div className="coil-name">
                    <img src={withoutChildIcon} alt="withoutChildIcon"/>
                    <span>{"Coil " + coilName}</span>
                </div>
                <div className="boxes">
                    {showBusbars ?
                        <select style={checkBasbarsBackground(tag)} className="busbars" value={tag ? tag : "None"}
                                onChange={(e) => {
                                    dispatch(changeChildTagCoil({
                                        [COIL_NAME]: coilName,
                                        [PARENT_NAME]: parentName,
                                        [TAG]: e.target.value
                                    }))
                                }}>
                            {tagData.map((option, index) => {
                                return <option value={option} key={index}>{option}</option>
                            })}
                        </select> : <div className="disable-busbars"/>}
                    <div className="starting-box">
                        <span>{startingSlot} : {startingLayer}</span>
                    </div>
                    <div className="ending-box">
                        <span>{endingSlot} : {endingLayer}</span>
                    </div>
                    <div className="remove-icon" onClick={() => {
                        dispatch(deleteChildCoil({[COIL_NAME]: coilName, [PARENT_NAME]: parentName}))
                    }}>
                        <img className="clickable" src={removeIcon} alt="closedChildren"/>
                    </div>
                </div>
            </StyledWrapper>
        </>
    );
};

const StyledWrapper = styled.div`
    background: #3a3b3f;
    margin-top: 2px;
    margin-bottom: 2px;
    margin-left: 1vw;
    padding-right: 1vw;
    padding-left: 1vw;
    height: 5vh;
    border-radius: 7px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .coil-name {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: left;
        width: 4vw;

        span {
            font-size: var(--font-size-2);
            font-family: Ubuntu-Light, serif;
            margin-left: 0.5vw;
        }
    }

    .boxes {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 13vw;
    }
`


export default ChildrenTreeBox;

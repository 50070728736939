import styled from "styled-components";

const OptimisationOptionsInputs = () => {
    // we do not use functionality of this window in this version
    return (
        <StyledWrapper>
            <div className="radio-input-wrapper">
                <label className="label">
                    <input className="radio-input" type="radio" id="fast-optimisation" value="fast-optimisation"
                           name="optimisation-options" onChange={(e) => {
                        console.log(e.target)
                    }}/>
                    <div className="radio-design"></div>
                    <div className="label-text">Fast Optimisation</div>
                </label>
                <label className="label">
                    <input className="radio-input" type="radio" id="standard-optimisation" value="standard-optimisation"
                           name="optimisation-options" onChange={(e) => {
                        console.log(e.target)
                    }}/>
                    <div className="radio-design"></div>
                    <div className="label-text">Standard Optimisation</div>
                </label>
                <label className="label">
                    <input className="radio-input" type="radio" id="max-performance-optimisation"
                           value="max-performance-optimisation" name="optimisation-options" onChange={(e) => {
                        console.log(e.target)
                    }}/>
                    <div className="radio-design"></div>
                    <div className="label-text">Max Performance Optimisation</div>
                </label>
            </div>
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
  margin-top: 2vh;
`


export default OptimisationOptionsInputs;

import styled from "styled-components";
import {toast} from "react-toastify";


const TwoButtonInput = ({currentState, changeCurrentStateFunc}) => {

    const checkInputState = (fieldState) => {
        if (fieldState === currentState) {
            return {background: "var(--clr-primary-dark)"}
        }
        return {background: "var(--clr-grey-5)"}
    }

    return (
        <StyledWrapper>
            <button style={checkInputState("Not Optimised")}
                    onClick={() => {
                        changeCurrentStateFunc("Not Optimised")
                    }}
            >Not Optimised
            </button>
            <button style={checkInputState("Optimised")}
                    onClick={() => {
                        toast.warning("This feature will be coming soon ...")
                        // changeCurrentStateFunc("Optimised")
                    }}
            >Optimised
            </button>
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2vh;
    
    button {
        width: 45%;
        height: 4vh;
        border: none;
        color: var(--clr-grey-9);
        border-radius: 6px;
        cursor: pointer;
    }
`

export default TwoButtonInput;
// each coil (forming and welding) contains these fields
export const PARENT_NAME = 'parent_name'
export const COIL_NAME = 'coil_name'
export const STARTING_SLOT = 'starting_slot'
export const STARTING_LAYER = 'starting_layer'
export const ENDING_SLOT = 'ending_slot'
export const SEGMENT5_CONTROL_POINTS = 'segment5_control_points'
export const ENDING_LAYER = 'ending_layer'
export const TAG = 'tag'
export const OPERATION = 'operation'
export const OPERATION_NAME = 'operation_name'
export const FORMING_HEIGHT = 'forming_height'
export const SEGMENT3_ROTATION = 'segment3_rotation'
export const SEGMENT7_ROTATION = 'segment7_rotation'
import styled from "styled-components";
import {ampsText} from "../../icons/toolbar";
import {appLogo} from "../../icons/global";

const HelpDialog = ({closeFunc}) => {
    return (
        <>
            <div className="modal-container-background"/>
            <div className="modal-container" onClick={closeFunc} style={{cursor: "pointer"}}>
                <StyledWrapper>
                    <div className="header-part">
                        <div className="name-part">
                            <img src={appLogo} alt="appLogo" className="logo"/>
                            <div className="name-version">
                                <p className="app-name">AMPS COILWORKS</p>
                                <p className="version">Version: {process.env.REACT_APP_VERSION}</p>
                            </div>
                        </div>
                        <div className="banner">
                            <a href="https://amps.co.uk" target="_blank" rel="noreferrer">
                                <img src={ampsText} alt="ampsText" className="ampsText"/>
                            </a>
                        </div>
                    </div>
                    <div className="main-text">
                        <p>AMPS is all about Right The First-Time approach by considering manufacturing front and
                            centre.
                            Direct experience of conventional and advanced winding such as hairpin and continuous
                            windings.
                            Capable of entire design process from concept to prototype with manufacturing front and
                            centre
                            leading to deliverable solution that are cost effective manufacturable and reliable.</p>
                    </div>
                    <div className="credit">
                        <p>© 2023 Agile Manufacturing Power Systems LTD. All Rights Reserved.</p>
                    </div>
                </StyledWrapper>
            </div>
        </>
    );
};

const StyledWrapper = styled.div`
    width: 30vw;
    border-radius: 3px;
    background: var(--clr-grey-8);

    .header-part {
        display: flex;
        margin: 0 1.5vw;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .name-part {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            .name-version {
                display: flex;
                flex-direction: column;
                align-items: start;
                justify-content: space-between;
                margin-left: 0.5vw;
                height: 3vw;
                margin-top: 2vh;

                .app-name {
                    margin-top: 0.5vh;
                    color: var(--clr-grey-1);
                    font-size: var(--font-size-1);
                }

                .version {
                    margin-bottom: 0.5vh;
                    color: var(--clr-grey-1);
                    font-size: var(--font-size-5);
                    text-align: left;
                }
            }

            img {
                margin-top: 2vh;
                width: 3vw;
            }
        }

        .banner {
            display: flex;
            width: 5vw;
            height: 9vh;
            text-align: center;
            justify-content: center;
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 3px;
            background: var(--clr-primary);

            a {
                display: flex;
                text-align: center;
                justify-content: center;
            }

            img {
                width: 3.5vw;
            }
        }
    }

    .main-text {
        margin: 4vh 1.5vw;
        padding-bottom: 8vh;
        color: var(--clr-grey-1);
        font-size: var(--font-size-4);
        text-align: justify;
        border-bottom: solid 2px var(--clr-grey-7);
    }

    .credit {
        color: var(--clr-grey-1);
        font-size: var(--font-size-2);
        text-align: center;
        margin-bottom: 1.5vh;
    }
`

export default HelpDialog;
import styled from "styled-components";
import {useEffect, useState} from "react";
import {
    changeCurrentProject,
    createNewProject,
    deleteProject,
    getUserProjectsList,
} from "../features/projectsSlice";
import {useDispatch, useSelector} from "react-redux";
import {LoadingDialog} from "../fields/dialogs";
import {closeIcon} from "../icons/inputLayout";
import {useNavigate} from 'react-router-dom';
import {makeAllReadyStlFilesFalse, setParametersLoadedFalse} from "../features/stlFilesSlice";
import {appLogo} from "../icons/login";
import {updateAuthorizationHeader} from "../data/url";

const Projects = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {projectsList, isLoading} = useSelector((store) => store.projects);
    const [showNewProjectBox, setShowNewProjectBox] = useState(false);
    const [showDeleteProjectBox, setShowDeleteProjectBox] = useState(false);
    const [deleteProjectName, setDeleteProjectName] = useState(false);
    const [newProjectName, setNewProjectName] = useState("");

    // we get users projects list here
    // and set parametersLoaded to false, so when user select a project, all new data will come from backend (check src/components/WholeWindow.js)
    useEffect(() => {
        dispatch(getUserProjectsList());
        dispatch(setParametersLoadedFalse());
        dispatch(makeAllReadyStlFilesFalse())
    }, [dispatch])

    // full screen the site
    const toggleFullScreen = () => {
        if (document.mozFullScreen || document.webkitIsFullScreen) {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            }
        } else {
            const element = document.documentElement;
            if (element.requestFullscreen) {
                element.requestFullscreen();
            } else if (element.mozRequestFullScreen) {
                element.mozRequestFullScreen();
            } else if (element.webkitRequestFullscreen) {
                element.webkitRequestFullscreen();
            } else if (element.msRequestFullscreen) {
                element.msRequestFullscreen();
            }
        }
    };

    return (
        <StyledWrapper>
            <div className="main-projects-box">
                {(isLoading !== 0) && <LoadingDialog/>}
                {showNewProjectBox && <div className="modal-container">
                    <div className="new-project">
                        <p>Enter Project Name:</p>
                        <input className="text-input" type="text" id="project_name" name="project_name"
                               value={newProjectName}
                               placeholder="Project Name"
                               onChange={(e) => setNewProjectName(e.target.value)}/>
                        <div className="buttons">
                            <button onClick={() => {
                                setShowNewProjectBox(false)
                            }} className="cancel">Cancel
                            </button>
                            <button onClick={() => {
                                dispatch(createNewProject({projectName: newProjectName})).then(() => navigate('/'))
                            }} className="create">Create
                            </button>
                        </div>
                    </div>
                </div>}
                {showDeleteProjectBox && <div className="modal-container">
                    <div className="delete-project">
                        <p>Are you sure you want to delete the "{deleteProjectName}" project?</p>
                        <div className="buttons">
                            <button onClick={() => {
                                setShowDeleteProjectBox(false)
                            }} className="cancel">Cancel
                            </button>
                            <button onClick={() => {
                                dispatch(deleteProject({projectName: deleteProjectName}));
                                setShowDeleteProjectBox(false)
                            }} className="create">Delete
                            </button>
                        </div>
                    </div>
                </div>}
                <div className="app-data" onDoubleClick={toggleFullScreen}>
                    <img src={appLogo} alt="appLogo" className="logo"/>
                    <p className="welcome-text">Welcome to</p>
                    <p className="amps-text">COILWORKS</p>
                    <p className="version-text">Version: {process.env.REACT_APP_VERSION}</p>
                    <p className="power-by">Powered By Agile Manufacturing Power Systems LTD.</p>
                    <div className="tag-content-box">
                        <p className="tag-content">Projects</p>
                    </div>
                </div>
                <div className="projects">
                    {projectsList.length !== 0 ? <div className="projects-list">
                            {projectsList.map((project, index) => {
                                return <div key={index} className="project-list-item-box">
                                    <div key={index} className="project-list-item">
                                        <p onClick={() => {
                                            dispatch(changeCurrentProject({projectName: project})).then(() => navigate('/'))
                                        }}>{project}</p>
                                        <img className="delete-btn" src={closeIcon} alt="delete-btn" onClick={() => {
                                            setShowDeleteProjectBox(!showDeleteProjectBox)
                                            setDeleteProjectName(project)
                                        }}/>
                                    </div>
                                    <div className="border-div"/>
                                </div>
                            })}
                        </div> :
                        <div className="empty-projects">
                            <p>You Have No Projects</p>
                            <p>Create New One</p>
                        </div>}
                    <div className="new-project-btn">
                        <div className="btn-box">
                            <button onClick={() => {
                                localStorage.removeItem("access_token")
                                localStorage.removeItem("username")
                                localStorage.removeItem("password")
                                updateAuthorizationHeader()
                                window.location.reload();
                            }}>Log Out
                            </button>
                        </div>
                        <div className="btn-box">
                            <button onClick={() => {
                                setShowNewProjectBox(!showNewProjectBox)
                            }}>New Project
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    width: 100vw;
    height: 100vh;
    background: var(--clr-grey-1);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .modal-container {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
        z-index: 10;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .new-project {
        background: var(--clr-grey-2);
        width: 20vw;
        color: var(--clr-grey-9);
        display: flex;
        flex-direction: column;
        padding: 2vh 1vw;
        align-items: start;
        justify-content: space-between;
        border-radius: 10px;

        p {
            font-family: Ubuntu-Light, serif;
            font-size: var(--font-size-2);
        }

        input {
            background: var(--clr-grey-4);
            padding: 0.25vw;
            margin-top: 1.75vh;
            margin-bottom: 0.5vh;
            width: 19.5vw;
            height: 2.5vh;
            border: none;
            border-radius: 5px;
            outline: none;
            color: var(--clr-grey-9);
        }

        .buttons {
            margin-top: 1.75vh;
            width: 20vw;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            button {
                width: 49%;
                height: 3.5vh;
                border: none;
                border-radius: 5px;
                cursor: pointer;
            }

            .create {
                background: var(--clr-primary-light);
                color: var(--clr-grey-9);

                &:hover {
                    background: var(--clr-primary);
                }
            }

            .cancel {

            }
        }
    }

    .delete-project {
        background: var(--clr-grey-2);
        width: 20vw;
        color: var(--clr-grey-9);
        display: flex;
        flex-direction: column;
        padding: 2vh 1vw;
        align-items: start;
        justify-content: space-between;
        border-radius: 10px;

        p {
            font-family: Ubuntu-Light, serif;
            font-size: var(--font-size-2);
        }

        input {
            background: var(--clr-grey-8);
            padding: 0.25vw;
            margin-top: 0.75vh;
            margin-bottom: 0.5vh;
            width: 14.5vw;
            border: none;
            border-radius: 5px;
            outline: none;
        }

        .buttons {
            margin-top: 4.5vh;
            width: 20vw;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            button {
                width: 49%;
                height: 3.5vh;
                border: none;
                border-radius: 5px;
                cursor: pointer;
            }

            .create {
                background: var(--clr-primary-light);
                color: var(--clr-grey-9);

                &:hover {
                    background: var(--clr-primary);
                }
            }

            .cancel {

            }
        }
    }

    .main-projects-box {
        border-radius: 15px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .app-data {
            position: relative;
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
            background: linear-gradient(56deg, #4942e4 8%, #7149c6 92%);
            height: 60vh;
            width: 20vw;
            padding: 0 1vw 1vw;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            img {
                width: 9vw;
                filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.6));
            }

            p {
                color: var(--clr-grey-9);
                text-shadow: 1px 3px 3px #00000030;
            }

            .welcome-text {
                font-family: Ubuntu-Light, serif;
                margin-top: 3vh;
                font-size: min(2.2vh, 1.1vw);
            }

            .amps-text {
                font-size: min(3.3vh, 1.65vw);
                font-family: Ubuntu-Bold, serif;
            }

            .version-text {
                margin-top: 1.3vh;
                font-size: var(--font-size-5);
                font-family: Ubuntu-Light, serif;
            }

            .power-by {
                position: absolute;
                bottom: 0.5vh;
                font-size: var(--font-size-6);
            }

            .tag-content-box {
                position: absolute;
                right: 0;
                top: 0;
                background: var(--clr-grey-4);
                height: 6vh;
                width: 3.5vh;
                padding-top: 2vh;
                border-bottom-left-radius: 6px;
                text-align: center;

                .tag-content {
                    position: absolute;
                    right: -0.5vw;
                    top: 3vh;
                    color: var(--clr-grey-8);
                    rotation-point: right;
                    rotate: 270deg;
                    font-size: var(--font-size-2);
                }
            }
        }

        .projects {
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
            background: var(--clr-grey-3);
            height: 60vh;
            width: 30vw;
            padding: 0 1vw 1vw;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: left;

            .new-project-btn {
                height: 4vh;
                width: 30vw;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                border-top: solid 1px var(--clr-grey-5);

                .btn-box {
                    margin-top: 2vh;
                    width: 25%;
                    background: linear-gradient(160deg, #4742d7 10%, #843eff 90%);
                    padding: 0.1vh;
                    border-radius: 10px;
                }

                button {
                    font-size: var(--font-size-1);
                    text-align: center;
                    width: 100%;
                    padding-top: 0.6vh;
                    padding-bottom: 0.6vh;
                    background: var(--clr-grey-3);
                    border: solid 1px var(--clr-grey-5);
                    border-radius: 10px;
                    color: var(--clr-grey-8);
                    cursor: pointer;

                    &:hover {
                        color: var(--clr-grey-9);
                    }
                }
            }

            .projects-list {
                padding-top: 2vh;
                width: 30vw;
                height: 56vh;
                padding-right: 1vw;
                overflow: auto;

                .project-list-item-box {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding-top: 0.25vh;
                    justify-content: space-between;

                    .project-list-item {
                        border-radius: 5px;
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                        cursor: pointer;
                        //border-bottom: solid 1px var(--clr-grey-5);

                        &:hover {
                            background: var(--clr-primary-dark);
                        }

                        p {
                            font-family: Ubuntu-Light, serif;
                            color: var(--clr-grey-9);
                            padding: 1vw 0.5vw 1vw 1vw;
                            font-size: var(--font-size-2);
                            width: 90%;
                            margin-top: 0.5vh;
                            margin-bottom: 0.5vh;
                        }

                        img {
                            padding-right: 1vw;
                        }
                    }

                    .border-div {
                        margin-top: 0.25vh;
                        height: 1px;
                        width: 100%;
                        background: #353538;
                    }
                }


            }

            .empty-projects {
                padding-top: 2vh;
                width: 30vw;
                height: 56vh;
                padding-right: 1vw;
                overflow: auto;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                p {
                    font-family: Ubuntu-Light, serif;
                    font-size: var(--font-size-2);
                    padding: 0.5vh;
                    color: var(--clr-grey-8);
                }
            }
        }
    }

`

export default Projects;
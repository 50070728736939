import {BrowserRouter, Routes, Route} from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import {WholeWindow, Login, Projects} from "./components";
import {PrivateRoute, ProjectRoute} from "./routes";

const App = () => {
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route exact path="/" element={
                        // PrivateRoute check user has token and there is a current project
                        <PrivateRoute>
                            <WholeWindow/>
                        </PrivateRoute>
                    }/>
                    <Route exact path="/projects" element={
                        // ProjectRoute check user has token
                        <ProjectRoute>
                            <Projects/>
                        </ProjectRoute>
                    }/>
                    <Route exact path="/login" element={<Login/>}/>
                </Routes>
            </BrowserRouter>
        </>
    );
};

export default App;
import {useSelector} from "react-redux";
import {
    INPUT_CONNECTION_TABLE_FORMING_SIDE_DESIGN, INPUT_CONNECTION_TABLE_WELDING_SIDE_DESIGN,
} from "../data/states";
import {addFormingSideCoil} from "../features/formingSideCoilsSlice";
import {addWeldingSideCoil} from "../features/weldingSideCoilsSlice";
import {LoadingDialog} from "../fields/dialogs";
import ConnectionTableViewerForming from "../fields/viewer/ConnectionTableViewerForming";
import ConnectionTableViewerWelding from "../fields/viewer/ConnectionTableViewerWelding";
// import {LoadingDialog} from "../fields/dialogs";


const Viewer = () => {
    const {inputState} = useSelector((store) => store.globalItems);
    const {formingSideCoils} = useSelector((store) => store.formingSideCoils);
    const {weldingSideCoils} = useSelector((store) => store.weldingSideCoils);
    const {isLoading} = useSelector((store) => store.stlParameters);

    // we check which ConnectionTableViewer should be rendered based on inputState
    const generateConnectionTable = () => {
        if (inputState === INPUT_CONNECTION_TABLE_FORMING_SIDE_DESIGN) {
            return <ConnectionTableViewerForming coils={formingSideCoils} addCoilFunction={addFormingSideCoil}/>
        }
        if (inputState === INPUT_CONNECTION_TABLE_WELDING_SIDE_DESIGN) {
            return <ConnectionTableViewerWelding coils={weldingSideCoils} addCoilFunction={addWeldingSideCoil}/>
        }
    }
    return (
        <>
            {/*{(isFormingSideLoading || isWeldingSideLoading) && <LoadingDialog/>}*/}
            {isLoading !== 0 && <LoadingDialog/>}
            {generateConnectionTable()}
        </>
    );
};

export default Viewer;

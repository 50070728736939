// we let user see and change control points value
import {useEffect, useState} from "react";

const PointsLine = ({
                        point,
                        index,
                        handleControlPointChange,
                        formingBSplineSegment,
                        formingBSplinePreviousSegmentText,
                        formingBSplineSegmentText,
                        formingBSplineNextSegmentText,
                        readOnly
                    }) => {

    /**
     * Users can input decimal points in the input box using this code
     */
        // Local state for input fields
    const [localPoint, setLocalPoint] = useState([...point]);

    useEffect(() => {
        setLocalPoint([...point]);
    }, [point]);

    // Handler for local input changes
    const handleInputChange = (vectorIndex, value) => {
        let updatedPoint = [...localPoint];
        updatedPoint[vectorIndex] = value;
        setLocalPoint(updatedPoint);
    };

    // Handler for onBlur event to update the redux state with validated number
    const handleInputBlur = (arrayIndex, vectorIndex, value) => {
        if (value === "" || !isNaN(value)) {
            handleControlPointChange(arrayIndex, vectorIndex, value === "" ? 0 : parseFloat(value),
                formingBSplineSegment, formingBSplinePreviousSegmentText, formingBSplineSegmentText,
                formingBSplineNextSegmentText);
        }
    };

    return (
        <div className="input-line">
            <div>
                <span>Point {index + 1}:</span>
            </div>
            <div className="line-inputs">
                <input
                    type="text"
                    value={localPoint[0]}
                    onChange={e => handleInputChange(0, e.target.value)}
                    onBlur={e => handleInputBlur(index, 0, e.target.value)}
                    readOnly={readOnly}
                />
                <input
                    type="text"
                    value={localPoint[1]}
                    onChange={e => handleInputChange(1, e.target.value)}
                    onBlur={e => handleInputBlur(index, 1, e.target.value)}
                    readOnly={readOnly}
                />
                <input
                    type="text"
                    value={localPoint[2]}
                    onChange={e => handleInputChange(2, e.target.value)}
                    onBlur={e => handleInputBlur(index, 2, e.target.value)}
                    readOnly={readOnly}
                />
            </div>
        </div>
    );
};

export default PointsLine;

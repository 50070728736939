import styled from "styled-components";
import {useSelector} from "react-redux";


const CoilNameDropDownInput = ({currentCoil, setCurrentCoil}) => {
    const {formingCoilsData} = useSelector((store) => store.export);

    return (
        <StyledWrapper>
            <div>
                <p className="layer-number-input">Select Coil:</p>
                <span>only available for single coil and one layer</span>
            </div>
            <select value={currentCoil}
                    onChange={(e) => setCurrentCoil(e.target.value)}
            >
                {formingCoilsData.length && formingCoilsData.map((option, index) => {
                    return <option value={option.coil_name} key={index}>
                        {option.coil_name} - ({option.starting_layer}:{option.starting_slot})({option.ending_layer}:{option.ending_slot})</option>
                })}
            </select>
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    margin-bottom: 1vh;

    .layer-number-input {
        margin-bottom: 0;
        width: 14vw;
    }

    span {
        font-size: var(--font-size-5);
        color: var(--clr-grey-8);
    }

    select {
        width: 8vw;
        height: 3vh;
        padding-left: 1vh;
        background-color: var(--clr-grey-5);
        border: none;
        color: var(--clr-grey-9);
        border-radius: 1vh;
    }
`

export default CoilNameDropDownInput;
import styled from "styled-components";
import {CoilNameDropDownInput, RadioInput, TwoButtonInput} from "./index";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {exportForming, exportFormingPolyline} from "../../../features/exportSlice";


const FormingExport = ({closeFunc}) => {
    const dispatch = useDispatch();
    const {formingCoilsData} = useSelector((store) => store.export);
    const [optimisationState, setOptimisationState] = useState("Not Optimised")
    const [optionState, setOptionState] = useState("single_coil")
    const [stepOrBrep, setStepOrBrep] = useState("step")
    const [coilState, setCoilState] = useState(formingCoilsData[0] ? formingCoilsData[0].coil_name : 0)

    const optionsList = [{
        title: "Single Coil",
        value: "single_coil",
    }, {
        title: "One Layer",
        value: "one_layer",
    }, {
        title: "Full Layer",
        value: "full_layer",
    }]
    const stepOrBrepList = [{
        title: "Step",
        value: "step",
    }, {
        title: "Polyline",
        value: "brep",
    }]

    return (
        <StyledWrapper>
            <div className="input-part">
                <TwoButtonInput currentState={optimisationState} changeCurrentStateFunc={setOptimisationState}/>
                <p>Please select an option: </p>
                <RadioInput name="options" optionsList={optionsList} optionState={optionState}
                            setOptionState={setOptionState}/>
                {(optionState === "single_coil" || optionState === "one_layer") &&
                    <CoilNameDropDownInput currentCoil={coilState} setCurrentCoil={setCoilState}/>}
                {optionState === "single_coil" && <div>
                    <br/>
                    <p>Export Format:</p>
                    <RadioInput name="step-or-brep" optionsList={stepOrBrepList} optionState={stepOrBrep}
                                setOptionState={setStepOrBrep}/>
                    <p className="radio-description">only available for single coil, for other options export format
                        will be step</p>
                </div>}
            </div>
            <div className="buttons-part">
                <button className="generate-button" onClick={() => {
                    if (optionState === "single_coil" && stepOrBrep === "brep") {
                        dispatch(exportFormingPolyline({
                            coilName: coilState,
                            exportWireType: optionState,
                            exportExtensionType: "brep"
                        }))
                    } else {
                        dispatch(exportForming({
                            coilName: coilState,
                            exportWireType: optionState,
                            exportExtensionType: "step"
                        }))
                    }
                }}
                >Export
                </button>
                <button className="exit-button" onClick={() => {
                    closeFunc()
                }}
                >Exit
                </button>
            </div>
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    padding: 2vh 1vw;

    p {
        color: var(--clr-grey-8);
        margin-bottom: 2vh;
    }

    .radio-description {
        margin-top: -1.5vh;
        font-size: var(--font-size-5);
        color: var(--clr-grey-8);
    }
`

export default FormingExport;
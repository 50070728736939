import styled from "styled-components";
import {
    addCoilTitleIcon,
} from "../../../icons/inputLayout";
import {useState} from "react";
import {useDispatch} from "react-redux";
import {ENDING_LAYER, ENDING_SLOT, STARTING_LAYER, STARTING_SLOT,SEGMENT5_CONTROL_POINTS} from "../../../data/connectionTable";

const AddCoil = ({exitFunc, addCoilFunc}) => {
    const dispatch = useDispatch();
    const [startingSlot, setStartingSlot] = useState(0);
    const [startingLayer, setStartingLayer] = useState(0);
    const [endingSlot, setEndingSlot] = useState(0);
    const [endingLayer, setEndingLayer] = useState(0);

    const [isDragging, setIsDragging] = useState(false);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });

    // when drag started
    const handleDragStart = (e) => {
        setIsDragging(true);
        setStartPosition({
            x: e.clientX - position.x,
            y: e.clientY - position.y,
        });
        // e.dataTransfer.effectAllowed = 'move';
        e.dataTransfer.setData('text/plain', null); // for Firefox compatibility
        // e.preventDefault();
        e.dataTransfer.setData('text/plain', '');

        // Hide the default ghost image
        const transparentImg = new Image();
        transparentImg.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
        e.dataTransfer.setDragImage(transparentImg, 0, 0);
    };

    // when drag is working
    const handleDragOver = (e) => {
        e.preventDefault();
        if (isDragging) {
            const newX = e.clientX - startPosition.x;
            const newY = e.clientY - startPosition.y;
            setPosition({ x: newX, y: newY });
        }
    };

    // when drag ends
    const handleDragEnd = () => {
        setIsDragging(false);
    };


    return (
        <>
            <div className="modal-container-background"/>
            <div className="modal-container"
                 style={{
                     transform: `translate(${position.x}px, ${position.y}px)`
                 }}
                 draggable="true"
                 onDragStart={handleDragStart}
                 onDragOver={handleDragOver}
                 onDragEnd={handleDragEnd}
            >
                <StyledWrapper>
                    <div className="add-coil-title">
                        <img className="title-icon" src={addCoilTitleIcon} alt="definePatternTitleIcon"/>
                        <span className="restore-default">ADD COIL</span>
                    </div>
                    <div className='add-coil-body'>
                        <div className="add-coil-inputs">
                            <div className="input-part">
                                <div className="input-title">
                                    <div className="small-circles green-circle"/>
                                    <span>Starting Slot</span>
                                </div>
                                <input className="text-input" type="text" id="startingSlot" name="startingSlot"
                                       value={startingSlot} onChange={(e) => {
                                    setStartingSlot(e.target.value ? parseInt(e.target.value) : 0)
                                }}/>
                                <div className="input-title">
                                    <div className="small-circles green-circle"/>
                                    <span>Starting Layer</span>
                                </div>
                                <input className="text-input" type="text" id="startingLayer" name="startingLayer"
                                       value={startingLayer} onChange={(e) => {
                                    setStartingLayer(e.target.value ? parseInt(e.target.value) : 0)
                                }}/>
                            </div>
                            <div className="input-part">
                                <div className="input-title">
                                    <div className="small-circles red-circle"/>
                                    <span>Ending Slot</span>
                                </div>
                                <input className="text-input" type="text" id="endingSlot" name="endingSlot"
                                       value={endingSlot} onChange={(e) => {
                                    setEndingSlot(e.target.value ? parseInt(e.target.value) : 0)
                                }}/>
                                <div className="input-title">
                                    <div className="small-circles red-circle"/>
                                    <span>Ending Layer</span>
                                </div>
                                <input className="text-input" type="text" id="endingLayer" name="endingLayer"
                                       value={endingLayer} onChange={(e) => {
                                    setEndingLayer(e.target.value ? parseInt(e.target.value) : 0)
                                }}/>
                            </div>
                        </div>
                        <div className="add-coil-buttons">
                            <button className="cancel" onClick={() => {
                                dispatch(exitFunc())
                            }}>Cancel
                            </button>
                            <button className="add-coils" onClick={() => {
                                const newCoil = {
                                    [STARTING_SLOT]: startingSlot,
                                    [STARTING_LAYER]: startingLayer,
                                    [ENDING_SLOT]: endingSlot,
                                    [ENDING_LAYER]: endingLayer,
                                    [SEGMENT5_CONTROL_POINTS] : 7
                                }
                                dispatch(addCoilFunc({newCoil: newCoil}));
                                dispatch(exitFunc())
                            }}>Add Coils
                            </button>
                        </div>
                    </div>
                </StyledWrapper>
            </div>
        </>
    );
};

const StyledWrapper = styled.div`
    width: 25vw;
    font-family: Ubuntu-Light, serif;

    .add-coil-title {
        background: var(--clr-grey-1);
        height: 4vh;
        padding-right: 0.75vw;
        padding-left: 0.75vw;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: left;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;

        span {
            margin-left: 1vh;
            font-family: Ubuntu-Light, serif;
            font-size: var(--font-size-2);
            padding-bottom: 0.2vh;
        }

        .title-icon {
            width: 2vh;
            height: 2vh;
        }
    }

    .add-coil-body {
        background: var(--clr-grey-2);
        height: 17vh;
        padding: 1.5vh;
        text-align: left;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;

        span {
            font-family: Ubuntu-Light, serif;
            font-size: var(--font-size-4);
        }

        .add-coil-inputs {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .input-part {
                width: 48%;
                height: 13vh;

                input {
                    width: 96%;
                    height: 2.5vh;
                    outline: none;
                    margin-top: 1vh;
                    margin-bottom: 1vh;
                    background-color: var(--clr-grey-1);
                    border: none;
                    border-radius: 6px;
                    color: var(--clr-grey-9);
                    background: var(--clr-grey-1);
                    padding-left: 0.5vw;
                    font-family: Ubuntu-Light, serif;
                    font-size: var(--font-size-4);
                }

                .input-title {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    span {
                        margin-left: 0.5vw;
                    }
                }
            }
        }

        .small-circles {
            width: 1vh;
            height: 1vh;
            border-radius: 50%;
        }

        .green-circle {
            background: var(--clr-green);
        }

        .red-circle {
            background: var(--clr-pink-dark);
        }
    }


    .add-coil-buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        button {
            width: 48%;
            height: 3.5vh;
            border-radius: 8px;
            cursor: pointer;
            font-family: Ubuntu-Light, serif;
            font-size: var(--font-size-3);

            &:hover {
                font-family: Ubuntu-Bold, serif;
            }
        }

        .cancel {
            background: var(--clr-grey-2);
            color: var(--clr-grey-9);
            border: solid 1px var(--clr-grey-5);
        }

        .add-coils {
            background: var(--clr-primary);
            color: var(--clr-grey-9);
            border: none;
        }
    }
`


export default AddCoil;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './fonts/ubuntu/Ubuntu-Regular.ttf'
import App from './App';
// import store and provider
import {store} from './store';
import {Provider} from 'react-redux';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<>
    {/*we pass redux stores here*/}
    <Provider store={store}>
        <App/>
    </Provider>
</>,);
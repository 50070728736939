import styled from "styled-components";
import {useDispatch} from "react-redux";
import {changeGif} from "../../../features/guideLayoutSlice";

const TextInput = ({id, text, value, readOnly, gifFile, onChangeFunc, inputType, onEnterPressedFunc, unitText}) => {
    const dispatch = useDispatch();

    // this field used for integer and float input, we send type of input to the component
    // here we make sure input type is correct
    const handleInputChange = (e) => {
        const value = e.target.value.replace(/^0+(?=\d)/, "");
        if (!value) {
            onChangeFunc(0);
            return
        }
        let isValidFormat = false
        if (inputType === 'int') {
            isValidFormat = /^\d+$/.test(value);
        } else if (inputType === 'float') {
            isValidFormat = /^\d+(\.\d{0,2})?$/.test(value);
        }
        if (isValidFormat) {
            onChangeFunc(value);
        }
    };

    return (
        <StyledWrapper>
            <div className="single-text-input" onClick={() =>
                dispatch(changeGif({currentGif: gifFile, currentGifTitle: text}))}>
                <label htmlFor={id}>{text}</label><br/>
                {readOnly ?
                    <input className="auto-generate-input" type="text" id={id} name={id} value={value} readOnly/> :
                    <input className="text-input" type="text" id={id} name={id} value={value === 0.001 ? 0.00 : value}
                           onChange={(e) => handleInputChange(e)} onKeyDown={onEnterPressedFunc}/>}
                <small>{unitText}</small>
                <br/>
            </div>
        </StyledWrapper>
    );
};

const StyledWrapper = styled.div`
    .single-text-input {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: end;
        margin-bottom: 0.9vh;
    }

    small {
        color: var(--clr-grey-6);
        position: absolute;
        text-align: center;
        margin-right: 0.5vw;
        font-size: var(--font-size-5);
    }

    label {
        margin-right: 0.5vw;
        font-size: var(--font-size-5);
    }

    input {
        width: 10vw;
        height: 2.3vh;
        border: none;
        border-radius: 6px;
        color: var(--clr-grey-9);
        padding-left: 1vh;
        outline: none;
        font-size: var(--font-size-5)
    }


    .text-input {
        background-color: var(--clr-grey-2);
    }

    .text-input::after {
        position: absolute;
        top: 0;
        right: 0;
        content: " your text here";
        color: red;
    }

    .auto-generate-input {
        background-color: var(--clr-grey-4);
    }
`

export default TextInput;
